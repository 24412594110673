export enum TableName {
  Jobs = 'jobs',
  Cars = 'cars',
  Shuttles = 'shuttles',
  Trips = 'trips',
  Drivers = 'drivers',
  Users = 'users',
  DriverShifts = 'driverShifts',
  Shifts = 'shifts',
  Services = 'services',
  Holidays = 'holidays',
  Rates = 'rates',
  Locations = 'locations',
  Suppliers = 'suppliers',
  ExtraServices = 'extraservices',
  Profiles = 'profiles',
  Areas = 'areas',
  Fares = 'fares',
  Units = 'units',
  Attributes = 'attributes',
  History = 'history',
  Trans = 'trans',
  DriverReport = 'driverreports',
  ShuttleReport = 'shuttleJobView',
  HolidayReport = 'shuttleHolidayReport',
  VehicleReport = 'vehicle_report',
  HolidayTariffReport = 'shuttleHolidayTariffReport',
  Settings = 'settings',
  RateTypes = 'ratetypes',
  UniqueDriversReport = 'reportUniqueDriversPerDay',
  AssignPriority = 'assignPriorityGroups',
  Blacklist = 'blacklist',
}

// List of of table names existing in the DB as well

export type ServerTableNames =
  | TableName.Jobs
  | TableName.Cars
  | TableName.Shuttles
  | TableName.Trips
  | TableName.Drivers
  | TableName.Users
  | TableName.DriverShifts
  | TableName.Shifts
  | TableName.Services
  | TableName.Rates
  | TableName.Locations
  | TableName.Suppliers
  | TableName.ExtraServices
  | TableName.Profiles
  | TableName.Areas
  | TableName.Fares
  | TableName.Units
  | TableName.History
  | TableName.Trans
  | TableName.Settings
  | TableName.RateTypes
  | TableName.Attributes;
