const blacklist = {
  toast: {
    error: {
      badResponse: '[Lista Negra] resposta inválida do servidor',
      create: {},
      edit: {},
      duplicate: 'O cartão já existe',
    },
    success: {
      create: {
        body: 'O cartão foi adicionado com sucesso',
      },
      edit: {
        body: 'O Cartão Foi Atualizado com SUCESSO',
      },
      delete: {
        body: 'O cartão foi excluído com sucesso',
      },
    },
  },
  headerButtons: {
    create: 'Novo',
    delete: 'Remover',
  },
  table: {
    headers: {
      cardNumber: 'Número do cartão',
      site: 'Local',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Detalhes',
        fields: {
          cardNumber: 'Número do cartão',
        },
      },
      actions: {
        header: 'Ações',
        buttons: {
          delete: 'Remover',
        },
      },
    },
  },
};

export default blacklist;
