import React from 'react';

import MFormHeaderView from './MFormHeader.view';

interface Props {
  onBack?: () => void;
}

const MFormHeader: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MFormHeaderView onBack={props.onBack}>{props.children}</MFormHeaderView>;
};

MFormHeader.displayName = 'MFormHeader';
MFormHeader.defaultProps = {};

export default MFormHeader;
