import React from 'react';

const PandoraBot: React.FC = () => {
  return <div id="pb-widget"></div>;
};

PandoraBot.displayName = 'PandoraBot';
PandoraBot.defaultProps = {};

export default PandoraBot;
