const button = {
  text: {
    cancel: 'Cancelar',
    clearFilter: 'Limpar filtros',
    close: 'Fechar',
    create: 'Criar',
    edit: 'Editar',
    save: 'Editar',
  },
};

export default button;
