const passengers = {
  createNew: 'New',

  toast: {
    error: {
      badResponse: '[Passenger] bad server response',
      create: {},
      edit: {},
    },
    success: {
      resetSuccess: 'Reset Password email has been sent successfully.',
      create: {
        default: '[Passenger] Created',
      },
      edit: {
        default: '[Passenger] Updated',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Error Occurred',
      body: 'Passenger already exists!',
    },
    modalErrorRequest: {
      header: 'Error Occourd',
      body: 'Could Not Create Passenger!',
    },
  },

  edit: {
    modalRemoveConfirm: {
      header: 'Warning!',
      body: 'Are you sure you want to delete this passenger?',
      modalRemoveConfirmBtnConfirm: 'Delete',
    },
    formTabs: {
      informationTab: 'General Information',
      paymentTab: 'Payments',
      notesTab: 'Notes',
      historyTab: 'History',
    },
    activeStatus: {
      statusActive: 'Active',
      statusDeactivate: 'Inactive',
    },
    suspend: {
      actionActivate: 'Unsuspend',
      actionDeactivate: 'Suspend',
      suspendModal: {
        popupTitleDeactivation: 'Suspend Passenger',
        popupTitleActive: 'Suspend Reason',
        popupTitleUnsuspend: 'Unsuspend Reason',
        popupSubTitleDeactivation: 'Select the reason for the suspenssion',
        popupActionActive: 'Unsuspend Passenger',
        popupActionDeactivate: 'Suspend Passenger',
      },
    },
  },

  table: {
    headers: {
      name: 'Name',
      email: 'Email',
      created: 'Date created',
      id: 'Id',
      profiles: 'Profiles Attached',
      phone: 'Phone',
      createdBy: 'Created by',
      jobCount: 'Reservations',
    },
    passengersGettExtraHeaders: {
      home: 'Home',
      home2: 'Home2',
      work: 'Work',
      site: 'Site',
    },
    empty: 'Empty',
  },

  forms: {
    information: {
      profiles: {
        header: 'Profiles',
        fields: {
          profile: 'Profiles',
          site: 'Site',
        },
      },
      paymentTypes: {
        header: 'Payment Types',
        fields: {
          opusCard: 'OPUS Card',
          opusCardMensuel: 'Carte OPUS - Mensuel',
          opusCardPassage: 'Carte OPUS - Passage',
          cpoPassageSTL: 'Carte a puce occasionnelle (CPO-STL) - Passage',
          cpoPassage: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
        },
        actions: {
          save: 'Save',
        },
      },
      details: {
        header: 'Personal Details',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          phone: 'Phone',
          additionalPhone: 'Additional Phone',
          ada: 'Ada',
          code: 'Code',
        },
      },
      address: {
        header: 'Passenger Address',
        sharedValues: {
          manual: 'Manual address',
        },
        fields: {
          homeAddress: {
            label: 'Home Address',
          },
          homeAddress2: {
            label: 'Home Address 2',
          },
          officeAddress: {
            label: 'Office Address',
          },
          workAddress: {
            label: 'Work Address',
          },
        },
      },
      actions: {
        header: 'Actions',
        buttons: {
          delete: 'Delete',
          resetPassword: 'Reset Password',
        },
      },
      health: {
        header: 'Health Information',
        fields: {
          certificationDate: 'Certification Date',
          expirationDate: 'Expiration Date',
          disabilityAidTools: 'Disability Aid Tools',
        },
      },
      caregiverInfo: {
        header: 'Caregiver Information',
        fields: {
          caregiverFirstName: 'First Name',
          caregiverLastName: 'Last Name',
          caregiverEmail: 'Email',
          caregiverPhone: 'Phone',
        },
      },
    },
    jobHistory: {
      header: 'Reservation History',
      lastJob: 'Last Reservation: {{lastJob}}',
      firstJob: 'First Reservation: {{firstJob}}',
    },
    validations: {
      firstName: 'First Name is required',
      lastName: 'Last Name is required',
      profiles: 'Profiles is required',
      email: {
        required: 'Email is required',
        email: 'Invalid email address',
      },
      unit: {
        required: 'Site is required',
      },
    },
  },
};

export default passengers;
