import React from 'react';
import { OnChangeValue } from 'react-select';

import MAdderss from 'views/MAddress/MAddress';

import { MAddressSelectOption } from 'views/MAddress/MAddress.model';

interface Props {
  name: string;
  value: { label: string; value: string };
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  withLocations?: boolean;
  onChange: (value: OnChangeValue<MAddressSelectOption, false>) => void;
}

const MFormAddressView: React.FC<Props> = (props: Props) => {
  return (
    <MAdderss
      name={props.name}
      value={props.value}
      valid={props.valid}
      invalid={props.invalid}
      disabled={props.disabled}
      withLocations={props.withLocations}
      onChange={props.onChange}
    ></MAdderss>
  );
};

MFormAddressView.displayName = 'MFormAddressView';
MFormAddressView.defaultProps = {};

export default MFormAddressView;
