const driversOnline = {
  table: {
    headers: {
      name: 'Name',
      driverStatus: 'Status',
      email: 'Email',
      vehicle: 'Vehicle',
      phoneNumber: 'Phone number',
      address: 'Address',
    },
    driverStatusOptions: {
      online: 'Online',
      enRoute: 'En Route',
      break: 'Break',
      endBreak: 'End Break',
    },
    actions: {
      deactivate: 'Suspend',
    },
  },

  form: {
    fields: {
      driver: 'Driver',
      vehicle: 'Vehicle',
    },
    activateButton: 'Go Online',
  },

  deactivateModal: {
    header: 'Deactivate driver',
    body: 'Are you sure you want to deactivate {{driver}}?',
  },
  breakModal: {
    header: 'Break driver',
    body: 'Are you sure you want to release {{driver}} for a break?',
  },
};

export default driversOnline;
