const trips = {
  moreActions: {
    actions: 'More',
    sendTrip: 'Send Trip',
    delete: 'Delete',
    addNote: 'Add Note',
    showOnMap: 'Show On Map',
    sendMessage: 'Send Message',
    cancelTrip: 'Cancel Trip',
    export: 'Export',
    tripReports: 'Trip Reports',
  },

  tripInfo: {
    formTabs: {
      detailsTab: 'Details',
      historyTab: 'History',
    },
    toast: {
      toastSomethingWasWrong: 'Something was wrong',
      toastActionEndTrip: 'End trip available when all passengers are dropped',
    },
    headerBreakDown: 'Trip Breakdown',
    driverNotAssigned: 'Driver Not Assigned',
    vehicleNotAssigned: 'Vehicle Not Assigned',
    noDriver: 'No Driver',
    noCar: 'No Vehicle',
    noService: 'No Service',
    seats: 'Seats',
    originalStation: 'Original Station: ',
    newStation: 'New Station: ',
    moveStopHeader: 'You are about to move stop in trip: ',
    date: 'Date',
    pickupTime: 'Pickup time',
    eta: 'ETA',
    passengers: 'Passengers',
    stops: 'Stops',
    distance: 'Distance',
    miles: 'ml',
    kilometers: 'km',

    modalEndTrip: {
      body: 'Please mark all passengers as dropped-off',
      header: 'Warning!',
    },
    tripActions: {
      tripBreakdown: 'Trip Breakdown',
      merge: 'Merge trip',
      send: 'Send trip',
      note: 'Add note',
      history: 'History',
      msg: 'Send driver a message',
      start: {
        name: 'Start trip',
        title: 'Action available for status Approved',
      },
      end: {
        name: 'End trip',
        title: 'End trip available when all passengers are dropped',
      },
      cancel: 'Cancel trip',
      breakdown: 'Trip Breakdown',
    },
    stopActions: {
      queue: 'Add to queue',
    },
    passengerActions: {
      queue: 'Add to queue',
    },
    assignLog: {
      button: 'Assign Info',
      closeButton: 'Assign Log',
      table: {
        headers: {
          driver: 'Driver',
          vehicle: 'Vehicle',
          score: 'Score',
          flag: 'Flag',
          minutes: 'Minutes',
          radius: 'Radius',
          seatCount: 'Seat Count',
          totalTrips: 'Total Trips',
          totalPrice: 'Total Price',
        },
      },
    },
    breakdown: {
      table: {
        headers: {
          id: 'ID',
          planned: 'Planned time',
          date: 'Requested time',
          passenger: 'Passenger',
          type: 'Type',
          from: 'From',
          to: 'To',
          vin: 'VIN',
          make: 'Make',
          model: 'Modal',
          year: 'Year',
          weight: 'Weight',
          height: 'Height',
          width: 'Width',
          length: 'Length',
          price: 'Price',
        },
      },
    },
    tripsInfoPlans: {
      toastStatusChanged: 'Status changed',
      toastStopRemoved: 'Stop removed',
      toastTripRemoved: '[Trips] Successfully Removed',
      toastTripCanceled: '[Trips] Successfully Canceled',
      toastTripRemoveError: '[Trips] Could not Remove',
      toastClearSuccess: '[Trips] Drivers and Vehicles were cleared successfully',
      toastClearError: '[Trips] Drivers and Vehicles were cleared successfully',
      toastJobRemoved: 'Reservation removed',
      toastSomethingWasWrong: 'Something was wrong',
      btnRemoveStop: 'Remove',
      reservationActions: {
        btnPickUp: {
          name: 'Picked up',
          title: 'Avalable only for status in route',
        },
        btnDroppoff: {
          name: 'Dropped off',
          title: 'Avalable only for status in route',
        },
        btnNoShow: {
          name: 'No Show',
          title: 'Avalable only for status in route',
        },
        btnCancel: 'Cancel Reservation',
        btnRemove: 'Remove From Trip',
      },
      plannedTime: 'Planned time:  {{time}}',
    },
  },
  table: {
    statuses: {
      new: 'New',
      inRoute: 'In Route',
      sent: 'Sent',
      approved: 'Approved',
      pickingUp: 'Picking up',
      done: 'Done',
      canceled: 'Canceled',
    },

    footer: {
      totalSeats: 'Total Seats: {{seats}}',
    },

    filters: {
      students: 'Students',
    },

    headers: {
      id: 'ID',
      status: 'Status',
      date: 'Date',
      from: {
        templates: {
          default: 'From',
          schoolShuttles: 'From',
          trailers: 'Pickup Location',
        },
      },
      to: {
        templates: {
          default: 'To',
          schoolShuttles: 'To',
          trailers: 'Delivery Location',
        },
      },
      seats: {
        templates: {
          default: 'Seats',
          schoolShuttles: 'Seats',
          trailers: 'Item Quantity',
        },
      },
      profile: 'Profile',
      stops: 'Stops',
      distance: 'Distance',
      duration: 'Duration',
      service: 'Service',
      supplier: 'Supplier',
      driver: 'Driver',
      car: {
        templates: {
          default: 'Vehicle',
          schoolShuttles: 'Vehicle',
          trailers: 'Trailer',
        },
      },
      actions: 'Actions',
      attr: 'Attribute',
      externalId: 'ExternalID',
      waitUntil: 'Wait until',
      line: 'Line',
      passenger: {
        templates: {
          default: 'Passenger',
          schoolShuttles: 'Student',
          trailers: 'Recipient Name',
        },
      },
    },
    icons: {
      shared: 'Shared Trip',
      preAssigen: 'Manual Assignment',
    },
    cells: {
      id: {
        delay: 'The arrival at {{nextStop}} is delayed by {{delayTime}} minutes.',
        common: 'During the trip was identified delay'
      },

      address: {
        unknown: 'Unknown',
      },
      car: {
        templates:{
          default: 'No Vehicle',
          schoolShuttles: 'No Vehicle',
          trailers: 'No Trailer',
        },
      },
      driver: {
        none: 'No Driver',
      },
      supplier: {
        none: 'No Supplier',
      },
    },
    actions: {
      dropPassenger: {
        modal: {
          part1: 'Move ',
          part2: 'Reservation from ride  ',
          part3: 'to ride ',
        },
      },
      autoAssign: {
        modal: {
          header: 'Drivers and vehicles assign status',
          body: 'Total Drivers: {{carsUsed}}\nTotal Trips: {{trips}} - Assigned: {{match}}, Not Assigned: {{noMatch}}',
        },
        errorModal: {
          header: 'Error',
          body: 'All selected trips were already assigned. To re-assign drivers please clear the current assignment first.',
        },
      },
      deleteMultipleModal: {
        header: 'Delete Trips',
        body: 'You are about to delete {{num}} trips.',
      },
      deleteModal: {
        body: 'You are about to delete {{num}} trip.',
      },
      cancelModal: {
        body: 'You are about to cancel {{num}} trip.',
      },
      cancelMultipleModal: {
        header: 'Cancel Trips',
        body: 'You are about to cancel {{num}} trips.',
      },
      assign: {
        assign: 'Assign',
        clearDriversVehicle: 'Clear Drivers/Vehicles',
        advancedAssign: 'Advanced Assign',
        modalHeader: 'Assign Trips',
        youAreAboutToAssign: 'You are about to assign {{numberOfTrips}} trips.',
      },
      cancelReservation: 'You are about to cancel this reservation',
      report: {
        header: 'Route Summary',
        body: {
          totalTrips: 'Trips',
          totalCost: 'Cost',
          totalPrice: 'Price',
          totalJobs: 'Reservations',
          totalDuration: 'Duration',
          totalDistance: 'Distance',
          totalAssigned: 'Assigned',
          totalUnassigned: 'Unassigned',
          totalDrivers: 'Drivres',
          averageJobs: 'Avg. Reservations',
          averageDistance: 'Avg. Distance',
          averageDuration: 'Avg. Duration',
        },
      },
    },
  },
};

export default trips;
