import React from 'react';

import MFormFieldWrapView from './MFormFieldWrap.view';

interface Props {
  name?: string;
  displayName?: JSX.Element | string;
  className?: string;
  error?: string | null;
  action?: JSX.Element | null;
  errorClass?: string;
  onClickClear?: () => void;
  information?: string;
}

const MFormFieldWrap: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MFormFieldWrapView
      name={props.name}
      displayName={props.displayName}
      className={props.className}
      error={props.error}
      action={props.action}
      errorClass={props.errorClass}
      onClickClear={props.onClickClear}
      information={props.information}
    >
      {props.children}
    </MFormFieldWrapView>
  );
};

MFormFieldWrap.displayName = 'MFormFieldWrap';
MFormFieldWrap.defaultProps = {};

export default MFormFieldWrap;
