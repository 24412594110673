const users = {
  createNew: 'New',
  toast: {
    error: {
      badResponse: '[User] bad server response',
      create: {},
      edit: {
        change: 'Wrong old password',
      },
    },
    success: {
      delete: {
        default: '[User] removed',
      },
      create: {
        default: 'User has been created successfully.',
      },
      edit: {
        change: 'Password changed',
        default: 'User Updated.',
      },
      reset: {
        default: 'Reset Password email has been sent successfully.',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Error Occurred',
      modalAleadyExistBody: 'Email already exists. Please try a different email.',
    },
    modalErrorRequest: {
      header: 'Forbidden',
      body: 'You don`t have permissions to perform this action. Please contact an administrator.',
    },
    valdations: {
      firstName: 'First Name is required',
      lastName: 'Last Name is required',
      phone: 'Phone is required',
      email: {
        required: 'Email is required',
        email: 'Invalid email address',
      },
      role: {
        required: 'Role is required',
      },
      supplier: {
        required: 'Supplier is required',
      },
    },
  },

  edit: {
    modalRemoveConfirm: {
      header: 'Warning!',
      body: 'Are you sure you want to delete this user?',
      modalRemoveConfirmBtn: 'Delete',
    },
    formTabs: {
      informationTab: 'Information',
      notesTab: 'Notes',
      historyTab: 'History',
    },
    suspendButton: {
      actionActivate: 'Unsuspend',
      actionDeactivate: 'Suspend',
      suspendModal: {
        popupTitleActive: 'Suspend Reason',
        popupTitleDeactivation: 'Suspend User',
        popupTitleUnsuspend: 'Unsuspend Reason',
        popupSubTitleDeactivation: 'Select the reason for the suspenssion',
        popupActionActive: 'Unsuspend User',
        popupActionDeactivate: 'Suspend User',
      },
    },
    activeStatusButton: {
      statusActive: 'Active',
      statusDeactivate: 'Inactive',
      statusUnconfirmed: 'Unconfirmed',
    },
  },

  table: {
    headers: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      status: 'Status',
      role: 'Role',
    },
  },

  form: {
    selectOption: {
      select: 'Select',
    },
    information: {
      details: {
        header: 'Personal Details',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          name: 'Name',
          phone: 'Phone',
          email: 'Email',
          site: 'Site',
        },
      },
      roles: {
        header: 'Role',
        fields: {
          role: 'Role',
        },
      },
      actions: {
        header: 'Actions',
        buttons: {
          delete: 'Delete',
          resetPassword: 'Reset Password',
          changePassword: 'Change Password',
        },
      },
    },
    valdations: {
      firstName: 'First Name is required',
      lastName: 'Last Name is required',
      phone: 'Phone is required',
      supplier: 'Supplier is required',
      role: 'Role is required',
      email: {
        required: 'Email is required',
        email: 'Invalid email address',
      },
    },
  },

  userRoles: {
    superadmin: 'Super Admin',
    admin: 'Admin',
    supplier: 'Supplier',
    dispatchadmin: 'Dispatch Admin',
    dispatcher: 'Dispatcher',
    superagent: 'Super Agent',
    agent: 'Agent',
    accountingadmin: 'Accounting Admin',
    accountent: 'Accountent',
    driver: 'Driver',
    passenger: 'Passenger',
  },
};

export default users;
