import pages from './en/pages';
import views from './en/views';
import components from './en/components';
import common from './en/common';
import routes from './en/routes';

const en = {
  ...pages,
  views,
  components,
  common,
  routes,
};


export default en; 

