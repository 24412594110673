const imports = {
  mappingFields: 'Mapeamento de Campos',
  fieldsInDB: 'Campos no banco de dados',
  csvFields: 'Campo CSV',
  selectTheField: 'Selecione a Área',
  csvFile: 'Arquivo Csv',
  typeOfImport: 'Tipo de importação',
  selectTypeOfReports: 'Selecione os tipos de relatórios',
  default: 'Valor Padrão',
  uploadedSuccessfuly: 'Carregado com sucesso!',
  downloadExample: 'Exemplo de download',
  noCollection: 'Não há coleção com este nome',
  uploadMessage:
    'PEDIR - {{num}} itens, Isto pode demorar até 1 segundo por item.',
};

export default imports;
