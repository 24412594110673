import { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

const useCancelToken = (): React.Dispatch<React.SetStateAction<CancelTokenSource | null>> => {
  const [cancelTokenState, setCancelTokenState] = useState<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      cancelTokenState?.cancel();
    };
  }, [cancelTokenState]);

  return setCancelTokenState;
};

export default useCancelToken;
