const systemSettings = {
  headerButtons: {
    create: 'Novo',
    delete: 'Remover',
  },
  table: {
    headers: {
      unit: 'Local',
    },
    actions: {
      resetAll: 'Redefinir tudo',
      loadToCache: 'Carregar para o cache',
      save: 'Editar',
    },
  },
  form: {
    header: 'Detalhes',
    label: 'Legenda',
    description: 'Descrição',
    name: 'Nome',
    profiles: 'Perfis',
    type: 'Tipo',
    site: 'Local',
    value: 'Valor',
    category: 'Categoria',
    typeOptions: {
      String: 'Sequência',
      Boolean: 'Boleano',
      Object: 'Objeto',
      Array: 'Matriz',
      Integer: 'Inteiro',
      Range: 'Faixa',
      Float: 'Flutuador',
    },
    categoryOptions: {
      Misc: 'Outros',
      RoutingEngine: 'Motor de roteamento',
      RoutingConfig: 'Configuração de roteamento',
      RouteingAssign: 'Atribuição de Rota',
      Reservations: 'Reservas',
    },
    select: 'Selecione',
  },
  create: {
    confirmationModal: {
      header: 'sucesso!',
      body: 'Definição criada com êxito',
    },
    errorModal: {
      header: 'Erro!',
      body: 'Não foi possível criar configuração',
    },
  },
  update: {
    formTabs: {
      historyTab: 'Aba do histórico',
      editTab: 'Editar aba',
    },
    confirmationModal: {
      header: 'sucesso!',
      body: 'Configuração atualizada com sucesso',
    },
    errorModal: {
      header: 'Erro!',
      body: 'Não foi possível atualizar a configuração',
    },
  },
};

export default systemSettings;
