import { AxiosResponse } from 'axios';

import { IAddChildrensToParentResponse, ICreateParentResponse, IGetParentByIdResponse } from 'models/response';
import { IParentsCreateFormFields } from 'pages/Parents/ParentsForm/ParentsCreate/ParentsCreate.model';
import { prepareDataToServer } from 'pages/Parents/ParentsForm/ParentsForm.utils';
import { IParentsUpdateFormFields } from 'pages/Parents/ParentsForm/ParentsUpdate/ParentsUpdate.model';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { serverAxios } from 'utils/http';

class ParentHttp {
  private static route = '/';

  public async getById(id: string): Promise<IGetParentByIdResponse> {
    return serverAxios
      .post(
        ParentHttp.route,
        {
          act: 'find',
          col: 'users',
          query: {
            _id: id,
          },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetParentByIdResponse>) => {
        return response.data;
      });
  }

  public async create(values: IParentsUpdateFormFields): Promise<ICreateParentResponse> {
    return serverAxios
      .post(ParentHttp.route, {
        queries: [
          {
            act: 'insert',
            col: 'users',
            data: [prepareDataToServer(values)],
          },
          {
            act: 'passwordsendresettoken',
            email: values.email,
            mode: 'parent',
            customer: 'DC School Connect',
            name: `${values.firstName} ${values.lastName}`,
          },
        ],
      })
      .then((response: AxiosResponse<ICreateParentResponse>) => {
        return response.data;
      });
  }

  public async update(id: string, values: IParentsCreateFormFields): Promise<void> {
    serverAxios
      .post(
        ParentHttp.route,
        {
          act: 'update',
          col: 'users',
          query: { _id: id },
          data: [prepareDataToServer(values)],
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        console.log('success');
      });
  }

  public async addChildrens(parentEmail: string, childrenEmails: string[]): Promise<IAddChildrensToParentResponse> {
    return serverAxios
      .post(ParentHttp.route, {
        queries: childrenEmails.map((email: string) => {
          return {
            act: 'addparent',
            parent: parentEmail,
            child: email,
          };
        }),
      })
      .then((response: AxiosResponse<IAddChildrensToParentResponse>) => {
        return response.data;
      });
  }

  public async removehildrens(parentEmail: string, childrenEmails: string[]): Promise<IAddChildrensToParentResponse> {
    return serverAxios
      .post(ParentHttp.route, {
        queries: childrenEmails.map((email: string) => {
          return {
            act: 'delparent',
            parent: parentEmail,
            child: email,
          };
        }),
      })
      .then((response: AxiosResponse<IAddChildrensToParentResponse>) => {
        return response.data;
      });
  }
}

export default ParentHttp;
