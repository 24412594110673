const auth = {
  email: 'Endereço de e-mail',
  password: 'Senha',
  welcomeMessage: 'Ei, bom ver você',
  validations: {
    email: {
      required: 'E-mail é obrigatório',
      email: 'Endereço de e-mail inválido',
      emailReceived:
        'O endereço de e-mail que você recebeu do seu administrador',
    },
    password: {
      requeired: 'Campo senha vazio',
      password: 'Senha inválida',
    },
  },
  loginBtn: 'Entrar',
  authFailed: 'E-mail ou senha inválidos',
};

export default auth;
