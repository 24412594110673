import React, { CSSProperties, useEffect, useRef } from 'react';

import MCheckBoxView from './MCheckBox.view';

interface Props {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name?: string;
  value?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  title?: string;
  inderterminate?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MCheckBox: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = props.inputRef || inputRef;

  useEffect(() => {
    if (typeof props.inderterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !props.checked && props.inderterminate;
    }
  }, [props.inderterminate, ref]);

  return (
    <MCheckBoxView
      id={props.id}
      name={props.name}
      value={props.value}
      className={props.className}
      checked={props.checked}
      disabled={props.disabled}
      onChange={props.onChange}
    >
      {props.children}
    </MCheckBoxView>
  );
};

MCheckBox.displayName = 'MCheckBox';
MCheckBox.defaultProps = {};

export default MCheckBox;
