import React from 'react';
import { FormikContextType } from 'formik';

import ParentsFormGroupsView from './ParentsFormGroups.view';
import { IParentsFormGroupsFeilds } from './ParentsFormGroups.model';

type Props<Fields extends IParentsFormGroupsFeilds> = {
  form: FormikContextType<Fields>;
};

const ParentsFormGroups = <Fields extends IParentsFormGroupsFeilds>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  return <ParentsFormGroupsView form={props.form}></ParentsFormGroupsView>;
};

ParentsFormGroups.defaultProps = {};
ParentsFormGroups.displayName = 'ParentsFormGroups';

export default ParentsFormGroups;
