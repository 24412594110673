import React from 'react';

import MInput from 'views/MInput/MInput';

import { MFilterProps } from '../../Table.model';
import classes from './MInputFilter.module.scss';

interface Props<T extends { [key: string]: unknown }> extends MFilterProps<T> {
  updateChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const InputFilterView = <T extends { [key: string]: unknown }>(props: Props<T>): JSX.Element => {
  return (
    <MInput
      className={classes['container']}
      value={props.column.filterValue || ''}
      onChange={props.updateChange}
      placeholder={props.placeholder}
    />
  );
};

InputFilterView.displayName = 'InputFilterView';

export default InputFilterView;
