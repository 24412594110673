import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { ICreateAreasData, IDeleteAreasFilters, IUpdateAreasFilters, IUpdateAreasData, IGetAreasFilters } from 'models/http/area';
import { IGetAreasResponse } from 'models/response';
import { IArea } from 'models/area';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class AreaHttp {
  private static route = '/';

  public async get(filters: IGetAreasFilters, cancelTokenSource?: CancelTokenSource): Promise<IArea[]> {
    return serverAxios
      .post(
        AreaHttp.route,
        {
          act: 'find',
          col: 'areas',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetAreasResponse>) => {
        return response.data.data;
      });
  }

  public async create(data: ICreateAreasData): Promise<void> {
    return serverAxios
      .post(
        AreaHttp.route,
        {
          act: 'insert',
          col: 'areas',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateAreasFilters, data: IUpdateAreasData): Promise<void> {
    return serverAxios
      .post(
        AreaHttp.route,
        {
          act: 'update',
          col: 'areas',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteAreasFilters): Promise<void> {
    return serverAxios
      .post(
        AreaHttp.route,
        {
          act: 'delete',
          col: 'areas',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default AreaHttp;
