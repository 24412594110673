import assignBox from './assignBox';
import history from './history';
import map from './map';
import mTableAdvancedServer from './mTableAdvancedServer';

const index = {
  assignBox,
  history,
  map,
  mTableAdvancedServer,
};

export default index;
