import React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

import MTableExpandCheckHeaderView from './MTableExpandCheckHeader.view';

interface Props {
  disableSelection?: boolean;
  tableToggleAllRowsSelectedProps: (props?: Partial<TableToggleAllRowsSelectedProps>) => TableToggleAllRowsSelectedProps;
}

const MTableExpandCheckHeader: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MTableExpandCheckHeaderView
      tableToggleAllRowsSelectedProps={props.tableToggleAllRowsSelectedProps}
      disableSelection={props.disableSelection}
    >
      {props.children}
    </MTableExpandCheckHeaderView>
  );
};

MTableExpandCheckHeader.displayName = 'MTableExpandCheckHeader';
MTableExpandCheckHeader.defaultProps = {};

export default MTableExpandCheckHeader;
