import { ITripsTableData } from 'pages/Trips/TripsTable/TripsTable.model';

import * as actions from '../actions/trips';

const initialState: State = {
  selectedTrips: [],
  onQueueAssign: null,
  onQueueRemove: null,
};

export const reducer = (state: State = initialState, action: actions.TripsTypes): State => {
  switch (action.type) {
    case actions.TripsActions.SetSeleceted:
      return { ...state, selectedTrips: action.payload.trips };
    case actions.TripsActions.SetOnQueueRemove:
      return { ...state, onQueueRemove: action.payload.callback };
    case actions.TripsActions.SetOnQueueAssign:
      return { ...state, onQueueAssign: action.payload.callback };
    default:
      return state;
  }
};

export interface State {
  selectedTrips: ITripsTableData[];
  onQueueAssign: ((jobIds: string[]) => void) | null;
  onQueueRemove: ((jobIds: string[]) => void) | null;
}
