const units = {
  toast: {
    error: {
      badResponse: '[Unit] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    create: 'New',
    delete: {
      label: 'Delete',
      deleteModal: {
        header: 'Delete Units',
        body: 'You are about to delete {{units}} units',
      },
    },
  },

  create: {
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Update',
    },
  },

  edit: {
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Update',
    },
  },

  form: {
    information: {
      details: {
        header: 'Details',
        fields: {
          name: 'Name',
        },
      },
      info: {
        header: 'Information',
        fields: {
          name: 'Operation',
        },
      },
    },
    types: {
      Default: 'Default',
      School: 'School',
      Employees: 'Employees',
    },
    valdations: {
      name: {
        required: 'Name is required',
        name: 'Invalid Name',
      },
    },
  },
};

export default units;
