import React from 'react';

import MButton from 'views/Buttons/MButton/MButton';

import classes from './MFormFooter.module.scss';

interface Props {
  cancelText?: string;
  confirmText?: string;
  additionalActionText?: string;
  className?: string;
  disabled?: boolean;
  hideSubmitButton?: boolean;
  onCancel?: () => void;
  onAdditionalAction?: () => void;
}
const MFormFooterButtonsView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={classes['container']}>
      {props.onCancel ? (
        <MButton block color="danger" onClick={props.onCancel} className={classes['button']}>
          {props.cancelText}
        </MButton>
      ) : null}
      {props.onAdditionalAction ? (
        <MButton onClick={props.onAdditionalAction} disabled={props.disabled} block type="button" color="info" className={`${classes['button']} ${props.className}`}>
          {props.additionalActionText}
        </MButton>
      ) : null}
      {props.hideSubmitButton ? null :
        <MButton disabled={props.disabled} block type="submit" color="primary" className={`${classes['button']} ${props.className}`}>
          {props.confirmText}
        </MButton>
      }
    </div>
  );
};

MFormFooterButtonsView.displayName = 'MFormFooterButtonsView';
MFormFooterButtonsView.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

export default MFormFooterButtonsView;
