import React from 'react';

import classes from './MTooltip.module.scss';

interface Props {
  text: string | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}

const MTooltipView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={classes['container']} style={props.style}>
      <span className={props.className}>{props.text}</span>
      <span className={classes['container__text']}>{props.children}</span>
    </div>
  );
};

MTooltipView.displayName = 'MTooltipView';
MTooltipView.defaultProps = {};

export default MTooltipView;
