import { SystemTemplate } from 'utils/enums/system-template';
import { GuardData } from './guard.model';

const accessTemplateGuard = (pageTypeTemplate: SystemTemplate): ((guardData: GuardData) => boolean) => {
  return (guestGuard: GuardData) => {
    return pageTypeTemplate === guestGuard.systemTemplate;
  };
};

export default accessTemplateGuard;
