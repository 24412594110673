import React from 'react';
import moment from 'moment-timezone';

import MFormDateTimePickerView from './MFormDateTimePicker.view';

import classes from './MFormDateTimePicker.module.scss';
import { MFormFieldProps } from 'views/MForm/MForm.model';
import { DatePickerFormats } from 'utils/enums/time-format';

interface Props extends MFormFieldProps<[moment.Moment | null], moment.Moment | null> {
  disabled?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: DatePickerFormats;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  className?: string;
  timeIntervals?: number;
  showTimeSelectOnly?: boolean;
  showYearDropdown?: boolean;
}

const MFormDateTimePicker: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={classes['container']}>
      <MFormDateTimePickerView
        disabled={props.disabled}
        valid={props.valid}
        invalid={props.invalid}
        value={props.value?.isValid() ? props.value : null}
        showTimeSelect={props.showTimeSelect}
        dateFormat={props.dateFormat}
        minDate={props.minDate}
        maxDate={props.maxDate}
        className={props.className}
        timeIntervals={props.timeIntervals}
        showTimeSelectOnly={props.showTimeSelectOnly}
        onChange={props.onChange}
        showYearDropdown={props.showYearDropdown}
      ></MFormDateTimePickerView>
    </div>
  );
};

MFormDateTimePicker.displayName = 'MFormDateTimePicker';
MFormDateTimePicker.defaultProps = {};

export default MFormDateTimePicker;
