import React from 'react';
import { useTranslation } from 'react-i18next';
import Statuses from 'utils/enums/statuses';
import { MTableAdvancedFilterProps } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';

import ParentsTableFilterStatusView from './ParentsTableFilterStatus.view';

type Props = MTableAdvancedFilterProps<Statuses[]>;

const ParentsTableFilterStatus: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();


  const options = [
    { id: Statuses.Active, label: t('parents.table.statuses.active') },
    { id: Statuses.Inactive, label: t('parents.table.statuses.inactive') },
  ];

  return <ParentsTableFilterStatusView {...props} options={options}>
    {props.children}
  </ParentsTableFilterStatusView>;
};

ParentsTableFilterStatus.displayName = 'ParentsTableFilterStatus';
ParentsTableFilterStatus.defaultProps = {};

export default ParentsTableFilterStatus;
