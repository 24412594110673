import React, { useEffect, useRef, useState } from 'react';

import MDropdownContainerView from './MDropdownContainer.view';

import { IDropdownAction, IDropdownType } from '../MDropdown.model';

interface Props<Actions, Id extends string | number | null> {
  actions: IDropdownAction<Actions>[];
  type: IDropdownType;
  alignTo?: 'start' | 'end';
  children: (onClick: () => void) => JSX.Element;
  onSelect: (action: IDropdownAction<Actions, Id>, index: number) => void;
}

const MDropdownContainer = <Actions, Id extends string | number | null = null>(
  props: React.PropsWithChildren<Props<Actions, Id>>,
): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showState, setShowState] = useState<boolean>(false);

  useEffect(() => {
    if (showState && dropdownRef.current) {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && dropdownRef.current !== event.target && !dropdownRef.current.contains(event.target as HTMLElement)) {
          setShowState(() => false);
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [showState, dropdownRef, setShowState]);

  const onSelect = (action: IDropdownAction<Actions, Id>, index: number) => {
    if (!action.showAfterClick) {
      setShowState(() => false);
    }

    props.onSelect(action, index);
  };

  const clickHandler = () => {
    setShowState((prev: boolean) => !prev);
  };

  return (
    <MDropdownContainerView
      type={props.type}
      onSelect={onSelect}
      dropdownRef={dropdownRef}
      show={showState}
      actions={props.actions}
      alignTo={props.alignTo}
    >
      {props.children(clickHandler)}
    </MDropdownContainerView>
  );
};

MDropdownContainer.displayName = 'MDropdownContainer';
MDropdownContainer.defaultProps = {};

export default MDropdownContainer;
