import { MModalProps } from 'views/MModal/MModal.model';

import * as actions from '../actions/ui';

const initialState: State = {
  darkMode: true,
  asideShow: false,
  sidebarShow: 'HIDE',
  modal: null,
  pageName: '',
  loadingSpinner: false,
};

export const reducer = (state: State = initialState, action: actions.UITypes): State => {
  switch (action.type) {
    case actions.UIActions.SetTheme:
      return { ...state, darkMode: action.payload.type === 'DARK' };
    case actions.UIActions.SetAside:
      return { ...state, asideShow: action.payload.show };
    case actions.UIActions.SetSidebar:
      return { ...state, sidebarShow: action.payload.state };
    case actions.UIActions.ShowModal:
      return { ...state, modal: action.payload };
    case actions.UIActions.HideModal:
      return { ...state, modal: null };
    case actions.UIActions.SetPageName:
      return { ...state, pageName: action.payload.text };
    case actions.UIActions.ShowSpinner:
      return { ...state, loadingSpinner: action.payload.state };
    default:
      return state;
  }
};

export interface State {
  darkMode: boolean;
  asideShow: boolean;
  sidebarShow: 'MOBILE' | 'HIDE' | 'SHOW';
  modal: MModalProps | null;
  pageName: string;
  loadingSpinner: number | boolean;
}
