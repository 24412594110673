import React from 'react';


interface Props {
  viewSite: string | null;
  site: string | null;
}

const WithoutSiteView = (props: React.PropsWithChildren<Props>): JSX.Element | null => {
  return <React.Fragment> {props.site || props.viewSite ? null : props.children}</React.Fragment>;
};

WithoutSiteView.displayName = 'WithoutSiteView';

export default WithoutSiteView;
