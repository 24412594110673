import React from 'react';

import classes from './MCardBody.module.scss';

interface Props {
  className?: string;
}

const MCardBodyView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <div className={`${classes['container']} ${props.className || ''}`}>{props.children}</div>;
};

MCardBodyView.displayName = 'MCardBodyView';
MCardBodyView.defaultProps = {};

export default MCardBodyView;
