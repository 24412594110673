const shuttleReports = {
  actions: {
    export: 'Exportar',
    showTotals: 'Mostrar totais',
  },
  table: {
    headers: {
      tripId: 'Identificação de viagem:',
      routingType: 'Tipo de Linha',
      reservationId: 'Id de reserva',
      externalId: 'Identificador Externo',
      reservationStatus: 'Status da reserva',
      tripStatus: 'Status de Viagem',
      date: 'Data',
      dateType: 'Tipo de Data',
      passenger: 'Passageiro',
      email: 'E-mail',
      phone: 'Telefone',
      seats: 'Assentos',
      supplier: 'Fornecedor',
      shuttle: 'Transporte',
      line: 'Linha',
      from: 'De',
      to: 'Para',
      driver: 'Motorista',
      vehicle: 'Veículo',
      paymentType: 'Tipo de pagamento',
      paymentDetails: 'Detalhes de pagamento',
      profile: 'Perfil',
      shuttleArea: 'Área',
      dropOff: 'Devolução',
      area: 'Área',
      direction: 'Direção',
      price: 'Preço',
      payAmount: 'Quantidade',
      pickedTime: 'Tempo Real de Recolhimento',
      droppedTime: 'Tempo Real de Devolução',
      noshowTime: 'Tempo real de não comparência',
      shuttleFare: 'tarifa de transporte',
    },
    cells: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
    },
    filters: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
      tripStatus: {
        options: {
          new: 'Novo',
          inroute: 'Em rota',
          sent: 'Enviado',
          approved: 'Aprovado',
          pickingUp: 'Recolhimento',
          done: 'OK',
          cancelled: 'Cancelado',
        },
      },
      routingType: {
        options: {
          bus: 'Autocarro',
          demand: 'Demanda',
          dynamic: 'Dinâmico',
        },
      },
    },
    footer: {
      lastUpdated: 'Última Atualização: {{lastUpdated}}',
      noLastUpdate: 'Desconhecido',
    },
  },
  tableSummary: {
    title: 'Relatórios de transporte',
    headers: {
      trips: 'Viagens',
      onDemandShuttle: 'Transporte sob demanda',
      buses: 'ônibus',
      onDemandSeats: 'Lugares sob demanda',
    },
  },
};

export default shuttleReports;
