const extraServices = {
  toast: {
    error: {
      badResponse: '[Serviço extra] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Serviço extra',
      profileType: 'Tipo de Perfil',
      basePrice: 'Base',
      extraPrice: 'Cada Extra',
      baseUpTo: 'Baseie até',
      surcharge: 'Sobretaxa',
    },
  },
  create: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Serviço extra criado!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar um serviço extra',
    },
  },
  edit: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Serviço extra atualizado!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar o serviço extra',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Detalhes',
        fields: {
          name: 'Serviço extra',
          profileType: 'Tipo de Perfil',
          description: 'Descrição',
          profileTypeOptions: {
            retail: 'Varejo',
            individual: 'Pessoal',
            wholesale: 'Atacado',
            corporate: 'Corporativa',
            website: 'Web site',
            hotel: 'Hotel',
            thirdParty: 'Terceiros',
            tourOperator: 'Operador de Viagem',
            receiptiveOperator: 'Operador Receptivo',
          },
        },
      },
      settings: {
        header: 'Configurações',
        fields: {
          basePrice: 'Base',
          extraPrice: 'Cada Extra',
          baseUpTo: 'Baseie até',
          surcharge: 'Sobretaxa',
        },
      },
    },
  },
};

export default extraServices;
