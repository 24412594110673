import React from 'react';

import MRadio from 'views/MRadio/MRadio';

import { MRadioOption } from './MRadioGroup.model';
import classes from './MRadioGroup.module.scss';

interface Props {
  options: MRadioOption[];
  name: string;
  value: string;
  display?: 'col' | 'row';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MRadioGroupView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${classes['container']} ${props.display === 'col' ? classes['container--col'] : ''}`}>
      {props.options.map((option: MRadioOption) => {
        return (
          <div key={option.id} className={classes['item']}>
            <MRadio
              checked={option.id === props.value}
              value={option.id}
              id={option.id}
              name={props.name}
              onChange={props.onChange}
            ></MRadio>
            <label className={classes['item__text']} htmlFor={option.id}>
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

MRadioGroupView.displayName = 'MRadioGroupView';
MRadioGroupView.defaultProps = {};

export default MRadioGroupView;
