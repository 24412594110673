const drivers = {
  createNew: 'Novo',
  toast: {
    error: {
      badResponse: '[Motorista] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Nome',
      driverStatus: 'Status',
      email: 'E-mail',
      vehicle: 'Veículo',
      phoneNumber: 'Número de telefone',
      address: 'Endereço',
      firstTripDistance: 'Distância máxima da 1ª Viagem',
      action: 'Ação',
      attributes: 'Atributos',
    },
    statusOptions: {
      online: 'Online',
      offline: 'Off-line',
      break: 'Pausa',
      enRoute: 'Em rota',
    },
    viewOptions: {
      showSuspended: 'Mostrar suspenso',
      showUnsuspended: 'Mostrar Não suspenso',
    },
  },
  create: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Motorista criado!',
    },
    modalAleadyExist: {
      header: 'Ocorreu um Erro',
      body: 'O motorista já existe!',
    },
    modalErrorRequest: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar motorista!',
    },
  },
  edit: {
    formTabs: {
      informationTab: 'Informação',
      notesTab: 'Observações',
      historyTab: 'Histórico',
    },
    toast: {
      toastResetSuccess:
        'O e-mail de Redefinição de Senha foi enviado com sucesso.',
      toastError: 'Ocorreu um erro, por favor, tente novamente.',
      toastRemoveDriverSuccess: 'Motorista excluído com sucesso',
    },
    modalSuccess: {
      header: 'Resultado',
      body: 'Motorista atualizado!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar o motorista',
    },
    driverStatusOptions: {
      activate: 'Reativar',
      statusSuspended: 'Suspensa',
      deactivate: 'Compensar',
      statusOffline: 'Off-line',
      statusOnline: 'Online',
      statusEnRoute: 'Em rota',
      statusBreak: 'Pausa',
      popUpOptions: {
        popupTitleActive: 'Motivo da Suspensão',
        popupTitleDeactivation: 'Suspender motorista',
        popupTitleUnsuspend: 'Motivo do Recolhimento da Suspensão',
        popupSubTitleDeactivation: 'Selecione o motivo da suspensão',
        popupActionActive: 'Reativar Motorista',
        popupActionDeactivate: 'Suspender motorista',
      },
    },
    modalRemoveConfirm: {
      header: 'Aviso!',
      body: 'Você tem certeza que deseja excluir este motorista?',
      confirmBtn: 'Remover',
    },
  },
  deleteModal: {
    header: 'Excluir Motoristas',
    body: 'Você está prestes a excluir {{num}} motoristas.',
  },
  forms: {
    information: {
      personalDetails: {
        header: 'Detalhes pessoais',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          name: 'Nome de exibição',
          phone: 'Número de telefone',
          email: 'Endereço de e-mail',
          password: 'Senha',
        },
      },
      vehicles: {
        header: 'Veículos Atribuídos',
        fields: {
          available: 'Veículos disponíveis',
          current: 'Veículo atual',
        },
      },
      address: {
        header: 'Morada inicial',
        fields: {
          mainAddress: 'Morada principal',
          firstTripDistance: 'Distância máxima da 1ª Viagem',
          alt: 'Endereço Alternativo',
          firstTripDistanceType: {
            miles: 'Em milhas',
            km: 'Em KM',
          },
        },
      },
      settings: {
        header: 'Configurações adicionais',
        fields: {
          services: 'Serviços',
          areas: 'Áreas',
          externalId: 'Identificador Externo',
          perAreaMatch: 'Por Pontuação de Correspondência por área',
        },
        validation: {
          perAreaMatch: 'Não é possível inserir mais de 6 dígitos',
        },
        guide: {
          perAreaMatch: `0 = sem consideração,
            Pontuação entre 1 a 999998 Dará mais pontos ao motorista se todas as áreas da viagem corresponderem às áreas do motorista,
            999999 = O motorista só pode fazer viagens de suas áreas (todas as paradas de viagem, origem, destino, devem estar dentro das áreas)`,
        },
      },
      supplier: {
        header: 'Fornecedor',
        fields: {
          supplier: 'Fornecedor',
        },
      },
      attributes: {
        header: 'Atributos',
        itemHeader: 'Atributo de Pontuação <1>{{name}}</1> não utilizado',
      },
      buttons: {
        btnResetPassword: 'Redefinir Senha',
        btnDelete: 'Remover',
      },
    },
  },
};

export default drivers;
