import React from 'react';
import MMap from 'views/MMap/MMap';
import classes from './RoadBlocks.module.scss';
import { LatLng } from 'leaflet';
import { IMMapMarker } from 'views/MMap/MMap.model';
import MButton from 'views/Buttons/MButton/MButton';
import { useTranslation } from 'react-i18next';

type Props = {
  markers: IMMapMarker[];
  onClickCurrentLocation?: (latLng: LatLng) => void;
  onSave: () => void;
  saveDisabled: boolean;
};

const RoadBlocksView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className={classes['container']}>
      <MMap
        className={classes['map']}
        scrollWheelZoom
        searchControl
        markers={props.markers}
        onClickCurrentLocation={props.onClickCurrentLocation}
      ></MMap>
      <div className={classes['content']}>
        <div className={classes['actions']}>
          <MButton color="success" type="button" onClick={props.onSave}>
            {t('roadBlocks.btnSave')}
          </MButton>
        </div>
      </div>
    </div>
  );
};

RoadBlocksView.displayName = 'RoadBlocksView';
RoadBlocksView.defaultProps = {};

export default RoadBlocksView;
