import React, { useEffect, useState } from 'react';

import MFormPageView from './MFormPage.view';

interface Props {
  formJSX: JSX.Element | null;
  open: boolean;
  className?: string;
}

const MFormPage: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const [formJSXState, setFormJSXState] = useState<JSX.Element | null>(props.formJSX);
  const [openState, setOpenState] = useState<boolean>(false);

  useEffect(() => {
    setOpenState(props.open);
  }, [props.open]);

  useEffect(() => {
    setFormJSXState((prev: JSX.Element | null) => {
      if (prev && props.formJSX) {
        setFormJSXState(() => props.formJSX);
      }

      return prev;
    });
  }, [props.formJSX]);

  return (
    <MFormPageView
      className={props.className}
      formJSX={formJSXState}
      open={openState}
      onFormOpenStart={() => setFormJSXState(() => props.formJSX)}
      onFormCloseEnd={() => setFormJSXState(() => null)}
    >
      {props.children}
    </MFormPageView>
  );
};

MFormPage.displayName = 'MFormPage';
MFormPage.defaultProps = {};

export default MFormPage;
