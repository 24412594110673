const driverShifts = {
  toast: {
    error: {
      badResponse: '[Driver Shift] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    createBtn: 'Create',
    createMultiBtn: 'Create Multiple Shifts',
    deleteBtn: 'Delete',
  },

  table: {
    headers: {
      name: 'Name',
      vehicle: 'Vehicle',
      address: 'Address',
      firstTripDistance: '1st Trip Max Distance',
      addrLon: 'Lon',
      addrLat: 'Lat',
    },
  },

  create: {
    modalError: {
      header: 'Error Occourd',
      body: 'Could Not Create',
    },
    modalSuccess: {
      header: 'Success',
      body: 'Created!',
    },
  },

  edit: {
    modalError: {
      header: 'Error Occourd',
      body: 'Could Not Update',
    },
    modalSuccess: {
      header: 'Success',
      body: 'Update successfully',
    },
  },

  delete: {
    modalError: {
      header: 'Error Occourd',
      body: 'Could Not Update',
    },
    modalSuccess: {
      header: 'Success',
      body: ' - Shifts deleted successfully',
    },
  },

  modalRemoveConfirm: {
    header: 'Warning!',
    bodySingle: 'Are you sure you want to delete this shift?',
    bodyMultiple: 'Are you sure you want to delete these shifts?',
    confirmBtn: 'Delete',
  },

  form: {
    information: {
      details: {
        header: 'Driver Details',
        fields: {
          driver: 'Driver',
        },
      },
      shiftDetails: {
        header: 'Shift Details',
        fields: {
          fromDate: 'Start',
          toDate: 'End',
          suppliers: 'Supplier',
          address: 'Address',
          firstTripDistance: '1st Trip Max Distance',
          firstTripDistanceType: {
            miles: 'In Miles',
            km: 'In KM',
          },
          areas: 'Areas',
        },
      },
    },
    validations: {
      drivers: {
        required: 'Drivers is required',
        drivers: '',
      },
      driver: {
        required: 'Driver is required',
        driver: '',
      },
      start: {
        required: 'Start is required',
        start: '',
      },
      end: {
        required: 'End is required',
        end: '',
      },
    },
    select: 'Select',
    buttons: {
      addBreak: '+ Add Break',
    },
  },

  formMulti: {
    information: {
      details: {
        header: 'Driver Details',
        fields: {
          drivers: 'Drivers',
        },
      },
      shiftDetails: {
        header: 'Shift Details',
        fields: {
          fromDate: 'From Date',
          toDate: 'To Date',
          weeksDays: 'Week Days',
          fromHour: 'From Hour',
          tohour: 'To Hour',
          breakStart: 'Break Start',
          breakEnd: 'Break End',
        },
      },
    },
    validations: {
      drivers: {
        required: 'Drivers is required',
      },
      driver: {
        required: 'Driver is required',
      },
      start: {
        required: 'Start is required',
      },
      end: {
        required: 'End is required',
      },
    },
    select: 'Select',
  },
};

export default driverShifts;
