import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ParentsCreateView from './ParentsCreate.view';

import { IParentsCreateFormFields } from './ParentsCreate.model';
import MIziToast from 'views/MIziToast/MIziToast';
import { initialValues, validationSchema } from '../ParentsForm.utils';
import { useHttp } from 'utils/hooks';
import { ICreateParentResponse, IGetParentByIdResponse } from 'models/response';
import { useSelector } from 'react-redux';
import { AppState } from 'models/app-store';

type Props = {
  onBack: () => void;
  onSubmit: () => void;
};

const ParentsCreate: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const viewSite = useSelector((state: AppState) => state.sites.viewSite);
  const site = useSelector((state: AppState) => state.auth.user?.getSite() || null);
  const unit = site || viewSite?.name || '';
  const http = useHttp();

  const form = useFormik<IParentsCreateFormFields>({
    initialValues: {
      ...initialValues,
      site: unit,
    },
    validationSchema,
    onSubmit(values: IParentsCreateFormFields) {
      http.parent.create(values).then(async (response: ICreateParentResponse) => {
        const parent: IGetParentByIdResponse['data'][0] = (await http.parent.getById(response.results[0].insertedIds[0])).data[0];

        http.parent
          .addChildrens(parent.email, values.studentEmails)
          .then(() => {
            MIziToast.success(t('parents.form.create.toasts.success'));
            props.onSubmit();
          })
          .catch(() => {
            MIziToast.success(t('parents.form.create.toasts.error'));
          });
      });
    },
  });

  return <ParentsCreateView form={form} onBack={props.onBack}></ParentsCreateView>;
};

ParentsCreate.displayName = 'ParentsCreate';
ParentsCreate.defaultProps = {};

export default ParentsCreate;
