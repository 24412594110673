import React, { useMemo } from 'react';
import { ValueContainerProps } from 'react-select';
import { MReactSelectOption } from '../MSelect.model';

import MSelectValueContainerView from './MSelectValueContainer.view';

const MSelectValueContainer = <IsMulti extends boolean>(
  props: ValueContainerProps<MReactSelectOption, IsMulti>,
): JSX.Element => {
  const [placeholder, input] = props.children as [JSX.Element, JSX.Element];

  const value = useMemo(() => {
    const selected = props.getValue() || [];

    if (selected.length >= 1) {
      return selected.length <= 2 ? selected.map((s) => s.label).join(', ') : `${selected.length} items`;
    }

    return placeholder;
  }, [props.getValue(), placeholder]);

  return (
    <MSelectValueContainerView {...props}>
      {value}
      {input}
    </MSelectValueContainerView>
  );
};

MSelectValueContainer.displayName = 'MSelectValueContainer';
MSelectValueContainer.defaultProps = {};

export default MSelectValueContainer;
