import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RoadBlocksView from './RoadBlocks.view';
import L, { LatLng } from 'leaflet';
import { useHttp } from 'utils/hooks';
import useDebounceState from 'utils/hooks/use-debounce-state';
import axios, { CancelTokenSource } from 'axios';
import { Location } from 'views/MAddress/MAddress.model';
import ReactDOMServer from 'react-dom/server';
import classes from './RoadBlocks.module.scss';
import pin_icon from 'assets/icons/pinBlock.png';
import { IRoadBlock } from 'models/roadBlocks';
import MIziToast from 'views/MIziToast/MIziToast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'models/app-store';

const RoadBlocks: React.FC = () => {
  const http = useHttp();
  const { t } = useTranslation();

  const geoServer = useSelector((state: AppState) =>
    state.auth.settings.find((setting: AppState['auth']['settings'][number]) => setting.name === 'map route server'),
  );

  const [roadBlocksState, setRoadBlocksState] = useState<IRoadBlock[]>([]);
  const [, debounceLatLngState, setDebounceLatLngState] = useDebounceState<LatLng | null>(null, 200);
  const [deletedRoadBlockState, setDeletedRoadBlockState] = useState<IRoadBlock[]>([]);

  const updateLocation = useCallback(
    (latLng: LatLng) => {
      setDebounceLatLngState(latLng);
    },
    [setDebounceLatLngState],
  );

  const roadBlocksMarker = useMemo(() => {
    return roadBlocksState.map((roadBlock) => {
      return {
        icon: L.divIcon({
          iconSize: [30, 30],
          iconAnchor: [11, 30],
          className: classes['markerIcon'],
          html: ReactDOMServer.renderToStaticMarkup(
            <div className={classes['icon']}>
              <span className={classes['address']}>{roadBlock.name}</span>
              <img className={classes['pin']} src={pin_icon} alt="Block"></img>
            </div>,
          ),
        }),
        coord: {
          lat: roadBlock.lat,
          lng: roadBlock.lon,
        },
        eventHandlers: {
          click: () => {
            setRoadBlocksState((prev: IRoadBlock[]) => [
              ...prev.filter((selected: IRoadBlock) => selected.lat !== roadBlock.lat && selected.lon !== roadBlock.lon),
            ]);
            setDeletedRoadBlockState((prev: IRoadBlock[]) => [...prev, roadBlock]);
          },
        },
      };
    });
  }, [roadBlocksState, roadBlocksState]);

  const save = () => {
    const roadBlocks = roadBlocksState.reduce((acc: IRoadBlock[], roadBlock: IRoadBlock) => {
      if (!roadBlock.id) {
        return [...acc, roadBlock];
      }

      return [...acc];
    }, []);

    http.roadBlocks
      .createMany(roadBlocks)
      .then(() => {
        const ids = deletedRoadBlockState.reduce((acc: string[], roadBlock: IRoadBlock) => {
          if (roadBlock.id) {
            return [...acc, roadBlock.id];
          }

          return [...acc];
        }, []);

        http.roadBlocks.delete(ids).then(() => {
          http.roadBlocks.get().then((roadBlocks: IRoadBlock[]) => {
            setRoadBlocksState(() => roadBlocks);
          });

          http.map
            .getUpdateRoadBlocksOnMap()
            .then(() => {
              MIziToast.success(t('roadBlocks.toasts.map.success'));
            });

          MIziToast.success(t('roadBlocks.toasts.create.success'));
        });
      })
      .catch(() => MIziToast.error(t('roadBlocks.toasts.create.error')));
  };

  useEffect(() => {
    http.roadBlocks.get().then((roadBlocks: IRoadBlock[]) => {
      setRoadBlocksState(() => roadBlocks);
    });
  }, [http, setRoadBlocksState]);

  useEffect(() => {
    if (debounceLatLngState === null || !geoServer) {
      return;
    }

    let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

    http.map
      .getNearest(debounceLatLngState, geoServer.value, cancelTokenSource)
      .then((location: Location | null) => {
        setRoadBlocksState((prev: IRoadBlock[]) => {
          if (location === null) {
            return prev;
          }

          return [
            ...prev,
            {
              name: location.address,
              lat: location.lat,
              lon: location.lon,
            },
          ];
        });
      })
      .finally(() => {
        cancelTokenSource = null;
      });

    return cancelTokenSource?.cancel;
  }, [debounceLatLngState, geoServer]);

  return (
    <RoadBlocksView
      markers={roadBlocksMarker}
      onClickCurrentLocation={updateLocation}
      onSave={save}
      saveDisabled={roadBlocksState.length === 0}
    ></RoadBlocksView>
  );
};

RoadBlocks.displayName = 'RoadBlocks';
RoadBlocks.defaultProps = {};

export default RoadBlocks;
