const profiles = {
  toast: {
    error: {
      badResponse: '[Perfil] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Nome',
      status: 'Status',
      profileType: 'Tipo de Perfil',
      rateType: 'Tipo de Taxa',
    },
    actions: {
      create: 'Novo',
      delete: 'Remover',
    },
  },
  forms: {
    general: {
      header: 'Geral',
      fields: {
        profileType: {
          label: 'Tipo de Perfil',
          options: {
            retail: 'Varejo',
            individual: 'Pessoal',
            wholesale: 'Atacado',
            corporate: 'Corporativa',
            web: 'Web',
            hotel: 'Hotel',
            thirdParty: 'Terceiros',
            tourOperator: 'Operador de Viagem',
            receiptiveOperator: 'Operador Receptivo',
          },
        },
        rateType: {
          label: 'Tipo de Taxa',
          options: {
            connections: 'Conexões',
            corporate: 'Taxa Corporativa',
            expedia: 'Expedia',
            groundTransportation: 'Transporte Terrestre',
            holidayTaxis: 'Táxis de feriados',
            respectiveOperator: 'Respectiva Taxa do Operador',
            retail: 'Taxa real',
            tourOperator: 'Taxa de Operador de Viagem',
            viator: 'Viator',
          },
        },
        name: {
          label: 'Nome',
        },
        status: {
          label: 'Status',
          options: {
            active: 'Ativo',
            inactive: 'Inativa',
            onHold: 'Em espera',
          },
        },
        sharedWith: {
          label: 'Compartilhado com',
          options: {
            private: 'Privado',
          },
        },
        email: {
          label: 'Endereço de e-mail',
        },
        startDate: {
          label: 'Data de início',
        },
        phone: {
          label: 'Número de telefone',
        },
        additionalPhone: {
          label: 'Telefone adicional',
        },
        fax: {
          label: 'Número do fax',
        },
      },
    },
    billing: {
      header: 'Faturamento',
      fields: {
        name: {
          label: 'Nome para o faturamento',
        },
        email: {
          label: 'Endereço de e-mail',
        },
        phone: {
          label: 'Número de telefone',
        },
        address: {
          label: 'Endereço',
        },
        city: {
          label: 'Cidade',
        },
        state: {
          label: 'Estado',
        },
        zip: {
          label: 'CEP',
        },
        country: {
          label: 'País',
          options: {
            usa: 'EUA',
          },
        },
      },
    },
    notes: {
      header: 'Observações',
      fields: {
        general: {
          label: 'Geral',
        },
        driver: {
          label: 'Motorista',
        },
        dispatch: {
          label: 'Despachar',
        },
      },
    },
    complexNotes: {
      header: 'Observações',
      fields: {
        type: {
          label: 'Tipo de nota',
          options: {
            accident: 'Acidente',
            complaint: 'Reclamação',
            agentIssue: '(CQ) Problema do agente',
            carIssue: '(CQ) Problema do veículo',
            noShow: 'Não comparência',
            general: 'Nota geral',
            disaptch: 'Aviso ao despacho',
            driver: 'Nota para o motorista',
            agent: 'Nota do Agente',
            cancel: 'Nota de Cancelamento',
            booking: 'Nota de Reserva',
          },
        },
        value: {
          label: 'Conteúdo da observação',
        },
      },
      add: 'Adicionar observação',
    },
    jobPreferences: {
      header: 'Preferências de Reserva',
      fields: {
        emailSent: {
          label: 'E-mail enviado',
        },
        payment: {
          label: 'Pagamento',
          options: {
            cash: 'Numerário',
            creditCard: 'Cartão de crédito',
            direct: 'Contagem direta',
            voucher: 'Compensação',
          },
        },
        service: {
          label: 'Serviço',
        },
        extraServices: {
          label: 'Serviços extras',
          headers: {
            type: 'TIPO',
            value: 'VALOR',
            surcharge: 'SOBRETAXA',
            description: 'DESCRIÇÃO',
          },
        },
      },
    },
    jobHistory: {
      header: 'Histórico de reservas',
      lastJob: 'Última reserva: {{lastJob}}',
      firstJob: 'Primeira Reserva: {{firstJob}}',
    },
    contacts: {
      header: 'Contatos',
      fields: {
        name: {
          label: 'Nome do contato',
        },
        type: {
          label: 'Tipo de contato',
          options: {
            sales: 'Vendedor',
            employee: 'Empregado',
          },
        },
        email: {
          label: 'E-mail',
        },
        phone: {
          label: 'Número de telefone',
        },
        fax: {
          label: 'Número do fax',
        },
        address: {
          label: 'Endereço',
        },
        city: {
          label: 'Cidade',
        },
        state: {
          label: 'Estado',
        },
        zip: {
          label: 'CEP',
        },
        country: {
          label: 'País',
        },
        comment: {
          label: 'Comentário',
        },
      },
      add: 'Adicione contato',
    },
    rules: {
      header: 'Regras',
      fields: {
        discount: {
          label: 'Desconto',
          options: {
            none: 'Nenhum',
            fixed: 'Fixo',
            fixedPP: 'Fixo por passageiro',
            percent: 'Porcentagem',
            percentR: 'Porcentagem (arredondado)',
            percentRU: 'Porcentagem (arredondado para cima)',
          },
        },
        markup: {
          label: 'Remarcação',
          options: {
            none: 'Nenhum',
            fixed: 'Fixo',
            fixedPP: 'Fixo por passageiro',
            percent: 'Porcentagem',
            percentR: 'Porcentagem (arredondado)',
            percentRU: 'Porcentagem (arredondado para cima)',
          },
        },
      },
    },
    passengers: {
      header: 'Passageiros',
      found: '{{number}} passageiros encontrados',
      linkedTable: {
        title: 'Perfil da Lista de Passageiros',
        columns: {
          passenger: 'Passageiro',
          registerDate: 'Data de Criação',
          vip: 'VIP',
          registerWith: 'Criado em',
          reservations: 'Reservas',
        },
        actions: {
          clear: 'Limpar filtro',
          remove: 'Remover passageiro',
        },
      },
      nonLinkedTable: {
        title: 'Lista de Passageiros Não Conectados',
        columns: {
          passenger: 'Passageiro',
          registerDate: 'Data de criação',
          registerWith: 'Criar em',
          profilesLinked: 'Perfis associados',
          reservations: 'Reservas',
        },
        actions: {
          clear: 'Limpar filtro',
          add: 'Conectar passageiro',
        },
      },
    },
  },
  update: {
    success: {
      content: 'As suas alterações foram salvas com sucesso.',
    },
    error: {
      content: 'Não foi possível salvar as alterações.',
    },
  },
  create: {
    success: {
      content: 'Seu Perfil foi Criado.',
    },
    error: {
      content: 'Não foi possível criar o perfil.',
    },
  },
  delete: {
    deleteBtn: 'Remover',
    deleteModal: {
      header: 'Excluir perfil?',
      body: 'Você está prestes a excluir {{number}} perfis',
    },
  },
};

export default profiles;
