const resetPassword = {
  resetPasswordTitle: 'Redefina sua senha',
  createPasswordTitle: 'Crie sua senha',
  resetNotSuccess:
    'Algo correu mal. Por favor, tente novamente, ou entre em contato com michael@moovex.com',
  resetPasswordBtn: 'Prosseguir',
  passRequierments:
    'Sua senha deve ter no mínimo 8 caracteres, incluir no mínimo uma letra maiúscula, um caractere especial e um número.',
  resetPasswordHint: 'Por favor, digite uma nova senha.',
  resetSuccessful: 'Você alterou sua senha com sucesso',
  backToLogIn: 'Por favor, volte ao login e entre novamente',
  passProtect0: 'Campo senha vazio',
  passProtect1: 'A senha é muito curta',
  passProtect2: 'Esta senha não corresponde. Tente novamente.',
  passProtect3:
    'A senha deve conter no mínimo uma letra maiúscula, um caractere especial e um número',
  passProtect4: 'Por favor, confirme a nova senha',
  newPassword: 'Nova senha',
  reEnterPassword: 'Confirme sua senha',
};

export default resetPassword;
