import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Filters, FilterValue, IdType, Row, SortingRule } from 'react-table';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import MServerTableView from './MServerTable.view';

import { SessionStorageTablesKeys } from 'utils/enums/storage';
import { TableName } from 'utils/enums/table-page';
import { TimeFormat } from 'utils/enums/time-format';
import { Response } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { useTableCache, useTableCacheEffect } from 'utils/hooks';
import useCancelToken from 'utils/hooks/use-cancel-token-effect';
import useDateNavHandler from 'utils/hooks/use-date-nav-handler';
import useDebounceState from 'utils/hooks/use-debounce-state';
import { serverAxios } from 'utils/http';
import { AppState } from 'models/app-store';
import { BaseQuery, MColumnOptions, RowData, TableRef, TableSessionData } from '../Table.model';
import { MServerTableRef } from './MServerTable.model';
import { IDropdownAction } from 'views/MDropdown/MDropdown.model';
import { DEFAULT_ROWS_LIMIT } from './MServerTable.utils';

interface Props<T extends RowData> {
  columns: string[];
  generalFilterColumns?: Props<T>['columns'][number][];
  className?: string;
  baseQuery?: BaseQuery;
  columnsOptions?: MColumnOptions<T>;
  hideFooter?: boolean;
  generalFilterPlaceholder?: string;
  initialSort?: SortingRule<T>[];
  disableFilters?: boolean;
  disablePagination?: boolean;
  initialFilters?: Filters<T>;
  filters?: Filters<T>;
  headerButtons?: JSX.Element | JSX.Element[] | null;
  limit?: number;
  initialLimit?: number;
  name?: TableName;
  hideSelection?: boolean;
  page?: number;
  sortValue?: SortingRule<T>[];
  openExpandLabel?: string;
  closeExpandLabel?: string;
  hiddenColumns?: string[];
  showDateNavigator?: boolean;
  selectedRow?: Partial<T> | Partial<T>[];
  rowClassName?: string;
  hideColumnSelect?: boolean;
  footerDataView?: JSX.Element | JSX.Element[];
  disableCache?: boolean;
  cacheKey?: SessionStorageTablesKeys;
  showOnlyWithGeneralFilter?: boolean;
  dateNavigatorCell?: string;
  deleteButton?: boolean;
  disableDeleteBtn?: boolean;
  isMulty?: boolean;
  generalFilter?: string;
  onDeleteBtnClick?: () => void;
  onRowClick?: (row: Row<T>) => void;
  fetch?: (queryData: RequestBody, cancelToken: CancelTokenSource) => Promise<{ count: number; data: T[] }>;
  getRowId?: (row: T) => string;
  renderExpand?: (row: Row<T>) => JSX.Element;
  onFiltersChange?: (filters: Filters<T>, generalFilter: string) => void;
  onClearFilters?: (prevFilters: Filters<T>) => Filters<T> | void;
  selectedChange?: (items: string[], allResultsQuery: BaseQuery | false) => void;
}
interface Sort {
  [column: string]: -1 | 1;
}

const MServerTable = <T extends RowData>(
  props: React.PropsWithChildren<Props<T>>,
  ref: React.ForwardedRef<MServerTableRef<T>>,
): JSX.Element => {
  const tableRef = useRef<TableRef<T>>(null);
  const setFetchCancelTokenState = useCancelToken();
  const viewSite = useSelector((state: AppState) => state.sites.viewSite)?.name;

  const initialColumnOptions = useMemo(() => {
    if (props.columnsOptions) {
      return props.columns
        .filter((column: string) => !props.hiddenColumns?.includes(column))
        .map((column: string) => {
          return {
            value: true,
            title: column,
            label: (props.columnsOptions && props.columnsOptions[column]?.Header) || column[0].toUpperCase() + column.slice(1),
          };
        });
    }

    return [];
  }, [props.columnsOptions, JSON.stringify(props.columns), props.hiddenColumns]);

  const [limitState, setLimitState] = useState<number | null>(props.limit || null);
  const [pageState, setPageState] = useState<number | null>(null);
  const [sortState, setSortState] = useState<SortingRule<T>[] | null>(null);
  const [filtersState, debouncedFiltersState, setFiltersState] = useDebounceState<Filters<T> | null>(null, 250);
  const [generalFilterState, debouncedGeneralFilterState, setGeneralFilterState] = useDebounceState<string | null>(null, 250);
  const [dataState, setDataState] = useState<T[]>([]);
  const [allResultsState, setAllResultsState] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [pagesState, setPagesState] = useState<number>(1);
  const [countState, setCountState] = useState<number>(1);
  const [selectedState, setSelectedState] = useState<string[]>([]);
  const [filtersJSXState, setFiltersJSXState] = useState<{ id: IdType<T>; jsxFunc: () => React.ReactNode }[]>([]);
  const [columnItemsState, setColumnItemsState] = useState<IDropdownAction<boolean>[]>(initialColumnOptions);

  const initialTableDataReady =
    debouncedFiltersState !== null &&
    debouncedGeneralFilterState !== null &&
    sortState !== null &&
    pageState !== null &&
    limitState !== null;

  const prepareRequestBody = useCallback(
    (filters: Filters<T>, generalFilter: string, sortingRules: SortingRule<T>[], page: number, limit: number): RequestBody => {
      const queryFieldsFilters = filters.reduce<BaseQuery>((queryAcc: BaseQuery, filter: { id: IdType<T>; value: FilterValue }) => {
        // Free text
        if (typeof filter.value === 'string') {
          return {
            ...queryAcc,
            [filter.id]: {
              $regex: filter.value,
              $options: 'i',
            },
          };
        }

        // Array of values
        if (Array.isArray(filter.value)) {
          // Date Range
          if (filter.value.length === 2) {
            const dateQuery: { $gte?: string; $lte?: string } = {};

            if (moment.isMoment(filter.value[0])) {
              dateQuery.$gte = filter.value[0].format(TimeFormat.ServerFormatDateTime);
            }

            if (moment.isMoment(filter.value[1])) {
              dateQuery.$lte = filter.value[1].format(TimeFormat.ServerFormatDateTime);
            }

            if (Object.values(dateQuery).length > 0) {
              return {
                ...queryAcc,
                [filter.id]: dateQuery as { $gte: string; $lte: string },
              };
            }
          }

          // Array of values (but not date range)
          if (filter.value.length > 0) {
            return {
              ...queryAcc,
              [filter.id]: { $in: filter.value },
            };
          }

          return queryAcc;
        }

        // Date
        if (moment.isMoment(filter.value)) {
          return {
            ...queryAcc,
            [filter.id]: filter.value.format(TimeFormat.ServerFormatDateTime),
          };
        }

        return queryAcc;
      }, {});

      let queryFilters = queryFieldsFilters;

      // General search query
      if (props.generalFilterColumns && generalFilter.length > 0) {
        const uniqueColumnsNames = Array.from(new Set(props.generalFilterColumns));

        queryFilters = {
          ...queryFieldsFilters,
          $or: uniqueColumnsNames.map((column: string) => {
            return { [column]: { $regex: generalFilter, $options: 'i' } };
            // TO SUPORT NUMBERS:
            // return {
            //   $expr: {
            //     $regexMatch: {
            //       input: { $toString: `$${column}` },
            //       regex: generalFilter,
            //       options: 'i',
            //     },
            //   },
            // };
          }),
        };
      }

      if (viewSite) {
        queryFilters.unit = viewSite;
      }

      const sort: Sort = (sortingRules || []).reduce((lastSort: Sort, sortRule: SortingRule<T>) => {
        if (!sortRule.id) {
          return lastSort;
        }

        const newSort: Sort = {
          ...lastSort,
          [sortRule.id]: sortRule.desc ? -1 : 1,
        };

        return newSort;
      }, {});

      return {
        skip: Math.max((page || 1) - 1, 0) * limit,
        query: { ...props.baseQuery, ...queryFilters },
        sort,
        limit,
      };
    },
    [JSON.stringify(props.generalFilterColumns), JSON.stringify(props.columns), props.name, JSON.stringify(props.baseQuery), viewSite],
  );

  useEffect(() => {
    if (!props.generalFilter) {
      updateGeneralFilter(() => '');
    }
  }, [props.generalFilter]);

  const updateFilters = useCallback(
    (updater: (prevState: Filters<T> | null) => Filters<T>) => {
      setFiltersState((prev: Filters<T> | null) => {
        const newFilters = updater(prev);

        setPageState(() => 1);

        return newFilters;
      });
    },
    [setFiltersState, setPageState],
  );

  const updateGeneralFilter = useCallback(
    (updater: (prevState: string | null) => string) => {
      setGeneralFilterState((prev: string | null) => {
        const newGeneralFilter = updater(prev);

        setPageState(() => 1);

        return newGeneralFilter;
      });
    },
    [setGeneralFilterState],
  );

  const updateLimit = useCallback(
    (newLimit: number) => {
      const limitChange$ = new Promise<number | null>((resolve) => {
        setLimitState((prev: number | null) => {
          if (prev) {
            const limitRatio = prev / newLimit;

            resolve(limitRatio);
          }

          resolve(null);

          return newLimit;
        });
      });

      limitChange$.then((limitRatio: number | null) => {
        if (!limitRatio) {
          return;
        }

        setPageState((prevPage: number | null) => {
          if (!prevPage) {
            return prevPage;
          }

          return limitRatio < 1 ? Math.ceil(limitRatio * prevPage) : Math.ceil(limitRatio * (prevPage - 1)) + 1;
        });
      });
    },
    [setLimitState, setPageState],
  );

  const cacheTable = useTableCache(props.cacheKey || `table_${props.name}`);

  const updateDateNavigatorFilter = useDateNavHandler(props.dateNavigatorCell || 'date', filtersState || [], (filters: Filters<T>) => {
    updateFilters(() => filters);
  });

  const clearFilters = () => {
    updateGeneralFilter(() => '');
    updateFilters((prev: Filters<T> | null) => props.onClearFilters?.(prev || []) || []);
  };

  const fetchData = useCallback(
    (requestBodyData: RequestBody) => {
      setLoadingState(() => true);

      const defaultFetch = async (queryData: RequestBody, cancelToken: CancelTokenSource): Promise<{ count: number; data: T[] }> => {
        return serverAxios
          .post(
            '/',
            {
              act: 'find',
              col: props.name,
              count: true,
              ...queryData,
            },
            {
              cancelToken: cancelToken.token,
              timeout: HttpTimeoutPriority.Highest,
            },
          )
          .then((response: AxiosResponse<Response & { data: T[]; count: number }>): { count: number; data: T[] } => {
            const { data, count } = response.data;

            return { count, data };
          })
          .catch((error) => {
            console.error(error);

            return { count: 0, data: [] };
          });
      };

      const fetchFunc = props.fetch || defaultFetch;
      const cancelToken = axios.CancelToken.source();

      setFetchCancelTokenState(() => cancelToken);
      fetchFunc(requestBodyData, cancelToken)
        .then((body: { count: number; data: T[] }) => {
          setDataState(() => body.data);
          setPagesState(() => (body.count ? Math.ceil(body.count / (limitState || DEFAULT_ROWS_LIMIT)) : 0));
          setCountState(() => body.count);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingState(() => false);
          setFetchCancelTokenState(() => null);
        });
    },
    [setLoadingState, props.fetch, limitState],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        getData() {
          return dataState;
        },
        forceFetch: () => {
          if (
            debouncedFiltersState === null ||
            debouncedGeneralFilterState === null ||
            limitState === null ||
            sortState === null ||
            pageState === null
          ) {
            console.error('Can not fetch data when request body is not ready.');

            return;
          }

          return fetchData(prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState));
        },
      };
    },
    [
      setDataState,
      fetchData,
      prepareRequestBody,
      dataState,
      debouncedFiltersState,
      debouncedGeneralFilterState,
      sortState,
      pageState,
      limitState,
    ],
  );

  useTableCacheEffect(
    props.disableCache ? null : props.cacheKey || (`table_${props.name}` as SessionStorageTablesKeys),
    (data: TableSessionData<T> | null) => {
      if (!data) {
        updateFilters(() => props.initialFilters || []);
        updateGeneralFilter(() => '');
        setLimitState(() => props.initialLimit || props.limit || DEFAULT_ROWS_LIMIT);
        setSortState(() => props.initialSort || []);
        setPageState(() => 1);

        return;
      }

      updateFilters(() => data.filters);
      updateGeneralFilter(() => data.generalFilter || '');
      setLimitState(() => data.limit);
      setSortState(() => data.sort);
      setPageState(() => data.page);
    },
    [updateGeneralFilter, updateFilters, setLimitState, setSortState, setPageState],
  );

  useEffect(() => {
    const filters = props.filters;

    if (filters) {
      updateFilters(() => filters);
    }
  }, [props.filters, updateFilters]);

  // Prompt filter change to the parent component
  useEffect(() => {
    if (filtersState === null || generalFilterState === null) {
      return;
    }

    props.onFiltersChange?.(filtersState, generalFilterState);
  }, [filtersState, generalFilterState, props.onFiltersChange]);

  useEffect(() => {
    setPageState((prev: number | null) => props.page || prev);
  }, [props.page, setPagesState]);

  useEffect(() => {
    if (props.limit) {
      updateLimit(props.limit);
    }
  }, [props.limit, updateLimit]);

  useEffect(() => {
    if (
      props.disableCache ||
      filtersState === null ||
      generalFilterState === null ||
      limitState === null ||
      sortState === null ||
      pageState === null
    ) {
      return;
    }

    cacheTable({
      generalFilter: generalFilterState,
      sort: sortState,
      filters: filtersState,
      page: pageState,
      limit: limitState,
    });
  }, [cacheTable, props.disableCache, sortState, filtersState, pageState, limitState, generalFilterState]);

  useEffect(() => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      sortState === null ||
      pageState === null ||
      limitState === null
    ) {
      return;
    }

    fetchData(prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState));
  }, [fetchData, prepareRequestBody, debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState]);

  useEffect(() => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      sortState === null ||
      pageState === null ||
      limitState === null
    ) {
      return;
    }

    const query = allResultsState
      ? prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState).query
      : false;

    props.selectedChange?.(selectedState, query);
  }, [
    selectedState,
    allResultsState,
    prepareRequestBody,
    debouncedFiltersState,
    debouncedGeneralFilterState,
    sortState,
    pageState,
    limitState,
  ]);

  useEffect(() => {
    if (selectedState.length) {
      setAllResultsState(() => false);
    }
  }, [selectedState]);

  const handleColumnItemsClick = (_: IDropdownAction<boolean>, index: number) => {
    setColumnItemsState((prev: IDropdownAction<boolean>[]) => {
      const newOptions = [...prev];

      newOptions[index] = { ...newOptions[index], value: !newOptions[index].value };

      return newOptions;
    });
  };

  const hiddenColumns = useMemo(
    () => [
      ...columnItemsState.reduce((acc: string[], curr: IDropdownAction<boolean>) => (curr.value ? acc : [...acc, curr.title!]), []),
      ...(props.hiddenColumns || []),
    ],
    [columnItemsState],
  );

  useEffect(() => {
    setColumnItemsState(() => initialColumnOptions);
  }, [initialColumnOptions]);

  useEffect(() => {
    setLimitState(() => props.limit || DEFAULT_ROWS_LIMIT);
  }, [props.limit]);

  return (
    <MServerTableView
      className={props.className}
      rowClassName={props.rowClassName}
      tableRef={tableRef}
      limit={limitState || DEFAULT_ROWS_LIMIT}
      data={dataState}
      loading={loadingState || !initialTableDataReady}
      pages={pagesState}
      page={pageState || 1}
      allResults={allResultsState}
      columnItems={columnItemsState}
      count={countState}
      sort={sortState || []}
      disableFilters={props.disableFilters}
      showDateNavigator={props.showDateNavigator}
      disablePagination={props.disablePagination}
      headerButtons={props.headerButtons}
      columns={props.columns}
      columnsOptions={props.columnsOptions}
      hiddenColumns={hiddenColumns}
      generalFilterPlaceholder={props.generalFilterPlaceholder}
      showOnlyWithGeneralFilter={props.showOnlyWithGeneralFilter}
      openExpandLabel={props.openExpandLabel}
      closeExpandLabel={props.closeExpandLabel}
      hideGeneralFilter={!props.generalFilterColumns}
      hideFooter={props.hideFooter}
      filters={filtersState || []}
      hideColumnSelect={props.hideColumnSelect}
      onChangeColumnItem={handleColumnItemsClick}
      generalFilterValue={generalFilterState || ''}
      disableDeleteBtn={props.disableDeleteBtn}
      onDeleteBtnClick={props.onDeleteBtnClick}
      onGeneralFilterChange={(event: React.ChangeEvent<HTMLInputElement>) => updateGeneralFilter(() => event.target.value)}
      selectedRow={props.selectedRow}
      onRowClick={props.onRowClick}
      footerDataView={props.footerDataView}
      hideSelection={!props.selectedChange}
      onLimitChange={(event: React.ChangeEvent<HTMLSelectElement>) => updateLimit(Number(event.target.value))}
      onDateNavigatorClick={updateDateNavigatorFilter}
      onAllResultsChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllResultsState(() => event.target.checked)}
      onClearButtonClick={clearFilters}
      onSortChange={(sortValue: SortingRule<T>[]) => setSortState(() => sortValue)}
      onPageChange={(page: number) => setPageState(() => page)}
      onSelectChange={(selected: string[]) => setSelectedState(() => selected)}
      onFiltersChange={(value: Filters<T>) => updateFilters(() => value)}
      getRowId={props.getRowId}
      renderExpand={props.renderExpand}
      onFiltersRender={(filtersJSX: { id: IdType<T>; jsxFunc: () => React.ReactNode }[]) => setFiltersJSXState(() => filtersJSX)}
      filtersJSX={filtersJSXState}
      deleteButton={props.deleteButton}
    ></MServerTableView>
  );
};

MServerTable.displayName = 'MServerTable';

export interface RequestBody {
  skip: number;
  query: BaseQuery;
  sort: Record<string, number>;
  limit: number;
}

export default React.forwardRef(MServerTable) as <T extends RowData = RowData>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<T>>> & React.RefAttributes<MServerTableRef<T>>,
) => JSX.Element;
