import iZtoast from 'izitoast';

const toast = {
  error: (message?: string, title = 'Error'): void => {
    return iZtoast.error({
      title,
      message: message || '',
      position: 'bottomRight',
    });
  },
  success: (message?: string, title = 'Success'): void => {
    return iZtoast.success({
      title: title,
      message: message || '',
      position: 'bottomRight',
    });
  },
  custom: (message: string, title = 'Success', action: () => void): void => {
    iZtoast.success({
      title,
      message,
      timeout: 0,
      progressBarColor: 'rgb(0, 255, 184)',
      buttons: [
        [
          '<a style="background-color:transparent; border: none; color: blue">Open Duplicate Shuttle</a>',
          function (instance, toast) {
            action();
            instance.hide(
              {
                transitionOut: 'fadeOutUp',
              },
              toast,
            );
          },

          true,
        ],
      ],
    });
  },
};

export default toast;
