import React from 'react';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';

import MButton from 'views/Buttons/MButton/MButton';

import { RowData } from '../Table.model';
import classes from './ExpandCell.module.scss';

interface Props<T extends RowData> {
  row: Row<T>;
  openLabel: string;
  closeLabel: string;
}

const ExpandCell = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <span {...props.row.getToggleRowExpandedProps({ style: { paddingLeft: `${props.row.depth * 2}rem` } })}>
      <MButton color="primary" block className={classes['useTranslationButton']}>
        {props.row.isExpanded ? t(props.closeLabel) : t(props.openLabel)}
      </MButton>
    </span>
  );
};

ExpandCell.defaultProps = {
  openLabel: 'views.button.text.edit',
  closeLabel: 'views.button.text.close',
};

ExpandCell.displayName = 'ExpandCell';

export default ExpandCell;
