const notes = {
  title: 'Cargo',
  details: 'Detalhes',
  content: 'Conteúdo',
  showForm: 'Nova observação',
  to: {
    label: 'Para',
    validation: 'Para é requerido',
  },
  addNote: 'Adicionar',
  cancel: 'Cancelar',
  save: 'Editar',
  createdAt: 'Criado em:',
  createdBy: 'Criado por:',
  noteType: 'Tipo de observação',
  typeNewNote: 'Digite uma nova nota...',
  dropDownIcons: {
    pinToTop: 'Afixar no início',
    unpinFromTop: 'Desafixar do início',
    EditUpdate: 'Editar atualização',
    DeleteUpdate: 'Excluir atualização de todos',
  },
  edit: {
    toast: {
      error: {
        delete: 'Nota não removida',
        add: 'Observação não adicionada',
        update: 'A observação não foi atualizada',
        pin: 'A nota não foi alfinetada no início',
        unpin: 'A nota não foi desafixada do alto',
      },
    },
  },
};

export default notes;
