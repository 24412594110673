import React from 'react';

import MLoadingView from './MLoading.view';

interface Props {
  show: boolean;
  percent: number | null;
  header?: string;
}

const MLoading: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MLoadingView header={props.header} show={props.show} percent={props.percent}></MLoadingView>;
};

MLoading.displayName = 'MLoading';
MLoading.defaultProps = {};

export default MLoading;
