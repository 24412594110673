export enum Dashboard {
  View = 'view',
}

export enum Timeline {
  View = 'view',
}

export enum Jobs {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  ChangeProfile = 'changeProfile',
  AddNotes = 'addNotes',
  Cancel = 'cancel',
  Route = 'route',
  Export = 'export',
  Import = 'import',
  CreateQuick = 'createQuick',
  SetShifts = 'setShifts',
  SetSchoolShifts = 'setSchoolShifts',
}

export enum Trips {
  View = 'view',
  Create = 'create',
  Assign = 'assign',
  SendTrip = 'sendTrip',
  AddNote = 'addNote',
  SendMessage = 'sendMessage',
  ExportTrip = 'exportTrip',
  ImportTrip = 'importTrip',
  ChangeStatus = 'changeStatus',
  EditQueue = 'editQueue',
  CombineTrip = 'combineTrip',
}

export enum Drivers {
  View = 'view',
  AddNotes = 'addNotes',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum ActiveDrivers {
  View = 'view',
  ChangeStatus = 'changeStatus',
}

export enum Passengers {
  View = 'view',
  AddNotes = 'addNotes',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum Students {
  View = 'view',
  AddNotes = 'addNotes',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum Vehicles {
  View = 'view',
  AddNotes = 'addNotes',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum Blacklist {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum LiveMap {
  View = 'view',
}

export enum DriversShifts {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Reports {
  View = 'view',
  Export = 'export',
}

export enum Analytics {
  View = 'view',
  Export = 'export',
}

export enum Services {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Locations {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum AreasZonePricing {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
  ViewSpecificTypes = 'viewSpecificTypes',
}

export enum AreasRoutingZones {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
  ViewSpecificTypes = 'viewSpecificTypes',
}

export enum Rates {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
  AddNote = 'addNote',
}

export enum RateTypes {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum ExtraServices {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Suppliers {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Users {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  ChangeRole = 'changeRole',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum Profiles {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  ChangeStatus = 'changeStatus',
  Export = 'export',
  Import = 'import',
}

export enum History {
  View = 'view',
  Export = 'export',
}

export enum UploadData {
  Upload = 'upload',
}

export enum Settings {
  View = 'view',
}

export enum Parameters {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum MultiLanguage {
  View = 'view',
  Edit = 'edit',
}

export enum Shifts {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  Export = 'export',
  Import = 'import',
}

export enum DriverAssignment {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum DriversScheduler {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum Messages {
  View = 'view',
  Create = 'create',
}

export enum NotificationsEmail {
  View = 'view',
  Create = 'create',
}

export enum NotificationsSMS {
  View = 'view',
  Create = 'create',
}

export enum NotificationsPushNotifications {
  View = 'view',
  Create = 'create',
}

export enum Fares {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Accounting {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export enum ClearCity {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum HoldingList {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
}

export enum Support {
  View = 'view',
}

export enum JenniAI {
  Active = 'active',
}

export enum Bidding {
  Active = 'active',
}

export enum RouteOptimization {
  Active = 'active',
}

export enum DriverApp {
  Active = 'active',
}

export enum PassengerApp {
  Active = 'active',
}

export enum Shuttles {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  AddTime = 'addTime',
  AddStop = 'addStop',
}

export enum PassengerShuttlesWebApp {
  Active = 'active',
}

export enum PassengersWebPortal {
  Active = 'active',
}

export enum RoutingScheduler {
  Active = 'active',
}

export enum PredictiveETA {
  Active = 'active',
}

export enum PredictiveETANotifications {
  Active = 'active',
}

export enum WhiteLabelSolution {
  Active = 'active',
}

export enum ApiAccess {
  Active = 'active',
}

export enum TestPageAccess {
  Active = 'active',
}

export enum EditSites {
  Active = 'active',
}

export enum Attributes {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}
