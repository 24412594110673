const assignBox = {
  form: {
    generalForm: {
      header: 'Configurações avançadas de atribuição de motoristas',
      actions: {
        saveCheckBox: 'Salvar como padrão',
      },
      formBtns: {
        btnCancel: 'Cancelar',
        btnAssign: 'Atribuir',
      },
      inputAssignBy: 'Atribuir por',
      inputActualDrivers: 'Motoristas Reais',
      inputVirtualDrivers: 'Motoristas virtuais',
    },
    actualForm: {
      information: {
        inputOnlyDrivers: 'Apenas Motoristas com Turnos',
        inputMaxShiftsHours: 'Máximo de horas de turnos',
        defaultCarLocation: 'Localização Padrão de Carro',
        inputMaxPrice: 'Preço Máximo',
        inputFillVirtual: 'Preencher não atribuídos com motoristas virtuais',
        inputMaxTrips: 'Máximo de viagens',
        inputMinimizeDrivers: 'Minimizar motoristas',
        inputAssignFairly: 'Atribuir de forma equitativa',
      },
      validations: {
        onlyWithShifts: {
          require: 'Necessário',
        },
        maxShifts: {
          require: 'Necessário',
          min: 'Pelo menos 1',
          max: 'Máximo de 24',
        },
        carLocation: {
          require: 'Necessário',
        },
        fillVirtual: {
          require: 'Necessário',
        },
        maxTrips: {
          require: 'Necessário',
        },
        maxPrice: {
          require: 'Necessário',
        },
        default: {
          require: 'Necessário',
        },
      },
    },
    virtualForm: {
      information: {
        inputUpTo: 'Até',
        inputDrivers: 'Motoristas',
        inputMaxSeats: 'Máximo de lugares',
        inputMaxShiftsHours: 'Máximo de horas de turnos',
        defaultCarLocation: 'Localização Padrão de Carro',
        inputMinimizeDrivers: 'Minimizar motoristas',
        inputAssignFairly: 'Atribuir de forma equitativa',
      },
      validations: {
        limitDrivers: {
          require: 'Necessário',
        },
        carLocation: {
          require: 'Necessário',
        },
        maxShifts: {
          require: 'Necessário',
          min: 'Pelo menos 1',
          max: 'Máximo de 24',
        },
        default: {
          require: 'Necessário',
        },
      },
    },
  },
};

export default assignBox;
