const routes = {
  rates: 'Rates',
  viewSites: 'View Sites',
  sites: 'Sites',
  attributes: 'Attributes',
  suppliers: 'Suppliers',
  updateSupplier: 'Update Supplier',
  createSupplier: 'Create Supplier',
  shuttles: 'Shuttles',
  updateShuttle: 'Update Shuttle',
  createShuttle: 'Create Shuttles',
  fares: 'Fares',
  updateFare: 'Update Fare',
  createFare: 'Create Fare',
  services: 'Services',
  updateService: 'Update Service',
  createService: 'Create Service',
  users: 'Users',
  updateUser: 'Update User',
  createUser: 'Create User',
  vehicles: {
    templates: {
      default: 'Vehicles',
      schoolShuttles: 'Vehicles',
      employees: 'Vehicles',
      trailers: 'Trailers',
    },
  },
  updateVehicle: 'Update Vehicle',
  createVehicle: 'Create Vehicle',
  areas: 'Areas',
  updateArea: 'Update Area',
  createArea: 'Create Area',
  drivers: 'Drivers',
  updateDriver: 'Update Driver',
  createDriver: 'Create Driver',
  playground: 'Playground',
  support: 'Support',
  parameters: 'Parameters',
  createParameter: 'Create Parameter',
  billReportTitle: 'Bill Report',
  holidayReportTitle: 'Holiday Report',
  holidayTariffReportTitle: 'Holiday Tariff Report',
  analytics: 'Analytics',
  settings: 'Settings',
  logout: 'Logout',
  liveMap: 'Live Map',
  driverShifts: 'Driver Shifts',
  createDriverShift: 'Create a Single Driver Shift',
  createMultipleDriverShifts: 'Create Multiple Driver Shifts',
  passengers: 'Passengers',
  students: 'Students',
  employees: 'Employees',
  editShuttle: 'Edit Shuttle',
  updatePassenger: 'Edit Passenger',
  createPassenger: 'New Passenger',
  onlineDrivers: 'Online Drivers',
  trips: 'Trips',
  productionTrips: 'Production Trips',
  reservations: {
    templates: {
      default: 'Reservations',
      schoolShuttles: 'Bookings',
      employees: 'Bookings',
      trailers: 'Orders',
    },
  },
  editReservation: 'Edit Reservation',
  createReservation: 'Create Reservation',
  createQuick: 'Create Quick',
  timeline: 'Timeline',
  home: 'Home',
  import: 'Upload Data',
  reports: 'Reports',
  shuttleReports: 'Shuttle Reports',
  locations: 'Locations',
  createLocation: 'Create Location',
  editLocation: 'Update Location',
  profiles: 'Profiles',
  shifts: {
    templates: {
      default: 'Shifts',
      schoolShuttles: 'School Hours',
      employees: 'Work Hours',
      trailers: 'Shifts',
    },
  },
  createShift:{
    templates: {
      default: 'Create Shift',
      schoolShuttles: 'Create School Hours',
      employees: 'Create shift',
      trailers: 'Create Shift',
    },
  },
  updateShift:{
    templates: {
      default: 'Update Shift',
      schoolShuttles: 'Update School Hours',
      employees: 'Update Shift',
      trailers: 'Update Shift',
    },
  },
  agentHome: 'Agent',
  createExtraService: 'Crete Extra Service',
  updateExtraService: 'Update Extra Service',
  extraServices: 'Extra Services',
  tripsPaxReports: 'Trips / Passengers Summary',
  uniqueDriversReport: 'Unique Drivers Per Day',
  permissions: 'Roles and Permissions',
  createJob: 'Create Reservation',
  quickJob: 'Quick Reservation',
  holidays: 'Holidays',
  assignPriority: 'Assign Priority',
  parents: 'Parents',
  blacklist: 'Card Blacklist',
  roadBlocks: 'Road Blocks',
};

export default routes;
