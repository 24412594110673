const recurringInput = {
  repeatEvery: 'Repeat Every',
  repeatOn: 'Repeat On',
  ends: 'Ends',
  endsOn: 'Ends On',

  form: {
    month: 'Month',
    day: 'Day',
    week: 'Week',
    year: 'Year',
    occurrences: 'Occurrences',
    after: 'After',
    never: 'Never',
    on: 'On',
  },
};

export default recurringInput;
