const permissions = {
  actions: {
    save: {
      button: 'Editar',
      confirmModal: {
        title: 'Confirmação',
        content: 'Tem certeza de que deseja salvar as alterações?',
        confirm: 'Confirmar',
      },
      error: 'Ocorreu um erro ao atualizar as permissões.',
      success: 'Permissões atualizadas com sucesso!',
    },
    search: {
      placeholder: 'Pesquisar...',
    },
  },
  roleCard: {
    select: {
      all: 'Selecionar tudo',
      none: 'Desmarcar tudo',
    },
    count: '{{ count }} usuários',
  },
};

export default permissions;
