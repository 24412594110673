import React, { useEffect, useReducer } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import AppView from 'App.view';

import * as connectionsActions from 'store/actions/connections';
import * as settingsActions from 'store/actions/settings';
import { ISystemInfoResponse } from 'models/response';
import * as authActions from 'store/actions/auth';
import { serverAxios } from 'utils/http';
import { AppState } from './models/app-store';
import { IAppRoute, AppRouteType } from './App.model';
import { GuardData } from './routes/guards/guard.model';
import useRoutes, { Route } from './utils/hooks/use-routes';
import usePerm from 'utils/hooks/use-perm';
import useSystemTemplate from 'utils/hooks/use-system-template';

const App: React.FC = () => {
  const routes = useRoutes();
  const perm = usePerm();

  const dispatch = useDispatch();
  const store = useSelector((state: AppState) => state);
  const isAuthenticated = useSelector((state: AppState) => !!state.auth.user);
  const settings = useSelector((state: AppState) => state.auth.settings);
  const loadingSpinner = useSelector((state: AppState) => state.ui.loadingSpinner);
  const userEmail = useSelector((state: AppState) => state.auth.user?.getEmail() || null);
  const token = useSelector((state: AppState) => state.auth.token);
  const [socketAttemptState, incSocketAttemptState] = useReducer((counter: number) => counter++, 1);
  const systemTemplate = useSystemTemplate();

  useEffect(() => {
    dispatch(authActions.autoLogin());
  }, [dispatch]);

  useEffect(() => {
    serverAxios.post('/', { act: 'systeminfo' }).then((res: AxiosResponse<ISystemInfoResponse>) => {
      dispatch(settingsActions.setSystemSpecs(res.data.specs));
    });
  }, [dispatch]);

  // Add token to all the requests
  useEffect(() => {
    if (token) {
      const tokenInterceptor = serverAxios.interceptors.request.use((request: AxiosRequestConfig) => {
        if (request.method === 'post') {
          return { ...request, data: { ...request.data, token } };
        }

        return request;
      });

      return () => {
        serverAxios.interceptors.request.eject(tokenInterceptor);
      };
    }
  }, [token]);

  // Add logout when token invalid sent back from the server
  useEffect(() => {
    if (isAuthenticated) {
      const logoutInterceptor = serverAxios.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        (error) => {
          if (error?.response?.data.error && ['bad token', 'invalid token'].includes(error.response.data.error)) {
            dispatch(authActions.logout());
          }

          throw error;
        },
      );

      return () => {
        serverAxios.interceptors.response.eject(logoutInterceptor);
      };
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const socket: WebSocket = new WebSocket(
      process.env.NODE_ENV === 'development' ? 'ws://localhost:9999' : `wss://${window.location.hostname}/socket`,
    );

    socket.onopen = () => {
      dispatch(connectionsActions.setSocket(socket));
    };

    socket.onerror = () => {
      console.log('Socket error');
    };

    socket.onclose = () => {
      setTimeout(() => incSocketAttemptState(), 3000);
      console.log(`Socket Disconnected! Restarting attempts: ${socketAttemptState}`);
    };

    return () => {
      socket.close();
    };
  }, [socketAttemptState, incSocketAttemptState, dispatch]);

  useEffect(() => {
    const bot = settings.find((setting: { name: string; value: string }) => setting.name === 'bot');
    const $widget = document.querySelector<HTMLDivElement>('#pb-widget');

    if ($widget) {
      if (!bot || bot.value !== 'true') {
        $widget.style.display = 'none';
      } else {
        $widget.style.display = 'auto';
      }
    }
  }, [settings]);

  const prepareRouteList = (routes: Route[]) => {
    const guardData: GuardData = {
      store,
      perm,
      systemTemplate,
    };

    return routes.reduce((acc: IAppRoute[], route: Route) => {
      // TODO: Remove this with new server

      if (route.path === '/permissions' && userEmail !== 'kiki@kiki.com') {
        return acc;
      }

      if (route.guard && !route.guard.every((guard) => guard(guardData))) {
        if (route.fallback) {
          const contentRoute: IAppRoute = {
            type: AppRouteType.Fallback,
            path: route.path,
            to: route.fallback,
            children: route.children ? prepareRouteList(route.children) : null,
          };

          return [...acc, contentRoute];
        }

        return acc;
      }

      const component: React.FC | undefined = route['component'];
      const jsx: JSX.Element = route['jsx'];

      const contentRoute: IAppRoute = {
        type: AppRouteType.Page,
        path: route.path,
        pageName: route.name,
        component,
        jsx,
        children: route.children ? prepareRouteList(route.children) : null,
      };

      return [...acc, contentRoute];
    }, []);
  };

  return <AppView loadingSpinner={loadingSpinner} isAuthenticated={isAuthenticated} routesList={prepareRouteList(routes)}></AppView>;
};

App.displayName = 'App';

export default App;
