const driverReport = {
  driverReportTitle: 'Relatório do motorista',
  id: 'Identificação',
  driver: 'Motorista',
  filter: {
    driver: 'Motorista',
    car: 'Veículo',
    submit: 'Filtrar',
    export: 'Exportar',
    clear: 'Limpar filtro',
    validation: {
      startDateRequired: 'A data de início é obrigatória',
      endDateRequired: 'A data de término é obrigatória',
    },
  },
  table: {
    drivers: 'Motoristas',
    vehicles: 'Veículos',
    trips: 'Viagens',
  },
  vechile: 'Veículo',
  pax: 'Pessoal',
  cash: 'Numerário',
  start: 'Início',
  end: 'Fim',
  reservations: {
    reservations: 'Reservas',
    table: {
      from: 'De',
      to: 'Para',
      date: 'Data',
      passenger: 'Passageiro',
      reservation: 'Identificador de reserva',
      paymentType: 'Tipo de pagamento',
      pax: 'Pessoal',
      pickup: 'Recolhimento',
      drop_off: 'deixar',
      duration: 'Duração',
      car: 'Carro',
    },
  },
  form: {
    dateEnd: 'Data de fim',
    dateStart: 'Data de início',
  },
};

export default driverReport;
