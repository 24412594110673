import React from 'react';

import MSpinner from 'views/MSpinner/MSpinner';

import classes from './MLoading.module.scss';

interface Props {
  show: boolean;
  percent: number | null;
  header?: string;
}
const MLoadingView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return props.show ? (
    <div className={classes['container']}>
      <div className={classes['spinner']}>
        <MSpinner size="lg"></MSpinner>
        {props.percent === null ? null : (
          <React.Fragment>
            <br />
            <div className={classes['percent']}>
              {props.header} {props.percent || props.percent === 0 ? props.percent + '%' : ''}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  ) : null;
};

MLoadingView.displayName = 'MLoadingView';
MLoadingView.defaultProps = {};

export default MLoadingView;
