export * as ModuleActions from './actions';

export enum ModuleCodes {
  Dashboard = '010',
  Timeline = '015',
  Jobs = '100',
  Trips = '110',
  Drivers = '120',
  ActiveDrivers = '130',
  Passengers = '140',
  Vehicles = '150',
  LiveMap = '160',
  DriversShifts = '170',
  Reports = '180',
  Analytics = '190',
  Services = '200',
  Locations = '210',
  AreasZonePricing = '220',
  AreasRoutingZones = '230',
  Rates = '240',
  RateTypes = '250',
  ExtraServices = '260',
  Suppliers = '270',
  Users = '280',
  Profiles = '290',
  History = '300',
  UploadData = '310',
  Settings = '320',
  Parameters = '330',
  MultiLanguage = '340',
  Shifts = '350',
  DriverAssignment = '360',
  DriversScheduler = '370',
  Messages = '380',
  NotificationsEmail = '390',
  NotificationsSMS = '400',
  NotificationsPushNotifications = '410',
  Fares = '420',
  Accounting = '430',
  ClearCity = '440',
  HoldingList = '450',
  JenniAI = '470',
  Bidding = '480',
  RouteOptimization = '490',
  DriverApp = '500',
  PassengerApp = '510',
  Shuttles = '520',
  PassengerShuttlesWebApp = '530',
  PassengersWebPortal = '540',
  RoutingScheduler = '550',
  PredictiveETA = '560',
  PredictiveETANotifications = '570',
  WhiteLabelSolution = '580',
  ApiAccess = '590',
  TestPageAccess = '600',
  EditSite = '610',
  Attributes = '620',
  Parents = '630',
  Blacklist = '640',
}

export enum Roles {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Supplier = 'supplier',
  DispatcherAdmin = 'dispatchadmin',
  Dispatcher = 'dispatcher',
  SuperAgent = 'superagent',
  Agent = 'agent',
  AccountingAdmin = 'accountingadmin',
  Accountant = 'accountant',
  Driver = 'driver',
  Passenger = 'passenger',
}

export enum RoleIDs {
  SuperAdmin = '10',
  Admin = '20',
  Supplier = '30',
  DispatcherAdmin = '40',
  Dispatcher = '50',
  SuperAgent = '60',
  Agent = '70',
  AccountingAdmin = '80',
  Accountant = '90',
  Driver = '110',
  Passenger = '120',
}
