import pages from './pt/pages';
import views from './pt/views';
import components from './pt/components';
import common from './pt/common';
import routes from './pt/routes';

const pt = {
  ...pages,
  views,
  components,
  common,
  routes,
};


export default pt; 

