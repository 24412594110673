import React from 'react';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';

import MFormCard from 'views/MForm/MFormCard/MFormCard';

import classes from './ParentsFormPersonalDetails.module.scss';
import { IParentsFormPersonalDetailsFields } from './ParentsFormPersonalDetails.model';
import MFormFieldWrap from 'views/MForm/MFormFieldWrap/MFormFieldWrap';
import MFormInput from 'views/MForm/MFormFields/MFormInput/MFormInput';
import setFieldProps from 'utils/setFieldProps';
import MFormAddress from 'views/MForm/MFormFields/MFormAddress/MFormAddress';

type Props<Fields extends IParentsFormPersonalDetailsFields> = {
  form: FormikContextType<Fields>;
};

const ParentsFormGroupsView = <Fields extends IParentsFormPersonalDetailsFields>(
  props: React.PropsWithChildren<Props<Fields>>,
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MFormCard header={t('parents.form.sections.personalDetails.header')}>
      <div className={classes['fields']}>
        <MFormFieldWrap name="firstName" displayName={t('parents.form.sections.personalDetails.fields.firstName.label')}>
          <MFormInput {...setFieldProps(props.form, 'firstName')}></MFormInput>
        </MFormFieldWrap>

        <MFormFieldWrap name="lastName" displayName={t('parents.form.sections.personalDetails.fields.lastName.label')}>
          <MFormInput {...setFieldProps(props.form, 'lastName')}></MFormInput>
        </MFormFieldWrap>

        <MFormFieldWrap name="email" displayName={t('parents.form.sections.personalDetails.fields.email.label')}>
          <MFormInput {...setFieldProps(props.form, 'email')}></MFormInput>
        </MFormFieldWrap>

        <MFormFieldWrap name="phone" displayName={t('parents.form.sections.personalDetails.fields.phone.label')}>
          <MFormInput {...setFieldProps(props.form, 'phone')} autoComplete="tel"></MFormInput>
        </MFormFieldWrap>

        <MFormFieldWrap name="additionalPhone" displayName={t('parents.form.sections.personalDetails.fields.additionalPhone.label')}>
          <MFormInput {...setFieldProps(props.form, 'additionalPhone')} autoComplete="tel"></MFormInput>
        </MFormFieldWrap>

        <MFormFieldWrap name="address" displayName={t('parents.form.sections.personalDetails.fields.address.label')}>
          <MFormAddress {...setFieldProps(props.form, 'address')}></MFormAddress>
        </MFormFieldWrap>
      </div>
    </MFormCard>
  );
};

ParentsFormGroupsView.displayName = 'ParentsFormGroupsView';
ParentsFormGroupsView.defaultProps = {};

export default ParentsFormGroupsView;
