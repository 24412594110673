import React, { useCallback, useMemo, useState } from 'react';

import ParentsTableView from './ParentsTable.view';
import ParentsTableCellsName from './ParentsTableCells/ParentsTableCellsName/ParentsTableCellsName';

import { IParentsTableData, ParentsTableColumns } from './ParentsTable.model';
import { MTableAdvancedCellConfigParam, MTableAdvancedColumn } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import { MTableAdvancedServerRef } from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';
import { BaseQuery } from 'views/MTable/Table.model';
import { TimeFormat } from 'utils/enums/time-format';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ParentsTableCellsAddress from './ParentsTableCells/ParentsTableCellsAddress/ParentsTableCellsAddress';
import ParentsTableFilterStatus from './ParentsTableCells/ParentsTableFilterStatus/ParentsTableFilterStatus';

type Props = {
  tableRef: React.RefObject<MTableAdvancedServerRef>;
};

const ParentsTable: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const {t} = useTranslation();
 
  const [allResultsState, setAllResultsState] = useState<BaseQuery | null>(null);
  const [selectedRowsState, setSelectedRowsState] = useState<IParentsTableData['_id'][]>([]);

  const columns: MTableAdvancedColumn<IParentsTableData, ParentsTableColumns, keyof IParentsTableData | undefined>[] = useMemo(() => {
    return [
      {
        id: t('parents.table.header.name'),
        key: 'firstName',
        cell: ParentsTableCellsName,
      },
      {
        id: t('parents.table.header.email'),
        key: 'email',
      },
      {
        id: t('parents.table.header.phone'),
        key: 'phone',
      },
      {
        id: t('parents.table.header.address'),
        key: 'address',
        cell: ParentsTableCellsAddress,
      },
      {
        id: t('parents.table.header.status'),
        key: 'status',
        Filter: ParentsTableFilterStatus,
      },
      {
        id: t('parents.table.header.createdBy'),
        key: 'registerWith',
      },
      {
        id: t('parents.table.header.createdAt'),
        key: 'registerDate',
        cell: (row: MTableAdvancedCellConfigParam<IParentsTableData, 'registerDate'>) => {
          return moment(row.getValue(), TimeFormat.ServerFormatDateTime).format(TimeFormat.Date);
        },
      },
    ];
  }, []);

  const onSelectionChange = useCallback(
    (items: IParentsTableData['_id'][], allResultsQuery: BaseQuery | false) => {
      setSelectedRowsState(() => items);
      setAllResultsState(() => allResultsQuery || null);
    },
    [setSelectedRowsState, setAllResultsState],
  );

  return (
    <ParentsTableView
      numSelectedRows={allResultsState ? 'ALL' : selectedRowsState.length}
      selectedRows={selectedRowsState}
      columns={columns}
      tableRef={props.tableRef}
      onSelectionChange={onSelectionChange}
    ></ParentsTableView>
  );
};

ParentsTable.defaultProps = {};
ParentsTable.displayName = 'ParentsTable';

export default ParentsTable;
