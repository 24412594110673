import React from 'react';
import moment from 'moment-timezone';

import MDatetimePicker from 'views/MDatetimePicker/MDatetimePicker';

import classes from './MFormDateTimePicker.module.scss';
import { DatePickerFormats } from 'utils/enums/time-format';

interface Props {
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  value: moment.Moment | null;
  showTimeSelect?: boolean;
  dateFormat?: DatePickerFormats;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  className?: string;
  timeIntervals?: number;
  showTimeSelectOnly?: boolean;
  onChange: (value: moment.Moment | null) => void;
  showYearDropdown?: boolean;
}

const MFormDateTimePickerView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={classes['container']}>
      <MDatetimePicker
        disabled={props.disabled}
        valid={props.valid}
        invalid={props.invalid}
        value={props.value}
        showTimeSelect={props.showTimeSelect}
        dateFormat={props.dateFormat}
        minDate={props.minDate}
        maxDate={props.maxDate}
        className={props.className}
        timeIntervals={props.timeIntervals}
        showTimeSelectOnly={props.showTimeSelectOnly}
        showYearDropdown={props.showYearDropdown}
        onChange={props.onChange}
      ></MDatetimePicker>
    </div>
  );
};

MFormDateTimePickerView.displayName = 'MFormDateTimePickerView';
MFormDateTimePickerView.defaultProps = {};

export default MFormDateTimePickerView;
