const modalChangePassword = {
  header: 'Alterar senha',
  currentPassword: 'Senha atual',
  newPassword: 'Nova senha',
  confirmPassword: 'Confirmar senha',
  buttonSubmit: 'Confirmar',
  validation: {
    required: 'Campo senha vazio',
    leastCharacters: 'A senha deve ter no mínimo 6 caracteres',
    criteria: 'Deve conter caixa baixa e alta',
    mustMatch: 'As senhas devem corresponder',
    confirmRequired: 'Confirmar a senha é obrigatória',
  },
};

export default modalChangePassword;
