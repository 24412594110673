import React, { useRef } from 'react';

import MInputView from './MInput.view';

interface Props {
  innerRef?: React.RefObject<HTMLInputElement>;
  name?: string;
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  value?: string | number;
  type?: string;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  pattern?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  onFocus?: () => void;
  onPaste?: (value: React.ClipboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MInput: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const innerInputRef = useRef<HTMLInputElement>(null);
  const inputRef = props.innerRef || innerInputRef;

  return (
    <MInputView
      innerRef={inputRef}
      name={props.name}
      valid={props.valid}
      invalid={props.invalid}
      disabled={props.disabled}
      value={props.value}
      type={props.type}
      className={props.className}
      min={props.min}
      max={props.max}
      step={props.step}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete || 'off'}
      autoFocus={props.autoFocus}
      defaultValue={props.defaultValue}
      pattern={props.pattern}
      onInput={props.onInput}
      onFocus={props.onFocus}
      onPaste={props.onPaste}
      onBlur={props.onBlur}
      onChange={props.onChange}
    >
      {props.children}
    </MInputView>
  );
};

MInput.displayName = 'MInput';
MInput.defaultProps = {};

export default MInput;
