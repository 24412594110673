import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import * as uiActions from '../../store/actions/ui';

interface Props {
  pageName?: string;
}

const RouteConfig: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiActions.SetPageName(props.pageName || ''));
  }, [props.pageName, dispatch]);

  return null;
};

RouteConfig.displayName = 'RouteConfig';
RouteConfig.defaultProps = {};

export default RouteConfig;
