export enum ConnectionsActions {
  SetSocket = '[Connections] Set Socket',
}

// Interface
export interface SetSocket {
  type: ConnectionsActions.SetSocket;
  payload: { socket: WebSocket };
}

// Action creators

export const setSocket = (socket: WebSocket): SetSocket => {
  return {
    type: ConnectionsActions.SetSocket,
    payload: { socket },
  };
};

export type ConnectionsTypes =
   SetSocket;