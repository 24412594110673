import React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

import TableCheckbox from '../TableCheckbox/TableCheckbox';

interface Props {
  disableSelection?: boolean;
  tableToggleAllRowsSelectedProps: (props?: Partial<TableToggleAllRowsSelectedProps>) => TableToggleAllRowsSelectedProps;
}

const MTableExpandCheckHeaderView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <TableCheckbox {...props.tableToggleAllRowsSelectedProps()} disabled={props.disableSelection}></TableCheckbox>;
};

MTableExpandCheckHeaderView.displayName = 'MTableExpandCheckHeaderView';
MTableExpandCheckHeaderView.defaultProps = {};

export default MTableExpandCheckHeaderView;
