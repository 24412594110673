export const routeColors = ['#1E90FF', '#004AFF', '#18A957', '#44EE01', '#51E8F1', '#DE92EA', '#DF1642', '#EDF027', '#FF18C9', '#FFBB38'];

export function decodeValhallaPolyline(str: string): [number, number][] {
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates:[number, number][] = [];
  let shift = 0;
  let result = 0;
  let byte: number | null = null;
  let latitude_change = 0;
  let longitude_change = 0;
  const factor = Math.pow(10, 6);

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;
    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lat / factor, lng / factor]);
  }

  return coordinates;
}
