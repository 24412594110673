import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { ICreateVehiclesData, IGetVehicleFilters, IUpdateVehiclesData, IUpdateVehiclesFilters } from 'models/http/vehicle';
import { IGetVehiclesResponse } from 'models/response';
import { IVehicle } from 'models/vehicle';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class VehicleHttp {
  private static route = '/';

  public async get(filters: IGetVehicleFilters, cancelTokenSource?: CancelTokenSource): Promise<IVehicle[]> {
    return serverAxios
      .post(
        VehicleHttp.route,
        {
          act: 'find',
          col: 'cars',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetVehiclesResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource?: CancelTokenSource): Promise<IVehicle> {
    return serverAxios
      .post(
        VehicleHttp.route,
        {
          act: 'find',
          col: 'cars',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetVehiclesResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateVehiclesData): Promise<void> {
    return serverAxios
      .post(
        VehicleHttp.route,
        {
          act: 'insert',
          col: 'cars',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateVehiclesFilters, data: IUpdateVehiclesData): Promise<void> {
    return serverAxios
      .post(
        VehicleHttp.route,
        {
          act: 'update',
          col: 'cars',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default VehicleHttp;
