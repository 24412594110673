import React from 'react';

import MTooltipView from './MTooltip.view';

interface Props {
  text: string | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}

const MTooltip: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MTooltipView text={props.text} className={props.className} style={props.style}>
      {props.children}
    </MTooltipView>
  );
};

MTooltip.displayName = 'MTooltip';
MTooltip.defaultProps = {};

export default MTooltip;
