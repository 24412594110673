import { AxiosResponse, CancelTokenSource } from 'axios';

import { IGetSettingsResponseBase } from 'models/response';
import { IParameter } from 'models/setting';
import {
  IGetSettingsFilters,
  ICreateSettingsData,
  IUpdateSettingsFilters,
  IUpdateSettingsData,
  IDeleteSettingsFilters,
} from 'models/http/setting';

import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { serverAxios } from '../../http';

class SettingHttp {
  private static route = '/';

  public async get(filters: IGetSettingsFilters, cancelTokenSource?: CancelTokenSource): Promise<IParameter[]> {
    return serverAxios
      .post(
        SettingHttp.route,
        {
          act: 'find',
          col: 'settings',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetSettingsResponseBase>) => {
        return response.data.data;
      });
  }

  public async create(data: ICreateSettingsData): Promise<void> {
    return serverAxios
      .post(
        SettingHttp.route,
        {
          act: 'insert',
          col: 'settings',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateSettingsFilters, data: IUpdateSettingsData): Promise<void> {
    return serverAxios
      .post(
        SettingHttp.route,
        {
          act: 'update',
          col: 'settings',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteSettingsFilters): Promise<void> {
    return serverAxios
      .post(
        SettingHttp.route,
        {
          act: 'delete',
          col: 'settings',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default SettingHttp;
