const rates = {
  toast: {
    error: {
      badResponse: '[Rate] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createNew: 'New',
  total: 'Total: {{amount}}',
  additions: {
    newAddition: 'New Addition',
    form: {
      formButtons: {
        cancel: 'Cancel',
        addAddition: 'Add Addition',
      },
      fields: {
        name: {
          label: 'Name',
          validations: {
            required: 'Name is required',
          },
        },
        valueType: {
          label: 'Value Type',
          valueOptions: {
            price: 'Price',
            total: 'Total',
            percent: 'Percent',
          },
        },
        value: {
          label: 'Value',
          validations: {
            required: 'Value is required',
          },
        },
        startTime: {
          label: 'Start Time',
        },
        endTime: {
          label: 'End Time',
        },
        startDate: {
          label: 'Start Date',
        },
        endDate: {
          label: 'End Date',
        },
        weekDays: {
          label: 'Week Days',
        },
        priority: {
          label: 'Priority',
        },
      },
    },
  },

  create: {
    modalSuccess: {
      header: 'Success',
      body: 'Rate Created!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Create Rate',
    },
  },

  edit: {
    modalSuccess: {
      header: 'Success',
      body: 'Rate Updated!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Update Rate',
    },
  },

  form: {
    blocks: {
      details: 'Details',
      mileage: 'Add Mileage',
      conditions: 'Conditions',
      additions: 'Additions',
    },
    fields: {
      unit: {
        label: 'Site',
        validations: {
          required: 'Site is required',
        },
      },
      code: {
        label: 'Code',
        validations: {
          required: 'Code is required',
        },
      },
      from: {
        label: 'From',
        validations: {
          required: 'From is required',
        },
      },
      to: {
        label: 'To',
        validations: {
          required: 'To is required',
        },
      },
      biDir: {
        label: 'Bi Directional',
        validations: {},
      },
      service: {
        label: 'Service',
        validations: {
          required: 'Service is required',
        },
      },
      price: {
        label: 'Price',
        validations: {},
      },
      date1: {
        label: 'Start Date',
        validations: {},
      },
      date2: {
        label: 'End Date',
        validations: {},
      },
      hour1: {
        label: 'Start Time',
        validations: {},
      },
      hour2: {
        label: 'End Time',
        validations: {},
      },
      distanceFrom: {
        label: 'Distance From',
      },
      distanceTo: {
        label: 'Distance To',
      },
      weekDays: {
        label: 'Week Days',
      },
    },
  },
};

export default rates;
