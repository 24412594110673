import React from 'react';
import { CSSTransition } from 'react-transition-group';

import classes from './MFormPage.module.scss';

interface Props {
  className?: string;
  formJSX: JSX.Element | null;
  open: boolean;
  onFormOpenStart: () => void;
  onFormCloseEnd: () => void;
}

const MFormPageView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const formatedClassName = classes['container'] + (props.className ? ` ${props.className}` : '');

  return (
    <div className={formatedClassName}>
      <CSSTransition
        in={props.open}
        timeout={500}
        classNames={{ enterActive: classes['form--formOpen'], enterDone: classes['form--formOpen'] }}
        onEnter={props.onFormOpenStart}
        onExited={props.onFormCloseEnd}
        unmountOnExit
      >
        <div className={classes['form']}>{props.formJSX}</div>
      </CSSTransition>
      <div className={classes['content']}>{props.children}</div>
    </div>
  );
};

MFormPageView.displayName = 'MFormPageView';
MFormPageView.defaultProps = {};

export default MFormPageView;
