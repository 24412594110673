import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import classes from './MButton.module.scss';
import { ThemeColors } from 'models/general';

interface Props {
  className?: string;
  color?: ThemeColors;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  block?: boolean;
  to?: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MButtonView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const containerClasses = [classes['btn'], classes[`btn--${props.color}`]];

  if (props.block) {
    containerClasses.push(classes['btn--block']);
  }

  if (props.className) {
    containerClasses.push(props.className);
  }

  return props.to ? (
    <Link to={props.to}>
      <button style={props.style} className={containerClasses.join(' ')} type={props.type} onClick={props.onClick} disabled={props.disabled}>
        {props.children}
      </button>
    </Link>
  ) : (
    <button style={props.style} className={containerClasses.join(' ')} type={props.type} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
};

MButtonView.displayName = 'MButtonView';
MButtonView.defaultProps = {
  color: 'transparent',
};

export default MButtonView;
