import React from 'react';
import { FormikContextType } from 'formik';

import MFormContainer from 'views/MForm/MFormContainer/MFormContainer';
import MFormHeader from 'views/MForm/MFormHeader/MFormHeader';
import ParentsFormGroups from '../ParentsFormSections/ParentsFormGroups/ParentsFormGroups';
import ParentsFormPersonalDetails from '../ParentsFormSections/ParentsFormPersonalDetails/ParentsFormPersonalDetails';
import MFormFooter from 'views/MForm/MFormFooter/MFormFooter';

import { IParentsCreateFormFields } from './ParentsCreate.model';
import ParentsFormLinkStudent from '../ParentsFormSections/ParentsFormLinkStudent/ParentsFormLinkStudent';

type Props = {
  form: FormikContextType<IParentsCreateFormFields>;
  onBack: () => void;
};

const ParentsCreateView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MFormContainer formik={props.form} headerJSX={<MFormHeader onBack={props.onBack}></MFormHeader>}>
      <ParentsFormGroups form={props.form}></ParentsFormGroups>
      <ParentsFormPersonalDetails form={props.form}></ParentsFormPersonalDetails>
      <ParentsFormLinkStudent form={props.form}></ParentsFormLinkStudent>
      <MFormFooter></MFormFooter>
    </MFormContainer>
  );
};

ParentsCreateView.displayName = 'ParentsCreateView';
ParentsCreateView.defaultProps = {};

export default ParentsCreateView;
