const driverShifts = {
  toast: {
    error: {
      badResponse: 'resposta inválida do servidor [turno de motorista]',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    createBtn: 'Criar',
    createMultiBtn: 'Crie Turnos Múltiplos',
    deleteBtn: 'Remover',
  },
  table: {
    headers: {
      name: 'Nome',
      vehicle: 'Veículo',
      address: 'Endereço',
      firstTripDistance: 'Distância máxima da 1ª Viagem',
      addrLon: 'Long',
      addrLat: 'Latência',
    },
  },
  create: {
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar',
    },
    modalSuccess: {
      header: 'Resultado',
      body: 'Criado!',
    },
  },
  edit: {
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar',
    },
    modalSuccess: {
      header: 'Resultado',
      body: 'Atualização concluída',
    },
  },
  delete: {
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar',
    },
    modalSuccess: {
      header: 'Resultado',
      body: ' - Turnos excluídos com sucesso',
    },
  },
  modalRemoveConfirm: {
    header: 'Aviso!',
    bodySingle: 'Você tem certeza que deseja excluir este turno?',
    bodyMultiple: 'Você tem certeza que deseja excluir estes turnos?',
    confirmBtn: 'Remover',
  },
  form: {
    information: {
      details: {
        header: 'Detalhes do motorista',
        fields: {
          driver: 'Motorista',
        },
      },
      shiftDetails: {
        header: 'Detalhes do Turno',
        fields: {
          fromDate: 'Início',
          toDate: 'Fim',
          address: 'Endereço',
          firstTripDistance: 'Distância máxima da 1ª Viagem',
          firstTripDistanceType: {
            miles: 'Em milhas',
            km: 'Em KM',
          },
          areas: 'Áreas',
        },
      },
    },
    validations: {
      drivers: {
        required: 'Motoristas é necessário',
        drivers: '',
      },
      driver: {
        required: 'Requer-se motorista',
        driver: '',
      },
      start: {
        required: 'O início é necessário',
        start: '',
      },
      end: {
        required: 'Terminar é requerido',
        end: '',
      },
    },
    select: 'Selecione',
    buttons: {
      addBreak: '+ Adicionar Intervalo',
    },
  },
  formMulti: {
    information: {
      details: {
        header: 'Detalhes do motorista',
        fields: {
          drivers: 'Motoristas',
        },
      },
      shiftDetails: {
        header: 'Detalhes do Turno',
        fields: {
          fromDate: 'A partir da Data',
          toDate: 'Até à data',
          weeksDays: 'Dias da Semana',
          fromHour: 'De hora em hora',
          tohour: 'Para a hora',
          breakStart: 'Início do intervalo',
          breakEnd: 'Fim de intervalo',
        },
      },
    },
    validations: {
      drivers: {
        required: 'Motoristas é necessário',
      },
      driver: {
        required: 'Requer-se motorista',
      },
      start: {
        required: 'O início é necessário',
      },
      end: {
        required: 'Terminar é requerido',
      },
    },
    select: 'Selecione',
  },
};

export default driverShifts;
