import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Cell,
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';

import MTableView from './MTableAdvanced.view';

import { RowData } from '../Table.model';
import MCheckBox from 'views/MCheckBox/MCheckBox';
import {
  MTableAdvancedCellConfigParam,
  MTableAdvancedColumn,
  MTableAdvancedExpand,
  MTableAdvancedHeaderConfigParam,
  MTableAdvancedRowData,
  MTableAdvancedSorting,
} from './MTableAdvanced.model';
import { useTranslation } from 'react-i18next';
import MButton from 'views/Buttons/MButton/MButton';

interface Props<
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
> {
  tableRef?: React.MutableRefObject<Table<T>>;
  className?: string;
  rowClassName?: string;
  noResultsClassName?: string;
  columns: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>[];
  data: T[];
  selectedRow?: Partial<T> | null; // for single selecting row
  loading?: boolean;
  allowHeaderMaxWidth?: boolean;
  enableColumnResize?: boolean;
  hideSelection?: boolean;
  sorting?: MTableAdvancedSorting<T>;
  hiddenColumns?: Id[];
  expand?: MTableAdvancedExpand<T>;
  selectedRows?: RowIdValue[];
  onSortingChange?: (value: MTableAdvancedSorting<T>) => void;
  onSelectionChange?: (value: RowIdValue[]) => void;
  onRowClick?: (row: Row<T>) => void;
  getRowId?: (row: T) => RowIdValue;
}

const MTable = <
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
>(
    props: React.PropsWithChildren<Props<T, Id, RowIdValue>>,
    ref: React.ForwardedRef<Table<T>>,
  ): JSX.Element => {
  const { t } = useTranslation();

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({}); // TODO: check for better generic type
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [expandedState, setExpandedState] = React.useState<ExpandedState>({});

  const columnVisibility = useMemo(() => {
    return props.hiddenColumns?.reduce((acc: VisibilityState, column: keyof T) => {
      return { ...acc, [column]: false };
    }, {});
  }, [props.hiddenColumns]);

  const columns = useMemo(() => {
    const cols: ColumnDef<T, unknown>[] = props.columns.map((column: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>) => {
      return {
        id: column.id,
        accessorKey: column.key,
        header: column.header || column.id[0].toUpperCase() + column.id.slice(1),
        size: column.width || 150,
        cell: (item: MTableAdvancedCellConfigParam<T, (keyof T & string) | undefined>) =>
          column.cell?.(item as MTableAdvancedCellConfigParam<T, keyof T & string>) || item.row.original?.[column.key!] || '',
      } as ColumnDef<T, unknown>;
    });

    if (!props.hideSelection) {
      cols.unshift({
        id: 'select',
        size: 25,
        header: (item: MTableAdvancedHeaderConfigParam<T>) => {
          return (
            <MCheckBox
              checked={item.table.getIsAllRowsSelected()}
              inderterminate={item.table.getIsSomeRowsSelected()}
              onChange={item.table.getToggleAllRowsSelectedHandler()}
            ></MCheckBox>
          );
        },
        cell: (item: MTableAdvancedCellConfigParam<T>) => {
          return (
            <MCheckBox
              checked={item.row.getIsSelected()}
              inderterminate={item.row.getIsSomeSelected()}
              onChange={item.row.getToggleSelectedHandler()}
            ></MCheckBox>
          );
        },
      });
    }

    const expand = props.expand;

    if (expand) {
      cols.push({
        id: 'expendButton',
        size: 30, // TODO: as above
        header: t('settings.parameters.actions'), // TODO: better translation source
        cell: (item: MTableAdvancedCellConfigParam<T>) => {
          item.row.getCanExpand() ? (
            <MButton onClick={item.row.getToggleExpandedHandler()}>
              {item.row.getIsExpanded() ? expand.button.openLabel : expand.button.closeLabel}
            </MButton>
          ) : null;
        },
      });
    }

    return cols;
  }, [props.columns, props.hideSelection]);

  const table = useReactTable<T>({
    columns,
    data: props.data,
    state: {
      rowSelection: rowSelectionState,
      sorting: sortingState,
      columnVisibility,
      expanded: expandedState,
    },
    columnResizeMode: props.enableColumnResize ? 'onChange' : undefined,
    onExpandedChange: setExpandedState,
    onRowSelectionChange: (updater: RowSelectionState | ((prev: RowSelectionState) => RowSelectionState)) => {
      setRowSelectionState((prev: RowSelectionState) => {
        const value = typeof updater === 'object' ? updater : updater(prev);

        props.onSelectionChange?.(Object.keys(value) as RowIdValue[]);

        return value;
      });
    },
    onSortingChange: (updater: SortingState | ((prev: SortingState) => SortingState)) => {
      setSortingState((prev: SortingState) => {
        const value = Array.isArray(updater) ? updater : updater(prev);
        const singleSortValue = value.slice(0, 1); // Only one sorting value at a time

        props.onSortingChange?.(
          singleSortValue[0] ? { id: singleSortValue[0].id, direction: singleSortValue[0].desc ? 'desc' : 'asc' } : false,
        );

        return singleSortValue;
      });
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getRowId: props.getRowId,
    getExpandedRowModel: getExpandedRowModel(),
  });

  useImperativeHandle(ref, () => table, [table]);

  const isRowSelected = (row: Row<T>): boolean => {
    const selectedRow = props.selectedRow;

    return (
      !!selectedRow &&
      Object.keys(selectedRow).every((key: keyof T) =>
        row.getAllCells().some((cell: Cell<T, unknown>) => cell.id === key && cell.getValue() === selectedRow[key]),
      )
    );
  };

  useEffect(() => {
    if (typeof props.sorting === 'undefined') {
      return;
    }

    setSortingState(() => (props.sorting ? [{ id: props.sorting.id, desc: props.sorting.direction === 'desc' }] : []));
  }, [props.sorting]);

  useEffect(() => {
    const selected = props.selectedRows;

    if (!selected) {
      return;
    }

    setRowSelectionState(() => {
      return selected.reduce((acc: RowSelectionState, item: RowIdValue) => {
        return { ...acc, [item]: true };
      }, {});
    });
  }, [props.selectedRows?.join(','), setRowSelectionState]);

  return (
    <MTableView
      className={props.className}
      rowClassName={props.rowClassName}
      noResultsClassName={props.noResultsClassName}
      table={table}
      loading={props.loading}
      isRowSelected={isRowSelected}
      onRowClick={props.onRowClick}
      allowHeaderMaxWidth={props.allowHeaderMaxWidth}
    ></MTableView>
  );
};

MTable.displayName = 'MTable';

export default React.forwardRef(MTable) as <
  T extends RowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<T, Id, RowIdValue>>> & React.RefAttributes<Table<T>>,
) => JSX.Element;
