import { SelectComponents } from 'react-select/dist/declarations/src/components';
import React, { useMemo } from 'react';
import { components, SelectComponentsConfig, StylesConfig, OnChangeValue, SingleValue, MultiValue, GroupBase } from 'react-select';
import { useSelector } from 'react-redux';

import MSelectValueContainer from './MSelectValueContainer/MSelectValueContainer';

import classes from './MSelect.module.scss';
import { AppState } from '../../models/app-store';
import MSelectView from './MSelect.view';
import { MReactSelectOption, MSelectOption } from './MSelect.model';

interface Props<Value, Id extends string, IsMulti extends boolean> {
  className?: string;
  placeholder?: string;
  options: MSelectOption<Value>[];
  isMulti?: IsMulti;
  value?: IsMulti extends true
    ? (MSelectOption<Value> | MSelectOption<Value>['id'])[]
    : MSelectOption<Value> | MSelectOption<Value>['id'] | null;
  customStylesSelect?: StylesConfig<MReactSelectOption, IsMulti>;
  name?: string;
  valid?: boolean;
  invalid?: boolean;
  hideSelectedOptions?: boolean;
  showSelected?: boolean;
  disabled?: boolean;
  hideEffectSelection?: boolean;
  isClearable?: boolean;
  components?: Partial<SelectComponents<MReactSelectOption, IsMulti, GroupBase<MReactSelectOption>>>;
  isLoading?: boolean;
  onChange?: (value: OnChangeValue<MSelectOption<Value, Id>, IsMulti>) => void;
}

const MSelect = <Value, Id extends string = string, IsMulti extends boolean = false>(props: Props<Value, Id, IsMulti>): JSX.Element => {
  type ValueType = IsMulti extends true ? MultiValue<MReactSelectOption> : SingleValue<MReactSelectOption>;

  const darkMode = useSelector((state: AppState) => state.ui.darkMode);

  const componentsObj = useMemo(() => {
    const cmps: SelectComponentsConfig<MReactSelectOption, IsMulti, GroupBase<MReactSelectOption>> = { Menu: components.Menu };

    if (props.isMulti && !props.showSelected) {
      cmps.ValueContainer = MSelectValueContainer;
    }

    return cmps;
  }, [props.isMulti, props.showSelected]);

  const value: ValueType = useMemo(() => {
    if (props.isMulti) {
      const inputValue = props.value as (MSelectOption<Value> | MSelectOption<Value>['id'])[];

      return inputValue.reduce((acc: MReactSelectOption[], valueItem: MSelectOption<Value> | MSelectOption<Value>['id']) => {
        if (typeof valueItem === 'string') {
          const label = props.options.find((option: MSelectOption<Value>) => option.id === valueItem)?.label;

          if (!label) {
            return acc;
          }

          return [...acc, { label, value: valueItem }];
        }

        return [...acc, { label: valueItem.label, value: valueItem.id }];
      }, []) as unknown as ValueType;
    }

    if (!props.value) {
      return null as unknown as ValueType;
    }

    const inputValue = props.value as MSelectOption<Value> | MSelectOption<Value>['id'];

    if (typeof inputValue === 'string') {
      const label = props.options.find((option: MSelectOption<Value>) => option.id === inputValue)?.label;

      if (!label) {
        return null as unknown as ValueType;
      }

      return { label, value: inputValue } as unknown as ValueType;
    }

    return { label: inputValue.label, value: inputValue.id } as unknown as ValueType;
  }, [props.value, props.isMulti, props.options]);

  const options: MReactSelectOption[] = props.options.map((option: MSelectOption<Value>) => {
    return { label: option.label, value: option.id, isDisabled: option.isDisabled };
  });

  const customStylesSelect: StylesConfig<MReactSelectOption, IsMulti> = useMemo(() => {
    return {
      menu: (provided) => ({
        ...provided,
        background: darkMode ? '#000' : '#fff',
        zIndex: 2,
      }),
      option: (provided, state) => {
        let backgroundColor = darkMode ? '#000' : '#fff';

        if (state.isSelected && !props.hideEffectSelection) {
          backgroundColor = '#1e90ff';
        }

        if (state.isFocused) {
          backgroundColor = 'rgba(30, 144, 255, 0.1)';
        }

        if (state.isSelected && state.isFocused) {
          backgroundColor = 'rgba(30, 144, 255, 0.9)';
        }

        return {
          ...provided,
          backgroundColor,
          ':active': {
            background: '#1e90ff',
          },
        };
      },
      control: (provided) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        background: props.components ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
        borderColor: props.components ? 'transparent' : darkMode ? 'rgba(74, 74, 79, 0.655)' : '#e4e7ea',
        minHeight: props.showSelected ? '100%' : 'calc(1.5em + 0.75rem + 2px)',
        height: props.showSelected ? '100%' : 'calc(1.5em + 0.75rem + 2px)',
        color: '#fff',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: darkMode ? '#fff' : '#5c6873',
      }),
      multiValue: (provided) => {
        return {
          ...provided,
          backgroundColor: '#03a9f4',
          fontSize: '15px',
          borderRadius: '4px',
          padding: '2px',
        };
      },
      multiValueLabel: (provided) => ({
        ...provided,
        color: '#ffffff',
      }),
      input: (provided) => ({
        ...provided,
        color: '#fff',
      }),
      container: (provided, state) => {
        return {
          ...provided,
          opacity: state.isDisabled ? 0.6 : 1,
        };
      },
    };
  }, [darkMode]);

  const promptChange = (event: OnChangeValue<MReactSelectOption, IsMulti>) => {
    if (!props.onChange) {
      return;
    }

    if (props.isMulti) {
      const inputValue = event as ReadonlyArray<MReactSelectOption>;

      const selectedValues = inputValue.reduce((acc: MSelectOption<Value>[], valueItem: MReactSelectOption) => {
        const option = props.options.find((option: MSelectOption<Value>) => option.id === valueItem.value);

        if (!option) {
          return acc;
        }

        return [...acc, option];
      }, []);

      props.onChange(selectedValues as unknown as OnChangeValue<MSelectOption<Value, Id>, IsMulti>);

      return;
    }

    if (event === null) {
      props.onChange(null as unknown as OnChangeValue<MSelectOption<Value, Id>, IsMulti>);

      return;
    }

    const inputValue = event as MReactSelectOption;
    const option = props.options.find((option: MSelectOption<Value>) => option.id === inputValue.value);
    const value = props.value as MSelectOption<Value> | MSelectOption<Value>['id'];

    // If the value havn't changed, do nothing
    if ((typeof value === 'string' && props.value === option?.id) || (typeof value === 'object' && value?.id === option?.id)) {
      return;
    }

    if (!option) {
      props.onChange(null as unknown as OnChangeValue<MSelectOption<Value, Id>, IsMulti>);

      return;
    }

    props.onChange(option as unknown as OnChangeValue<MSelectOption<Value, Id>, IsMulti>);
  };

  return (
    <MSelectView
      placeholder={props.placeholder || ''}
      name={props.name}
      disabled={props.disabled}
      className={`${classes['container']} ${props.invalid ? classes['container--invalid'] : ''} ${props.className || ''}`}
      styles={props.customStylesSelect || customStylesSelect}
      isMulti={props.isMulti}
      value={value}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={props.hideSelectedOptions}
      components={props.components || componentsObj}
      options={options}
      isDisabled={props.disabled}
      onChange={promptChange}
      isClearable={props.isClearable}
      isLoading={props.isLoading}
    ></MSelectView>
  );
};

MSelect.displayName = 'MSelect';
MSelect.defaultProps = {
  hideSelectedOptions: false,
};

export default MSelect;
