import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from 'utils/http';
import { IDriverShift } from 'models/driverShift';
import { IGetDriverShiftsFilters, IUpdateDriverShiftsData, IUpdateDriverShiftsFilters, IDeleteDriverShiftsFilters, ICreateDriverShiftsData } from 'models/http/driverShift';
import { IGetDriverShiftResponse } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class DriverShiftHttp {
  private static route = '/';

  public async get(filters: IGetDriverShiftsFilters, cancelTokenSource?: CancelTokenSource): Promise<IDriverShift[]> {
    return serverAxios
      .post(DriverShiftHttp.route, {
        act: 'find',
        col: 'driverShifts',
        query: filters,
      },
      {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Medium,

      })
      .then((response: AxiosResponse<IGetDriverShiftResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource: CancelTokenSource): Promise<IDriverShift> {
    return serverAxios
      .post(DriverShiftHttp.route, {
        act: 'find',
        col: 'driverShifts',
        query: { _id: id },
      },
      {
        cancelToken: cancelTokenSource.token,
        timeout: HttpTimeoutPriority.Medium,

      })
      .then((response: AxiosResponse<IGetDriverShiftResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateDriverShiftsData | ICreateDriverShiftsData[]): Promise<void> {
    return serverAxios
      .post(DriverShiftHttp.route, {
        act: 'insert',
        col: 'driverShifts',
        data,
      },
      {
        timeout: HttpTimeoutPriority.Medium,
      })
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteDriverShiftsFilters): Promise<void> {
    return serverAxios
      .post(DriverShiftHttp.route, {
        act: 'delete',
        col: 'driverShifts',
        query: filters,
      },
      {
        timeout: HttpTimeoutPriority.Low,
      })
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateDriverShiftsFilters, data: IUpdateDriverShiftsData): Promise<void> {
    return serverAxios
      .post(DriverShiftHttp.route, {
        act: 'update',
        col: 'driverShifts',
        query: filters,
        data,
      },
      {
        timeout: HttpTimeoutPriority.Medium,
      })
      .then(() => {
        return;
      });
  }
}

export default DriverShiftHttp;
