import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { OnChangeValue } from 'react-select';

import MServerSelectView from './MServerSelect.view';

import { serverAxios } from 'utils/http';
import { ServerTableNames } from 'utils/enums/table-page';
import { ServerResponseByTableName, TableRequestIdentifiersKeys, TableRequestData } from './MServerSelect.model';
import { MSelectOption } from 'views/MSelect/MSelect.model';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { BaseQuery } from 'views/MTable/Table.model';

interface Props<IsMulti extends boolean, Table extends ServerTableNames, Id extends string> {
  table: Table;
  field: TableRequestIdentifiersKeys<Table>;
  value: IsMulti extends true ? Id[] : Id | null;
  isMulti?: IsMulti;
  placeholder?: string;
  name: string;
  valid?: boolean;
  staticOptions?: MSelectOption<null, Id>[];
  unit?: string | null;
  invalid?: boolean;
  filterIds?: string[];
  baseQuery?: BaseQuery;
  isClearable?: boolean; 
  onChange?: (props: OnChangeValue<MSelectOption<null, Id>, IsMulti>) => void;
}
const MServerSelect = <Table extends ServerTableNames, IsMulti extends boolean = false, Id extends string = string>(
  props: Props<IsMulti, Table, Id>,
): JSX.Element => {
  const [optionsState, setOptionsState] = useState<MSelectOption<null, Id>[] | null>(null);

  useEffect(() => {
    setOptionsState(() => null);

    let cancelToken: CancelTokenSource | null = axios.CancelToken.source();
    const query: { unit?: string } = {};

    if (props.unit) {
      query.unit = props.unit;
    }

    serverAxios
      .post(
        '/',
        {
          act: 'find',
          col: props.table,
          query: { ...query, ...props.baseQuery },
        },
        {
          cancelToken: cancelToken.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<ServerResponseByTableName<Table>>) => {
        setOptionsState(() => {
          const formatedResponse = response.data.data.map((option: TableRequestData<Table>) => {
            // TODO: Check issue without putting 'as' key. Why typescript things the value is not a string?
            return { label: option[props.field] as unknown as string, id: option[props.field] as unknown as Id };
          });

          if (!props.staticOptions) {
            return formatedResponse;
          }

          const results = formatedResponse.filter((value: { label: string; id: string }) => !props.filterIds?.includes(value.id));

          return [...props.staticOptions, ...results];
        });
      })
      .finally(() => {
        cancelToken = null;
      });

    return cancelToken?.cancel;
  }, [props.field, props.staticOptions, setOptionsState, props.unit]);

  return (
    <MServerSelectView
      isMulti={props.isMulti}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      options={optionsState || []}
      isLoading={!optionsState}
      valid={props.valid}
      invalid={props.invalid}
      isClearable={props.isClearable}
      onChange={props.onChange}
    ></MServerSelectView>
  );
};

MServerSelect.displayName = 'MServerSelect';

export default MServerSelect;
