const routes = {
  rates: 'Taxas',
  viewSites: 'Visualizar locais',
  sites: 'Locais',
  attributes: 'Atributos',
  suppliers: 'Fornecedores',
  updateSupplier: 'Atualizar Fornecedor',
  createSupplier: 'Criar fornecedor',
  shuttles: 'Transporte',
  updateShuttle: 'Atualizar Transporte',
  createShuttle: 'Criação de transporte',
  fares: 'Tarifas',
  updateFare: 'Atualizar tarifa',
  createFare: 'Criar tarifa',
  services: 'Serviços',
  updateService: 'Atualizar Serviço',
  createService: 'Crie um serviço',
  users: 'Usuários',
  updateUser: 'Atualizar Usuário',
  createUser: 'Criar usuário',
  vehicles:{
    templates: {
      default: 'Veículos',
      schoolShuttles: 'Veículos',
      employees: 'Veículos',
      trailers: 'Veículos',
    },
  },
  updateVehicle: 'Atualizar Veículo',
  createVehicle: 'Crie o Veículo',
  areas: 'Áreas',
  updateArea: 'Atualizar Área',
  createArea: 'Criar Área',
  drivers: 'Motoristas',
  updateDriver: 'Atualizar Motorista',
  createDriver: 'Criar Motorista',
  playground: 'Recreio',
  support: 'Suporte',
  parameters: 'Parâmetros',
  createParameter: 'Criar parâmetro',
  billReportTitle: 'Relatório de faturamento',
  holidayReportTitle: 'Relatório de feriados',
  holidayTariffReportTitle: 'Relatório de tarifa de feriados',
  analytics: 'Analítica',
  settings: 'Configurações',
  logout: 'Sair',
  liveMap: 'Mapa em tempo real',
  driverShifts: 'Turnos do motorista',
  createDriverShift: 'Crie um turno de motorista único',
  createMultipleDriverShifts: 'Crie Turnos de Motoristas Múltiplos',
  passengers: 'Passageiros',
  students: 'Alunos',
  employees: 'Empregados',
  editShuttle: 'Editar transporte',
  updatePassenger: 'Editar passageiro',
  createPassenger: 'Novo passageiro',
  onlineDrivers: 'Motoristas on-line',
  trips: 'Viagens',
  productionTrips: 'Viagens de Produção',
  reservations: {
    templates: {
      default: 'Reservas',
      schoolShuttles: 'Reservas',
      employees: 'Reservas',
      trailers: 'Reservas',
    },
  },
  editReservation: 'Editar reserva',
  createReservation: 'Criar reserva',
  createQuick: 'Criação Rápida',
  timeline: 'Linha do tempo',
  home: 'Home',
  import: 'Carregar dados',
  reports: 'Relatórios',
  shuttleReports: 'Relatórios de transporte',
  locations: 'Localização',
  createLocation: 'Criar Localização',
  editLocation: 'Atualizar Localização',
  profiles: 'Perfis',
  shifts: {
    templates: {
      default: 'Turnos',
      schoolShuttles: 'Horário letivo',
      employees: 'Horas de trabalho',
      trailers: 'Turnos',
    },
  },
  createShift: {
    templates: {
      default: 'Criar Turno',
      schoolShuttles: 'Crie o horário escolar',
      employees: 'Criar turno',
      trailers: 'Criar Turno',
    },
  },
  updateShift: {
    templates: {
      default: 'Atualizar Turno',
      schoolShuttles: 'Atualizar o horário de funcionamento',
      employees: 'Atualizar Turno',
      trailers: 'Atualizar Turno',
    },
  },
  agentHome: 'Agente',
  createExtraService: 'Serviço extra em Creta',
  updateExtraService: 'Atualizar serviço extra',
  extraServices: 'Serviços extras',
  tripsPaxReports: 'Resumo de Viagens / Passageiros',
  uniqueDriversReport: 'Motoristas únicos por dia',
  permissions: 'Funções e Permissões',
  createJob: 'Criar reserva',
  quickJob: 'Reserva rápida',
  holidays: 'Feriados',
  assignPriority: 'Atribuir prioridade',
  parents: 'Pais',
  blacklist: 'Lista Negra de Cartões',
  roadBlocks: 'Road Blocks', // TODO: translate
};

export default routes;
