import React from 'react';

import MCardBodyView from './MCardBody.view';

interface Props {
  className?: string;
}

const MCardBody: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MCardBodyView className={props.className}>{props.children}</MCardBodyView>;
};

MCardBody.displayName = 'MCardBody';
MCardBody.defaultProps = {};

export default MCardBody;
