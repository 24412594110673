import authGuard from './auth.guard';
import guestGuard from './guest.guard';
import accessGuard from './access.guard';
import accessTemplateGuard from './accessTemplate.guard';

const guards = {
  authGuard,
  guestGuard,
  accessGuard,
  accessTemplateGuard,
};

export default guards;