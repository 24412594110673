import { useCallback } from 'react';
import moment from 'moment-timezone';
import { FilterValue, IdType } from 'react-table';

import sess from 'utils/session-storage';
import { RowData, TableSessionData } from 'views/MTable/Table.model';
import { TimeFormat } from 'utils/enums/time-format';

type ReturnFunc = <TableFields extends RowData>(dataToCache: TableSessionData<TableFields>) => void;

const useTableCache = <TableFields extends RowData>(key: string | null): ReturnFunc => {
  return useCallback(
    (dataToCache: TableSessionData<TableFields>) => {
      if (!key) {
        return;
      }

      const formattedState = dataToCache.filters.map((filter: { id: IdType<TableFields>; value: FilterValue }) => {
        if (Array.isArray(filter.value)) {
          return {
            id: filter.id,
            value: filter.value.map((value: FilterValue) =>
              moment.isMoment(value) ? value.format(TimeFormat.ServerFormatDateTime) : value,
            ),
          };
        }

        return {
          id: filter.id,
          value: moment.isMoment(filter.value) ? filter.value.format(TimeFormat.ServerFormatDateTime) : filter.value,
        };
      }, {});

      sess.save(key, {
        ...dataToCache,
        filters: formattedState,
      });
    },
    [key],
  );
};

export default useTableCache;
