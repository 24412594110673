import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetBlacklistResponse } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { ICreateBlacklistItemData, IDeleteBlacklistFilters, IGetBlacklistFilters, IUpdateBlacklistItemData } from 'models/http/blacklist';
import { IBlacklistItem } from 'models/blacklist';

class BlacklistHttp {
  private static route = '/';

  public async get(filters: IGetBlacklistFilters, cancelTokenSource?: CancelTokenSource): Promise<IBlacklistItem[]> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'find',
          col: 'blacklist',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetBlacklistResponse>) => {
        return response.data.data;
      });
  }

  public async getByCardNumber(
    data: { cardNumber: string; site: string },
    cancelTokenSource?: CancelTokenSource,
  ): Promise<IBlacklistItem[]> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'find',
          col: 'blacklist',
          query: { number: data.cardNumber, unit: data.site },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetBlacklistResponse>) => {
        return response.data.data;
      });
  }

  public async getById(id: string, cancelTokenSource?: CancelTokenSource): Promise<IBlacklistItem> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'find',
          col: 'blacklist',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetBlacklistResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateBlacklistItemData): Promise<void> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'insert',
          col: 'blacklist',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateBlacklistItemData, data: IUpdateBlacklistItemData): Promise<void> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'update',
          col: 'blacklist',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteBlacklistFilters): Promise<void> {
    return serverAxios
      .post(
        BlacklistHttp.route,
        {
          act: 'delete',
          col: 'blacklist',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default BlacklistHttp;
