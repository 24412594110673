import { SystemTemplate } from 'utils/enums/system-template';

export enum SettingsActions {
  SetMapCenter = '[Settings] Set Map Center',
  SetMapZoom = '[Settings] Set Map Zoom',
  FetchTableData = '[Settings] Fetch Table Data',
  SetGeoServer = '[Settings] Set Geo Server',
  SetSystemSpecs = '[Settings] Set System Specs',
  SetSystemTemplate = '[Settings] Set System Template',
}

export interface SetMapCenter {
  type: SettingsActions.SetMapCenter;
  payload: { lat: number; lng: number };
}

export interface SetSystemTemplate {
  type: SettingsActions.SetSystemTemplate;
  payload: SystemTemplate;
}

export interface SetMapZoom {
  type: SettingsActions.SetMapZoom;
  payload: { zoom: number };
}

export interface SetGeoServer {
  type: SettingsActions.SetGeoServer;
  payload: string;
}

export interface SetSystemSpecs {
  type: SettingsActions.SetSystemSpecs;
  payload: string;
}

export const setMapCenter = (lat: number, lng: number): SetMapCenter => {
  return {
    type: SettingsActions.SetMapCenter,
    payload: { lat, lng },
  };
};

export const setMapZoom = (zoom: number): SetMapZoom => {
  return {
    type: SettingsActions.SetMapZoom,
    payload: { zoom },
  };
};

export const setGeoServer = (geoServer: string): SetGeoServer => {
  return {
    type: SettingsActions.SetGeoServer,
    payload: geoServer,
  };
};

export const setSystemSpecs = (systemSpecs: string): SetSystemSpecs => {
  return {
    type: SettingsActions.SetSystemSpecs,
    payload: systemSpecs,
  };
};

export const setSystemTemplate = (systemTemplate: SystemTemplate): SetSystemTemplate => {
  return {
    type: SettingsActions.SetSystemTemplate,
    payload: systemTemplate,
  };
};

export type SettingsTypes = SetMapCenter | SetMapZoom | SetGeoServer | SetSystemSpecs | SetSystemTemplate;
