import React from 'react';
import { useTranslation } from 'react-i18next';

import MCard from 'views/MCard/MCard';
import MCardBody from 'views/MCard/MCardBody/MCardBody';

import classes from './HomeAgentWeather.module.scss';

interface Props {}

const HomeAgentWeatherView: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={classes['container']}>
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/en/40d71n74d01/new-york/"
        data-label_1="NEW YORK"
        data-label_2="WEATHER"
        data-theme="original"
      >
        {t('agent.weatherInfo.loadingWeather')}
      </a>

      <MCard className={classes['card']}>
        <MCardBody className={classes['card__body']}>{t('agent.weatherInfo.noNewMessages')}</MCardBody>
      </MCard>

      {(function (document, tagName, id) {
        if (document.getElementById(id)) {
          return;
        }

        const link = document.getElementsByTagName(tagName)[0];
        const sourceScript: HTMLScriptElement = document.createElement(tagName) as HTMLScriptElement;

        sourceScript.id = id;
        sourceScript.src = 'https://weatherwidget.io/js/widget.min.js';
        link?.parentNode?.insertBefore(sourceScript, link);
      })(document, 'script', 'weatherwidget-io-js')}
    </div>
  );
};

HomeAgentWeatherView.displayName = 'HomeAgentWeatherView';
HomeAgentWeatherView.defaultProps = {};

export default React.memo(HomeAgentWeatherView);
