import React from 'react';
import { OnChangeValue } from 'react-select';

import MFormAddressView from './MFormAddress.view';

import { MFormFieldProps } from 'views/MForm/MForm.model';
import { MAddressSelectOption } from 'views/MAddress/MAddress.model';

interface Props extends MFormFieldProps<[string | undefined], string> {
  withLocations?: boolean;
  disabled?: boolean;
}

const MFormAddress: React.FC<Props> = (props: Props) => {
  return (
    <MFormAddressView
      name={props.name}
      value={{ label: props.value?.toString(), value: props.value?.toString() }}
      valid={props.valid}
      invalid={props.invalid}
      onChange={(value: OnChangeValue<MAddressSelectOption, false>) => props.onChange?.(value?.value)}
      disabled={props.disabled}
      withLocations={props.withLocations}
    ></MFormAddressView>
  );
};

MFormAddress.displayName = 'MFormAddress';
MFormAddress.defaultProps = {};

export default MFormAddress;
