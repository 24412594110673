import React from 'react';

import MSimpleSelectView from './MSimpleSelect.view';

import { MSimpleSelectOption } from './MSimpleSelect.model';

interface Props<OptionValue extends string | number> {
  className?: string;
  value?: OptionValue | null;
  name?: string;
  invalid?: boolean;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  showSelected?: boolean;
  options: MSimpleSelectOption<OptionValue>[];
  placeholder?: string;
  defaultValue?: OptionValue;
  hideDefaultValue?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const MSimpleSelect = <OptionValue extends string | number>(props: React.PropsWithChildren<Props<OptionValue>>): JSX.Element => {
  return (
    <MSimpleSelectView
      className={props.className}
      value={props.value}
      name={props.name}
      invalid={props.invalid}
      hideSelectedOptions={props.hideSelectedOptions}
      disabled={props.disabled}
      showSelected={props.showSelected}
      placeholder={props.placeholder}
      options={props.options}
      onBlur={props.onBlur}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      hideDefaultValue={props.hideDefaultValue}
    >
      {props.children}
    </MSimpleSelectView>
  );
};

MSimpleSelect.displayName = 'MSimpleSelect';
MSimpleSelect.defaultProps = {};

export default MSimpleSelect;
