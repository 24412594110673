import React from 'react';
import { CellProps } from 'react-table';

import MTableCellExpandRowView from './MTableCellExpandRow.view';

import { RowData } from '../Table.model';

interface Props<T extends RowData> extends CellProps<T> {}

const MTableCellExpandRow = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <MTableCellExpandRowView value={props.value} row={props.row}>
      {props.children}
    </MTableCellExpandRowView>
  );
};

MTableCellExpandRow.displayName = 'MTableCellExpandRow';
MTableCellExpandRow.defaultProps = {};

export default MTableCellExpandRow;
