import React from 'react';
import { TableInstance, Row, FilterValue, IdType, HeaderGroup, Cell } from 'react-table';
import CIcon from '@coreui/icons-react';

import MSpinner from 'views/MSpinner/MSpinner';

import classes from './Table.module.scss';
import { RowData } from './Table.model';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

interface Props<T extends RowData> {
  table: TableInstance<T>;
  disableFilters?: boolean;
  noResultsClassName?: string;
  rowClassName?: string;
  loading: boolean;
  className?: string;
  hiddenColumns: string[];
  draggable?: boolean;
  onFilterChange: (field: IdType<T>, value: FilterValue) => void;
  isRowSelected: (row: Row<T>) => boolean;
  onHeaderClick: (name: string) => void;
  renderExpand?: (row: Row<T>) => React.ReactElement | null;
  onRowClick?: (row: Row<T>) => void;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
}

const MTableView = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <div className={`${classes['container']} ${props.className || ''}`.trim()}>
      <div className={`${classes['tableContainer']} ${props.table.rows.length <= 0 ? classes['tableContainer--maxHeightSpace'] : ''}`}>
        <DragDropContext onDragEnd={(result: DropResult, provided: ResponderProvided) => props.onDragEnd?.(result, provided)}>
          <table
            className={`table table-striped table-hover m-0 ${classes['table']} ${
              !props.table.rows.length ? classes['table--noResults'] : ''
            }`.trim()}
            {...props.table.getTableProps()}
          >
            <thead>
              {props.table.headerGroups.map((headerGroup: HeaderGroup<T>) => {
                const { key, ...rest } = headerGroup.getHeaderGroupProps();

                return (
                  <tr key={key} {...rest}>
                    {headerGroup.headers
                      .filter((header: HeaderGroup<T>) => !props.hiddenColumns.includes(header.id))
                      .map((column: HeaderGroup<T>) => {
                        const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());
                        let thStyle: React.CSSProperties = {};

                        if (column.width) {
                          thStyle = {
                            width: column.width,
                            maxWidth: column.width,
                            minWidth: column.width,
                          };
                        }

                        return (
                          <th
                            key={key}
                            {...rest}
                            onClick={(event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
                              if (!column.canSort) {
                                return;
                              }

                              rest['onClick'](event);
                              props.onHeaderClick(column.id);
                            }}
                            style={thStyle}
                            className={classes['header']}
                          >
                            <div className={classes['headerContent']}>
                              <span>{column.render('Header')}</span>

                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <CIcon size="lg" name="cilArrowBottom"></CIcon>
                                  ) : (
                                    <CIcon size="lg" name="cilArrowTop"></CIcon>
                                  )
                                ) : null}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                  </tr>
                );
              })}
              {!props.disableFilters &&
                props.table.headerGroups.map((headerGroup: HeaderGroup<T>) => {
                  const { key, ...rest } = headerGroup.getHeaderGroupProps();

                  return (
                    <tr key={key} {...rest}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...rest } = column.getHeaderProps();

                        return (
                          <td key={key} {...rest} className={classes['filterCell']}>
                            {column.canFilter
                              ? column.render('Filter', { onChange: (value: FilterValue) => props.onFilterChange(column.id, value) })
                              : null}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </thead>

            <Droppable droppableId="table-body">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef} {...props.table.getTableBodyProps()}>
                  {props.loading ? (
                    <tr></tr>
                  ) : props.table.rows.length ? (
                    props.table.rows.map((row: Row<T>, i: number) => {
                      props.table.prepareRow(row);

                      const rowClasses = [classes['row'], props.rowClassName];

                      if (props.onRowClick) {
                        rowClasses.push(classes['selectable']);
                      }

                      const rowSelected = props.isRowSelected(row);

                      return (
                        <Draggable isDragDisabled={!props.draggable} key={i} draggableId={row.id} index={i}>
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <tr
                                {...row.getRowProps()}
                                className={rowClasses.join(' ')}
                                onClick={() => props.onRowClick && props.onRowClick(row)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={
                                  snapshot.isDragging
                                    ? {
                                      display: 'flex',
                                      gap: '20px',
                                      justifyContent: 'space-between',
                                      backgroundColor: '#23242d',
                                      border: 'green 1px solid',
                                      ...provided.draggableProps.style,
                                    }
                                    : { ...provided.draggableProps.style }
                                }
                              >
                                {row.cells
                                  .filter((cell: Cell<T>) => !props.hiddenColumns.includes(cell.column.id))
                                  .map((cell: Cell<T>) => {
                                    const { key, ...rest } = cell.getCellProps();

                                    if (props.isRowSelected(row)) {
                                      rowClasses.push(classes['selected']);
                                    }

                                    const cellClasses = [classes['cell']];

                                    if (rowSelected) {
                                      cellClasses.push(classes['cell--selectedRow']);
                                    }

                                    let thStyle: React.CSSProperties = {};

                                    if (cell.column.width) {
                                      thStyle = {
                                        width: cell.column.width,
                                        maxWidth: cell.column.width,
                                        minWidth: cell.column.width,
                                      };
                                    }

                                    return (
                                      <td style={thStyle} className={cellClasses.join(' ')} key={key} {...rest}>
                                        <div>{cell.render('Cell')}</div>
                                      </td>
                                    );
                                  })}
                              </tr>
                              {row.isExpanded ? (
                                <tr className={classes['expandedRow']}>
                                  <td colSpan={props.table.visibleColumns.length}>{props.renderExpand?.(row) || null}</td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    <tr>
                      <td className={`${classes['noResults']} ${props.noResultsClassName}`} colSpan={props.table.visibleColumns.length}>
                        No Results
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>

      {props.loading && (
        <div className={classes['loading']}>
          <MSpinner size="md"></MSpinner>
        </div>
      )}
    </div>
  );
};

MTableView.displayName = 'MTableView';

export default MTableView;
