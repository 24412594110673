import React from 'react';

import MSpinnerView from './MSpinner.view';

interface Props {
  size: 'sm' | 'md' | 'lg';
}

const MSpinner: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MSpinnerView size={props.size}>{props.children}</MSpinnerView>;
};

MSpinner.displayName = 'MSpinner';
MSpinner.defaultProps = {};

export default MSpinner;
