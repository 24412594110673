import React from 'react';
import { FormikContextType } from 'formik';

import ParentsFormPersonalDetailsView from './ParentsFormPersonalDetails.view';
import { IParentsFormPersonalDetailsFields } from './ParentsFormPersonalDetails.model';

type Props<Fields extends IParentsFormPersonalDetailsFields> = {
  form: FormikContextType<Fields>;
};

const ParentsFormPersonalDetails = <Fields extends IParentsFormPersonalDetailsFields>(
  props: React.PropsWithChildren<Props<Fields>>,
): JSX.Element => {
  return <ParentsFormPersonalDetailsView form={props.form}></ParentsFormPersonalDetailsView>;
};

ParentsFormPersonalDetails.defaultProps = {};
ParentsFormPersonalDetails.displayName = 'ParentsFormPersonalDetails';

export default ParentsFormPersonalDetails;
