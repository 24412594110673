import AreaHttp from './http/area.http';
import VehicleHttp from './http/vehicle.http';
import SupplierHttp from './http/supplier.http';
import ServiceHttp from './http/service.http';
import DriverShiftHttp from './http/driver-shift.http';
import ProfileHttp from './http/profile.http';
import ExtraServicesHttp from './http/extraServices.http';
import JobsHttp from './http/jobs.http';
import RateTyperHttp from './http/rateType.http';
import SettingHttp from './http/setting.htts';
import AttributesHttp from './http/attributes.htts';
import RoleHttp from './http/role.http';
import HolidayHttp from './http/holidays.http';
import AssignPriorityGroupsHttp from './http/assign-priority-groups.http';
import ParentHttp from './http/parent.http';
import BlacklistHttp from './http/blacklist.http';
import MapHttp from './http/map.http';
import RoadBlocksHttp from './http/roadBlocks';
import { useMemo } from 'react';

const area = new AreaHttp();
const vehicle = new VehicleHttp();
const supplier = new SupplierHttp();
const service = new ServiceHttp();
const driverShift = new DriverShiftHttp();
const profile = new ProfileHttp();
const extraService = new ExtraServicesHttp();
const job = new JobsHttp();
const rateType = new RateTyperHttp();
const setting = new SettingHttp();
const attributes = new AttributesHttp();
const roles = new RoleHttp();
const holiday = new HolidayHttp();
const assignPriorityGroups = new AssignPriorityGroupsHttp();
const parent = new ParentHttp();
const blacklist = new BlacklistHttp();
const map = new MapHttp();
const roadBlocks = new RoadBlocksHttp();

interface HttpHook {
  area: AreaHttp;
  vehicle: VehicleHttp;
  supplier: SupplierHttp;
  service: ServiceHttp;
  driverShift: DriverShiftHttp;
  profile: ProfileHttp;
  extraService: ExtraServicesHttp;
  job: JobsHttp;
  rateType: RateTyperHttp;
  setting: SettingHttp;
  attributes: AttributesHttp;
  roles: RoleHttp;
  holiday: HolidayHttp;
  assignPriorityGroups: AssignPriorityGroupsHttp;
  parent: ParentHttp;
  blacklist: BlacklistHttp;
  map: MapHttp;
  roadBlocks: RoadBlocksHttp;
}

const useHttp = (): HttpHook => {
  const services = {
    area,
    vehicle,
    supplier,
    service,
    driverShift,
    profile,
    extraService,
    job,
    rateType,
    setting,
    attributes,
    roles,
    holiday,
    assignPriorityGroups,
    parent,
    blacklist,
    map,
    roadBlocks,
  };

  return useMemo(() => services, []);
};

export default useHttp;
