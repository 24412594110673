import React from 'react';
import ReactSelect, { SelectComponentsConfig, StylesConfig, OnChangeValue, MultiValue, SingleValue, GroupBase } from 'react-select';
import { MReactSelectOption } from './MSelect.model';

import classes from './MSelect.module.scss';

interface Props<IsMulti extends boolean = false> {
  placeholder?: string;
  name?: string;
  className: string;
  disabled?: boolean;
  styles: StylesConfig<MReactSelectOption, IsMulti>;
  isMulti?: IsMulti;
  value: IsMulti extends true ? MultiValue<MReactSelectOption> : SingleValue<MReactSelectOption>;
  closeMenuOnSelect: boolean;
  hideSelectedOptions?: boolean;
  components: SelectComponentsConfig<MReactSelectOption, IsMulti, GroupBase<MReactSelectOption>>;
  options: MReactSelectOption[];
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  onChange?: (value: OnChangeValue<MReactSelectOption, IsMulti>) => void;
}

const MSelectView = <IsMulti extends boolean>(props: Props<IsMulti>): JSX.Element => {
  return (
    <div className={props.className}>
      <ReactSelect
        placeholder={props.placeholder}
        name={props.name}
        className={props.disabled ? classes['select'] : classes['noSelect']}
        styles={props.styles}
        isMulti={props.isMulti}
        value={props.value}
        isDisabled={props.disabled}
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideSelectedOptions={props.hideSelectedOptions}
        components={props.components}
        isClearable={props.isClearable}
        onChange={props.onChange}
        options={props.options}
        isLoading={props.isLoading}
      ></ReactSelect>
    </div>
  );
};

MSelectView.displayName = 'MSelectView';
MSelectView.defaultProps = {};

export default MSelectView;
