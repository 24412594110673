import { AnyMongoAbility, AbilityBuilder, Ability } from '@casl/ability';
import { IModule } from 'models/setting';
import { createContext } from 'react';

const initializeAbility = (initialModules?: { [key: string]: IModule }): AnyMongoAbility => {
  const abilityBuilder = new AbilityBuilder(Ability);

  if (initialModules) {
    Object.values(initialModules).forEach(({ actions, moduleCode }: IModule) => abilityBuilder.can(actions, moduleCode));
  }

  return new Ability(abilityBuilder.rules);
};

export const updateAbility = (ability: AnyMongoAbility, modules: { [key: string]: IModule }): void => {
  const abilityBuilder = new AbilityBuilder(Ability);

  Object.values(modules).forEach(({ actions, moduleCode }: IModule) => abilityBuilder.can(actions, moduleCode));

  ability.update(abilityBuilder.rules);
};

export const ability = initializeAbility();

export const AbilityContext = createContext(ability);
