import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  usePagination,
  TableInstance,
  useExpanded,
  CellProps,
  Row,
  Column,
  Filters,
  SortingRule,
  FilterValue,
  IdType,
  HeaderGroup,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import InputFilter from './TableFilters/MInputFilter/InputFilter';
import MTableView from './MTable.view';
import MTableCellExpandCheck from './MTableCells/MTableCellExpandCheck/MTableCellExpandCheck';
import MTableCellExpendCol from './MTableCells/MTableCellExpendCol/MTableCellExpendCol';
import MTableExpandCheckHeader from './MTableExpandCheckHeader/MTableExpandCheckHeader';
import MTableCellExpandRow from './MTableCellExpandRow/MTableCellExpandRow';

import { MColumnOptions, TableRef, RowData } from './Table.model';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';

interface Props<T extends RowData> {
  className?: string;
  columns: string[];
  columnsOptions?: MColumnOptions<T>;
  data: T[];
  disableFilters?: boolean;
  filters?: Filters<T>; // pass values for filters
  hideSelection?: boolean;
  disableSelection?: boolean;
  disableSortBy?: boolean;
  loading?: boolean;
  manualFilters?: boolean;
  manualSortBy?: boolean;
  minHeight?: number;
  noResultsClassName?: string;
  page?: number;
  pageCount?: number;
  openExpandLabel?: string;
  closeExpandLabel?: string;
  hideSelectAll?: boolean;
  selectedRow?: Partial<T> | Partial<T>[] |  null; // for single selecting row
  sort?: SortingRule<T>[];
  tableInstance?: TableInstance<T>;
  rowClassName?: string;
  hiddenColumns?: string[];
  draggable?: boolean;
  getRowId?: (row: T) => string;
  onFiltersChange?: (filters: Filters<T>) => void;
  onRowClick?: (row: Row<T>) => void; // in most cases for single selecting row. but can be used for other stuff
  onRowHover?: (row: number | null) => void;
  onSelect?: (selected: string[]) => void;
  onSortChange?: (sortValue: SortingRule<T>[]) => void;
  renderExpand?: (row: Row<T>) => React.ReactElement | null;
  onFiltersRender?: (filters: { id: IdType<T>; jsxFunc: () => React.ReactNode }[]) => void;
  onDragEnd?: (result: DropResult, provided: ResponderProvided)=> void;
}

const MTable = <T extends RowData>(props: React.PropsWithChildren<Props<T>>, ref: React.ForwardedRef<TableRef<T>>): JSX.Element => {
  const { t } = useTranslation();

  // Generate columns of the table
  const columns: Column<T>[] = useMemo(() => {
    const columns: Column<T>[] = [
      ...props.columns.map((column: string) => {
        const tmpCol: Column<T> = {
          width: 'auto',
          accessor: column,
          Header: column[0].toUpperCase() + column.slice(1),
        };

        if (props.columnsOptions?.[column]) {
          if (props.columnsOptions[column]?.expander) {
            return {
              ...tmpCol,
              ...props.columnsOptions[column],
              Cell: MTableCellExpandRow,
            };
          }

          return { ...tmpCol, ...props.columnsOptions[column] };
        }

        return tmpCol;
      }),
    ];

    if (!props.hideSelection) {
      const expandCheck: Column<T> = {
        accessor: 'selection',
        Cell: (item: CellProps<T>) => MTableCellExpandCheck({ ...item, disableSelection: props.disableSelection }),
        width: '1%',
        disableSortBy: true,
        disableFilters: true,
      };

      if (!props.hideSelectAll) {
        expandCheck.Header = React.memo(({ getToggleAllPageRowsSelectedProps }: CellProps<T>) =>
          MTableExpandCheckHeader({
            tableToggleAllRowsSelectedProps: getToggleAllPageRowsSelectedProps,
            disableSelection: props.disableSelection,
          }),
        );
      }

      columns.unshift(expandCheck);
    }

    if (props.renderExpand && props.openExpandLabel && props.closeExpandLabel) {
      const expandCol = {
        accessor: 'expander',
        Header: t('settings.parameters.actions'),
        width: 1,
        disableSortBy: true,
        disableFilters: true,
        Cell: (item: CellProps<T>) =>
          MTableCellExpendCol({ ...item, openExpandLabel: props.openExpandLabel, closeExpandLabel: props.closeExpandLabel }),
      };

      columns.push(expandCol);
    }

    return columns;
  }, [props.columns, props.columnsOptions, props.hideSelection, props.disableSelection]);

  const baseTable = useTable<T>(
    {
      columns,
      data: props.data,
      defaultColumn: { Filter: InputFilter },
      disableFilters: props.disableFilters,
      manualFilters: props.manualFilters,
      manualSortBy: props.manualSortBy,
      manualPagination: true,
      initialState: { sortBy: props.sort || [], pageIndex: props.page },
      pageCount: props.pageCount,
      disableSortBy: props.disableSortBy,
      getRowId: props.getRowId,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const table: TableInstance<T> = props.tableInstance || baseTable;

  const promptFiltersChange = (field: IdType<T>, value: FilterValue) => {
    const newFilters = [...table.state.filters];
    const filterItemIndex = newFilters.findIndex((filter: { id: IdType<T>; value: FilterValue }) => filter.id === field);

    if (filterItemIndex !== -1) {
      newFilters[filterItemIndex] = { id: field, value };
      props.onFiltersChange?.(newFilters);

      return;
    }

    props.onFiltersChange?.([...newFilters, { id: field, value }]);
  };

  const filtersRendererDependency = (() => {
    try {
      return JSON.stringify(table.headerGroups);
    } catch {
      return '';
    }
  })();

  const filtersRenderer = useMemo(() => {
    if (props.disableFilters) {
      return null;
    }

    return table.headerGroups.reduce(
      (headersGroupAcc: { id: IdType<T>; jsxFunc: () => React.ReactNode }[], currHeaderGroup: HeaderGroup<T>) => {
        return [
          ...headersGroupAcc,
          ...currHeaderGroup.headers.reduce(
            (headersAcc: { id: IdType<T>; jsxFunc: () => React.ReactNode }[], currHeader: HeaderGroup<T>) => {
              if (!currHeader.canFilter) {
                return headersAcc;
              }

              return [
                ...headersAcc,
                {
                  id: currHeader.id,
                  jsxFunc: () =>
                    currHeader.render('Filter', {
                      key: currHeader.id,
                      onChange: (value: FilterValue) => promptFiltersChange(currHeader.id, value),
                      placeholder: currHeader.render('Header'),
                    }),
                },
              ];
            },
            [],
          ),
        ];
      },
      [],
    );
  }, [filtersRendererDependency, props.disableFilters]);

  useEffect(() => {
    props.onFiltersRender?.(filtersRenderer || []);
  }, [JSON.stringify(props.onFiltersRender), filtersRenderer]);

  useEffect(() => {
    table.setAllFilters((prev: Filters<T>) => {
      if (props.filters) {
        return props.filters;
      }

      return prev;
    });
  }, [props.filters]);

  useEffect(() => {
    if (Array.isArray(props.sort)) {
      table.setSortBy(props.sort);
    }
  }, [props.sort]);

  useEffect(() => {
    if (props.page && table.gotoPage) {
      table.gotoPage(props.page);
    }
  }, [props.page]);

  useEffect(() => {
    props.onSelect?.(Object.keys(table.state.selectedRowIds));
  }, [Object.keys(table.state.selectedRowIds).join(',')]);

  const isRowSelected = (row: Row<T>): boolean => {
    const selectedRow = props.selectedRow;

    if (!Array.isArray(selectedRow)) {
      return !!selectedRow && Object.keys(selectedRow).every((key: keyof T) => selectedRow[key] === row.original[key]);
    }

    const isSelected = selectedRow.some((item: Partial<T>) => Object.keys(item).every((key: keyof T) => item[key] === row.original[key]));

    return isSelected;
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        table,
        setSelectedRows(condition: (row: Row<T>, index: number) => boolean) {
          table.rows.forEach((row: Row<T>, index: number) => row.toggleRowSelected(condition(row, index)));
        },
        setFilters(updateFunc: (prevFilters: Filters<T>) => Filters<T>) {
          table.setAllFilters((prev: Filters<T>) => {
            const newFilters = updateFunc(prev);

            props.onFiltersChange?.(newFilters);

            return newFilters;
          });
        },
        setSort() {
          console.warn('set sort');
        },
      };
    },
    [table, table.setAllFilters, props.onFiltersChange],
  );

  const updateSort = (name: string) => {
    const newSort = [...table.state.sortBy];
    const columnIndex = newSort.findIndex((sort: { id: IdType<T>; desc?: boolean | undefined }) => sort.id === name);

    if (columnIndex === -1) {
      newSort.push({ id: name, desc: false });
    } else if (!newSort[columnIndex].desc) {
      newSort[columnIndex] = {
        ...newSort[columnIndex],
        desc: true,
      };
    } else {
      newSort.splice(columnIndex, 1);
    }

    props.onSortChange?.(newSort);
  };

  return (
    <MTableView
      table={table}
      loading={!!props.loading}
      rowClassName={props.rowClassName}
      disableFilters={props.disableFilters || !!props.onFiltersRender}
      noResultsClassName={props.noResultsClassName}
      className={props.className}
      isRowSelected={isRowSelected}
      renderExpand={props.renderExpand}
      onRowClick={props.onRowClick}
      onFilterChange={promptFiltersChange}
      onHeaderClick={updateSort}
      hiddenColumns={props.hiddenColumns || []}
      draggable={props.draggable}
      onDragEnd={props.onDragEnd}
    ></MTableView>
  );
};

MTable.displayName = 'MTable';

export default React.forwardRef(MTable) as <T extends RowData = RowData>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<T>>> & React.RefAttributes<TableRef<T>>,
) => JSX.Element;
