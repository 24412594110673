const reports = {
  filterForm: {
    filter: 'Filter',
    export: 'Export',
    validation: {
      reportTypeRequired: 'Report type is required',
      startDateRequired: 'Start date is required',
      endDateRequired: 'End date is required',
    },
    form: {
      reportType: {
        label: 'Report type',
        reportTypeOptions: {
          selectReport: 'Select report',
          shuttlePassangers: 'Shuttle Passangers',
          dailySummary: 'Daily Summary',
          delaysReport: 'Delays Report',
          shuttleReport: 'Shuttle Report',
          vehicleOccupancy: 'Vehicle Occupancy',
          commuterSatisfaction: 'Commuter Satisfaction',
          passengerCostPareto: 'Passenger Cost - PARETO',
          carbonEfficiency: 'Carbon Efficiency',
          totalTripsPassengers: ' Total Trips/Passengers',
        },
      },
      selectShuttle: 'Select shuttle',
      dateStart: 'Start date',
      dateEnd: 'End date',
    },
  },
};

export default reports;
