const roadBlocks = {
  btnSave: 'Save',
  btnDelete: 'Delete',
  btnUpdateMap: 'Update Map',

  toasts: {
    delete: {
      success: 'Road blocks deleted successfully',
      error: 'Road blocks could not be deleted',
    },
    create: {
      success: 'Road blocks created successfully',
      error: 'Road blocks could not be created',
    },
    map: {
      success: 'Road blocks on map updated successfully',
      error: 'Road blocks on map could not be updated',
    },
  }
};

export default roadBlocks;
