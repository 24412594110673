const holidays = {
  headerButtons: {
    create: 'New',
    delete: 'Delete',
  },

  table: {
    headers: {
      code: 'Code',
      start: 'Start',
      end: 'End',
    },
  },

  create: {
    successToast: {
      body: 'Holiday Has Been Added Successfully',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Create Holiday',
    },
  },
  delete: {
    successToast: {
      body: 'Holidays Has Been Deleted Successfully',
    },
    errorToast: {
      body: 'Could Not Create Holiday',
    },
  },
  edit: {
    errorToast: {
      body: 'Could Not Create Holiday',
    },
    successToast: {
      body: 'Holiday Has Been Updated Successfully',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Update Holiday',
    },
    successModal: {
      header: 'Success',
      body: 'Holiday Updated!',
    },
  },

  forms: {
    details: {
      header: 'Details',
      fields: {
        name: 'Name',
        code: 'Code',
      },
    },
    dates: {
      header: 'Range of Dates',
      fields: {
        rangeSwitch: 'Range of Dates',
        start: 'Start',
        end: 'End',
        date: 'Date',
      },
    },
  },
};

export default holidays;
