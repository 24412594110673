import { IModule } from 'models/setting';

import * as actions from '../actions/auth';
import { User } from 'models/user';

const initialState: State = {
  user: null,
  settings: [],
  modules: {},
  token: null,
};

export const reducer = (state: State = initialState, action: actions.AuthTypes): State => {
  switch (action.type) {
    case actions.AuthActions.Logout:
      return { ...state, user: null };
    case actions.AuthActions.SetToken:
      return { ...state, token: action.payload.token };
    case actions.AuthActions.Login:
      return { ...state, user: action.payload.user };
    case actions.AuthActions.SetSettings:
      return { ...state, settings: action.payload };
    case actions.AuthActions.SetSetting:
      return {
        ...state,
        settings: state.settings.reduce(
          (acc: { name: string; value: string; site?: string }[], curr: { name: string; value: string; site?: string }) => {
            if (curr.name === action.payload.name) {
              return [...acc, action.payload];
            }

            return [...acc, curr];
          },
          [],
        ),
      };
    case actions.AuthActions.SetFavorites:
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
};

export interface State {
  user: User | null;
  settings: { name: string; value: string; site?: string }[];
  modules: Record<string, IModule>;
  token: string | null;
}
