import * as actions from '../actions/connections';

const initialState: State = {
  socket: null,
};

export const reducer = (state: State = initialState, action: actions.ConnectionsTypes): State => {
  switch (action.type) {
    case actions.ConnectionsActions.SetSocket:
      return { ...state, socket: action.payload.socket };
    default:
      return state;
  }
};

export interface State {
  socket: WebSocket | null;
}
