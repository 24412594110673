const support = {
  header: 'Administrador',
  howCreateUser: 'Como crio um novo usuário?',
  explainHowCreateaUser:
    'Para criar um novo usuário, faça login como administrador da web, clique na aba” Usuários” no menu do lado esquerdo, clique em “Novo usuário”, insira seus dados e clique em “Acrescentar” na parte inferior.',
  howCreateaJob: 'Como efetuo reservas?',
  explainHowCreateaJob:
    'Para fazer uma reserva, selecione &apos; reservas no menu principal e, em seguida, toque em &apos; Nova reserva . Insira as informações relevantes e clique em Reservar.',
  howCreateaJobManual: 'Como faço para rotular reservas manualmente?',
  explainHowCreateaJobManual:
    'No menu à esquerda, toque em reservas. A fim de conduzir as reservas desejadas em viagens compartilhadas otimizadas, selecione as reservas clicando no pequeno quadro à esquerda das reservas que você deseja otimizar (<b>Ou</b> o quadro à esquerda de Todas as páginas/ Todos os resultados) e em seguida, toque em linha',
  howMakeReports: 'Como visualizar relatórios?',
  explainMakeReports:
    'Os relatórios avançados podem ser encontrados na seção &quot;Relatórios&quot; aba no portal.',
  whereGuide: 'Onde posso encontrar um guia do usuário?',
  explaneWhereGuideStart:
    'Se você precisa de ajuda para começar, você pode ver nossa',
  explaneWhereGuideEnd: 'guia do usuário para administrador',
  infoHeader: 'E se eu precisar de falar com alguém?',
  infoBody1: 'Você pode nos entrar em contato através do Tel:',
  infoBody2: 'ou por e-mail:',
  infoBody3:
    'A nossa equipe de atendimento ao cliente está disponível 24 horas por dia, 7 dias por semana, para responder a quaisquer perguntas',
};

export default support;
