import { AxiosResponse, CancelTokenSource } from 'axios';

import { ICountRolesResponse, IPermissionsResponse } from 'models/http/roles';
import { IPermissionsModule, IRole } from 'models/permissions';
import { IPermissionsData, IRoleData } from 'pages/Permissions/Permissions.model';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { serverAxios } from 'utils/http';

class RoleHttp {
  private static route = '/';

  public async get(
    cancelTokenSource?: CancelTokenSource,
  ): Promise<{ modules: IPermissionsModule[]; roles: IRole[]; count: ICountRolesResponse }> {
    return serverAxios
      .post(
        RoleHttp.route,
        {
          queries: [
            {
              act: 'find',
              col: 'modules',
            },
            {
              act: 'find',
              col: 'roles',
            },
            {
              act: 'countroles',
            },
          ],
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IPermissionsResponse>) => {
        const [modulesResponse, rolesResponse, countRolesResponse] = response.data.results;

        return {
          modules: modulesResponse.data.filter((module: IPermissionsModule) => !!module.actions?.length),
          roles: rolesResponse.data,
          count: countRolesResponse,
        };
      });
  }

  public async update(
    data: { roles: IRoleData[]; permissions: IPermissionsData[] },
    cancelTokenSource?: CancelTokenSource,
  ): Promise<{ modules: IPermissionsModule[]; roles: IRole[]; count: ICountRolesResponse }> {
    const queries = data.roles.map((role: IRoleData) => {
      const perm = data.permissions.reduce((acc: { moduleCode: string; action: string }[], permData: IPermissionsData) => {
        if (!permData.rolesAccess[role.code]) {
          return acc;
        }

        return [...acc, { moduleCode: permData.moduleCode, moduleName: permData.module, action: permData.action, access: 'fullAccess' }];
      }, []);

      if (!role.exists) {
        const data = {
          roleId: role?.id,
          roleCode: role.code,
          roleName: role?.name,
          startDate: String(new Date()),
          permissions: perm,
        };

        return {
          act: 'insert',
          col: 'roles',
          data,
        };
      }

      return {
        act: 'update',
        col: 'roles',
        query: { _id: role._id },
        data: { permissions: perm },
      };
    });

    return serverAxios.post(
      '/',
      {
        queries,
      },
      {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Low,
      },
    );
  }
}

export default RoleHttp;
