//
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// eslint-disable-next-line
import 'react-app-polyfill/ie11'; // For IE 11 support
// eslint-disable-next-line
import 'react-app-polyfill/stable';

import { AbilityContext, ability } from './utils/ability';

import './styles/main.scss';
import reportWebVitals from './reportWebVitals';
import './polyfill';
import * as icons from './assets/icons';
import './i18n/i18n';
import App from './App';
import * as fromApp from './store/app';

// Icons
React.icons = icons;

// PandoraBot
window.bot_config = {
  PB_HOST: 'home',
  PB_BOTKEY: process.env.REACT_APP_PANDORA_BOT_KEY,
  title: 'Jenny',
  subtitle: 'Moovex Orchestrator',
  colors: { theme: '#d9066a', text: '#FFFFFF' },
  conversationOpener: 'Hi, I`m Jenni. We have some insights for you today!',
  descriptionTitle: 'Moovex',
  descriptionText: 'Fleet Intelligence',
  botAvatar: 'https://bit.ly/2HDf1I0',
};

window.cardknox = {
  setAccount: window.setAccount,
  getTokens: window.getTokens,
  setIfieldStyle: window.setIfieldStyle,
  clearIfield: window.clearIfield,
  enableAutoFormatting: window.enableAutoFormatting,
  focusIfield: window.focusIfield,
  addIfieldCallback: window.addIfieldCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <Provider store={fromApp.store}>
        <BrowserRouter basename="admin">
          <AbilityContext.Provider value={ability}>
            <App></App>
          </AbilityContext.Provider>
        </BrowserRouter>
      </Provider>
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
