import React from 'react';

import MRadioView from './MRadio.view';

interface Props {
  id?: string;
  name: string;
  checked: boolean;
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'];
  className?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MRadio: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MRadioView
      id={props.id}
      value={props.value}
      name={props.name}
      checked={props.checked}
      className={props.className}
      disabled={props.disabled}
      onChange={props.onChange}
    >
      {props.children}
    </MRadioView>
  );
};

MRadio.displayName = 'MRadio';
MRadio.defaultProps = {};

export default MRadio;
