import React from 'react';

import classes from './MCardHeader.module.scss';

interface Props {
  className?: string;
  color?: string;
  id?: string;
}

const MCardHeaderView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${classes['container']} ${props.className || ''}`} color={props.color} id={props.id}>
      {props.children}
    </div>
  );
};

MCardHeaderView.displayName = 'MCardHeaderView';
MCardHeaderView.defaultProps = {};

export default MCardHeaderView;
