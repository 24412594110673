import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetSuppliersFilters } from 'models/http/supplier';
import { IGetSuppliersResponse } from 'models/response';
import { ISupplier } from 'models/supplier';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class SupplierHttp {
  private static route = '/';

  public async get(filters: IGetSuppliersFilters, cancelTokenSource?: CancelTokenSource): Promise<ISupplier[]> {
    return serverAxios
      .post(SupplierHttp.route, {
        act: 'find',
        col: 'suppliers',
        query: filters,
      },
      {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Medium,
      },
      )
      .then((response: AxiosResponse<IGetSuppliersResponse>) => {
        return response.data.data;
      });
      
  }
}

export default SupplierHttp;
