import moment from 'moment-timezone';

import { MDateNavigatorDirection } from 'views/MDateNavigator/MDateNavigator.model';
import { MTableAdvancedFilters } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';

type ReturnFunction = (direction: MDateNavigatorDirection) => void;

const useDateNavHandlerAdvanced = <Ids extends string>(
  columnName: Ids | null,
  filtersCurrentState: MTableAdvancedFilters<Ids>,
  onUpdate: (filters: MTableAdvancedFilters<Ids>) => void,
): ReturnFunction | null => {
  if (!columnName) {

    return null;
  }

  const handler: ReturnFunction = (direction: MDateNavigatorDirection) => {
    if (direction === MDateNavigatorDirection.Now) {
      onUpdate({ ...filtersCurrentState, [columnName]: [moment(), null] });

      return;
    }

    const daysToAdd = {
      [MDateNavigatorDirection.Now]: 0,
      [MDateNavigatorDirection.Backword]: -1,
      [MDateNavigatorDirection.Forward]: 1,
    };

    const currentValue = filtersCurrentState[columnName]?.[0] || moment();
    const newStartDate = currentValue.add({ days: daysToAdd[direction] }).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const newEndDate = newStartDate.clone().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });

    onUpdate({ ...filtersCurrentState, [columnName]: [newStartDate, newEndDate] });
  };

  return handler;
};

export default useDateNavHandlerAdvanced;
