import { FormikContextType } from 'formik';
import React from 'react';
import { OnChangeValue } from 'react-select';

import WithoutSite from 'views/WithoutSite/WithoutSite';
import MFormServerSelect from 'views/MForm/MFormFields/MFormServerSelect/MFormServerSelect';
import MFormFieldWrap from 'views/MForm/MFormFieldWrap/MFormFieldWrap';

import { TableName } from 'utils/enums/table-page';
import setFieldProps from 'utils/setFieldProps';
import { MSelectOption } from 'views/MSelect/MSelect.model';
import { IMFormSiteField } from './MFormSiteField.model';

interface Props<Fields extends IMFormSiteField> {
  formInstance: FormikContextType<Fields>;
  isClearable?: boolean;
  onChange: () => void;
}

const MFormSiteFieldView = <Fields extends IMFormSiteField>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  return (
    <WithoutSite>
      <MFormFieldWrap name="site" displayName="Site">
        <MFormServerSelect
          {...setFieldProps<Fields, string, [OnChangeValue<MSelectOption, false>], string, string>(props.formInstance, 'site', {
            beforeChange: (event: OnChangeValue<MSelectOption, false>) => event?.id || '',
            onChange: props.onChange,
          })}
          table={TableName.Units}
          field="name"
          isClearable={props.isClearable}
        ></MFormServerSelect>
      </MFormFieldWrap>
    </WithoutSite>
  );
};

MFormSiteFieldView.displayName = 'MFormSiteFieldView';
MFormSiteFieldView.defaultProps = {};

export default MFormSiteFieldView;
