const parents = {
  tabs: {
    history: 'History',
    information: 'Information',
  },

  modal: {
    header: 'Change Status',
    body: 'Are you sure you want to change the parent status to ',
  },

  table: {
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
    },

    header: {
      createBtn: 'Create',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      status: 'status',
      address: 'Address',
      createdBy: 'Created By',
      createdAt: 'Created At',
    },
  },
  form: {
    create: {
      toasts: {
        success: 'Parent successfully created',
        error: 'An error occured while creating the parent',
      },
    },
    update: {
      toasts: {
        success: 'Parent successfully updated',
        error: 'An error occured while updating the parent',
        fetchError: 'Something went wrong, please try again.',
      },
    },
    sections: {
      groups: {
        header: 'Site & Profile',
        fields: {
          profile: {
            label: 'Profile',
          },
        },
      },
      personalDetails: {
        header: 'Personal Details',
        fields: {
          firstName: {
            label: 'First name',
          },
          lastName: {
            label: 'Last name',
          },
          email: {
            label: 'Email',
          },
          phone: {
            label: 'Phone',
          },
          additionalPhone: {
            label: 'Additional phone',
          },
          address: {
            label: 'Address',
          },
        },
      },
      linkStudent: {
        header: 'Students',
        btnAddStudent: 'Add Student',
        btnRemoveStudent: 'Remove Student',
        searchPlaceholder: 'Search',
      },
    },
  },

  studentsTable: {
    headers: {
      name: 'Name',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
    },
  },
};

export default parents;
