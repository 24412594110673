import React, { useState } from 'react';
import { TFunctionResult } from 'i18next';

import MFormCardView from './MFormCard.view';

interface Props {
  className?: string;
  header?: string | React.ReactChild | TFunctionResult;
  bodyClassName?: string;
  headerClassName?: string;
  showToggler?: boolean;
}

const MFormCard: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const [showState, setShowState] = useState<boolean>(true);

  return (
    <MFormCardView
      className={props.className}
      onToggleClick={() => setShowState((prev: boolean) => !prev)}
      showToggler={props.showToggler}
      showBody={showState}
      header={props.header}
      bodyClassName={props.bodyClassName}
      headerClassName={props.headerClassName}

    >
      {props.children}
    </MFormCardView>
  );
};

MFormCard.displayName = 'MFormCard';
MFormCard.defaultProps = {};

export default MFormCard;
