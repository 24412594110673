import { ISite } from 'models/site';
import * as actions from '../actions/sites';

const initialState: State = {
  viewSite: null,
  sites: [],
};

export interface State {
  viewSite: ISite | null;
  sites: ISite[];
}

export const reducer = (state: State = initialState, action: actions.SiteTypes): State => {
  switch (action.type) {
    case actions.SitesActions.SetViewSite:
      return { ...state, viewSite: action.payload };
    case actions.SitesActions.SetSites:
      return { ...state, sites: action.payload };
    case actions.SitesActions.AddSite:
      return { ...state, sites: [...state.sites, action.payload] };
    default:
      return state;
  }
};
