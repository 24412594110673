import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CSSObjectWithLabel, GroupBase, OnChangeValue, OptionProps, SingleValue, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';

import MAddressInputMenu from './MAddressInputMenu/MAddressInputMenu';

import { AppState } from '../../models/app-store';
import { MAddressSelectOption } from './MAddress.model';

interface Props {
  customStylesSelect?: StylesConfig<MAddressSelectOption, false, GroupBase<MAddressSelectOption>>;
  name?: string;
  value?: SingleValue<MAddressSelectOption>;
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  size?: string;
  placeholder?: string;
  onChange?: (value: OnChangeValue<MAddressSelectOption, false>) => void;
  loadOptions: (inputValue: string, callback: (prop: MAddressSelectOption[]) => void) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onMenuClose?: () => void;
}

const Menu = MAddressInputMenu;

const MAddressView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const darkMode = useSelector((state: AppState) => state.ui.darkMode);

  const customStylesSelect = useMemo((): StylesConfig<MAddressSelectOption, false> => {
    return {
      menu(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          background: darkMode ? 'black' : 'white',
          zIndex: 2,
        };
      },
      option(provided: CSSObjectWithLabel, state: OptionProps<MAddressSelectOption, false>) {
        return {
          ...provided,
          'backgroundColor': state.isSelected ? 'blue' : darkMode ? 'black' : 'white',
          ':hover': {
            background: '#1e90ff',
          },
          ':active': {
            background: 'purple !important',
          },
        };
      },
      control(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          background: 'rgba(255, 255, 255, 0.1)',
          borderColor: props.invalid ? '#e55353' : props.valid ? '#2eb85c' : darkMode ? 'rgba(74, 74, 79, 0.655)' : '#e4e7ea',
          height: props.size === 'sm' ? 28.36 : 35,
          minHeight: props.size === 'sm' ? 28.36 : 35,
          color: '#fff',
        };
      },
      singleValue(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          color: '#fff',
        };
      },
      input(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          color: '#fff',
          maxWidth: '100%',
        };
      },
      indicatorsContainer(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          height: '100%',
        };
      },
      valueContainer(provided: CSSObjectWithLabel) {
        return {
          ...provided,
          padding: '0 8px',
        };
      },
      ...props.customStylesSelect,
    };
  }, [props.valid, props.invalid, darkMode]);

  return (
    <AsyncSelect
      cacheOptions
      styles={customStylesSelect}
      loadOptions={props.loadOptions}
      defaultOptions
      components={{ Menu }}
      onChange={props.onChange}
      value={
        props.value?.value && !props.placeholder
          ? { value: props.value?.value || '', label: (props.value?.value && props.value?.value.split('|')[0]) || '' }
          : null
      }
      onBlur={props.onBlur}
      onMenuClose={props.onMenuClose}
      isDisabled={props.disabled}
      placeholder={props.placeholder}
    ></AsyncSelect>
  );
};

MAddressView.displayName = 'MAddressView';
MAddressView.defaultProps = {};

export default MAddressView;
