const attributes = {
  toast: {
    error: {
      badResponse: '[Attribute] update error',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {
        default: '[Attribute] has been updated successful',
      },
    },
  },
  table: {
    headerButtons: {
      create: 'New',
      delete: {
        label: 'Delete',
        deleteModal: {
          header: 'Delete Attributes',
          body: 'You are about to delete {{attributes}} attributes',
        },
      },
      clear: {
        label: 'Clear',
        clearModal: {
          header: 'Clear Attributes',
          body: 'You are about to clear {{attributes}} attributes',
        },
      },
    },
    placeholder: {
      select: 'Select',
    },
    headers: {
      name: 'Name',
      site: 'Site',
      assignPriority: 'Assign Priority',
    },
  },
  form: {
    information: {
      details: {
        header: 'General Information',
        fields: {
          name: 'Name',
          site: 'Site',
          assignPriority: 'Assign Priority',
        },
      },
      advancedSettings: {
        header: 'Advanced Settings',
        fields: {
          parameters: 'Parameters',
        },
      },
    },
    valdations: {
      name: {
        required: 'Name is required',
        name: 'Invalid Name',
      },
      unit: {
        required: 'Unit is required',
        name: 'Invalid Unit',
      },
      parameters: {
        required: 'Paraemters are required',
        name: 'Check carefully the values you are passing',
      },
    },
    create: {
      toastError: {
        message: 'Could Not Create',
      },
      toastSuccess: {
        message: 'Created successfully',
      },
    },

    edit: {
      formTabs: {
        generalInfoTab: 'General',
        historyTab: 'History',
      },

      toastError: {
        message: 'Could Not Update',
      },
      toastSuccess: {
        message: 'Updated successfully',
      },
    },
  },
};

export default attributes;
