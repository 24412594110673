const locations = {
  toast: {
    error: {
      badResponse: '[localização] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    new: 'Novo',
    moreActions: {
      title: 'Mais',
      delete: 'Remover',
      export: 'Exportar',
      showOnMap: {
        title: 'Mostrar no mapa',
        mapModal: {
          header: 'Mostrar no mapa',
          closeBtn: 'Fechar',
        },
      },
    },
  },
  table: {
    headers: {
      name: 'Nome',
      type: 'Tipo',
      address: 'Endereço',
      lat: 'Latitude',
      lon: 'Longitude',
      locationTypes: {
        Location: 'Localização',
        InAndOut: 'Dentro e Fora',
        Airport: 'Aeroporto',
        School: 'Escola',
      },
      wait: 'Aguarde',
    },
  },
  form: {
    addressInformation: {
      title: 'Informação de endereço',
      name: 'Nome',
      type: 'Tipo',
      wait: 'Aguarde',
      address: 'Endereço',
      locationTypes: {
        General: '',
        Special: 'Especial',
        Airport: 'Aeroporto',
        InAndOut: 'Dentro e Fora',
        School: 'Escola',
        Work: 'Trabalho',
      },
    },
    advanced: {
      title: 'Avançado',
      addTime: {
        addTimeBtn: 'Adicione tempo',
        name: 'Nome',
        minutes: 'Minutos',
        enterMinutes: 'Inserir minutos',
        enterName: 'Inserir Nome',
      },
    },
    map: {
      title: 'Mapa',
    },
    site: {
      title: 'Local',
    },
    info: {
      subLocations: {
        addSubLocationBtn: 'Adicione Terminal',
        header: 'Terminal',
        fields: {
          subLocation: 'Endereço',
          name: {
            label: 'Nome do endereço',
            placeholder: 'Nome',
          },
        },
      },
    },
  },
  update: {
    confirmButtonText: 'Atualizar',
    formTabs: {
      edit: 'Editar',
      history: 'Histórico',
    },
    successModal: {
      header: 'Atualizado!',
      body: 'Localização atualizada com sucesso.',
    },
    errorModal: {
      header: 'Erro',
      body: 'Não foi possível atualizar a localização.',
    },
  },
  create: {
    successModal: {
      header: 'Criado!',
      body: 'Localização criada com sucesso.',
    },
    errorModal: {
      header: 'Erro',
      body: 'Não foi possível criar localização.',
    },
  },
};

export default locations;
