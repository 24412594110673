import React from 'react';

import MTable from 'views/MTable/MTable';
import MRadioGroup from 'views/MRadioGroup/MRadioGroup';

import { HomeAgentTableBookingsData } from './HomeAgentTableBookings.model';
import { MColumnOptions } from 'views/MTable/Table.model';
import { useTranslation } from 'react-i18next';

interface Props {
  data: HomeAgentTableBookingsData[];
  columns: string[];
  columnsOptions: MColumnOptions<HomeAgentTableBookingsData>;
  limitState: number;
  onChangeDisplay: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const HomeAgentTableBookingsView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.data.length ? (
        <MRadioGroup
          name="limitDisplay"
          value={props.limitState.toString()}
          options={[
            { id: '5', label: t('agent.radioButton.showFive') },
            { id: '10', label: t('agent.radioButton.showTen') },
          ]}
          onChange={props.onChangeDisplay}
        ></MRadioGroup>
      ) : null}
      <MTable
        data={props.data}
        columns={props.columns}
        columnsOptions={props.columnsOptions}
        loading={false}
        hideSelection
        disableFilters
      ></MTable>
    </React.Fragment>
  );
};

HomeAgentTableBookingsView.displayName = 'HomeAgentTableBookingsView';
HomeAgentTableBookingsView.defaultProps = {};

export default HomeAgentTableBookingsView;
