import React from 'react';
import Statuses from 'utils/enums/statuses';
import { MSelectOption } from 'views/MSelect/MSelect.model';
import { MTableAdvancedFilterProps } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import MSelectFilter from 'views/MTable/TableFilters/MSelectFilterAdvanced/MSelectFilter';

type Props = MTableAdvancedFilterProps<Statuses[]> & {
  options: MSelectOption<null, Statuses>[];
};

const ParentsTableFilterStatusView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MSelectFilter {...props} isMulti options={props.options}>
      {props.children}
    </MSelectFilter>
  );
};

ParentsTableFilterStatusView.displayName = 'ParentsTableFilterStatusView';
ParentsTableFilterStatusView.defaultProps = {};

export default ParentsTableFilterStatusView;
