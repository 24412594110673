import { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';

import { RequestBody } from 'views/MTable/MServerTable/MServerTable';
import MSelectTableView from './MSelectTable.view';

import { BaseQuery, MColumnOptions, RowData } from 'views/MTable/Table.model';
import { TableName } from 'utils/enums/table-page';
import { Filters } from 'react-table';

interface Props<T extends RowData> {
  columns: string[];
  columnsOptions?: MColumnOptions<T>;
  generalFilterColumns?: Props<T>['columns'][number][];
  showOnlyWithGeneralFilter?: boolean;
  baseQuery?: BaseQuery;
  name?: TableName;
  hideFooter?: boolean;
  showNoResults?: boolean;
  limit?: number;
  generalFilterPlaceholder?: string;
  isMulty?: boolean;
  generalFilter?: string;
  isButtonClicked?: boolean;
  data?: Partial<T>[] | null;
  className?: string;
  fetch?: (queryData: RequestBody, cancelToken: CancelTokenSource) => Promise<{ count: number; data: T[] }>;
  onSelect?: (row: T) => void;
  onSelectMany?: (row: Partial<T>[]) => void;
  onFiltersChange?: (filters: Filters<T>, generalFilter: string) => void;
}

const MSelectTable = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const [selectedRow, setSelectedRowState] = useState<Partial<T>[]>([]);

  const handleSelectRow = (row) => {
    if (props.isMulty) {
      setSelectedRowState((prev) => {
        const unSelected = prev.findIndex((item: Partial<T>) => {
          return item['_id'] === row['_id'];
        });

        const tempPrev = [...prev];

        if (unSelected > -1) {
          tempPrev.splice(unSelected, 1);

          props.onSelectMany && props.onSelectMany(tempPrev);

          return tempPrev;
        } else {
          props.onSelectMany && props.onSelectMany([...prev, row]);
        }

        return [...tempPrev, row];
      });
    } else {
      setSelectedRowState(row);

      props.onSelect && props.onSelect(row);
    }
  };

  useEffect(() => {
    if (props.data) {
      setSelectedRowState(() => []);
    }
  }, [props.data]);

  return (
    <MSelectTableView
      className={props.className}
      columns={props.columns}
      limit={props.limit}
      generalFilterColumns={props.generalFilterColumns}
      columnsOptions={props.columnsOptions}
      selectedRow={selectedRow}
      fetch={props.fetch}
      hideFooter={props.hideFooter}
      baseQuery={props.baseQuery}
      showOnlyWithGeneralFilter={props.showOnlyWithGeneralFilter}
      generalFilterPlaceholder={props.generalFilterPlaceholder}
      name={props.name}
      isMulty={props.isMulty}
      showNoResults={props.showNoResults}
      onChange={handleSelectRow}
      generalFilter={props.generalFilter}
      onFiltersChange={props.onFiltersChange}
    ></MSelectTableView>
  );
};

MSelectTable.displayName = 'MSelectTable';

export default MSelectTable;
