const units = {
  toast: {
    error: {
      badResponse: '[Unidade] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    create: 'Novo',
    delete: {
      label: 'Remover',
      deleteModal: {
        header: 'Excluir Unidades',
        body: 'Está prestes a eliminar {{units}} unidades',
      },
    },
  },
  create: {
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar',
    },
  },
  edit: {
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar',
    },
  },
  form: {
    information: {
      details: {
        header: 'Detalhes',
        fields: {
          name: 'Nome',
        },
      },
      info: {
        header: 'Informação',
        fields: {
          name: 'Operação',
        },
      },
    },
    types: {
      Default: 'Padrão',
      School: 'Escola',
      Employees: 'Empregados',
    },
    valdations: {
      name: {
        required: 'Um nome é requerido',
        name: 'Nome inválido',
      },
    },
  },
};

export default units;
