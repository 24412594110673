const workShifts = {
  form: {
    general: {
      header: 'Geral',
      fields: {
        students: {
          label: 'Selecionar Empregados',
        },
      },
    },
    details: {
      header: 'Detalhes',
      fields: {
        direction: {
          label: 'Direção',
        },
        shift: {
          label: 'Horas de trabalho',
        },
        from: 'De',
        to: 'Para',
        fromDate: {
          label: 'A partir da Data',
        },
        toDate: {
          label: 'Até à data',
        },
        weekDays: 'Dias úteis',
        fromHour: {
          label: 'Os Trabalhos Iniciam às',
        },
        reqPick: {
          label: 'Horário de embarque solicitado',
        },
        toHour: {
          label: 'O trabalho termina às',
        },
      },
    },
  },
  select: 'Selecione',
  direction: {
    collect: {
      templates: {
        default: 'A recolher',
        schoolShuttles: 'Para a Escola',
        employees: 'Para trabalhar',
        trailers: 'Recolher'
      },
    },
    distribute: {
      templates: {
        default: 'Distribuir',
        schoolShuttles: 'Da escola',
        employees: 'Do Trabalho',
        trailers: 'Distribuir',
      },
    },
    collectAndDistribute: {
      templates: {
        default: 'Coletar/Distribuir',
        schoolShuttles: 'Para a Escola / Da Escola',
        employees: 'Para o trabalho / Do trabalho',
        trailers: 'Coletar/Distribuir',
      },
    },
  },
};

export default workShifts;
