import React from 'react';

import PandoraBot from 'pages/PandoraBot/PandoraBot';

import Loading from 'views/MLoading/MLoading';

import { Navigate, Route, Routes } from 'react-router-dom';
import classes from './App.module.scss';
import { AppRouteType, IAppRoute } from './App.model';
import RouteConfig from 'components/RouteConfig/RouteConfig';

interface Props {
  loadingSpinner: number | boolean;
  isAuthenticated: boolean;
  routesList: IAppRoute[];
}

const AppView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const generateRoutes = (routes: IAppRoute[]) => {
    return routes.map((route: IAppRoute) => {
      const Component: React.FC | undefined = route['component'];
      const jsx: JSX.Element = route['jsx'];

      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.type === AppRouteType.Fallback ? (
              <Navigate to={route.to}></Navigate>
            ) : (
              <React.Fragment>
                <RouteConfig pageName={route.pageName}></RouteConfig>
                {Component ? <Component></Component> : jsx}
              </React.Fragment>
            )
          }
        >
          {route.children ? generateRoutes(route.children) : null}
        </Route>
      );
    });
  };

  return (
    <React.Fragment>
      <PandoraBot></PandoraBot>
      <Loading
        show={props.loadingSpinner !== false}
        percent={typeof props.loadingSpinner === 'number' ? props.loadingSpinner : null}
      ></Loading>
      <React.Suspense
        fallback={
          <div className={classes['loading']}>
            <div className="sk-spinner sk-spinner-pulse"></div>
          </div>
        }
      >
        <Routes>{generateRoutes(props.routesList)}</Routes>
      </React.Suspense>
    </React.Fragment>
  );
};

AppView.displayName = 'AppView';
AppView.defaultProps = {};

export default AppView;
