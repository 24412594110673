const systemSettings = {
  headerButtons: {
    create: 'New',
    delete: 'Delete',
  },

  table: {
    headers: {
      unit: 'Site',
    },
    actions: {
      resetAll: 'Reset all',
      loadToCache: 'Load to cache',
      save: 'Save',
    },
  },

  form: {
    header: 'Details',
    label: 'Label',
    description: 'Description',
    name: 'Name',
    profiles: 'Profiles',
    type: 'Type',
    site: 'Site',
    value: 'Value',
    category: 'Category',
    typeOptions: {
      String: 'String',
      Boolean: 'Boolean',
      Object: 'Object',
      Array: 'Array',
      Integer: 'Integer',
      Range: 'Range',
      Float: 'Float',
    },
    categoryOptions: {
      Misc: 'Misc',
      RoutingEngine: 'Routing Engine',
      RoutingConfig: 'Routing Config',
      RouteingAssign: 'Routeing Assign',
      Reservations: 'Reservations',
    },
    select: 'Select',
  },

  create: {
    confirmationModal: {
      header: 'Success!',
      body: 'Setting Created Successfuly',
    },
    errorModal: {
      header: 'Error!',
      body: 'Could not create setting',
    },
  },

  update: {
    formTabs: {
      historyTab: 'History tab',
      editTab: 'Edit tab',
    },
    confirmationModal: {
      header: 'Success!',
      body: 'Setting Updated Successfuly',
    },
    errorModal: {
      header: 'Error!',
      body: 'Could not update setting',
    },
  },
};

export default systemSettings;
