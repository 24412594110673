import React, { useEffect, useRef } from 'react';

const useUnmoutedFlag = (): React.MutableRefObject<boolean> => {
  const unmountedFlagRef = useRef<boolean>(false);

  useEffect(() => {
    unmountedFlagRef.current = false;

    return () => {
      unmountedFlagRef.current = true;
    };
  }, [unmountedFlagRef]);

  return unmountedFlagRef;
};

export default useUnmoutedFlag;
