import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Row } from '@tanstack/react-table';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import MServerTableView from './MTableAdvancedServer.view';

import { SessionStorageTablesKeys } from 'utils/enums/storage';
import { TableName } from 'utils/enums/table-page';
import { useDateNavHandlerAdvanced, useTableCacheEffectAdvanced } from 'utils/hooks';
import useDebounceState from 'utils/hooks/use-debounce-state';
import {
  MTableAdvancedColumn,
  MTableAdvancedExpand,
  MTableAdvancedFilters,
  MTableAdvancedRowData,
  MTableAdvancedSorting,
} from '../MTableAdvanced.model';
import { DEFAULT_ROWS_LIMIT } from './MTableAdvancedServer.utils';
import { IDropdownAction } from 'views/MDropdown/MDropdown.model';
import {
  MTableAdvancedServerBaseQuery,
  MTableAdvancedServerCacheEffectData,
  MTableAdvancedServerFilterRender,
  MTableAdvancedServerRef,
  MTableAdvancedServerRequestBody,
} from './MTableAdvancedServer.model';
import { AppState } from 'models/app-store';
import useCancelToken from 'utils/hooks/use-cancel-token-effect';
import { useSelector } from 'react-redux';
import { TimeFormat } from 'utils/enums/time-format';
import moment from 'moment';
import { serverAxios } from 'utils/http';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import useTableCacheAdvanced from 'utils/hooks/use-table-cache-advanced';
import { FilterValue } from 'react-table';
import MIziToast from 'views/MIziToast/MIziToast';
import { useTranslation } from 'react-i18next';

type Props<
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
> = {
  columns: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>[];
  generalFilterColumns?: Id[];
  hiddenColumns?: Id[];
  className?: string;
  baseQuery?: MTableAdvancedServerBaseQuery;
  hideFooter?: boolean;
  generalFilterPlaceholder?: string;
  initialSort?: MTableAdvancedSorting<T>;
  disableFilters?: boolean;
  disablePagination?: boolean;
  initialFilters?: MTableAdvancedFilters<Id>;
  filters?: MTableAdvancedFilters<Id>;
  headerButtons?: JSX.Element | JSX.Element[] | null;
  allowHeaderMaxWidth?: boolean;
  limit?: number;
  name?: TableName;
  hideSelection?: boolean;
  page?: number;
  sortValue?: MTableAdvancedSorting<T>[];
  openExpandLabel?: string;
  closeExpandLabel?: string;
  hideDateNavigator?: boolean;
  selectedRow?: Partial<T>;
  selectedRows?: RowIdValue[];
  rowClassName?: string;
  hideColumnSelect?: boolean;
  footerDataView?: JSX.Element | JSX.Element[];
  disableCache?: boolean;
  cacheKey?: SessionStorageTablesKeys;
  showOnlyWithGeneralFilter?: boolean;
  dateNavigatorCell?: Id;
  deleteButton?: boolean;
  disableDeleteBtn?: boolean;
  expand?: MTableAdvancedExpand<T>;
  onDeleteBtnClick?: () => void;
  onRowClick?: (row: Row<T>) => void;
  fetch?: (queryData: MTableAdvancedServerRequestBody, cancelToken: CancelTokenSource) => Promise<{ count: number; data: T[] }>;
  getRowId?: (row: T) => RowIdValue;
  renderExpand?: (row: Row<T>) => JSX.Element;
  onFiltersChange?: (filters: MTableAdvancedFilters<Id>, generalFilter: string) => void;
  onClearFilters?: (prevFilters: MTableAdvancedFilters<Id>) => MTableAdvancedFilters<Id> | void;
  onSelectionChange?: (items: RowIdValue[], allResultsQuery: MTableAdvancedServerBaseQuery | false) => void;
};

const MServerTable = <
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
>(
    props: React.PropsWithChildren<Props<T, Id, RowIdValue>>,
    ref: React.ForwardedRef<MTableAdvancedServerRef>,
  ): JSX.Element => {
  const { t } = useTranslation();
  const setFetchCancelTokenState = useCancelToken();
  const viewSite = useSelector((state: AppState) => state.sites.viewSite)?.name;

  const initialColumnOptions = useMemo(() => {
    return props.columns.reduce(
      (acc: IDropdownAction<boolean, Id>[], column: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>) => {
        if (props.hiddenColumns?.includes(column.id)) {
          return acc;
        }

        const label = column.header || column.id[0].toUpperCase() + column.id.slice(1);

        const item: IDropdownAction<boolean, typeof column.id> = {
          value: true,
          title: column.key,
          label,
          id: column.id,
        } as unknown as IDropdownAction<boolean, typeof column.id>;

        return [...acc, item];
      },
      [],
    );
  }, [props.columns, props.hiddenColumns]);

  const [limitState, setLimitState] = useState<number | null>(null);
  const [pageState, setPageState] = useState<number | null>(null);
  const [sortState, setSortState] = useState<MTableAdvancedSorting<T> | null>(false);
  const [filtersState, debouncedFiltersState, setFiltersState] = useDebounceState<MTableAdvancedFilters<Id> | null>(null, 250);
  const [generalFilterState, debouncedGeneralFilterState, setGeneralFilterState] = useDebounceState<string | null>(null, 250);
  const [dataState, setDataState] = useState<T[]>([]);
  const [allResultsState, setAllResultsState] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [pagesState, setPagesState] = useState<number>(1);
  const [countState, setCountState] = useState<number>(1);
  const [selectedState, setSelectedState] = useState<RowIdValue[]>([]);
  const [columnItemsState, setColumnItemsState] = useState<IDropdownAction<boolean, Id>[]>(initialColumnOptions);

  const initialTableDataReady =
    debouncedFiltersState !== null &&
    debouncedGeneralFilterState !== null &&
    sortState !== null &&
    pageState !== null &&
    limitState !== null;

  const prepareRequestBody = useCallback(
    (
      filters: MTableAdvancedFilters<Id>,
      generalFilter: string,
      sorting?: MTableAdvancedSorting<T>,
      page?: number,
      limit = 0,
    ): MTableAdvancedServerRequestBody => {
      const queryFieldsFilters = Object.entries(filters).reduce<MTableAdvancedServerBaseQuery>(
        (queryAcc: MTableAdvancedServerBaseQuery, filterField: [id: string, value: FilterValue]) => {
          const filter = { id: filterField[0], value: filterField[1] };

          // Free text
          if (typeof filter.value === 'string') {
            return {
              ...queryAcc,
              [filter.id]: {
                $regex: filter.value,
                $options: 'i',
              },
            };
          }

          // Array of values
          if (Array.isArray(filter.value)) {
            // Date Range
            if (filter.value.length === 2) {
              const dateQuery: { $gte?: string; $lte?: string } = {};

              if (moment.isMoment(filter.value[0])) {
                dateQuery.$gte = filter.value[0].format(TimeFormat.ServerFormatDateTime);
              }

              if (moment.isMoment(filter.value[1])) {
                dateQuery.$lte = filter.value[1].format(TimeFormat.ServerFormatDateTime);
              }

              if (Object.values(dateQuery).length > 0) {
                return {
                  ...queryAcc,
                  [filter.id]: dateQuery as { $gte: string; $lte: string },
                };
              }
            }

            // Array of values (but not date range)
            if (filter.value.length > 0) {
              return {
                ...queryAcc,
                [filter.id]: { $in: filter.value },
              };
            }

            return queryAcc;
          }

          // Date
          if (moment.isMoment(filter.value)) {
            return {
              ...queryAcc,
              [filter.id]: filter.value.format(TimeFormat.ServerFormatDateTime),
            };
          }

          return queryAcc;
        },
        {},
      );

      let queryFilters = queryFieldsFilters;

      // General search query
      if (props.generalFilterColumns && generalFilter.length > 0) {
        const uniqueColumnsNames = Array.from(new Set(props.generalFilterColumns));

        queryFilters = {
          ...queryFieldsFilters,
          $or: uniqueColumnsNames.map((column: keyof T) => {
            return { [column]: { $regex: generalFilter, $options: 'i' } };
            // TO SUPORT NUMBERS:
            // return {
            //   $expr: {
            //     $regexMatch: {
            //       input: { $toString: `$${column}` },
            //       regex: generalFilter,
            //       options: 'i',
            //     },
            //   },
            // };
          }),
        };
      }

      if (viewSite) {
        queryFilters.unit = viewSite;
      }

      const request: MTableAdvancedServerRequestBody = {
        skip: Math.max((page || 1) - 1, 0) * limit,
        query: { ...props.baseQuery, ...queryFilters },
        limit,
      };

      if (sorting) {
        request.sort = { [sorting.id]: sorting.direction === 'asc' ? 1 : -1 };
      }

      return request;
    },
    [props.generalFilterColumns?.join(','), props.name, JSON.stringify(props.baseQuery), viewSite],
  );

  const updateFilters = useCallback(
    (updater: (prevState: MTableAdvancedFilters<Id> | null) => MTableAdvancedFilters<Id>) => {
      setFiltersState((prev: MTableAdvancedFilters<Id> | null) => {
        const newFilters = updater(prev);

        setPageState(() => 1);

        return newFilters;
      });
    },
    [setFiltersState, setPageState],
  );

  const updateGeneralFilter = useCallback(
    (updater: (prevState: string | null) => string) => {
      setGeneralFilterState((prev: string | null) => {
        const newGeneralFilter = updater(prev);

        setPageState(() => 1);

        return newGeneralFilter;
      });
    },
    [setGeneralFilterState],
  );

  const updateLimit = useCallback(
    (newLimit: number) => {
      const limitChange$ = new Promise<number | null>((resolve) => {
        setLimitState((prev: number | null) => {
          if (prev) {
            const limitRatio = prev / newLimit;

            resolve(limitRatio);
          }

          resolve(null);

          return newLimit;
        });
      });

      limitChange$.then((limitRatio: number | null) => {
        if (!limitRatio) {
          return;
        }

        setPageState((prevPage: number | null) => {
          if (!prevPage) {
            return prevPage;
          }

          return limitRatio < 1 ? Math.ceil(limitRatio * prevPage) : Math.ceil(limitRatio * (prevPage - 1)) + 1;
        });
      });
    },
    [setLimitState, setPageState],
  );

  const cacheTable = useTableCacheAdvanced(props.cacheKey || `table_${props.name}`);

  const updateDateNavigatorFilter = useDateNavHandlerAdvanced(
    props.dateNavigatorCell || null,
    filtersState || {},
    (filters: MTableAdvancedFilters<Id>) => updateFilters(() => filters),
  );

  const clearFilters = () => {
    updateGeneralFilter(() => '');
    updateFilters((prev: MTableAdvancedFilters<Id> | null) => props.onClearFilters?.(prev || {}) || {});
  };

  const fetchData = useCallback(
    (requestBodyData: MTableAdvancedServerRequestBody) => {
      setLoadingState(() => true);

      const defaultFetch = async (
        queryData: MTableAdvancedServerRequestBody,
        cancelToken: CancelTokenSource,
      ): Promise<{ count: number; data: T[] }> => {
        return serverAxios
          .post(
            '/',
            {
              act: 'find',
              col: props.name,
              count: true,
              ...queryData,
            },
            {
              cancelToken: cancelToken.token,
              timeout: HttpTimeoutPriority.Medium,
            },
          )
          .then((response: AxiosResponse<Response & { data: T[]; count: number }>): { count: number; data: T[] } => {
            const { data, count } = response.data;

            return { count, data };
          })
          .catch((error) => {
            console.error(error);

            return { count: 0, data: [] };
          });
      };

      const fetchFunc = props.fetch || defaultFetch;
      const cancelToken = axios.CancelToken.source();

      setFetchCancelTokenState(() => cancelToken);
      fetchFunc(requestBodyData, cancelToken)
        .then((body: { count: number; data: T[] }) => {
          setDataState(() => body.data);
          setPagesState(() => (body.count ? Math.ceil(body.count / (limitState || DEFAULT_ROWS_LIMIT)) : 0));
          setCountState(() => body.count);
          setSelectedState(() => []);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingState(() => false);
          setFetchCancelTokenState(() => null);
        });
    },
    [setLoadingState, props.fetch, limitState],
  );

  const forceFetch = () => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      limitState === null ||
      sortState === null ||
      pageState === null
    ) {
      console.error('Can not fetch data when request body is not ready.');

      return;
    }

    return fetchData(prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState));
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        forceFetch,
      };
    },
    [
      setDataState,
      fetchData,
      dataState,
      prepareRequestBody,
      debouncedFiltersState,
      debouncedGeneralFilterState,
      sortState,
      pageState,
      limitState,
    ],
  );

  useTableCacheEffectAdvanced(
    props.disableCache ? null : props.cacheKey || (`table_${props.name}` as SessionStorageTablesKeys),
    (data: MTableAdvancedServerCacheEffectData<T, Id> | null) => {
      if (!data) {
        updateFilters(() => props.initialFilters || {});
        updateGeneralFilter(() => '');
        setLimitState(() => props.limit || DEFAULT_ROWS_LIMIT);
        setSortState(() => props.initialSort || false);
        setPageState(() => 1);

        return;
      }

      updateFilters(() => data.filters);
      updateGeneralFilter(() => data.generalFilter || '');
      setLimitState(() => data.limit);
      setSortState(() => data.sort);
      setPageState(() => data.page);
    },
    [updateGeneralFilter, updateFilters, setLimitState, setSortState, setPageState],
  );

  useEffect(() => {
    const filters = props.filters;

    if (filters) {
      updateFilters(() => filters);
    }
  }, [props.filters, updateFilters]);

  // Prompt filter change to the parent component
  useEffect(() => {
    if (filtersState === null || generalFilterState === null) {
      return;
    }

    props.onFiltersChange?.(filtersState, generalFilterState);
  }, [filtersState, generalFilterState, props.onFiltersChange]);

  useEffect(() => {
    setPageState((prev: number | null) => props.page || prev);
  }, [props.page, setPagesState]);

  useEffect(() => {
    if (props.limit) {
      updateLimit(props.limit);
    }
  }, [props.limit, updateLimit]);

  useEffect(() => {
    if (
      props.disableCache ||
      filtersState === null ||
      generalFilterState === null ||
      limitState === null ||
      sortState === null ||
      pageState === null
    ) {
      return;
    }

    cacheTable({
      generalFilter: generalFilterState,
      sort: sortState,
      filters: filtersState,
      page: pageState,
      limit: limitState,
    });
  }, [cacheTable, props.disableCache, sortState, filtersState, pageState, limitState, generalFilterState]);

  useEffect(() => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      sortState === null ||
      pageState === null ||
      limitState === null
    ) {
      return;
    }

    fetchData(prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState));
  }, [fetchData, prepareRequestBody, debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState]);

  useEffect(() => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      sortState === null ||
      pageState === null ||
      limitState === null
    ) {
      return;
    }

    const query = allResultsState
      ? prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState).query
      : false;

    props.onSelectionChange?.(selectedState, query);
  }, [
    selectedState,
    allResultsState,
    debouncedFiltersState,
    debouncedGeneralFilterState,
    sortState,
    pageState,
    limitState,
    prepareRequestBody,
  ]);

  useEffect(() => {
    if (selectedState.length) {
      setAllResultsState(() => false);
    }
  }, [selectedState]);

  const handleColumnItemsClick = (_: IDropdownAction<boolean, Id>, index: number) => {
    setColumnItemsState((prev: IDropdownAction<boolean, Id>[]) => {
      const newOptions = [...prev];

      newOptions[index] = { ...newOptions[index], value: !newOptions[index].value };

      return newOptions;
    });
  };

  const hiddenColumns: Id[] = useMemo(
    () => [
      ...columnItemsState.reduce((acc: Id[], curr: IDropdownAction<boolean, Id>) => {
        if (curr.value) {
          return acc;
        }

        const newAcc: Id[] = [...acc, curr.id as Id];

        return newAcc;
      }, []),
      ...(props.hiddenColumns || []),
    ],
    [columnItemsState, props.hiddenColumns],
  );

  const filters = useMemo(() => {
    return props.columns.reduce(
      (acc: MTableAdvancedServerFilterRender[], column: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>) => {
        if (!column.Filter) {
          return acc;
        }

        const itemRender: MTableAdvancedServerFilterRender = {
          id: column.id,
          Component: column.Filter,
          value: filtersState?.[column.id],
          defaultPlaceholder: column.header || column.id[0].toUpperCase() + column.id.slice(1),
          onChange: (newValue: FilterValue) => {
            updateFilters((prev: MTableAdvancedFilters<Id> | null) => {
              if (!prev) {
                if (newValue === null) {
                  return {};
                }

                return { [column.id]: newValue } as MTableAdvancedFilters<Id>;
              }

              if (newValue === null) {
                if (column.id in prev) {
                  const newFilters = { ...prev };

                  delete newFilters[column.id];

                  return newFilters;
                }

                return prev;
              }

              return { ...prev, [column.id]: newValue };
            });
          },
        };

        const newAcc = [...acc, itemRender];

        return newAcc;
      },
      [],
    );
  }, [props.columns, updateFilters, filtersState]);

  useEffect(() => {
    setColumnItemsState(() => initialColumnOptions);
  }, [initialColumnOptions]);

  useEffect(() => {
    setLimitState(() => props.limit || DEFAULT_ROWS_LIMIT);
  }, [props.limit]);

  const deleteRow = useCallback(() => {
    let cancelToken: CancelTokenSource | null = axios.CancelToken.source();

    if (!viewSite && !props.selectedRows?.length) {
      MIziToast.error(t('components.mTableAdvancedServer.unitError'));

      return;
    }

    let queryData =
      filtersState && generalFilterState
        ? prepareRequestBody(filtersState, generalFilterState).query
        : { unit: viewSite, ...props.baseQuery };

    if (props.selectedRows?.length) {
      queryData = { _id: { $in: props.selectedRows } };
    }

    serverAxios
      .post(
        '/',
        {
          act: 'delete',
          col: props.name,
          query: queryData,
        },
        {
          cancelToken: cancelToken.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        MIziToast.success(t('components.mTableAdvancedServer.removed'));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        forceFetch();

        cancelToken = null;
      });
  }, [props.selectedRows, filtersState, generalFilterState, props.baseQuery]);

  const onShowDuplicates = () => {
    if (
      debouncedFiltersState === null ||
      debouncedGeneralFilterState === null ||
      limitState === null ||
      sortState === null ||
      pageState === null
    ) {
      console.error('Can not fetch data when request body is not ready.');

      return;
    }

    const query = prepareRequestBody(debouncedFiltersState, debouncedGeneralFilterState, sortState, pageState, limitState);

    fetchData({ ...query, query: { ...query.query, similar: { $exists: true, $not: { $size: 0 } } } });
  };

  return (
    <MServerTableView
      allowHeaderMaxWidth={props.allowHeaderMaxWidth}
      className={props.className}
      rowClassName={props.rowClassName}
      limit={limitState || DEFAULT_ROWS_LIMIT}
      data={dataState}
      loading={loadingState || !initialTableDataReady}
      pagesCount={pagesState}
      page={pageState || 1}
      allResults={allResultsState}
      columnItems={columnItemsState}
      count={countState}
      sorting={sortState || false}
      filters={filters}
      disableFilters={props.disableFilters}
      hideDateNavigator={props.hideDateNavigator}
      disablePagination={props.disablePagination}
      headerButtons={props.headerButtons}
      columns={props.columns}
      hiddenColumns={hiddenColumns}
      generalFilterPlaceholder={props.generalFilterPlaceholder}
      showOnlyWithGeneralFilter={props.showOnlyWithGeneralFilter}
      hideGeneralFilter={!props.generalFilterColumns}
      hideFooter={props.hideFooter}
      hideColumnSelect={props.hideColumnSelect}
      onChangeColumnItem={handleColumnItemsClick}
      generalFilterValue={generalFilterState || ''}
      disableDeleteBtn={props.disableDeleteBtn}
      onDeleteBtnClick={props.onDeleteBtnClick || deleteRow}
      onGeneralFilterChange={(event: React.ChangeEvent<HTMLInputElement>) => updateGeneralFilter(() => event.target.value)}
      selectedRow={props.selectedRow}
      onRowClick={props.onRowClick}
      footerDataView={props.footerDataView}
      hideSelection={!props.onSelectionChange}
      onLimitChange={(event: React.ChangeEvent<HTMLSelectElement>) => updateLimit(Number(event.target.value))}
      onDateNavigatorClick={updateDateNavigatorFilter || undefined}
      onAllResultsChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllResultsState(() => event.target.checked)}
      onClearButtonClick={clearFilters}
      onSortingChange={(sortValue: MTableAdvancedSorting<T>) => setSortState(() => sortValue)}
      onPageChange={(page: number) => setPageState(() => page)}
      onSelectionChange={(selected: RowIdValue[]) => setSelectedState(() => selected)}
      deleteButton={props.deleteButton}
      expand={props.expand}
      selectedRows={props.selectedRows}
      getRowId={props.getRowId}
      onShowDuplicates={onShowDuplicates}
    ></MServerTableView>
  );
};

MServerTable.displayName = 'MServerTable';

export default React.forwardRef(MServerTable) as <
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<T, Id, RowIdValue>>> & React.RefAttributes<MTableAdvancedServerRef>,
) => JSX.Element;
