const uniqueDriversReport = {
  table: {
    headers: {
      date: 'Data',
      uniqueDrivers: 'Motoristas únicos',
    },
    tableDetails: {
      headers: {
        date: 'Data',
        uniqueDrivers: 'Motoristas únicos',
        driver: 'Motorista',
        trips: 'Viagens',
      },
    },
  },
  actions: {
    export: 'Exportar',
  },
};

export default uniqueDriversReport;
