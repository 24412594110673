const history = {
  table: {
    headers: {
      createdBy: 'Criado por',
      createdAt: 'Criado em',
      date: 'Data',
    },
  },
};

export default history;
