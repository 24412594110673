export enum UserRoles  {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Supplier = 'supplier',
  DispatchAdmin = 'dispatchadmin',
  Dispatcher = 'dispatcher',
  SuperAgent = 'superagent',
  Agent = 'agent',
  AccountingAdmin = 'accountingadmin',
  Accountent = 'accountent',
  Driver = 'driver',
  Passenger = 'passenger',
  Parent = 'parent',
  Student = 'student',
}
