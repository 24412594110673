const suppliers = {
  toast: {
    error: {
      badResponse: '[Fornecedor] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createNew: 'Novo',
  table: {
    headers: {
      name: 'Nome',
      supplierStatus: 'Status',
    },
  },
  create: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Fornecedor criado!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar fornecedor',
    },
  },
  edit: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Fornecedor atualizado!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar o fornecedor',
    },
  },
  forms: {
    information: {
      generalInformation: {
        header: 'Informações Gerais',
        fields: {
          name: 'Nome',
          unit: 'Local',
          status: 'Status',
          statusOptions: {
            select: 'Selecione',
            active: 'Ativo',
            inactive: 'Inativa',
          },
        },
      },
      advancedSettings: {
        header: 'Configurações avançadas',
        fields: {
          areas: 'Áreas',
          offPeak: 'Fora de pico',
          defaultCommission: 'Comissão padrão',
          pariorityScoring: 'Pontuação de Paridade',
          minutesBefore: 'Limitação de Reserva',
          multiplePaxDetails: 'Detalhes de Habilitar Passageiros Múltiplos',
          pax: 'Número máximo de passageiros',
        },
      },
      contactInformation: {
        header: 'Informações de contato',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          phone: 'Telefone',
          email: 'E-mail',
        },
      },
    },
    select: 'Selecione',
  },
};

export default suppliers;
