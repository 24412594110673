import { call, CallEffect, delay, ForkEffect, put, race, take, TakeEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import * as settingsActions from '../actions/settings';
import * as sitesActions from 'store/actions/sites';
import * as authActions from '../actions/auth';
import * as uiActions from '../actions/ui';

import { IRefreshTokenAuthResponse } from '../../models/response';
import { ISetting, IParameter } from 'models/setting';
import { SessionStorage } from 'utils/enums/storage';
import { User } from 'models/user';
import { serverAxios } from 'utils/http';
import sess from 'utils/session-storage';
import { ability, updateAbility } from '../../utils/ability';
import { SystemTemplate } from 'utils/enums/system-template';
import { getSiteOperation } from 'utils/helpers';
import { OperationTypes } from 'models/site';
import { Roles } from 'models/modules';
import { fullPermissions } from 'assets/data/roles';

// ======================= Workers (Effects) =======================

// Login

function* loginSaga() {
  let loggedIn: boolean = yield true;

  while (loggedIn) {
    const [delayAction, logoutAction]: [CallEffect, TakeEffect] = yield race<CallEffect | TakeEffect>([
      delay(60 * 1000),
      take(authActions.AuthActions.Logout),
    ]);

    if (delayAction) {
      try {
        const token = yield call(async () => {
          return sess
            .load<string>(SessionStorage.Token)
            .then(async (token: string) => {
              return serverAxios
                .post('/', {
                  act: 'refreshtoken',
                  token,
                })
                .then((response: AxiosResponse<IRefreshTokenAuthResponse>) => response.data.token);
            })
            .catch(() => {
              return serverAxios
                .post('/', {
                  act: 'refreshtoken',
                  token: localStorage.getItem('token'),
                })
                .then((response: AxiosResponse<IRefreshTokenAuthResponse>) => response.data.token);
            });
        });

        yield localStorage.setItem('token', token);
        yield put(authActions.setToken(token));
        yield sess.save(SessionStorage.Token, token);
      } catch {
        loggedIn = yield false;
      }

      if (!loggedIn) {
        yield put(authActions.logout());
      }
    }

    if (logoutAction) {
      loggedIn = yield false;
    }
  }
}

// Logout

function* logoutSaga() {
  yield put(authActions.setToken(null));
  yield put(sitesActions.setViewSite(null));
  yield sessionStorage.clear();
  yield localStorage.removeItem('token');
}

// Auto Login
function* autoLoginSaga() {
  yield put(uiActions.showSpinner(true));

  try {
    const response: AxiosResponse<IRefreshTokenAuthResponse> = yield call(async () => {
      return sess
        .load<string>(SessionStorage.Token)
        .then((token: string) => {
          return serverAxios.post('/', {
            act: 'refreshtoken',
            appType: 'admin_v1',
            token,
          });
        })
        .catch(() => {
          return serverAxios.post('/', {
            act: 'refreshtoken',
            appType: 'admin_v1',
            token: localStorage.getItem('token'),
          });
        });
    });

    yield put(
      authActions.setSettings(
        response.data.settings.settings.map((setting: IParameter) => {
          return { name: setting.name, value: setting.value, site: setting.unit };
        }),
      ),
    );

    yield updateAbility(ability, response.data.user.role === Roles.SuperAdmin ? fullPermissions : response.data.user.modulesAccess);

    const settings = yield response.data.settings.settings;
    const mapCenter = yield settings.find((setting: ISetting) => setting.name === 'map center');
    const systemTemplate: string = yield settings.find((setting: ISetting) => setting.name === 'systemTemplate')?.value;

    if (mapCenter) {
      const location = yield mapCenter.value.split(', ');

      yield put(settingsActions.setMapCenter(+location[0], +location[1]));
    }

    yield put(authActions.setToken(response.data.token));
    yield put(authActions.login(new User(response.data.user)));

    const siteOperation = yield call(() => {
      return response.data.user.unit ? getSiteOperation(response.data.user.unit) : null;
    });

    yield put(
      settingsActions.setSystemTemplate(
        siteOperation === OperationTypes.School || systemTemplate === SystemTemplate.SchoolShuttles
          ? SystemTemplate.SchoolShuttles
          : siteOperation === OperationTypes.Employees || systemTemplate === SystemTemplate.Employees
            ? SystemTemplate.Employees
            : systemTemplate === SystemTemplate.Trailers
              ? SystemTemplate.Trailers
              : SystemTemplate.Default,
      ),
    );
  } catch {
    yield sessionStorage.clear();
    yield localStorage.removeItem('token');
  }

  return yield put(uiActions.showSpinner(false));
}

// ======================= Watchers =======================

export function* watchAuth(): IterableIterator<ForkEffect> {
  yield takeEvery(authActions.AuthActions.Logout, logoutSaga);
  yield takeEvery(authActions.AuthActions.AutoLogin, autoLoginSaga);
  yield takeEvery(authActions.AuthActions.Login, loginSaga);
}
