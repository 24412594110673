import React from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

interface Props {
  size: 'sm' | 'md' | 'lg';
}

const MSpinnerView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <ClipLoader size={props.size === 'sm' ? 20 : props.size === 'md' ? 35 : 170} color="#4799eb"></ClipLoader>;
};

MSpinnerView.displayName = 'MSpinnerView';
MSpinnerView.defaultProps = {};

export default MSpinnerView;
