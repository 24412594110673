const modalChangePassword = {
  header: 'Change Password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  buttonSubmit: 'Confirm',
  validation: {
    required: 'Password is required',
    leastCharacters: 'Password must be at least 6 characters',
    criteria: 'Should contain lower and upper case letter',
    mustMatch: 'Passwords must match',
    confirmRequired: 'Confirm Password is required',
  },
};

export default modalChangePassword;
