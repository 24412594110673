const common = {
  table: {
    footer: {
      allResult: 'Todos os resultados',
    },
    noResults: 'Sem resultados',
    total: 'Total',
    generalSearch: 'Buscar',
  },
  update: 'Atualizar',
  create: 'Criar',
  deselectAll: 'Desmarcar Tudo',
  createNew: 'Novo',
  select: 'Selecione',
  selectAll: 'Selecionar Tudo',
  all: 'Tudo',
  weekDays: ['Dom', 'Segunda', 'Ter', 'Qua', 'Quinta', 'Sexta', 'Sábado'],
};

export default common;
