import React from 'react';
import { components, MenuProps } from 'react-select';

import { MAddressSelectOption } from '../MAddress.model';


interface Props {
  hide: boolean;
  menuProps: MenuProps<MAddressSelectOption, false>;
}

const MAddressInputMenuView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return props.hide ? null : <components.Menu {...props.menuProps}></components.Menu>;
};

MAddressInputMenuView.displayName = 'MAddressInputMenuView';
MAddressInputMenuView.defaultProps = {};

export default MAddressInputMenuView;
