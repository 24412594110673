import React from 'react';
import { CellProps } from 'react-table';

import MTableCellExpendColView from './MTableCellExpendCol.view';

import { RowData } from 'views/MTable/Table.model';

interface Props<T extends RowData> extends CellProps<T> {
  openExpandLabel?: string;
  closeExpandLabel?: string;
}

const MTableCellExpendCol = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <MTableCellExpendColView row={props.row} openLabel={props.openExpandLabel} closeLabel={props.closeExpandLabel}>
      {props.children}
    </MTableCellExpendColView>
  );
};

MTableCellExpendCol.displayName = 'MTableCellExpendCol';
MTableCellExpendCol.defaultProps = {};

export default MTableCellExpendCol;
