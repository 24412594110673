const services = {
  toast: {
    error: {
      badResponse: '[Service] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {
        body: 'Service Has Been Added Successfully',
      },
      edit: {},
    },
  },
  headerButtons: {
    create: 'New',
    delete: 'Delete',
  },

  table: {
    headers: {
      maxPassengers: 'Max Passengers',
      sharedWith: 'Shared With',
    },
  },

  create: {
    errorToast: {
      header: 'Error Occourd',
      body: 'Could Not Create Service',
    },
    successToast: {
      header: 'Success',
      body: 'Service Has Been Added Successfully',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Create Service',
    },
    successModal: {
      header: 'Success',
      body: 'Service Created!',
    },
  },

  edit: {
    errorToast: {
      header: 'Error Occourd',
      body: 'Could Not Create Service',
    },
    successToast: {
      header: 'Success',
      body: 'Service Has Been Updated Successfully',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Update Service',
    },
    successModal: {
      header: 'Success',
      body: 'Service Updated!',
    },
  },

  forms: {
    information: {
      details: {
        header: 'Details',
        fields: {
          name: 'Name',
          type: 'Type',
          maxPassengers: 'Max Passengers',
          shared: 'Shared With',
        },
      },
      actions: {
        header: 'Actions',
        buttons: {
          delete: 'Delete',
          resetPassword: 'Reset Password',
        },
      },
    },
  },
};

export default services;
