import React from 'react';

import MTextTooltipView from './MTextTooltip.view';

interface Props {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const MTextTooltip: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MTextTooltipView text={props.text} className={props.className} style={props.style}>
      {props.children}
    </MTextTooltipView>
  );
};

MTextTooltip.displayName = 'MTextTooltip';
MTextTooltip.defaultProps = {};

export default MTextTooltip;
