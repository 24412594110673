import React from 'react';

import MFormPage from 'views/MForm/MFormPage/MFormPage';
import ParentsTable from './ParentsTable/ParentsTable';

import { MFormPageTypes } from 'views/MForm/MForm.model';
import ParentsCreate from './ParentsForm/ParentsCreate/ParentsCreate';
import ParentsUpdate from './ParentsForm/ParentsUpdate/ParentsUpdate';
import { MTableAdvancedServerRef } from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';

type Props = {
  type?: MFormPageTypes;
  tableRef: React.RefObject<MTableAdvancedServerRef>;
  onBack: () => void;
  onSubmit: () => void;
};

const ParentsView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MFormPage
      formJSX={
        props.type === 'create' ? (
          <ParentsCreate onBack={props.onBack} onSubmit={props.onSubmit}></ParentsCreate>
        ) : props.type === 'edit' ? (
          <ParentsUpdate onBack={props.onBack} onSubmit={props.onSubmit}></ParentsUpdate>
        ) : null
      }
      open={!!props.type}
    >
      <ParentsTable tableRef={props.tableRef}></ParentsTable>
    </MFormPage>
  );
};

ParentsView.displayName = 'ParentsView';
ParentsView.defaultProps = {};

export default ParentsView;
