import { AxiosResponse, CancelTokenSource } from 'axios';
import { Moment } from 'moment-timezone';

import { serverAxios } from 'utils/http';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

import { IFare } from 'models/fare';
import { IJob, JobType } from 'models/job';
import { ICreateJobData, IUpdateJobData } from 'models/http/job';
import { IGetDriverShiftsFilters } from 'models/http/driverShift';
import { IGetJobFaresResponse, IGetJobsResponse, IInsertJobsRespose } from 'models/response';
import { BaseQuery } from 'views/MTable/Table.model';

class JobsHttp {
  private static route = '/';

  public async get<Type extends JobType>(filters: IGetDriverShiftsFilters, cancelTokenSource?: CancelTokenSource): Promise<IJob<Type>[]> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'find',
          col: 'jobs',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetJobsResponse<Type>>) => {
        return response.data.data as IJob<Type>[];
      });
  }

  public async create(data: ICreateJobData): Promise<IInsertJobsRespose> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'insert',
          col: 'jobs',
          allowDuplicate: true,
          createUser: true,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IInsertJobsRespose>) => {
        return response.data;
      });
  }

  public async update(jobId: string, data: IUpdateJobData): Promise<void> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'update',
          col: 'jobs',
          query: { id: jobId },
          data,
        },
        {
          timeout: HttpTimeoutPriority.Highest,
        },
      )
      .then(() => {
        return;
      });
  }

  public async getJobFare(
    unit: string,
    profile: string,
    fromLat: number,
    fromLon: number,
    toLat: number,
    toLon: number,
    date: Moment,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<IFare[]> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'getjobfares',
          project: { code: 1, service: 1, price: 1, addition: 1, total: 1 },
          job: {
            unit,
            profile,
            fromLat,
            fromLon,
            toLat,
            toLon,
            date,
          },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((res: AxiosResponse<IGetJobFaresResponse>) => {
        return res.data.fares;
      });
  }

  public async createGroupJob(jobs: string[], query: BaseQuery | null): Promise<boolean> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'update',
          col: 'jobs',
          query: query || { id: { $in: jobs } },
          data: { forceJoin: 'AUTO' },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((res: AxiosResponse<IGetJobFaresResponse>) => {
        if (res.status !== 200) {
          return false;
        }

        return true;
      })
      .catch(() => {
        return false;
      });
  }

  public async removeGroupJob(jobs: string[], query: BaseQuery | null): Promise<boolean> {
    return serverAxios
      .post(
        JobsHttp.route,
        {
          act: 'update',
          col: 'jobs',
          query: query || { id: { $in: jobs } },
          data: { forceJoin: '' },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((res: AxiosResponse<IGetJobFaresResponse>) => {
        if (res.status !== 200) {
          return false;
        }

        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export default JobsHttp;
