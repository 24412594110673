const passengers = {
  createNew: 'Novo',
  toast: {
    error: {
      badResponse: 'resposta inválida do servidor [passageiro]',
      create: {},
      edit: {},
    },
    success: {
      resetSuccess: 'O e-mail de Redefinição de Senha foi enviado com sucesso.',
      create: {
        default: '[Passageiro] Criado',
      },
      edit: {
        default: '[Passageiro] Atualizado',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Ocorreu um Erro',
      body: 'O passageiro já existe!',
    },
    modalErrorRequest: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar passageiro!',
    },
  },
  edit: {
    modalRemoveConfirm: {
      header: 'Aviso!',
      body: 'Você tem certeza que deseja excluir este passageiro?',
      modalRemoveConfirmBtnConfirm: 'Remover',
    },
    formTabs: {
      informationTab: 'Informações Gerais',
      paymentTab: 'Pagamentos',
      notesTab: 'Observações',
      historyTab: 'Histórico',
    },
    activeStatus: {
      statusActive: 'Ativo',
      statusDeactivate: 'Inativa',
    },
    suspend: {
      actionActivate: 'Reativar',
      actionDeactivate: 'Compensar',
      suspendModal: {
        popupTitleDeactivation: 'Anular o passageiro',
        popupTitleActive: 'Motivo da Suspensão',
        popupTitleUnsuspend: 'Motivo do Recolhimento da Suspensão',
        popupSubTitleDeactivation: 'Selecione o motivo da suspensão',
        popupActionActive: 'Reativar Passageiro',
        popupActionDeactivate: 'Anular o passageiro',
      },
    },
  },
  table: {
    headers: {
      name: 'Nome',
      email: 'E-mail',
      created: 'Data de criação',
      id: 'Identificação',
      profiles: 'Perfis em Anexo',
      phone: 'Telefone',
      createdBy: 'Criado por',
      jobCount: 'Reservas',
    },
    passengersGettExtraHeaders: {
      home: 'Home',
      home2: 'Home2',
      work: 'Trabalho',
      site: 'Local',
    },
    empty: 'Vazio',
  },
  forms: {
    information: {
      profiles: {
        header: 'Perfis',
        fields: {
          profile: 'Perfis',
          site: 'Local',
        },
      },
      paymentTypes: {
        header: 'Tipos de pagamento',
        fields: {
          opusCard: 'Cartão OPUS',
          opusCardMensuel: 'Carte OPUS - Mensuel',
          opusCardPassage: 'OPUS Carte - Passagem',
          cpoPassage: 'Carte a puce occasionnelle (CPO-STL) - Passage',
          cpoPassageEXO: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
        },
        actions: {
          save: 'Editar',
        },
      },
      details: {
        header: 'Detalhes pessoais',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          email: 'E-mail',
          phone: 'Telefone',
          additionalPhone: 'Telefone Adicional',
          ada: 'Ada',
          code: 'Código',
        },
      },
      address: {
        header: 'Endereço do passageiro',
        sharedValues: {
          manual: 'Endereço do manual',
        },
        fields: {
          homeAddress: {
            label: 'Endereço de casa',
          },
          homeAddress2: {
            label: 'Endereço Residencial 2',
          },
          officeAddress: {
            label: 'Endereço do escritório',
          },
          workAddress: {
            label: 'Endereço do trabalho',
          },
        },
      },
      actions: {
        header: 'Ações',
        buttons: {
          delete: 'Remover',
          resetPassword: 'Redefinir Senha',
        },
      },
      health: {
        header: 'Informações de Saúde',
        fields: {
          certificationDate: 'Data de Certificação',
          expirationDate: 'Data de expiração',
          disabilityAidTools: 'Instrumentos de Apoio a Pessoas Deficientes',
        },
      },
      caregiverInfo: {
        header: 'Informações para Cuidadores',
        fields: {
          caregiverFirstName: 'Primeira Nome',
          caregiverLastName: 'Sobrenome',
          caregiverEmail: 'E-mail',
          caregiverPhone: 'Telefone',
        },
      },
    },
    jobHistory: {
      header: 'Histórico de reservas',
      lastJob: 'Última reserva: {{lastJob}}',
      firstJob: 'Primeira Reserva: {{firstJob}}',
    },
    validations: {
      firstName: 'Primeiro Nome é obrigatório',
      lastName: 'Sobrenome é obrigatório',
      profiles: 'Perfis é obrigatório',
      email: {
        required: 'E-mail é obrigatório',
        email: 'Endereço de e-mail inválido',
      },
      unit: {
        required: 'O local é obrigatório',
      },
    },
  },
};

export default passengers;
