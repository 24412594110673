import { ModuleCodes, RoleIDs, Roles } from 'models/modules';
import { IInitRole } from 'models/permissions';

export const fullPermissions: {
  [key: string]: {
    moduleName: string;
    moduleCode: string;
    actions: string[];
  };
} = {
  [ModuleCodes.Jobs]: {
    moduleName: 'reservations',
    moduleCode: ModuleCodes.Jobs,
    actions: ['view', 'create', 'edit', 'changeProfile', 'addNotes', 'cancel', 'route', 'export', 'import'],
  },
  [ModuleCodes.Trips]: {
    moduleName: 'trips',
    moduleCode: ModuleCodes.Trips,
    actions: [
      'view',
      'assign',
      'sendTrip',
      'addNote',
      'sendMessage',
      'exportTrip',
      'importTrip',
      'changeStatus',
      'editQueue',
      'combineTrip',
    ],
  },
  [ModuleCodes.Drivers]: {
    moduleName: 'drivers',
    moduleCode: ModuleCodes.Drivers,
    actions: ['view', 'addNotes', 'create', 'edit', 'changeStatus', 'export', 'import'],
  },
  [ModuleCodes.ActiveDrivers]: {
    moduleName: 'activeDrivers',
    moduleCode: ModuleCodes.ActiveDrivers,
    actions: ['view', 'changeStatus'],
  },
  [ModuleCodes.Passengers]: {
    moduleName: 'passengers',
    moduleCode: ModuleCodes.Passengers,
    actions: ['view', 'addNotes', 'create', 'edit', 'changeStatus', 'export', 'import'],
  },
  [ModuleCodes.Vehicles]: {
    moduleName: 'Vehicles',
    moduleCode: ModuleCodes.Vehicles,
    actions: ['view', 'addNotes', 'create', 'edit', 'changeStatus', 'export', 'import'],
  },
  [ModuleCodes.LiveMap]: {
    moduleName: 'liveMap',
    moduleCode: ModuleCodes.LiveMap,
    actions: ['view'],
  },
  [ModuleCodes.DriversShifts]: {
    moduleName: 'driversShifts',
    moduleCode: ModuleCodes.DriversShifts,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.Reports]: {
    moduleName: 'reports',
    moduleCode: ModuleCodes.Reports,
    actions: ['view', 'export'],
  },
  [ModuleCodes.Analytics]: {
    moduleName: 'analytics',
    moduleCode: ModuleCodes.Analytics,
    actions: ['view', 'export'],
  },
  [ModuleCodes.Services]: {
    moduleName: 'services',
    moduleCode: ModuleCodes.Services,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.Locations]: {
    moduleName: 'locations',
    moduleCode: ModuleCodes.Locations,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.AreasZonePricing]: {
    moduleName: 'areasZonePricing',
    moduleCode: ModuleCodes.AreasZonePricing,
    actions: ['view', 'create', 'edit', 'changeStatus', 'export', 'import', 'viewSpecificTypes'],
  },
  [ModuleCodes.AreasRoutingZones]: {
    moduleName: 'areasRoutingZones',
    moduleCode: ModuleCodes.AreasRoutingZones,
    actions: ['view', 'create', 'edit', 'changeStatus', 'export', 'import', 'viewSpecificTypes'],
  },
  [ModuleCodes.Rates]: {
    moduleName: 'rates',
    moduleCode: ModuleCodes.Rates,
    actions: ['view', 'create', 'edit', 'export', 'import', 'addNote'],
  },
  [ModuleCodes.RateTypes]: {
    moduleName: 'rateTypes',
    moduleCode: ModuleCodes.RateTypes,
    actions: ['view', 'create', 'edit'],
  },
  [ModuleCodes.ExtraServices]: {
    moduleName: 'extraServices',
    moduleCode: ModuleCodes.ExtraServices,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.Suppliers]: {
    moduleName: 'suppliers',
    moduleCode: ModuleCodes.Suppliers,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.Users]: {
    moduleName: 'users',
    moduleCode: ModuleCodes.Users,
    actions: ['view', 'create', 'edit', 'changeRole', 'changeStatus', 'export', 'import'],
  },
  [ModuleCodes.Profiles]: {
    moduleName: 'profiles',
    moduleCode: ModuleCodes.Profiles,
    actions: ['view', 'create', 'edit', 'changeStatus', 'export', 'import'],
  },
  [ModuleCodes.History]: {
    moduleName: 'history',
    moduleCode: ModuleCodes.History,
    actions: ['view', 'export'],
  },
  [ModuleCodes.UploadData]: {
    moduleName: 'uploadData',
    moduleCode: ModuleCodes.UploadData,
    actions: ['upload'],
  },
  [ModuleCodes.Settings]: {
    moduleName: 'settings',
    moduleCode: ModuleCodes.Settings,
    actions: ['view'],
  },
  [ModuleCodes.Parameters]: {
    moduleName: 'parameters',
    moduleCode: ModuleCodes.Parameters,
    actions: ['view', 'create', 'edit'],
  },
  [ModuleCodes.MultiLanguage]: {
    moduleName: 'multiLanguage',
    moduleCode: ModuleCodes.MultiLanguage,
    actions: ['view', 'edit'],
  },
  [ModuleCodes.Shifts]: {
    moduleName: 'shifts',
    moduleCode: ModuleCodes.Shifts,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.DriverAssignment]: {
    moduleName: 'driverAssignment',
    moduleCode: ModuleCodes.DriverAssignment,
    actions: ['view', 'create', 'edit'],
  },
  [ModuleCodes.DriversScheduler]: {
    moduleName: 'driversScheduler',
    moduleCode: ModuleCodes.DriversScheduler,
    actions: ['view', 'create', 'edit'],
  },
  [ModuleCodes.Messages]: {
    moduleName: 'messages',
    moduleCode: ModuleCodes.Messages,
    actions: ['view', 'create'],
  },
  [ModuleCodes.NotificationsEmail]: {
    moduleName: 'notificationsEmail',
    moduleCode: ModuleCodes.NotificationsEmail,
    actions: ['view', 'create'],
  },
  [ModuleCodes.NotificationsSMS]: {
    moduleName: 'notificationsSMS',
    moduleCode: ModuleCodes.NotificationsSMS,
    actions: ['view', 'create'],
  },
  [ModuleCodes.NotificationsPushNotifications]: {
    moduleName: 'notificationsPushNotifications',
    moduleCode: ModuleCodes.NotificationsPushNotifications,
    actions: ['view', 'create'],
  },
  [ModuleCodes.Fares]: {
    moduleName: 'fares',
    moduleCode: ModuleCodes.Fares,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.Accounting]: {
    moduleName: 'accounting',
    moduleCode: ModuleCodes.Accounting,
    actions: ['view', 'create', 'edit'],
  },
  [ModuleCodes.ClearCity]: {
    moduleName: 'clearCity',
    moduleCode: ModuleCodes.ClearCity,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.HoldingList]: {
    moduleName: 'holdingList',
    moduleCode: ModuleCodes.HoldingList,
    actions: ['view', 'create', 'edit', 'export', 'import'],
  },
  [ModuleCodes.JenniAI]: {
    moduleName: 'jenniAI',
    moduleCode: ModuleCodes.JenniAI,
    actions: ['active'],
  },
  [ModuleCodes.Bidding]: {
    moduleName: 'bidding',
    moduleCode: ModuleCodes.Bidding,
    actions: ['active'],
  },
  [ModuleCodes.RouteOptimization]: {
    moduleName: 'routeOptimization',
    moduleCode: ModuleCodes.RouteOptimization,
    actions: ['active'],
  },
  [ModuleCodes.DriverApp]: {
    moduleName: 'driverApp',
    moduleCode: ModuleCodes.DriverApp,
    actions: ['active'],
  },
  [ModuleCodes.PassengerApp]: {
    moduleName: 'passengerApp',
    moduleCode: ModuleCodes.PassengerApp,
    actions: ['active'],
  },
  [ModuleCodes.Shuttles]: {
    moduleName: 'shuttles',
    moduleCode: ModuleCodes.Shuttles,
    actions: ['view', 'create', 'edit', 'delete', 'addTime', 'addStop'],
  },
  [ModuleCodes.PassengerShuttlesWebApp]: {
    moduleName: 'passengerShuttlesWebApp',
    moduleCode: ModuleCodes.PassengerShuttlesWebApp,
    actions: ['active'],
  },
  [ModuleCodes.PassengersWebPortal]: {
    moduleName: 'passengersWebPortal',
    moduleCode: ModuleCodes.PassengersWebPortal,
    actions: ['active'],
  },
  [ModuleCodes.RoutingScheduler]: {
    moduleName: 'routingScheduler',
    moduleCode: ModuleCodes.RoutingScheduler,
    actions: ['active'],
  },
  [ModuleCodes.PredictiveETA]: {
    moduleName: 'predictiveETA',
    moduleCode: ModuleCodes.PredictiveETA,
    actions: ['active'],
  },
  [ModuleCodes.PredictiveETANotifications]: {
    moduleName: 'predictiveETANotifications',
    moduleCode: ModuleCodes.PredictiveETANotifications,
    actions: ['active'],
  },
  [ModuleCodes.WhiteLabelSolution]: {
    moduleName: 'whiteLabelSolution',
    moduleCode: ModuleCodes.WhiteLabelSolution,
    actions: ['active'],
  },
  [ModuleCodes.ApiAccess]: {
    moduleName: 'apiAccess',
    moduleCode: ModuleCodes.ApiAccess,
    actions: ['active'],
  },
  [ModuleCodes.TestPageAccess]: {
    moduleName: 'testPageAccess',
    moduleCode: ModuleCodes.TestPageAccess,
    actions: ['active'],
  },
  [ModuleCodes.EditSite]: {
    moduleName: 'editUnits',
    moduleCode: ModuleCodes.EditSite,
    actions: ['active'],
  },
  [ModuleCodes.Blacklist]: {
    moduleName: 'blacklist',
    moduleCode: ModuleCodes.Blacklist,
    actions: ['view', 'create', 'edit', 'delete'],
  },
  [ModuleCodes.Dashboard]: {
    moduleName: 'dashboard',
    moduleCode: ModuleCodes.Dashboard,
    actions: ['view'],
  },
  [ModuleCodes.Timeline]: {
    moduleName: 'timeline',
    moduleCode: ModuleCodes.Timeline,
    actions: ['view'],
  },
};

export const allRoles: IInitRole[] = [
  {
    roleId: RoleIDs.SuperAdmin,
    roleCode: Roles.SuperAdmin,
    roleName: 'Super Admin',
  },
  {
    roleId: RoleIDs.Admin,
    roleCode: Roles.Admin,
    roleName: 'Admin',
  },
  {
    roleId: RoleIDs.Supplier,
    roleCode: Roles.Supplier,
    roleName: 'Supplier',
  },
  {
    roleId: RoleIDs.AccountingAdmin,
    roleCode: Roles.DispatcherAdmin,
    roleName: 'Dispatcheradmin',
  },
  {
    roleId: RoleIDs.Dispatcher,
    roleCode: Roles.Dispatcher,
    roleName: 'Dispatcher',
  },
  {
    roleId: RoleIDs.SuperAgent,
    roleCode: Roles.SuperAgent,
    roleName: 'Super Agent',
  },
  {
    roleId: RoleIDs.Agent,
    roleCode: Roles.Agent,
    roleName: 'Agent',
  },
  {
    roleId: RoleIDs.AccountingAdmin,
    roleCode: Roles.AccountingAdmin,
    roleName: 'Accounting Admin',
  },
  {
    roleId: RoleIDs.Accountant,
    roleCode: Roles.Accountant,
    roleName: 'Accountant',
  },
  {
    roleId: RoleIDs.Driver,
    roleCode: Roles.Driver,
    roleName: 'Driver',
  },
  {
    roleId: RoleIDs.Passenger,
    roleCode: Roles.Passenger,
    roleName: 'Passenger',
  },
];
