const shuttles = {
  toast: {
    error: {
      badResponse: '[Shuttle] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {
        default: 'Atualização de [Shuttle] concluída',
      },
      delete: {
        default: '[Shuttle] excluído com sucesso',
      },
    },
    duplicate: '[Shuttle] duplicado',
  },
  createNew: 'Novo',
  deleteBtn: 'Remover',
  deleteModal: {
    header: 'Excluir os transportes',
    body: 'Você está prestes a excluir {{num}} transportes.',
  },
  duplicateModal: {
    header: 'Transporte Duplicado: {{name}}',
    placeholder: 'Insira o código de transporte',
    fields: {
      duplicateShuttleCode: 'Código do transporte:',
    },
    footer: {
      title: 'Você está prestes a duplicar este transporte',
      button: 'Confirmar',
    },
  },
  table: {
    headers: {
      code: 'Código',
      line: 'Linha',
      direction: 'Direção',
      weekdays: 'Dias úteis',
      supplier: 'Operado por',
      name: 'Nome',
      pax: 'Pessoal',
    },
    notAvailable: 'Não disponível',
  },
  update: {
    submit: {
      modal: {
        driversChanged: {
          header: 'Aprovação das alterações',
          body: 'As alterações na seleção de motoristas foram identificadas. Aprovar a ação pode resultar em alterações de atribuição de motorista.',
        },
      },
    },
  },
  form: {
    info: {
      details: {
        header: {
          create: 'Criar transporte',
          edit: 'Editar transporte',
          information: 'Informações do transporte',
        },
        shuttleBy: {
          flexibleTime: 'Horário Flexível',
          fixedTime: 'Horário fixo',
        },
        fields: {
          unit: 'Local',
          supplier: 'Operado por',
          hour1: 'De',
          hour2: 'Para',
          shuttleBy: 'Passar por',
          code: 'Código',
          name: 'Nome',
          area: 'Área',
          line: 'Linha',
          profile: 'Perfil',
          direction: 'Direção',
          drivers: 'Motoristas',
          allDrivers: 'Tudo',
          pax: 'Pessoal',
          paymentType: 'Tipo de pagamento',
          routingType: 'Tipo de Linha',
        },
        paymentTypeOptions: {
          free: 'Livre',
          opusCard: 'Cartão OPUS',
          cashOnBoard: 'Numerário',
          correspondance: 'Correspondência',
          opusCardMensuel: 'Carte OPUS - Mensuel',
          opusCardPassage: 'OPUS Carte - Passagem',
          cpoPassage: 'Carte a puce occasionnelle (CPO-STL) - Passage',
          cpoPassageEXO: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
        },
        routingTypeOptions: {
          dynamic: 'Dinâmico',
          demand: 'Demanda',
          bus: 'Autocarro',
          exo: 'Exo',
        },
      },
      times: {
        header: 'Tempo',
        fields: {
          hour: 'Hora',
          weekDays: 'Dias da Semana',
          limit: 'Limite',
          busses: 'Ônibus',
          holidays: 'Feriados',
          drivers: 'Motoristas',
        },
        btnAdd: 'Adicione tempo',
        weekDays: {
          sunday: 'Domingo',
          monday: 'Segunda-feira',
          tuesday: 'Terça-feira',
          wednesday: 'Quarta-feira',
          thursday: 'Quinta-feira',
          friday: 'Sexta-feira',
          saturday: 'Sábado',
        },
      },
      stops: {
        header: 'Paradas',
        fields: {
          address: 'Endereço',
          name: 'Nome',
          zone: 'Zona',
          save: 'Editar',
        },
        btnAdd: 'Adicionar Parada',
      },
      zone: '(Zona: {{zone}})',
    },
    validation: {
      unitRequired: 'O local é obrigatório',
      codeRequired: 'O código é obrigatório',
      nameRequired: 'Um nome é requerido',
      paxRequired: 'Pax é requerido',
      hourRequired: 'É requerido uma hora',
      limitRequired: 'Limite é obrigatório',
      latRequired: 'A latitude é obrigatória',
      lonRequired: 'Longitude necessária',
      duplicateShuttleCode: 'Você deve inserir um nome de transporte',
    },
    weekDays: {
      sunday: 'Domingo',
      monday: 'Segunda-feira',
      tuesday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      thursday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado',
    },
  },
  removeStopModal: {
    body: 'Tem certeza de que deseja remover essa parada?',
  },
  removeTimeModal: {
    body: 'Você tem certeza de que deseja remover este registro de data e hora?',
  },
};

export default shuttles;
