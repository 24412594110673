import React from 'react';
import CIcon from '@coreui/icons-react';
import { CPopover } from '@coreui/react';

import classes from './MInfo.module.scss';

interface Props {
  txt?: string;
}

const MInfoView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <CPopover content={props.txt}>
      <CIcon className={classes['icon']} name="cis-info-circle" />
    </CPopover>
  );
};

MInfoView.displayName = 'MInfoView';
MInfoView.defaultProps = {};

export default MInfoView;
