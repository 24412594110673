const assignPriority = {
  table: {
    headerButtons: {
      create: {
        label: 'Create',
      },
      delete: {
        label: 'Delete',
        modal: {
          header: 'Delete Assign Priority',
          body: 'You are about to delete {{selected}} Assign Priority records',
          confirm: {
            toasts: {
              failure: 'An error occurred while deleting assign priority.',
            },
          },
        },
      },
    },
    headers: {
      attributes: 'Name',
      assignPriority: 'Assign Priority',
      site: 'Site',
    },
  },
  form: {
    create: {
      submit: {
        toasts: {
          success: 'Assign priority successfully created!',
          failure: 'An error occurred while creating a new assign priority.',
        },
      },
    },
    update: {
      submit: {
        toasts: {
          success: 'Assign priority successfully updated!',
          failure: 'An error occurred while updating a new assign priority.',
        },
      },
      populate: {
        toasts: {
          failure: 'An error occurred while pulling assign priority data.',
        },
      },
    },
    sections: {
      site: {
        header: 'Site',
      },
      details: {
        header: 'Details',
        fields: {
          assignPriority: {
            label: 'Assign Priority',
          },
          attributes: {
            label: 'Attributes',
          },
        },
      },
    },
  },
};

export default assignPriority;
