import React from 'react';

import HistoryTableDataCol from 'components/History/HistoryTableDataCol/HistoryTableDataCol';

import { BaseHistoryData } from 'models/history';
import { IMHistoryTableData } from 'components/History/MHistory.model';

interface Props {
  data: IMHistoryTableData<BaseHistoryData>;
}

const MHistoryTableCellDataView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <HistoryTableDataCol data={props.data}></HistoryTableDataCol>;
};

MHistoryTableCellDataView.displayName = 'MHistoryTableCellDataView';
MHistoryTableCellDataView.defaultProps = {};

export default MHistoryTableCellDataView;
