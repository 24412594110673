import React from 'react';
import { Table, HeaderGroup, Header, flexRender, Row, Cell } from '@tanstack/react-table';

import MSpinner from 'views/MSpinner/MSpinner';

import classes from './MTableAdvanced.module.scss';
import CIcon from '@coreui/icons-react';
import { MTableAdvancedRowData } from './MTableAdvanced.model';

interface Props<T extends MTableAdvancedRowData> {
  className?: string;
  rowClassName?: string;
  noResultsClassName?: string;
  table: Table<T>;
  loading?: boolean;
  allowHeaderMaxWidth?: boolean;
  isRowSelected: (row: Row<T>) => boolean;
  onRowClick?: (row: Row<T>) => void;
}

const MTableView = <T extends MTableAdvancedRowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <div className={`${classes['container']} ${props.className || ''}`.trim()}>
      <div
        className={`${classes['tableContainer']} ${
          props.table.getRowModel().rows.length <= 0 ? classes['tableContainer--maxHeightSpace'] : ''
        }`}
      >
        <table
          className={`table table-striped table-hover m-0 ${classes['table']} ${
            !props.table.getRowModel().rows.length ? classes['table--noResults'] : ''
          }`.trim()}
        >
          <thead>
            {props.table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: Header<T, unknown>) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={classes['header']}
                        onClick={header.column.getToggleSortingHandler()}
                        style={{ width: header.getSize(), maxWidth: props.allowHeaderMaxWidth ? 1000 : 200, minWidth: 20 }}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <div className={classes['headerContent']}>
                            <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>

                            <span>
                              {header.column.getCanSort() && header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <CIcon size="lg" name="cilArrowBottom"></CIcon>
                                ) : (
                                  <CIcon size="lg" name="cilArrowTop"></CIcon>
                                )
                              ) : null}
                            </span>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody>
            {props.loading ? (
              <tr></tr>
            ) : props.table.getRowModel().rows.length ? (
              props.table.getRowModel().rows.map((row: Row<T>, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <tr
                      className={`${classes['row']} ${props.rowClassName || ''} ${props.onRowClick ? classes['selectable'] : ''}`.trim()}
                      onClick={() => props.onRowClick?.(row)}
                    >
                      {row.getVisibleCells().map((cell: Cell<T, unknown>) => {
                        return (
                          <td
                            key={cell.id}
                            className={`${classes['cell']} ${props.isRowSelected(row) ? classes['cell--selectedRow'] : ''}`.trim()}
                            style={{ width: cell.column.getSize(), minWidth: 20, maxWidth: 200 }} // TODO: change min max
                          >
                            <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                          </td>
                        );
                      })}
                    </tr>
                    {/* {row.isExpanded ? (
                      <tr className={classes['expandedRow']}>
                        <td colSpan={props.table.visibleColumns.length}>{props.renderExpand?.(row) || null}</td>
                      </tr>
                    ) : null} */}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td className={`${classes['noResults']} ${props.noResultsClassName}`} colSpan={props.table.getVisibleLeafColumns().length}>
                  No Results
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {props.loading && (
        <div className={classes['loading']}>
          <MSpinner size="md"></MSpinner>
        </div>
      )}
    </div>
  );
};

MTableView.displayName = 'MTableView';

export default MTableView;
