import React from 'react';

interface Props {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name?: string;
  value?: string;
  className?: string;
  type?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MCheckBoxView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <input
      id={props.id}
      ref={props.inputRef}
      name={props.name}
      value={props.value}
      className={props.className}
      type="checkbox"
      checked={props.checked}
      disabled={props.disabled}
      onChange={props.onChange}
    />
  );
};

MCheckBoxView.displayName = 'MCheckBoxView';
MCheckBoxView.defaultProps = {};

export default MCheckBoxView;
