import { FormikContextType } from 'formik';
import React from 'react';

import MFormSiteFieldView from './MFormSiteField.view';

import { IMFormSiteField } from './MFormSiteField.model';

interface Props<Fields extends IMFormSiteField> {
  formInstance: FormikContextType<Fields>;
  resetOnChange?: boolean;
  isClearable?: boolean; 
}

const MFormSiteField = <Fields extends IMFormSiteField>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  const handleSiteChange = () => {
    if (props.resetOnChange) {
      props.formInstance.resetForm();
    }
  };

  return <MFormSiteFieldView onChange={handleSiteChange} formInstance={props.formInstance} isClearable={props.isClearable}></MFormSiteFieldView>;
};

MFormSiteField.displayName = 'MFormSiteField';
MFormSiteField.defaultProps = {};

export default MFormSiteField;
