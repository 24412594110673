import React from 'react';

import HomeAgentWeatherView from './HomeAgentWeather.view';

interface Props {}

const HomeAgentWeather: React.FC<Props> = (/* props: React.PropsWithChildren<Props> */) => {
  return <HomeAgentWeatherView></HomeAgentWeatherView>;
};

HomeAgentWeather.displayName = 'HomeAgentWeather';
HomeAgentWeather.defaultProps = {};

export default HomeAgentWeather;
