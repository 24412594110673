import React from 'react';

import HomeAgentStatisticView from './HomeAgentStatistic.view';

interface Props {
  title: string;
  value: number;
}

const HomeAgentStatistic: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <HomeAgentStatisticView title={props.title} value={props.value}></HomeAgentStatisticView>;
};

HomeAgentStatistic.displayName = 'HomeAgentStatistic';
HomeAgentStatistic.defaultProps = {};

export default HomeAgentStatistic;
