import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { FormikContextType, useFormik } from 'formik';

import HomeAgentView from './HomeAgent.view';

import { serverAxios } from 'utils/http';
import { IGetStatisticsResponse, IHomeAgentSearchBoxFormFields } from './HomeAgent.model';
import JobStatus from 'utils/enums/job-status';
import { FormValidationObject } from 'views/MForm/MForm.model';
import { TimeFormat } from 'utils/enums/time-format';
import { AppState } from 'models/app-store';

const HomeAgent: React.FC = () => {
  const navigate = useNavigate();
  const userEmail = useSelector((state: AppState) => state.auth.user?.getEmail() || '');

  const [totalLast24AgentResevationState, setTotalLast24AgentReservarionState] = useState<number>(0);
  const [totalLast24ResevationState, setTotalLast24ReservarionState] = useState<number>(0);
  const [totalPastWeekAgentResevationState, setTotalPastWeekAgentResevationState] = useState<number>(0);
  const [totalPastWeekResevationState, setTotalPastWeekResevationState] = useState<number>(0);
  const [loadingState, setLoadingState] = useState<boolean>(true);

  useEffect(() => {
    const currentTimestamp = moment().format(TimeFormat.FullDateTime);
    const weekAgoTimestamp = moment().add(-700, 'days').format(TimeFormat.FullDateTime);
    const dayAgoTimestamp = moment().add(-1, 'days').format(TimeFormat.FullDateTime);

    const statisticsQueries = [
      { date: { $gt: dayAgoTimestamp, $lt: currentTimestamp }, createdBy: userEmail, status: { $ne: JobStatus.Canceled } },
      { date: { $gt: dayAgoTimestamp, $lt: currentTimestamp }, status: { $ne: JobStatus.Canceled } },
      { date: { $gt: weekAgoTimestamp, $lt: currentTimestamp }, createdBy: userEmail, status: { $ne: JobStatus.Canceled } },
      { date: { $gt: weekAgoTimestamp, $lt: currentTimestamp }, status: { $ne: JobStatus.Canceled } },
    ];

    let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

    serverAxios
      .post(
        '/',
        {
          queries: statisticsQueries.map((query) => {
            return {
              act: 'find',
              col: 'jobs',
              count: 2,
              query,
            };
          }),
        },
        {
          cancelToken: cancelTokenSource.token,
        },
      )
      .then((res: AxiosResponse<IGetStatisticsResponse>) => {
        setLoadingState(() => false);
        setTotalLast24AgentReservarionState(() => {
          return res.data.results[0].count;
        });
        setTotalLast24ReservarionState(() => {
          return res.data.results[1].count;
        });
        setTotalPastWeekAgentResevationState(() => {
          return res.data.results[2].count;
        });
        setTotalPastWeekResevationState(() => {
          return res.data.results[3].count;
        });

        return;
      })
      .catch(console.error)
      .finally(() => {
        cancelTokenSource = null;
      });

    return () => {
      cancelTokenSource?.cancel();
    };
  }, [
    setTotalPastWeekAgentResevationState,
    setTotalPastWeekResevationState,
    setTotalLast24ReservarionState,
    setTotalLast24AgentReservarionState,
    setLoadingState,
  ]);

  const form: FormikContextType<IHomeAgentSearchBoxFormFields> = useFormik<IHomeAgentSearchBoxFormFields>({
    initialValues: {
      inputValue: '',
      fromDateFilter: moment().subtract(2, 'months').startOf('day'),
      toDateFilter: moment().endOf('day'),
    },
    validationSchema: yup.object<FormValidationObject<keyof IHomeAgentSearchBoxFormFields>>({
      inputValue: yup.string().required(),
      fromDateFilter: yup.string(),
      toDateFilter: yup.string(),
    }),
    onSubmit(values: IHomeAgentSearchBoxFormFields) {
      navigate({
        pathname: '/jobs',
        search: `?${createSearchParams({
          search: values.inputValue,
          date: `${values.fromDateFilter?.format(TimeFormat.ServerFormatDateTime)},${values.toDateFilter?.format(
            TimeFormat.ServerFormatDateTime,
          )}`,
        })}`,
      });
    },
  });

  return (
    <HomeAgentView
      form={form}
      totalLast24Resevation={totalLast24ResevationState}
      totalLast24AgentResevation={totalLast24AgentResevationState}
      totalPastWeekAgentResevation={totalPastWeekAgentResevationState}
      totalPastWeekResevation={totalPastWeekResevationState}
      loadingState={loadingState}
    ></HomeAgentView>
  );
};

HomeAgent.displayName = 'HomeAgent';

export default HomeAgent;
