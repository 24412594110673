import * as L from 'leaflet';
import 'leaflet.markercluster';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import 'leaflet-editable';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet.path.drag';

import { ILatLng } from 'models/latlng';
import { serverAxios } from 'utils/http';

import { GeoJsonFeature, IMMapMarker, IMMapPolyline, MMapRef, MMapRoute } from './MMap.model';
import classes from './MMap.module.scss';
import MMapView from './MMap.view';
import { routeColors } from './MMap.utils';

interface Props<Clusters extends boolean> {
  polygons?: ILatLng[];
  fullScreen?: boolean;
  editable?: boolean;
  scrollWheelZoom?: boolean;
  className?: string;
  markers?: IMMapMarker<Clusters>[];
  polylines?: IMMapPolyline[];
  geoJsons?: GeoJsonFeature[];
  routes?: MMapRoute[];
  style?: React.CSSProperties;
  zoomControl?: boolean;
  searchControl?: boolean;
  selectedPolygons?: string[];
  fitBounds?: boolean;
  clusters?: Clusters;
  onChangePolygone?: (locations: L.LatLng[]) => void;
  onClickCurrentLocation?: (latLng: L.LatLng) => void;
}

const MMap = <Clusters extends boolean = false>(props: React.PropsWithChildren<Props<Clusters>>, ref: React.ForwardedRef<MMapRef>) => {
  const clusterMarkers = props.markers as IMMapMarker<true>[];
  const markersInClusterRef = useRef<Map<string, L.Marker>>(new Map<string, L.Marker>());
  const [searchState, setSearchState] = useState<string>('');
  const [mapState, setMapState] = useState<L.Map | null>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        map: mapState,
      };
    },
    [mapState],
  );

  // Set full screen mode
  useEffect(() => {
    if (!mapState || !props.fullScreen) {
      return;
    }

    const fullScreen = new L.Control.Fullscreen();

    mapState.addControl(fullScreen);

    return () => {
      mapState.removeControl(fullScreen);
    };
  }, [mapState, props.fullScreen]);

  useEffect(() => {
    if (!props.onClickCurrentLocation || !mapState) {
      return;
    }

    const handler = (event: L.LeafletMouseEvent) => {
      props.onClickCurrentLocation?.(event.latlng);
    };

    mapState.on('click', handler);

    return () => {
      mapState.off('click', handler);
    };
  }, [mapState, props.onClickCurrentLocation]);

  useEffect(() => {
    if (props.fitBounds && props.markers && props.markers.length > 0 && mapState) {
      mapState.invalidateSize();
      mapState.fitBounds(
        props.markers.map((marker: IMMapMarker<Clusters>) => [marker.coord.lat, marker.coord.lng]),
        { padding: [10, 10] },
      );
    }
  }, [props.markers, mapState, props.fitBounds]);

  useEffect(() => {
    if (!mapState || !props.polygons) {
      return;
    }

    const tmpPoly = L.polygon(props.polygons, { color: 'red', weight: 1.5 }).addTo(mapState);

    (tmpPoly as unknown as { enableEdit: () => void }).enableEdit();

    setTimeout(() => {
      mapState.invalidateSize();
      mapState.fitBounds(tmpPoly.getBounds(), { padding: [40, 40] });
    }, 500);

    return () => {
      tmpPoly?.remove();
    };
  }, [mapState, props.polygons]);

  useEffect(() => {
    if (!mapState) {
      return;
    }

    const handlePolygonEdit = (event: L.LeafletEvent) => props.onChangePolygone?.(event['vertex']['latlngs']);

    const handlePolygonDragAllShape: L.LeafletEventHandlerFn = (event: L.LeafletEvent) =>
      props.onChangePolygone?.(event['layer']['_latlngs'][0]);

    mapState.on('editable:vestex:dragend', handlePolygonEdit);
    mapState.on('editable:dragend', handlePolygonDragAllShape);

    return () => {
      mapState.off('editable:vertex:dragend', handlePolygonEdit);
      mapState.off('editable:dragend', handlePolygonDragAllShape);
    };
  }, [mapState, props.onChangePolygone]);

  useEffect(() => {
    let otherAreas: L.Polygon[] = [];

    if (mapState && props.selectedPolygons) {
      otherAreas = props.selectedPolygons.map((selectedArea: string) => {
        const points = selectedArea.split(',').reduce((acc: L.LatLng[], val: string, index: number, source: string[]) => {
          if (index % 2 !== 0) {
            return [...acc, new L.LatLng(+source[index - 1], +val)];
          }

          return acc;
        }, []);

        const newPol = L.polygon([points], { color: 'blue', weight: 1.5 });

        newPol.addTo(mapState);

        return newPol;
      });
    }

    return () => {
      otherAreas.forEach((area: L.Polygon) => area.remove());
    };
  }, [props.selectedPolygons, mapState]);

  // *****Clusters******
  const markersCluster: L.MarkerClusterGroup | null = useMemo(() => {
    if (!props.clusters) {
      return null;
    }

    const markers = L.markerClusterGroup({
      iconCreateFunction(cluster: L.MarkerCluster) {
        return L.divIcon({
          html: ReactDOMServer.renderToStaticMarkup(
            <div className={classes['cluster']}>
              <span className={classes['cluster__label']}>{cluster.getChildCount()}</span>
            </div>,
          ),
        });
      },
    });

    return markers;
  }, [props.clusters]);

  useEffect(() => {
    if (!markersCluster || !mapState) {
      return;
    }

    mapState.addLayer(markersCluster);

    return () => {
      mapState.removeLayer(markersCluster);
    };
  }, [mapState, markersCluster]);

  useEffect(() => {
    if (!markersCluster) {
      return;
    }

    const markerIds = clusterMarkers.map((marker: IMMapMarker<true>) => marker.id);

    // Removes markers that were removed from props.markers
    markersInClusterRef.current.forEach((value: L.Marker, id: string) => {
      if (!markerIds.includes(id)) {
        markersCluster.removeLayer(value);
        markersInClusterRef.current.delete(id);
      }
    });

    // Add new markers that were added to props.markers and update those who exist
    clusterMarkers.forEach((marker: IMMapMarker<true>) => {
      // Exist
      if (markersInClusterRef.current.has(marker.id)) {
        markersInClusterRef.current.get(marker.id)?.setLatLng({ lat: marker.coord.lat, lng: marker.coord.lng });

        return;
      }

      // New
      const markerLayer = L.marker({ lat: marker.coord.lat, lng: marker.coord.lng }, { icon: marker.icon });

      markersInClusterRef.current.set(marker.id, markerLayer);
      markersCluster.addLayer(markerLayer);
    });
  }, [markersCluster, props.markers, markersInClusterRef]);

  const onSearch = (address?: string) => {
    setSearchState(() => address || '');

    if (!mapState || !address) {
      return;
    }

    /** Translate address to coordinates and fly! */
    serverAxios.post('/', { act: 'getloc', address: address.split('|')[0] }).then((res) => {
      const mapZoom = mapState.getZoom();

      mapState?.flyTo(new L.LatLng(Number(res.data.lat), Number(res.data.lon)), mapZoom > 15 ? mapZoom : 15);
    });
  };

  useEffect(() => {
    if (!mapState || !props.geoJsons || !props.geoJsons.length) {
      return;
    }

    props.geoJsons.forEach((geoJson: GeoJsonFeature, index: number) => {
      L.polyline(geoJson.geometry.coordinates, { weight: 2, color: routeColors[index % 10] }).addTo(mapState);
    });
  }, [mapState, props.geoJsons]);

  return (
    <MMapView
      markers={props.markers}
      onCreate={(map: L.Map) => setMapState(() => map)}
      editable={props.editable}
      className={props.className}
      scrollWheelZoom={props.scrollWheelZoom}
      style={props.style}
      zoomControl={props.zoomControl}
      searchValue={searchState}
      onSearch={props.searchControl ? onSearch : undefined}
      polylines={props.polylines}
      routes={props.routes}
      clusters={props.clusters}
    ></MMapView>
  );
};

MMap.displayName = 'MMap';

export default React.forwardRef(MMap);
