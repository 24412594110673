import React from 'react';

import MModalView from './MModal.view';

import { TFunctionResult } from 'i18next';

interface Props {
  show?: boolean;
  header?: string;
  body?: string | React.ReactChild | TFunctionResult;
  customFooter?: React.ReactChild;
  btnConfirmName?: string;
  btnCloseName?: string;
  showCancel?: boolean;
  hideConfirm?: boolean;
  showOnClose?: boolean;
  hideCloseTopBotton?: boolean;
  modalClass?: string;
  closeOnConfirm?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  confirmDisabled?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
}

const MModal: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const confirm = () => {
    props.onClose && props.closeOnConfirm && props.onClose();
    props.onConfirm?.();
  };

  const cancel = () => {
    props.onClose && props.onClose();
    props.onCancel?.();
  };

  return (
    <MModalView
      show={props.show}
      header={props.header}
      body={props.body}
      customFooter={props.customFooter}
      btnConfirmName={props.btnConfirmName}
      btnCloseName={props.btnCloseName}
      hideConfirm={props.hideConfirm}
      showOnClose={props.showOnClose}
      hideCloseTopBotton={props.hideCloseTopBotton}
      modalClass={props.modalClass}
      size={props.size}
      confirmDisabled={props.confirmDisabled}
      onConfirm={confirm}
      onClose={cancel}
    >
    </MModalView>);
};

MModal.defaultProps = {
  closeOnConfirm: true,
};
MModal.displayName = 'MModal';

export default MModal;
