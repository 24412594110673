const forgotPassword = {
  forgotYourPassword: 'Forgot Your Password?',
  backToLogin: 'Continue to login',
  sendPassword: 'Send password',
  emailHint: 'The email address you recevied from your admin',
  noWorries: 'No worries! Please enter your email and we will send you a link to reset your password',
  PleaseEnterEmailAddress: 'Please enter your email address',
  thankYou: 'Thank You!',
  forgotPassword: 'Forgot Password',
  successHint: 'If the email you have entered exists in our system, we will send a link to reset your password in a few seconds',
  emailAddress: 'Email Address',
  redirect: 'You will be redirected in ',
  seconds: ' seconds',
  emailRequires: 'Email is required',
  invalidEmail: 'Email is invalid',
};

export default forgotPassword;
