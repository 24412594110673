import { AxiosResponse } from 'axios';
import { IGetSitesResponse } from 'models/response';
import { OperationTypes } from 'models/site';
import { FirstTripDistanceType } from 'pages/Drivers/Drivers.model';
import { HttpTimeoutPriority } from './enums/http-timeout-priority';
import { serverAxios } from './http';

export const hexIsLight = (color: string): boolean => {
  let hex = color.replace('#', '');

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((h) => h + h)
      .join('');
  }

  const colorRed = parseInt(hex.substr(0, 2), 16);
  const colorGreen = parseInt(hex.substr(2, 2), 16);
  const ColorBlue = parseInt(hex.substr(4, 2), 16);
  const brightness = (colorRed * 299 + colorGreen * 587 + ColorBlue * 114) / 1000;

  return brightness > 135;
};

export const addPlusOrMinus = (number: number | string): string => {
  if (Number(number) > 0) {
    return `+${number}`;
  }

  return number.toString();
};

export const convertDistanceFromServer = (distance?: number | null, distanceType?: FirstTripDistanceType | null): number | null => {
  if (distance) {
    switch (distanceType) {
      case FirstTripDistanceType.KM:
        return distance / 1000;
      case FirstTripDistanceType.Miles:
        return distance / 1609.34;
    }
  }

  return null;
};

export const convertDistanceToServer = (distance?: number | null, distanceType?: FirstTripDistanceType | null): number | null => {
  if (distance) {
    switch (distanceType) {
      case FirstTripDistanceType.KM:
        return distance * 1000;
      case FirstTripDistanceType.Miles:
        return distance * 1609.34;
    }
  }

  return null;
};

export const getSiteOperation = async (name: string): Promise<null | OperationTypes> => {
  return serverAxios
    .post(
      '/',
      {
        act: 'find',
        col: 'units',
        query: { name },
      },
      {
        timeout: HttpTimeoutPriority.Medium,
      },
    )
    .then((res: AxiosResponse<IGetSitesResponse>) => {
      if (res.status !== 200 || !res.data.data?.[0]?.operation) {
        return null;
      }

      return res.data.data[0].operation;
    })
    .catch((error) => {
      console.error(error);

      return null;
    });
};
