const extraServices = {
  toast: {
    error: {
      badResponse: '[Extra Service] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Extra Service',
      profileType: 'Profile Type',
      basePrice: 'Base',
      extraPrice: 'Each Extra',
      baseUpTo: 'Base Up To',
      surcharge: 'Surcharge',
    },
  },

  create: {
    modalSuccess: {
      header: 'Success',
      body: 'Extra Service Created!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Create Extra Service',
    },
  },

  edit: {
    modalSuccess: {
      header: 'Success',
      body: 'Extra Service Updated!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Update Extra Service',
    },
  },

  forms: {
    information: {
      details: {
        header: 'Details',
        fields: {
          name: 'Extra Service',
          profileType: 'Profile Type',
          description: 'Description',
          profileTypeOptions: {
            retail: 'Retail',
            individual: 'Individual',
            wholesale: 'Wholesale',
            corporate: 'Corporate',
            website: 'Website',
            hotel: 'Hotel',
            thirdParty: '3rd Party',
            tourOperator: 'Tour Operator',
            receiptiveOperator: 'Receiptive Operator',
          },
        },
      },
      settings: {
        header: 'Settings',
        fields: {
          basePrice: 'Base',
          extraPrice: 'Each Extra',
          baseUpTo: 'Base Up To',
          surcharge: 'Surcharge',
        },
      },
    },
  },
};

export default extraServices;
