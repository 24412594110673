const vehicleReport = {
  vehicleReportTitle: 'Vehicle Trips Report',
  table: {
    headers: {
      vehicle: 'Vehicle',
      driver: 'Driver',
      trips: 'Trips',
      start: 'Start',
      end: 'End',
      jobs: 'Reservations',
      tripDuration: 'Trip Duration',
      tripDistance: 'Trip Distance',
      distanceToPickUp: 'Distance To Pick Up',
      amount: 'Amount',
    },
    driver: 'Driver',
    Amount: 'Amount',
    unassignedTrips: 'Unassigned trips',
  },

  filter: {
    export: 'Export',
    driver: 'Driver',
    car: 'Vehicle',
    submit: 'Filter',
    clear: 'Clear Filter',
    validation: {
      startDateRequired: 'Start date is required',
      endDateRequired: 'End date is required',
    },
  },

  vehicleSummary: {
    total: 'Total',
    totalTrips: 'Trips',
    totalReservation: 'Reservations',
    totalAmount: 'Total Amount',
  },

  form: {
    dateEnd: 'Date End',
    dateStart: 'Date Start',
  },
};

export default vehicleReport;
