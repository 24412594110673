const location = {
  toast: {
    error: {
      badResponse: '[Location] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    new: 'New',
    moreActions: {
      title: 'More  ',
      delete: 'Delete',
      export: 'Export',
      showOnMap: {
        title: 'Show On Map',
        mapModal: {
          header: 'Show On Map',
          closeBtn: 'Close',
        },
      },
    },
  },

  table: {
    headers: {
      name: 'Name',
      type: 'Type',
      address: 'Address',
      lat: 'Latitude',
      lon: 'Longitude',
      locationTypes: {
        Location: 'Location',
        InAndOut: 'In And Out',
        Airport: 'Airport',
        School: 'School',
      },
      wait: 'Wait',
    },
  },

  form: {
    addressInformation: {
      title: 'Address Information',
      name: 'Name',
      type: 'Type',
      wait: 'Wait',
      address: 'Address',
      locationTypes: {
        General: '',
        Special: 'Special',
        Airport: 'Airport',
        InAndOut: 'In And Out',
        School: 'School',
        Work: 'Work',
      },
    },
    advanced: {
      title: 'Advanced',
      addTime: {
        addTimeBtn: 'Add Time',
        name: 'Name',
        minutes: 'Minutes',
        enterMinutes: 'Enter minutes',
        enterName: 'Enter Name',
      },
    },
    map: {
      title: 'Map',
    },
    site: {
      title: 'Site',
    },

    info: {
      subLocations: {
        addSubLocationBtn: 'Add Terminal',
        header: 'Terminal',
        fields: {
          subLocation: 'Address',
          name: {
            label: 'Address Name',
            placeholder: 'Name',
          },
        },
      },
    },
  },

  update: {
    confirmButtonText: 'Update',
    formTabs: {
      edit: 'Edit',
      history: 'History',
    },
    successModal: {
      header: 'Updated!',
      body: 'Location updated successfully.',
    },
    errorModal: {
      header: 'Error',
      body: 'Could not update location.',
    },
  },

  create: {
    successModal: {
      header: 'Created!',
      body: 'Location created successfully.',
    },
    errorModal: {
      header: 'Error',
      body: 'Could not create location.',
    },
  },
};

export default location;
