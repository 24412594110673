import React from 'react';
import { MTableAdvancedCellConfigParam } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import { IParentsTableData } from '../../ParentsTable.model';

import ParentsTableCellsAddressView from './ParentsTableCellsAddress.view';

type Props = MTableAdvancedCellConfigParam<IParentsTableData, 'address'>;

const ParentsTableCellsAddress: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <ParentsTableCellsAddressView text={props.row.original.address}>{props.children}</ParentsTableCellsAddressView>;
};

ParentsTableCellsAddress.displayName = 'ParentsTableCellsAddress';
ParentsTableCellsAddress.defaultProps = {};

export default ParentsTableCellsAddress;
