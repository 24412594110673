import driverReport from './driverReport';
import vehicleReport from './vehicleReport';
import permissions from './permissions';
import passengers from './passengers';
import students from './students';
import drivers from './drivers';
import users from './users';
import importSection from './import';
import areas from './areas';
import services from './services';
import driversOnline from './driversOnline';
import vehicles from './vehicles';
import units from './units';
import driverShifts from './driverShifts';
import trips from './trips';
import settings from './settings';
import shuttles from './shuttles';
import extraServices from './extraServices';
import shifts from './shifts';
import reports from './reports';
import profiles from './profiles';
import locations from './locations';
import agent from './agent';
import rates from './rates';
import fares from './fares';
import support from './support';
import systemSettings from './systemSettings';
import suppliers from './suppliers';
import layout from './layout';
import shuttleReports from './shuttleReports';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import timeline from './timeline';
import auth from './auth';
import tripReports from './tripReports';
import attributes from './attributes';
import uniqueDriversReport from './uniqueDriversReport';
import holidays from './holidays';
import assignPriority from './assign-priority';
import schoolShifts from './schoolShifts';
import parents from './parents';
import billReport from './billReport';
import holidayReport from './holidayReport';
import blacklist from './blacklist';
import employees from './employees';
import workShifts from './workShifts';
import jobs from './jobs';
import roadBlocks from './roadBlocks';

const index = {
  attributes,
  driverReport,
  suppliers,
  vehicleReport,
  permissions,
  parents,
  passengers,
  students,
  drivers,
  jobs,
  users,
  areas,
  importSection,
  services,
  driversOnline,
  units,
  trips,
  vehicles,
  driverShifts,
  settings,
  shuttles,
  profiles,
  fares,
  rates,
  agent,
  locations,
  extraServices,
  reports,
  shifts,
  support,
  systemSettings,
  layout,
  shuttleReports,
  forgotPassword,
  resetPassword,
  timeline,
  auth,
  tripReports,
  uniqueDriversReport,
  holidays,
  assignPriority,
  schoolShifts,
  billReport,
  holidayReport,
  blacklist,
  employees,
  workShifts,
  roadBlocks,
};

export default index;
