
const driverReport= {
  driverReportTitle: 'Driver Report',
  id: 'Id',
  driver: 'Driver',
  filter: {
    driver: 'Driver',
    car: 'Vehicle',
    submit: 'Filter',
    export: 'Export',
    clear: 'Clear Filter',
    validation: {
      startDateRequired: 'Start date is required',
      endDateRequired: 'End date is required',
    },
  },

  table: {
    drivers: 'Drivers',
    vehicles: 'Vehicles',
    trips: 'Trips',
  },

  vechile: 'Vehicle',
  pax: 'Pax',
  cash: 'Cash',
  start: 'Start',
  end: 'End',
  reservations: {
    reservations: 'Reservations',
    table: {
      from: 'From',
      to: 'To',
      date: 'Date',
      passenger: 'Passenger',
      reservation: 'Reservation id',
      paymentType: 'Payment Type',
      pax: 'Pax',
      pickup: 'Pick Up',
      drop_off: 'drop off',
      duration: 'Duration',
      car: 'Car',
    },
  },

  form: {
    dateEnd: 'Date End',
    dateStart: 'Date Start',
  },
};


export default driverReport;