import { User } from 'models/user';

export enum AuthActions {
  Logout = '[Auth] Logout',
  Login = '[Auth] Login',
  AutoLogin = '[Auth] Auto Login',
  SetSettings = '[Auth] Set Settings',
  SetSetting = '[Auth] Set Setting',
  SetFavorites = '[Auth] Set Favorites',
  SetToken = '[Auth] Set Token',
}

// Interface
export interface Logout {
  type: AuthActions.Logout;
}

export interface SetToken {
  type: AuthActions.SetToken;
  payload: { token: string | null };
}

export interface Login {
  type: AuthActions.Login;
  payload: { user: User };
}

export interface AutoLogin {
  type: AuthActions.AutoLogin;
}

export interface SetSettings {
  type: AuthActions.SetSettings;
  payload: { name: string; value: string, site?: string}[];
}

export interface SetSetting {
  type: AuthActions.SetSetting;
  payload: { name: string; value: string, site?: string};
}

export interface ISeFavorites {
  type: AuthActions.SetFavorites;
  payload: { user: User | null };
}

// Action creators

export const logout = (): Logout => {
  return {
    type: AuthActions.Logout,
  };
};

export const setToken = (token: string | null): SetToken => {
  return {
    type: AuthActions.SetToken,
    payload: { token },
  };
};

export const login = (user: User): Login => {
  return {
    type: AuthActions.Login,
    payload: { user },
  };
};

export const autoLogin = (): AutoLogin => {
  return {
    type: AuthActions.AutoLogin,
  };
};

export const setSettings = (settings: { name: string; value: string, site?: string}[]): SetSettings => {
  return {
    type: AuthActions.SetSettings,
    payload: settings,
  };
};

export const setSetting = (settings: { name: string; value: string, site?: string}): SetSetting => {
  return {
    type: AuthActions.SetSetting,
    payload: settings,
  };
};

export const setFavorites = (user: User | null): ISeFavorites => {
  return {
    type: AuthActions.SetFavorites,
    payload: { user },
  };
};

export type AuthTypes = Logout | SetToken | Login | AutoLogin | SetSettings | SetSetting | ISeFavorites;
