const common ={
  table: {
    footer: {
      allResult: 'All Results',
    },
    noResults: 'No Results',
    total: 'Total',
    generalSearch: 'Search',
  },

  update: 'Update',
  create: 'Create',
  deselectAll: 'Deselect All',
  createNew: 'New',
  select: 'Select',
  selectAll: 'Select All',
  all: 'All',
  weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
};

export default common;
