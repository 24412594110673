const reports = {
  filterForm: {
    filter: 'Filtrar',
    export: 'Exportar',
    validation: {
      reportTypeRequired: 'O tipo de relatório é obrigatório',
      startDateRequired: 'A data de início é obrigatória',
      endDateRequired: 'A data de término é obrigatória',
    },
    form: {
      reportType: {
        label: 'Tipo de relatório',
        reportTypeOptions: {
          selectReport: 'Selecionar relatório',
          shuttlePassangers: 'Transporte de Passageiros',
          dailySummary: 'Resumo diário',
          delaysReport: 'Relatório de Atrasos',
          shuttleReport: 'Relatório de transporte',
          vehicleOccupancy: 'Ocupação de Veículo',
          commuterSatisfaction: 'Satisfação dos passageiros',
          passengerCostPareto: 'Custo por Passageiro - PARETO',
          carbonEfficiency: 'Eficiência de Carbono',
          totalTripsPassengers: ' Total de Viagens/Passageiros',
        },
      },
      selectShuttle: 'Selecionar transporte',
      dateStart: 'Data de início',
      dateEnd: 'Data de término',
    },
  },
};

export default reports;
