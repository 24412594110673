const attributes = {
  toast: {
    error: {
      badResponse: 'erro ao atualizar [Atributo]',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {
        default: '[Atributo] foi atualizado com sucesso',
      },
    },
  },
  table: {
    headerButtons: {
      create: 'Novo',
      delete: {
        label: 'Remover',
        deleteModal: {
          header: 'Excluir Atributos',
          body: 'Você está prestes a excluir os atributos {{attributes}}',
        },
      },
      clear: {
        label: 'Limpar',
        clearModal: {
          header: 'Limpar atributos',
          body: 'Você está prestes a limpar {{attributes}} atributos',
        },
      },
    },
    placeholder: {
      select: 'Selecione',
    },
    headers: {
      name: 'Nome',
      site: 'Local',
      assignPriority: 'Atribuir prioridade',
    },
  },
  form: {
    information: {
      details: {
        header: 'Informações Gerais',
        fields: {
          name: 'Nome',
          site: 'Local',
          assignPriority: 'Atribuir prioridade',
        },
      },
      advancedSettings: {
        header: 'Configurações avançadas',
        fields: {
          parameters: 'Parâmetros',
        },
      },
    },
    valdations: {
      name: {
        required: 'Um nome é requerido',
        name: 'Nome inválido',
      },
      unit: {
        required: 'Requer-se a unidade',
        name: 'Unidade inválida',
      },
      parameters: {
        required: 'Os parâmetros são obrigatórios',
        name: 'Verifique com atenção os valores que você está passando',
      },
    },
    create: {
      toastError: {
        message: 'Não foi possível criar',
      },
      toastSuccess: {
        message: 'Criado com sucesso',
      },
    },
    edit: {
      formTabs: {
        generalInfoTab: 'Geral',
        historyTab: 'Histórico',
      },
      toastError: {
        message: 'Não foi possível atualizar',
      },
      toastSuccess: {
        message: 'Atualizado com sucesso',
      },
    },
  },
};

export default attributes;
