import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { IGetNearestLocation } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { LatLng } from 'leaflet';
import { Location } from 'views/MAddress/MAddress.model';
import { serverAxios } from 'utils/http';

class MapHttp {
  public async getNearest(latLng: LatLng, geoServer: string, cancelTokenSource?: CancelTokenSource): Promise<Location | null> {
    return axios
      .post(`https://${geoServer}/nearest/v1/driving/${latLng.lng},${latLng.lat}`, {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Medium,
      })
      .then((response: AxiosResponse<IGetNearestLocation>) => {
        if (response.data.code !== 'Ok' || response.data.waypoints.length === 0) {
          return null;
        }

        return {
          address: response.data.waypoints[0].name,
          lat: response.data.waypoints[0].location[1],
          lon: response.data.waypoints[0].location[0],
        };
      });
  }

  public async getUpdateRoadBlocksOnMap(): Promise<void> {
    return serverAxios
      .post('/', {
        act: 'rebuildroadblocks',
      })
      .then(() => {
        return;
      });
  }
}

export default MapHttp;
