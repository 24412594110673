import React, { useMemo } from 'react';

import { Navigate } from 'react-router-dom';
import { ModuleActions, ModuleCodes } from 'models/modules/index';
import guards from '../../routes/guards';
import usePerm from 'utils/hooks/use-perm';
import { GuardData } from '../../routes/guards/guard.model';
import HomeAgent from 'pages/Home/HomeAgent/HomeAgent';
import useTranslationByTemplate from './use-translation-by-template';
import { SystemTemplate } from 'utils/enums/system-template';
import Parents from 'pages/Parents/Parents';
import RoadBlocks from 'pages/RoadBlocks/RoadBlocks';

interface JSXRoute extends BaseRoute {
  jsx: React.ReactNode;
}

interface ComponentRoute extends BaseRoute {
  component: React.FC;
}

const Home = React.lazy(() => import('pages/Home/Home'));
const Timeline = React.lazy(() => import('pages/Timeline/Timeline'));
const Jobs = React.lazy(() => import('pages/Jobs/Jobs'));
const Trips = React.lazy(() => import('pages/Trips/Trips'));
const DriversOnline = React.lazy(() => import('pages/DriversOnline/DriversOnline'));

// TODO: Delete after new server
const Passengers = ['gett', 'zeelo'].includes(window.sessionStorage.getItem('systemSpecs') || '')
  ? React.lazy(() => import('pages/PassengersGett/Passengers'))
  : React.lazy(() => import('pages/Passengers/Passengers'));

const Students = React.lazy(() => import('pages/Students/Students'));
const Blacklist = React.lazy(() => import('pages/Blacklist/Blacklist'));
const DriverShifts = React.lazy(() => import('pages/DriverShifts/DriverShifts'));
const LiveMap = React.lazy(() => import('pages/LiveMap/LiveMap'));
const Logout = React.lazy(() => import('pages/LogoutContainer/LogoutContainer'));
const SystemSettings = React.lazy(() => import('pages/SystemSettings/SystemSettings'));
const Driver = React.lazy(() => import('pages/Drivers/Drivers'));
const Vehicles = React.lazy(() => import('pages/Vehicles/Vehicle'));
const Profiles = React.lazy(() => import('pages/Profiles/Profiles'));
const Users = React.lazy(() => import('pages/Users/Users'));
const Fares = React.lazy(() => import('pages/Fares/Fares'));
const Rates = React.lazy(() => import('pages/Rates/Rates'));
const Services = React.lazy(() => import('pages/Services/Services'));
const Holidays = React.lazy(() => import('pages/Holidays/Holidays'));
const Areas = React.lazy(() => import('pages/Areas/Areas'));
const Shuttles = React.lazy(() => import('pages/Shuttles/Shuttles'));
const CreateLocation = React.lazy(() => import('pages/Locations/LocationsForm/CreateLocation/CreateLocation'));
const UpdateLocation = React.lazy(() => import('pages/Locations/LocationsForm/UpdateLocation/UpdateLocation'));
const Locations = React.lazy(() => import('pages/Locations/Locations'));
const CreateShuttle = React.lazy(() => import('pages/Shuttles/ShuttleForm/CreateShuttle/CreateShuttle'));
const ShuttleEdit = React.lazy(() => import('pages/Shuttles/ShuttleForm/UpdateShuttle/UpdateShuttle'));
const Suppliers = React.lazy(() => import('pages/Suppliers/Suppliers'));
const Shifts = React.lazy(() => import('pages/Shifts/Shifts'));
const ExtraServices = React.lazy(() => import('pages/ExtraServices/ExtraServices'));
const Reports = React.lazy(() => import('pages/Reports/Reports'));
const ShuttleReports = React.lazy(() => import('pages/Reports/ShuttleReports/ShuttleReports'));
const UniqueDriversReport = React.lazy(() => import('pages/Reports/UniqueDriversReport/UniqueDriversReport'));
const TripsPaxReports = React.lazy(() => import('pages/Reports/TripsPaxReports/TripsPaxReports'));
const DriverReports = React.lazy(() => import('pages/Reports/DriverReports/DriverReports'));
const VehicleReports = React.lazy(() => import('pages/Reports/VehicleReports/VehicleReports'));
const BillReport = React.lazy(() => import('pages/Reports/BillReport/BillReport'));
const HolidayReport = React.lazy(() => import('pages/Reports/HolidayReport/HolidayReport'));
const HolidayTariffReport = React.lazy(() => import('pages/Reports/HolidayTariffReport/HolidayTariffReport'));
const Permissions = React.lazy(() => import('pages/Permissions/Permissions'));
const Import = React.lazy(() => import('pages/Import/Import'));
const Sites = React.lazy(() => import('pages/Sites/Sites'));
const Attributes = React.lazy(() => import('pages/Attributes/Attributes'));
const AssignPriority = React.lazy(() => import('pages/AssignPriority/AssignPriority'));
const CreateJob = React.lazy(() => import('pages/CreateJob/CreateJob'));
const QuickJob = React.lazy(() => import('pages/QuickJob/QuickJob'));
const Layout = React.lazy(() => import('pages/Layout/Layout'));
const Employees = React.lazy(() => import('pages/Employees/Employees'));

// Auth routes
const Auth = React.lazy(() => import('pages/Auth/Auth'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword/ResetPassword'));

interface BaseRoute {
  path: string;
  name?: string;
  module?: { code: string; action: string; fallback?: string } | string;
  guard?: ((guardData: GuardData) => boolean)[];
  fallback?: string;
  children?: Route[] | null;
}

interface ComponentRoute extends BaseRoute {
  component: React.FC;
}

const useRoutes = (): Route[] => {
  const t = useTranslationByTemplate();
  const perm = usePerm();

  // prettier-ignore
  const routes: Route[] = useMemo(() => {

    return [
      { path: '/*',                                   component: Auth,                                  guard: [guards.guestGuard]                                                                                                                                                                                                                                  },
      { path: '/resetpw',                             component: ResetPassword,                         guard: [guards.guestGuard]                                                                                                                                                                                                                                  },
      { path: '/forgot_password',                     component: ForgotPassword,                        guard: [guards.guestGuard]                                                                                                                                                                                                                                  },
      { path: '/*',                                   component: Layout,                                guard: [guards.authGuard], children: [
        { path: '*',                                  name: t('routes.home'),                           jsx: <Navigate to="/" replace></Navigate>,                        guard: [guards.accessGuard({ code: ModuleCodes.Dashboard, action: ModuleActions.Dashboard.View })], fallback: '/jobs'                                                                      },
        { path: '',                                   name: t('routes.home'),                           component: Home,                                                  guard: [guards.accessGuard({ code: ModuleCodes.Dashboard, action: ModuleActions.Dashboard.View })], fallback: '/jobs'                                                                      },
        { path: 'agent',                              name: t('routes.home'),                           component: HomeAgent,                                             guard: [guards.accessGuard({ code: ModuleCodes.Dashboard, action: ModuleActions.Dashboard.View })], fallback: '/jobs'                                                                      },
        { path: 'timeline',                           name: t('routes.timeline'),                       component: Timeline,                                              guard: [guards.accessGuard(ModuleCodes.Timeline)]                                                                                                                                         },

        { path: 'jobs',                               name: t('routes.reservations', true),                   component: Jobs,                                                  guard: [guards.accessGuard(ModuleCodes.Jobs)]                                                                                                                                             },
        { path: 'jobs*',                              name: t('routes.reservations', true),                   jsx: <Navigate to="/jobs" replace></Navigate>,                    guard: [guards.accessGuard(ModuleCodes.Jobs)]                                                                                                                                             },
        { path: 'jobs/create',                        name: t('routes.reservations', true),                   jsx: <Jobs type="create"></Jobs>,                                 guard:[guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.Create })], fallback: '/jobs'                                        },
        { path: 'jobs/quick',                         name: t('routes.reservations', true),                   jsx: <Jobs type="quick"></Jobs>,                                  guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.CreateQuick })], fallback: '/jobs'                                   },
        { path: 'jobs/setShifts',                     name: t('routes.reservations', true),                   jsx: <Jobs type="setShifts"></Jobs>,                              guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.SetShifts })], fallback: '/jobs'                                     },
        { path: 'jobs/setSchoolShifts',               name: t('routes.reservations', true),                   jsx: <Jobs type="setSchoolShifts"></Jobs>,                        guard: [], fallback: '/jobs'                                     },
        { path: 'jobs/setWorkShifts',                 name: t('routes.reservations', true),                   jsx: <Jobs type="setWorkShifts"></Jobs>,                          guard: [], fallback: '/jobs'                                     },
        { path: 'jobs/shuttle',                       name: t('routes.reservations', true),                   jsx: <Jobs type="shuttle"></Jobs>,                                guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.Create })], fallback: '/jobs'                                        },
        { path: 'jobs/edit/:id',                      name: t('routes.reservations', true),                   jsx: <Jobs type="edit"></Jobs>,                                   guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.View })], fallback: '/jobs'                                                                                                                                             },

        { path: 'trips/',                             name: t('routes.trips'),                          component: Trips,                                                 guard: [guards.accessGuard(ModuleCodes.Trips)]                                                                                                                                            },
        { path: 'trips/:id',                          name: t('routes.trips'),                          component: Trips,                                                 guard: [guards.accessGuard(ModuleCodes.Trips)]                                                                                                                                            },
  
        { path: 'drivers' ,                           name: t('routes.drivers'),                        component: Driver,                                                guard: [guards.accessGuard(ModuleCodes.Drivers)]                                                                                                                                          },
        { path: 'drivers*',                           name: t('routes.drivers'),                        jsx: <Navigate to="/drivers" replace></Navigate>,                 guard: [guards.accessGuard(ModuleCodes.Drivers)]                                                                                                                                             },
        { path: 'drivers/create',                     name: t('routes.drivers'),                        jsx: <Driver type="create"></Driver>,                             guard: [guards.accessGuard(ModuleCodes.Drivers), guards.accessGuard({ code: ModuleCodes.Drivers, action: ModuleActions.Drivers.Create })], fallback: '/drivers'                            },
        { path: 'drivers/edit/:id',                   name: t('routes.drivers'),                        jsx: <Driver type="edit"></Driver>,                               guard: [guards.accessGuard(ModuleCodes.Drivers), guards.accessGuard({ code: ModuleCodes.Drivers, action: ModuleActions.Drivers.Edit })], fallback: '/drivers'                                                                                                                                          },

        { path: 'onlinedrivers',                      name: t('routes.onlineDrivers'),                  component: DriversOnline,                                         guard: [guards.accessGuard(ModuleCodes.ActiveDrivers)]                                                                                                                                    },
        { path: 'onlinedrivers*',                     name: t('routes.onlineDrivers'),                  jsx: <Navigate to="/onlineDrivers" replace></Navigate>,           guard: [guards.accessGuard(ModuleCodes.ActiveDrivers)]                                                                                                                                             },

        { path: 'passengers',                         name: t('routes.passengers'),                     component: Passengers,                                            guard: [guards.accessGuard(ModuleCodes.Passengers)]                                                                                                                                       },
        { path: 'passengers*',                        name: t('routes.passengers'),                     jsx: <Navigate to="/passengers" replace></Navigate>,              guard: [guards.accessGuard(ModuleCodes.Passengers)]                                                                                                                                             },
        { path: 'passengers/create',                  name: t('routes.passengers'),                     jsx: <Passengers type="create"></Passengers>,                     guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Create})], fallback: '/passengers'                 },
        { path: 'passengers/edit/:id',                name: t('routes.passengers'),                     jsx: <Passengers type="edit"></Passengers>,                       guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Edit})], fallback: '/passengers'                                                                                                                                       },

        { path: 'students',                         name: t('routes.students'),                     component: Students,                                                  guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]                                                                                                                                          },
        { path: 'students*',                        name: t('routes.students'),                     jsx: <Navigate to="/students" replace></Navigate>,                    guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]                                                                                                                                           },
        { path: 'students/create',                  name: t('routes.students'),                     jsx: <Students type="create"></Students>,                             guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Create}), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]     , fallback: '/students'                 },
        { path: 'students/edit/:id',                name: t('routes.students'),                     jsx: <Students type="edit"></Students>,                               guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Edit}), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]     , fallback: '/students'                                                                                                                                       },

        { path: 'employees',                         name: t('routes.employees'),                     component: Employees,                                                  guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessTemplateGuard(SystemTemplate.Employees)]                                                                                                                                          },
        { path: 'employees*',                        name: t('routes.employees'),                     jsx: <Navigate to="/employees" replace></Navigate>,                    guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessTemplateGuard(SystemTemplate.Employees)]                                                                                                                                           },
        { path: 'employees/create',                  name: t('routes.employees'),                     jsx: <Employees type="create"></Employees>,                             guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Create}), guards.accessTemplateGuard(SystemTemplate.Employees)]     , fallback: '/employees'                 },
        { path: 'employees/edit/:id',                name: t('routes.employees'),                     jsx: <Employees type="edit"></Employees>,                               guard: [guards.accessGuard(ModuleCodes.Passengers), guards.accessGuard({ code: ModuleCodes.Passengers, action: ModuleActions.Passengers.Edit}), guards.accessTemplateGuard(SystemTemplate.Employees)]     , fallback: '/employees'                                                                                                                                       },

        { path: 'vehicles',                           name: t('routes.vehicles', true),                       component: Vehicles,                                              guard: [guards.accessGuard(ModuleCodes.Vehicles)]                                                                                                                                         },
        { path: 'vehicles*',                          name: t('routes.vehicles', true),                       jsx: <Navigate to="/vehicles" replace></Navigate>,                guard: [guards.accessGuard(ModuleCodes.Vehicles)]                                                                                                                                             },
        { path: 'vehicles/create',                    name: t('routes.vehicles', true),                       jsx: <Vehicles type="create"></Vehicles>,                         guard: [guards.accessGuard(ModuleCodes.Vehicles), guards.accessGuard({ code: ModuleCodes.Vehicles, action: ModuleActions.Vehicles.Create })], fallback: '/vehicles'                         },
        { path: 'vehicles/edit/:id',                  name: t('routes.vehicles', true),                       jsx: <Vehicles type="edit"></Vehicles>,                           guard: [guards.accessGuard(ModuleCodes.Vehicles), guards.accessGuard({ code: ModuleCodes.Vehicles, action: ModuleActions.Vehicles.Edit })], fallback: '/vehicles'                                                                                                                                         },

        { path: 'livemap',                            name: t('routes.liveMap'),                        component: LiveMap,                                               guard: [guards.accessGuard(ModuleCodes.LiveMap)]                                                                                                                                          },
        { path: 'roadBlocks',                         name: t('routes.roadBlocks'),                     component: RoadBlocks,                                            guard: [guards.accessGuard(ModuleCodes.LiveMap)]                                                                                                                                          },

        { path: 'driverShifts',                       name: t('routes.driverShifts'),                   component: DriverShifts,                                          guard: [guards.accessGuard(ModuleCodes.DriversShifts)]                                                                                                                                    },
        { path: 'driverShifts*',                      name: t('routes.driverShifts'),                   jsx: <Navigate to="/driverShifts" replace></Navigate>,            guard: [guards.accessGuard(ModuleCodes.DriversShifts)]                                                                                                                                             },
        { path: 'driverShifts/create',                name: t('routes.driverShifts'),                   jsx: <DriverShifts type="create"></DriverShifts>,                 guard: [guards.accessGuard(ModuleCodes.DriversShifts), guards.accessGuard({ code: ModuleCodes.DriversShifts, action: ModuleActions.DriversShifts.Create })], fallback: '/driverShifts'     },
        { path: 'driverShifts/multi',                 name: t('routes.driverShifts'),                   jsx: <DriverShifts type="multi"></DriverShifts>,                  guard: [guards.accessGuard(ModuleCodes.DriversShifts), guards.accessGuard({ code: ModuleCodes.DriversShifts, action: ModuleActions.DriversShifts.Create }) ], fallback: '/driverShifts'     },
        { path: 'driverShifts/edit/:id',              name: t('routes.driverShifts'),                   jsx: <DriverShifts type="edit"></DriverShifts>,                   guard: [guards.accessGuard(ModuleCodes.DriversShifts), guards.accessGuard({ code: ModuleCodes.DriversShifts, action: ModuleActions.DriversShifts.Edit })], fallback: '/driverShifts'                                                                                                                                    },

        { path: 'reports',                            name: t('routes.reports'),                        component: Reports,                                               guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports*',                           name: t('routes.reports'),                        jsx: <Navigate to="/reports" replace></Navigate>,                 guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                             },
        { path: 'reports/trips-pax-reports',          name: t('routes.tripsPaxReports'),                component: TripsPaxReports,                                       guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/shuttle-reports',            name: t('routes.shuttleReports'),                 component: ShuttleReports,                                        guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/unique-drivers-report',      name: t('routes.uniqueDriversReport'),            component: UniqueDriversReport,                                   guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/drivers-reports',            name: t('driverReport.driverReportTitle'),        component: DriverReports,                                         guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/vehicles-reports',           name: t('vehicleReport.vehicleReportTitle'),      component: VehicleReports,                                        guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/bill-reports',               name: t('routes.billReportTitle'),                component: BillReport,                                            guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/holiday-reports',            name: t('routes.holidayReportTitle'),             component: HolidayReport,                                         guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        { path: 'reports/holidaytariff-reports',      name: t('routes.holidayTariffReportTitle'),       component: HolidayTariffReport,                                   guard: [guards.accessGuard(ModuleCodes.Reports)]                                                                                                                                          },
        

        { path: 'services',                           name: t('routes.services'),                       component: Services,                                              guard: [guards.accessGuard(ModuleCodes.Services)]                                                                                                                                         },
        { path: 'services*',                          name: t('routes.services'),                       jsx: <Navigate to="/services" replace></Navigate>,                guard: [guards.accessGuard(ModuleCodes.Services)]                                                                                                                                             },
        { path: 'services/create',                    name: t('routes.services'),                       jsx: <Services type="create"></Services>,                         guard: [guards.accessGuard(ModuleCodes.Services), guards.accessGuard({ code: ModuleCodes.Services, action: ModuleActions.Services.Create })], fallback: '/services'                        },
        { path: 'services/edit/:id',                  name: t('routes.services'),                       jsx: <Services type="edit"></Services>,                           guard: [guards.accessGuard(ModuleCodes.Services), guards.accessGuard({ code: ModuleCodes.Services, action: ModuleActions.Services.Edit })], fallback: '/services'                                                                                                                                         },

        { path: 'holidays',                           name: t('routes.holidays'),                       component: Holidays,                                                                                                                                                                                    },
        { path: 'holidays*',                          name: t('routes.holidays'),                       jsx: <Navigate to="/holidays" replace></Navigate>,                                                                                                                                                       },
        { path: 'holidays/create',                    name: t('routes.holidays'),                       jsx: <Holidays type="create"></Holidays>,                                                },
        { path: 'holidays/edit/:id',                  name: t('routes.holidays'),                       jsx: <Holidays type="edit"></Holidays>,                                                                                                                                                                },

        
        { path: 'locations',                          name: t('routes.locations'),                      component: Locations,                                             guard: [guards.accessGuard(ModuleCodes.Locations)]                                                                                                                                        },
        { path: 'locations*',                         name: t('routes.locations'),                      jsx: <Navigate to="/locations" replace></Navigate>,               guard: [guards.accessGuard(ModuleCodes.Locations)]                                                                                                                                             },
        { path: 'locations/create',                   name: t('routes.createLocation'),                 component: CreateLocation,                                        guard: [guards.accessGuard(ModuleCodes.Locations), guards.accessGuard({ code: ModuleCodes.Locations, action: ModuleActions.Locations.Create })], fallback: '/locations'                    },
        { path: 'locations/edit/:id',                 name: t('routes.editLocation'),                   component: UpdateLocation,                                        guard: [guards.accessGuard(ModuleCodes.Locations), guards.accessGuard({ code: ModuleCodes.Locations, action: ModuleActions.Locations.Edit })], fallback: '/locations'                                                                                                                                        },

        { path: 'fares',                              name: t('routes.fares'),                          component: Fares,                                                 guard: [guards.accessGuard(ModuleCodes.Fares)]                                                                                                                                            },
        { path: 'fares*',                             name: t('routes.fares'),                          jsx: <Navigate to="/fares" replace></Navigate>,                   guard: [guards.accessGuard(ModuleCodes.Fares)]                                                                                                                                                },
        { path: 'fares/create',                       name: t('routes.fares'),                          jsx: <Fares type="create"></Fares>,                               guard: [guards.accessGuard(ModuleCodes.Fares), guards.accessGuard({ code: ModuleCodes.Fares, action: ModuleActions.Fares.Create }), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]     , fallback: '/fares'                                    },
        { path: 'fares/edit/:id',                     name: t('routes.fares'),                          jsx: <Fares type="edit"></Fares>,                                 guard: [guards.accessGuard(ModuleCodes.Fares), guards.accessGuard({ code: ModuleCodes.Fares, action: ModuleActions.Fares.Edit }), guards.accessTemplateGuard(SystemTemplate.SchoolShuttles)]     , fallback: '/fares'                                                                                                                                            },

        { path: 'rates',                              name: t('routes.rates'),                          component: Rates,                                                 guard: [guards.accessGuard(ModuleCodes.Rates)]                                                                                                                                            },
        { path: 'rates*',                             name: t('routes.rates'),                          jsx: <Navigate to="/rates" replace></Navigate>,                   guard: [guards.accessGuard(ModuleCodes.Rates)]                                                                                                                                             },
        { path: 'rates/create',                       name: t('routes.rates'),                          jsx: <Rates type="create"></Rates>,                               guard: [guards.accessGuard(ModuleCodes.Rates), guards.accessGuard({ code: ModuleCodes.Rates, action: ModuleActions.Rates.Create })], fallback: '/rates'                                    },
        { path: 'rates/edit/:id',                     name: t('routes.rates'),                          jsx: <Rates type="edit"></Rates>,                                 guard: [guards.accessGuard(ModuleCodes.Rates), guards.accessGuard({ code: ModuleCodes.Rates, action: ModuleActions.Rates.Edit })], fallback: '/rates'                                                                                                                                            },

        { path: 'extraservices',                      name: t('routes.extraServices'),                  component: ExtraServices,                                         guard: [guards.accessGuard(ModuleCodes.ExtraServices)]                                                                                                                                    },
        { path: 'extraservices*',                     name: t('routes.extraservices'),                  jsx: <Navigate to="/extraservices" replace></Navigate>,           guard: [guards.accessGuard(ModuleCodes.ExtraServices)]                                                                                                                                             },
        { path: 'extraservices/create',               name: t('routes.extraServices'),                  jsx: <ExtraServices type="create"></ExtraServices>,               guard: [guards.accessGuard(ModuleCodes.ExtraServices), guards.accessGuard({ code: ModuleCodes.ExtraServices, action: ModuleActions.ExtraServices.Create })], fallback: '/extraServices'    },
        { path: 'extraservices/edit/:id',             name: t('routes.extraServices'),                  jsx: <ExtraServices type="edit"></ExtraServices>,                 guard: [guards.accessGuard(ModuleCodes.ExtraServices), guards.accessGuard({ code: ModuleCodes.ExtraServices, action: ModuleActions.ExtraServices.Edit })], fallbakc: '/extraServices'                                                                                                                                    },

        { path: 'suppliers',                          name: t('routes.suppliers'),                      component: Suppliers,                                             guard: [guards.accessGuard(ModuleCodes.Suppliers)]                                                                                                                                        },
        { path: 'suppliers*',                         name: t('routes.suppliers'),                      jsx: <Navigate to="/suppliers" replace></Navigate>,               guard: [guards.accessGuard(ModuleCodes.Suppliers)]                                                                                                                                        },
        { path: 'suppliers/create',                   name: t('routes.suppliers'),                      jsx: <Suppliers type="create"></Suppliers>,                       guard: [guards.accessGuard(ModuleCodes.Suppliers), guards.accessGuard({ code: ModuleCodes.Suppliers, action: ModuleActions.Suppliers.Create })], fallback: '/suppliers'                    },
        { path: 'suppliers/edit/:id',                 name: t('routes.suppliers'),                      jsx: <Suppliers type="edit"></Suppliers>,                         guard: [guards.accessGuard(ModuleCodes.Suppliers), guards.accessGuard({ code: ModuleCodes.Suppliers, action: ModuleActions.Suppliers.Edit })], fallback: '/suppliers'                                                                                                                                        },

        { path: 'users',                              name: t('routes.users'),                          component: Users,                                                 guard: [guards.accessGuard(ModuleCodes.Users)]                                                                                                                                            },
        { path: 'users*',                             name: t('routes.users'),                          jsx: <Navigate to="/users" replace></Navigate>,                   guard: [guards.accessGuard(ModuleCodes.Users)]                                                                                                                                            },
        { path: 'users/create',                       name: t('routes.users'),                          jsx: <Users type="create"></Users>,                               guard: [guards.accessGuard(ModuleCodes.Users), guards.accessGuard({ code: ModuleCodes.Users, action: ModuleActions.Users.Create })], fallback: '/users'                                    },
        { path: 'users/edit/:id',                     name: t('routes.users'),                          jsx: <Users type="edit"></Users>,                                 guard: [guards.accessGuard(ModuleCodes.Users)/*, guards.accessGuard({ code: ModuleCodes.Users, action: ModuleActions.Users.Edit }) TODO: return when added to the db  - or when arrived to new server code*/], fallback: '/users'                                                                                                                                            },

        { path: 'profiles',                           name: t('routes.profiles'),                       component: Profiles,                                              guard: [guards.accessGuard(ModuleCodes.Profiles)]                                                                                                                                         },
        { path: 'profiles*',                          name: t('routes.profiles'),                       jsx: <Navigate to="/profiles" replace></Navigate>,                guard: [guards.accessGuard(ModuleCodes.Profiles)]                                                                                                                                         },
        { path: 'profiles/create',                    name: t('routes.profiles'),                       jsx: <Profiles type="create"></Profiles>,                         guard: [guards.accessGuard(ModuleCodes.Profiles), guards.accessGuard({ code: ModuleCodes.Profiles, action: ModuleActions.Profiles.Create })], fallback: '/profiles'                        },
        { path: 'profiles/edit/:id',                  name: t('routes.profiles'),                       jsx: <Profiles type="edit"></Profiles>,                           guard: [guards.accessGuard(ModuleCodes.Profiles), guards.accessGuard({ code: ModuleCodes.Profiles, action: ModuleActions.Profiles.Edit })], fallback: '/profiles'                                                                                                                                            },

        { path: 'shifts',                             name: t('routes.shifts',true),                         component: Shifts,                                                guard: [guards.accessGuard(ModuleCodes.Shifts)]                                                                                                                                           },
        { path: 'shifts*',                            name: t('routes.shifts',true),                         jsx: <Navigate to="/shifts" replace></Navigate>,                  guard: [guards.accessGuard(ModuleCodes.Shifts)]                                                                                                                                           },
        { path: 'shifts/create',                      name: t('routes.shifts',true),                         jsx: <Shifts type="create"></Shifts>,                             guard: [guards.accessGuard(ModuleCodes.Shifts), guards.accessGuard({ code: ModuleCodes.Shifts, action: ModuleActions.Shifts.Create })], fallback: '/shifts'                                },
        { path: 'shifts/edit/:id',                    name: t('routes.shifts',true),                         jsx: <Shifts type="edit"></Shifts>,                               guard: [guards.accessGuard(ModuleCodes.Shifts), guards.accessGuard({ code: ModuleCodes.Shifts, action: ModuleActions.Shifts.Edit })], fallback: '/shifts'                                                                                                                                           },

        { path: 'shuttles',                           name: t('routes.shuttles'),                       component: Shuttles,                                              guard: [guards.accessGuard(ModuleCodes.Shuttles)]                                                                                                                                         },
        { path: 'shuttles*',                          name: t('routes.shuttles'),                       jsx: <Navigate to="/shuttles" replace></Navigate>,                guard: [guards.accessGuard(ModuleCodes.Shuttles)]                                                                                                                                         },
        { path: 'shuttles/create',                    name: t('routes.createShuttle'),                  component: CreateShuttle,                                         guard: [guards.accessGuard(ModuleCodes.Shuttles), guards.accessGuard({ code: ModuleCodes.Shuttles, action: ModuleActions.Shuttles.Create })], fallback: '/shuttles'                        },
        { path: 'shuttles/edit/:id',                  name: t('routes.editShuttle'),                    component: ShuttleEdit,                                           guard: [guards.accessGuard(ModuleCodes.Shuttles), guards.accessGuard({ code: ModuleCodes.Shuttles, action: ModuleActions.Shuttles.View })], fallback: '/shuttles'                                                                                                                                         },

        { path: 'import',                             name: t('routes.import'),                         component: Import,                                                guard: [guards.accessGuard({ code: ModuleCodes.UploadData, action: ModuleActions.UploadData.Upload })]                                                                                                                                         },
        { path: 'create-job',                         name: t('routes.createJob'),                      component: CreateJob,                                             guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.Create })], fallback: '/jobs'                                        },
        { path: 'quick-job',                          name: t('routes.quickJob'),                       component: QuickJob,                                              guard: [guards.accessGuard(ModuleCodes.Jobs), guards.accessGuard({ code: ModuleCodes.Jobs, action: ModuleActions.Jobs.CreateQuick })], fallback: '/jobs'                                   },
        { path: 'logout',                             name: t('routes.logout'),                         component: Logout                                                                                                                                                                                                                                           },
        { path: 'permissions',                        name: t('routes.permissions'),                    component: Permissions                                                                                                                                                                                                                                      },

        { path: 'systemsettings',                     name: t('routes.settings'),                       component: SystemSettings,                                        guard: [guards.accessGuard(ModuleCodes.Settings)]                                                                                                                                         },
        { path: 'systemsettings*',                    name: t('routes.settings'),                       jsx: <Navigate to="/systemsettings" replace></Navigate>,          guard: [guards.accessGuard(ModuleCodes.Settings)]                                                                                                                                         },
        { path: 'systemsettings/create',              name: t('routes.settings'),                       jsx: <SystemSettings type="create"></SystemSettings>,             guard: [guards.accessGuard(ModuleCodes.Settings)]                                                                                                                                         },
        { path: 'systemsettings/edit/:id',            name: t('routes.settings'),                       jsx: <SystemSettings type="edit"></SystemSettings>,               guard: [guards.accessGuard(ModuleCodes.Settings)]                                                                                                                                         },

        { path: 'areas',                              name: t('routes.areas'),                          component: Areas,                                                 guard: [guards.accessGuard(ModuleCodes.AreasZonePricing)]                                                                                                                                                                                             },
        { path: 'areas*',                             name: t('routes.areas'),                          jsx: <Navigate to="/areas" replace></Navigate>,                   guard: [guards.accessGuard(ModuleCodes.AreasZonePricing)]                                                                                                                                                                                                                                 },
        { path: 'areas/create',                       name: t('routes.areas'),                          jsx: <Areas type="create"></Areas>,                               guard: [guards.accessGuard(ModuleCodes.AreasZonePricing), guards.accessGuard({ code: ModuleCodes.AreasZonePricing, action: ModuleActions.AreasZonePricing.Create })], fallback: '/areas'                                                                                                                                                                                          },
        { path: 'areas/edit/:id',                     name: t('routes.areas'),                          jsx: <Areas type="edit"></Areas>,                                 guard: [guards.accessGuard(ModuleCodes.AreasZonePricing), guards.accessGuard({ code: ModuleCodes.AreasZonePricing, action: ModuleActions.AreasZonePricing.Edit })], fallback: '/areas'                                                                                                                                                                                           },

        { path: 'blacklist',                           name: t('routes.blacklist'),                       component: Blacklist,                                              guard: [guards.accessGuard(ModuleCodes.Blacklist)]                                                                                                                                         },
        { path: 'blacklist*',                          name: t('routes.blacklist'),                       jsx: <Navigate to="/blacklist" replace></Navigate>,                guard: [guards.accessGuard(ModuleCodes.Blacklist)]                                                                                                                                         },
        { path: 'blacklist/create',                    name: t('routes.blacklist'),                       jsx: <Blacklist type="create"></Blacklist>,                         guard: [guards.accessGuard(ModuleCodes.Blacklist), guards.accessGuard({ code: ModuleCodes.Blacklist, action: ModuleActions.Blacklist.Create })], fallback: '/blacklist'                        },
        { path: 'blacklist/edit/:id',                  name: t('routes.blacklist'),                       jsx: <Blacklist type="edit"></Blacklist>,                           guard: [guards.accessGuard(ModuleCodes.Blacklist), guards.accessGuard({ code: ModuleCodes.Blacklist, action: ModuleActions.Blacklist.Edit })], fallback: '/blacklist'                                                                                                                                            },

        { path: 'sites',                              name: t('routes.sites'),                          component: Sites                                                                                                                                                                                                                                            },
        { path: 'sites*',                             name: t('routes.sites'),                          jsx: <Navigate to="/sites" replace></Navigate>,                                                                                                                                                                                                                                             },
        { path: 'sites/create',                       name: t('routes.sites'),                          jsx: <Sites type="create"></Sites>                                                                                                                                                                                                                          },
        { path: 'sites/edit/:id',                     name: t('routes.sites'),                          jsx: <Sites type="edit"></Sites>                                                                                                                                                                                                                            },

        { path: 'attributes',                        name: t('routes.attributes'),                     component: Attributes,                                             /*guard: [guards.accessGuard(ModuleCodes.Attributes)] TODO: return in new server */                                                                                                                                                                                           },
        { path: 'attributes*',                        name: t('routes.attributes'),                     jsx: <Navigate to="/attributes" replace></Navigate>,              /*guard: [guards.accessGuard(ModuleCodes.Attributes)] TODO: return*/                                                                                                                                                                                                                          },
        { path: 'attributes/create',                  name: t('routes.attributes'),                     jsx: <Attributes type="create"></Attributes>,                     /*guard: [guards.accessGuard(ModuleCodes.Attributes), guards.accessGuard({ code: ModuleCodes.Attributes, action: ModuleActions.Attributes.Create })], fallback: '/attributes' TODO: return*/                                                                                                                                                                                              },
        { path: 'attributes/edit/:id',                name: t('routes.attributes'),                     jsx: <Attributes type="edit"></Attributes>,                       /*guard: [guards.accessGuard(ModuleCodes.Attributes), guards.accessGuard({ code: ModuleCodes.Attributes, action: ModuleActions.Attributes.Edit })], fallback: '/attributes'  TODO: return*/                                                                                                                                                                                            },

        { path: 'assign-priority',                    name: t('routes.assignPriority'),                 component: AssignPriority,                                             /*guard: [guards.accessGuard(ModuleCodes.Attributes)] TODO: return in new server */                                                                                                                                                                                           },
        { path: 'assign-priority*',                   name: t('routes.assignPriority'),                 jsx: <Navigate to="/assign-priority" replace></Navigate>,              /*guard: [guards.accessGuard(ModuleCodes.Attributes)] TODO: return*/                                                                                                                                                                                                                          },
        { path: 'assign-priority/create',             name: t('routes.assignPriority'),                 jsx: <AssignPriority type="create"></AssignPriority>,                     /*guard: [guards.accessGuard(ModuleCodes.Attributes), guards.accessGuard({ code: ModuleCodes.Attributes, action: ModuleActions.Attributes.Create })], fallback: '/attributes' TODO: return*/                                                                                                                                                                                              },
        { path: 'assign-priority/edit/:id',           name: t('routes.assignPriority'),                 jsx: <AssignPriority type="edit"></AssignPriority>,                       /*guard: [guards.accessGuard(ModuleCodes.Attributes), guards.accessGuard({ code: ModuleCodes.Attributes, action: ModuleActions.Attributes.Edit })], fallback: '/attributes'  TODO: return*/                                                                                                                                                                                            },

        { path: 'parents',                            name: t('routes.parents'),                        component: Parents,                                              guard: []                                                                                                                                                                                          },
        { path: 'parents*',                           name: t('routes.parents'),                        jsx: <Navigate to="/parents" replace></Navigate>,                guard: []                                                                                                                                                                                                                       },
        { path: 'parents/create',                     name: t('routes.parents'),                        jsx: <Parents type="create"></Parents>,                          guard: [], fallback: '/parents'                                                                                                                                                                                          },
        { path: 'parents/edit/:id',                   name: t('routes.parents'),                        jsx: <Parents type="edit"></Parents>,                            guard: [], fallback: '/parents'                                                                                                                                                                                       },
      ] 
      },
    ];
  }, [t, perm]);

  return routes;
};

export type Route = ComponentRoute | JSXRoute;

export default useRoutes;
