import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { StylesConfig, OnChangeValue, SingleValue } from 'react-select';

import MAddressView from './MAddress.view';

import { IAutoCompleteResponse } from 'models/response';
import { serverAxios } from 'utils/http';
import { MAddressSelectOption } from './MAddress.model';
import useCancelToken from 'utils/hooks/use-cancel-token-effect';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

interface Props {
  name?: string;
  defaultValue?: string;
  valid?: boolean;
  invalid?: boolean;
  customStylesSelect?: StylesConfig<MAddressSelectOption, false>;
  value?: SingleValue<MAddressSelectOption>;
  disabled?: boolean;
  size?: string;
  placeholder?: string;
  withLocations?: boolean;
  onChange?: (value: OnChangeValue<MAddressSelectOption, false>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onMenuClose?: () => void;
}

const MAddress: React.FC<Props> = (props: Props) => {
  const setLoadOptionsTokenState = useCancelToken();

  const loadOptions = (inputValue: string, callback: (prop: MAddressSelectOption[]) => void) => {
    if (inputValue.length <= 0) {
      callback([]);

      return;
    }

    const cancelToken = axios.CancelToken.source();

    setLoadOptionsTokenState(() => cancelToken);

    // '&' sign does not supported by autocomple and there for replaced by 'and'
    const validAddressInput = inputValue.replace('&', ' and ');

    serverAxios
      .post(
        '/',
        {
          act: 'autocomplete',
          locations: props.withLocations,
          address: validAddressInput,
          limit: 10,
          options: {},
          components: null,
        },
        {
          cancelToken: cancelToken.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((res: AxiosResponse<IAutoCompleteResponse>) => {
        callback(
          res.data.map((address: string) => {
            return { label: address.split('|')[0], value: address };
          }),
        );
      })
      .catch(console.error)
      .finally(() => setLoadOptionsTokenState(() => null));
  };

  return <MAddressView size={props.size} loadOptions={loadOptions} {...props}></MAddressView>;
};

MAddress.displayName = 'MAddress';
MAddress.defaultProps = {};

export default MAddress;
