const settings = {
  parameters: {
    createParameter: 'Create Parameter',
    searchInputPlaceholder: 'Search parameter name',
    editParameter: 'Edit Parameter',
    parameters: 'Parameters',
    selectParameterMessage: 'Select a parameter',
    addNewParameter: 'Add new parameter',
    label: 'Label',
    name: 'Name',
    category: 'Category',
    unit: 'Profile',
    type: 'Type',
    actions: 'Actions',
    form: {
      name: 'Name',
      label: 'Label',
      selectName: 'Select name',
      enterLabel: 'Enter Label',
      profile: 'Profile',
      selectProfile: 'Select profile',
      category: 'Category',
      selectCategory: 'Select category',
      type: 'Type',
      selectType: 'Select type',
      value: 'Value',
      enterValue: 'Enter the value',
      showAdvanced: 'Show advanced',
      hideAdvanced: 'Hide advanced',
    },
  },
};

export default settings;