import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ParentsView from './Parents.view';

import { MFormPageProps } from 'views/MForm/MForm.model';
import { MTableAdvancedServerRef } from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';

type Props = MFormPageProps;

const Parents: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const tableRef = useRef<MTableAdvancedServerRef>(null);

  const handleSubmit = () => {
    tableRef.current?.forceFetch();
    navigate('/parents');
  };

  return <ParentsView tableRef={tableRef} type={props.type} onSubmit={handleSubmit} onBack={() => navigate('/parents')}></ParentsView>;
};

Parents.displayName = 'Parents';
Parents.defaultProps = {};

export default Parents;
