const recurringInput = {
  repeatEvery: 'Repetir a Cada',
  repeatOn: 'Repetir em',
  ends: 'Fim',
  endsOn: 'Finaliza em',
  form: {
    month: 'Mês',
    day: 'Dia',
    week: 'Semana',
    year: 'Ano',
    occurrences: 'Ocorrências',
    after: 'Depois',
    never: 'Nunca',
    on: 'Ligado',
  },
};

export default recurringInput;
