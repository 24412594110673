import React from 'react';
import { useSelector } from 'react-redux';

import WithoutSiteView from './WithoutSite.view';

import { AppState } from '../../models/app-store';

interface Props {}

const WithoutSite = (props: React.PropsWithChildren<Props>): JSX.Element | null => {
  const viewSite = useSelector((state: AppState) => state.sites.viewSite)?.name || '';
  const site = useSelector((state: AppState) => state.auth.user?.getSite() || null);

  return (
    <WithoutSiteView site={site} viewSite={viewSite}>
      {props.children}
    </WithoutSiteView>
  );
};

WithoutSite.displayName = 'WithoutSite';

export default WithoutSite;
