import React from 'react';
import { CellProps } from 'react-table';

import MTableCellExpandCheckView from './MTableCellExpandCheck.view';

import { RowData } from 'views/MTable/Table.model';

interface Props<T extends RowData> extends CellProps<T> {
  disableSelection?: boolean;
}

const MTableCellExpandCheck = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <MTableCellExpandCheckView disableSelection={props.disableSelection} toggleRowsSelectedProps={props.row.getToggleRowSelectedProps()}>
      {props.children}
    </MTableCellExpandCheckView>
  );
};

MTableCellExpandCheck.displayName = 'MTableCellExpandCheck';
MTableCellExpandCheck.defaultProps = {};

export default MTableCellExpandCheck;
