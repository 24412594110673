const uniqueDriversReport = {
  table: {
    headers: {
      date: 'Date',
      uniqueDrivers: 'Unique Drivers',
    },
    tableDetails: {
      headers: {
        date: 'Date',
        uniqueDrivers: 'Unique Drivers',
        driver: 'Driver',
        trips: 'Trips',
      },
    },
  },
  actions: {
    export: 'Export',
  },
};

export default uniqueDriversReport;
