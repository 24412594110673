const driversOnline = {
  table: {
    headers: {
      name: 'Nome',
      driverStatus: 'Status',
      email: 'E-mail',
      vehicle: 'Veículo',
      phoneNumber: 'Número de telefone',
      address: 'Endereço',
    },
    driverStatusOptions: {
      online: 'Online',
      enRoute: 'Em Rota',
      break: 'Pausa',
      endBreak: 'Fim de intervalo',
    },
    actions: {
      deactivate: 'Compensar',
    },
  },
  form: {
    fields: {
      driver: 'Motorista',
      vehicle: 'Veículo',
    },
    activateButton: 'Conectar-se',
  },
  deactivateModal: {
    header: 'Desativar motorista',
    body: 'Tem certeza de que deseja desativar o {{driver}}?',
  },
  breakModal: {
    header: 'Motorista de intervalo',
    body: 'Tem certeza de que deseja liberar {{driver}} para um intervalo?',
  },
};

export default driversOnline;
