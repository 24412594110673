const vehicleReport = {
  vehicleReportTitle: 'Relatório de viagens de veículos',
  table: {
    headers: {
      vehicle: 'Veículo',
      driver: 'Motorista',
      trips: 'Viagens',
      start: 'Início',
      end: 'Fim',
      jobs: 'Reservas',
      tripDuration: 'Duração da Viagem',
      tripDistance: 'Distância de Viagem',
      distanceToPickUp: 'Distância para Recolhimento',
      amount: 'Quantidade',
    },
    driver: 'Motorista',
    Amount: 'Quantidade',
    unassignedTrips: 'Viagens não atribuídas',
  },
  filter: {
    export: 'Exportar',
    driver: 'Motorista',
    car: 'Veículo',
    submit: 'Filtrar',
    clear: 'Limpar filtro',
    validation: {
      startDateRequired: 'A data de início é obrigatória',
      endDateRequired: 'A data de término é obrigatória',
    },
  },
  vehicleSummary: {
    total: 'Total',
    totalTrips: 'Viagens',
    totalReservation: 'Reservas',
    totalAmount: 'Valor Total',
  },
  form: {
    dateEnd: 'Data de fim',
    dateStart: 'Data de início',
  },
};

export default vehicleReport;
