const fares = {
  toast: {
    error: {
      badResponse: '[tarifa] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createFareBtn: 'Novo',
  showBreakdownBtn: 'Mostrar detalhamento',
  total: 'Total:',
  table: {
    headers: {
      biDir: 'Bidirecional',
    },
    biDirOptions: {
      way2: '2 Vias',
      way1: 'Só ida',
    },
  },
  fareTypeOptions: {
    Base: 'Base',
    Addition: 'Adição',
    Percent: 'Porcentagem',
    Total: 'Total',
  },
  additions: {
    value: 'Valor',
    valueType: 'Tipo do Valor',
    name: 'Nome',
    hour1: 'Horário de início',
    hour2: 'Fim do tempo',
    date1: 'Data de início',
    date2: 'Data de término',
    cancel: 'Cancelar',
    addAddition: 'Adicionar Adicionar',
    priority: 'Prioridade',
    weekDays: 'Dias da Semana',
    newAddition: 'Nova adição',
    price: 'Preço',
    total: 'Total',
    percent: 'Porcentagem',
    columnExist: 'O título já existe',
  },
  create: {
    confirmButtonText: 'Confirmar',
    code: 'Código',
    from: 'De',
    to: 'Para',
    service: 'Serviço',
    select: 'Selecione',
    validations: {
      code: 'O código é obrigatório',
    },
    modalSuccess: {
      header: 'Sucesso.',
      body: 'tarifa criada.',
    },
    modalError: {
      header: 'Erro.',
      body: 'Não é possível criar tarifa.',
    },
  },
  update: {
    code: 'Código',
    from: 'De',
    to: 'Para',
    service: 'Serviço',
    price: 'Preço',
    confirmButtonText: 'Atualizar',
    successMsg: 'Taxa atualizada com sucesso',
    modalError: {
      header: 'Erro.',
      body: 'Não é possível atualizar a tarifa.',
    },
  },
  validations: {
    code: {
      require: 'O código é obrigatório',
    },
    from: {
      require: 'De é obrigatório',
    },
    to: {
      require: 'Para é requerido',
    },
    service: {
      require: 'A assistência é necessária',
    },
    unit: {
      require: 'Requer-se a unidade',
    },
    rateType: {
      require: 'O tipo de taxa é obrigatório',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Detalhes da tarifa',
        fields: {
          units: {
            name: 'Locais',
          },
          code: {
            name: 'Código',
          },
          name: {
            name: 'Nome',
          },
          tag: {
            name: 'Etiqueta',
          },
          parentTag: {
            name: 'Etiqueta Principal',
          },
          parentCode: {
            name: 'Código Pai',
          },
          from: {
            name: 'De',
          },
          to: {
            name: 'Para',
          },
          service: {
            name: 'Serviço',
          },
          price: {
            name: 'Preço',
          },
          value: {
            name: 'Valor',
          },
          biDir: {
            name: 'Bidirecional',
          },
          rateType: {
            name: 'Tipo de Taxa',
          },
          type: {
            name: 'Tipo',
          },
          priority: {
            name: 'Prioridade',
          },
        },
      },
      mileage: {
        header: 'Quilometragem',
        fields: {
          distanceFrom: {
            name: 'Distância de',
          },
          distanceTo: {
            name: 'Distância até',
          },
        },
      },
      conditions: {
        header: 'Condições',
        fields: {
          startDate: {
            name: 'Data de início',
          },
          endDate: {
            name: 'Data de término',
          },
          startHour: {
            name: 'Horário de início',
          },
          endHour: {
            name: 'Fim do tempo',
          },
          weekDays: {
            name: 'Dias úteis',
          },
        },
      },
      additionsHead: {
        header: 'Divisão',
        validations: {
          name: 'O valor é obrigatório',
          value: 'O valor é obrigatório',
          valueType: 'O valor é obrigatório',
        },
        fields: {},
      },
    },
  },
};

export default fares;
