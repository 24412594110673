import React from 'react';
import CIcon from '@coreui/icons-react';

import classes from './MFormHeader.module.scss';

interface Props {
  onBack?: () => void;
}

const MFormHeaderView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <CIcon name="cil-arrow-left" onClick={props.onBack} className={classes['back']}></CIcon>;
};

MFormHeaderView.displayName = 'MFormHeaderView';
MFormHeaderView.defaultProps = {};

export default MFormHeaderView;
