import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetServicesResponse } from 'models/response';
import {
  ICreateServicesData,
  IDeleteServicesFilters,
  IGetServicesFilters,
  IUpdateServicesData,
  IUpdateServicesFilters,
} from 'models/http/service';
import { IService } from 'models/service';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class ServiceHttp {
  private static route = '/';

  public async get(filters: IGetServicesFilters, cancelTokenSource?: CancelTokenSource): Promise<IService[]> {
    return serverAxios
      .post(
        ServiceHttp.route,
        {
          act: 'find',
          col: 'services',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetServicesResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource?: CancelTokenSource): Promise<IService> {
    return serverAxios
      .post(
        ServiceHttp.route,
        {
          act: 'find',
          col: 'services',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetServicesResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateServicesData): Promise<void> {
    return serverAxios
      .post(
        ServiceHttp.route,
        {
          act: 'insert',
          col: 'services',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteServicesFilters): Promise<void> {
    return serverAxios
      .post(ServiceHttp.route, {
        act: 'delete',
        col: 'services',
        query: filters,
      },
      {
        timeout: HttpTimeoutPriority.Low,
      })
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateServicesFilters, data: IUpdateServicesData): Promise<void> {
    return serverAxios
      .post(ServiceHttp.route, {
        act: 'update',
        col: 'services',
        query: filters,
        data,
      },
      {
        timeout: HttpTimeoutPriority.Medium,
      })
      .then(() => {
        return;
      });
  }
}

export default ServiceHttp;
