const workShifts = {
  form: {
    general: {
      header: 'General',
      fields: {
        students: {
          label: 'Select Employees',
        },
      },
    },
    details: {
      header: 'Details',
      fields: {
        direction: {
          label: 'Direction',
        },
        shift: {
          label: 'Work Hours',
        },
        from: 'From',
        to: 'To',
        fromDate: {
          label: 'From Date',
        },
        toDate: {
          label: 'To Date',
        },
        weekDays: 'Weekdays',
        fromHour: {
          label: 'Work Starts at',
        },
        reqPick: {
          label: 'Requested Pickup Time',
        },
        toHour: {
          label: 'Work Ends at',
        },
      },
    },
  },
  select: 'Select',
  direction: {
    collect: {
      templates: {
        default: 'Collect',
        schoolShuttles: 'To School',
        employees: 'To Work',
        trailers: 'Collect',
      },
    },
    distribute: {
      templates: {
        default: 'Distribute',
        schoolShuttles: 'From School',
        employees: 'From Work',
        trailers: 'Distribute',
      },
    },
    collectAndDistribute: {
      templates: {
        default: 'Collect/Distribute',
        schoolShuttles: 'To School / From School',
        employees: 'To work / From work',
        trailers: 'Collect/Distribute',
      },
    },
  },
};

export default workShifts;
