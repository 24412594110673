import React from 'react';

import MDateNavigatorView from './MDateNavigator.view';

import { MDateNavigatorDirection } from './MDateNavigator.model';

interface Props {
  className?: string;
  onClick?: (direction: MDateNavigatorDirection) => void;
}

const MDateNavigator: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MDateNavigatorView className={props.className} onClick={props.onClick}></MDateNavigatorView>;
};

MDateNavigator.displayName = 'MDateNavigator';
MDateNavigator.defaultProps = {};

export default MDateNavigator;
