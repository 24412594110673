import React from 'react';

import MFormFooterView from './MFormFooter.view';

interface Props {
  cancelText?: string;
  confirmText?: string;
  additionalActionText?: string;
  className?: string;
  disabled?: boolean;
  hideSubmitButton?: boolean;
  onCancel?: () => void;
  onAdditionalAction?: () => void;
}

const MFormFooter: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MFormFooterView
      disabled={props.disabled}
      cancelText={props.cancelText}
      confirmText={props.confirmText}
      additionalActionText={props.additionalActionText}
      className={props.className}
      onCancel={props.onCancel}
      hideSubmitButton={props.hideSubmitButton}
      onAdditionalAction={props.onAdditionalAction}
    >
      {props.children}
    </MFormFooterView>
  );
};

MFormFooter.displayName = 'MFormFooter';
MFormFooter.defaultProps = {};

export default MFormFooter;
