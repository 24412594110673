import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetHolidaysResponse } from 'models/response';
import {
  ICreateHolidaysData,
  IDeleteHolidaysFilters,
  IGetHolidaysFilters,
  IUpdateHolidaysData,
  IUpdateHolidaysFilters,
} from 'models/http/holidays';
import { IHoliday } from 'models/holidays';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class HolidayHttp {
  private static route = '/';

  public async get(filters: IGetHolidaysFilters, cancelTokenSource?: CancelTokenSource): Promise<IHoliday[]> {
    const unitQuery = {
      $or: [filters, { unit: { $exists: false } }, { unit: { $eq: null } }],
    };

    return serverAxios
      .post(
        HolidayHttp.route,
        {
          act: 'find',
          col: 'holidays',
          query: unitQuery,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetHolidaysResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource?: CancelTokenSource): Promise<IHoliday> {
    return serverAxios
      .post(
        HolidayHttp.route,
        {
          act: 'find',
          col: 'holidays',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetHolidaysResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateHolidaysData): Promise<void> {
    return serverAxios
      .post(
        HolidayHttp.route,
        {
          act: 'insert',
          col: 'holidays',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteHolidaysFilters): Promise<void> {
    return serverAxios
      .post(
        HolidayHttp.route,
        {
          act: 'delete',
          col: 'holidays',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateHolidaysFilters, data: IUpdateHolidaysData): Promise<void> {
    return serverAxios
      .post(
        HolidayHttp.route,
        {
          act: 'update',
          col: 'holidays',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default HolidayHttp;
