import React from 'react';

import MInput from 'views/MInput/MInput';

interface Props {
  name: string;
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  value: string | number;
  type?: string;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  placeholder?: string;
  autoComplete?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MFormInputView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MInput
      name={props.name}
      valid={props.valid}
      invalid={props.invalid}
      disabled={props.disabled}
      value={props.value}
      type={props.type}
      className={props.className}
      min={props.min}
      max={props.max}
      step={props.step}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      onInput={props.onInput}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={props.onChange}
    />
  );
};

MFormInputView.displayName = 'MFormInputView';
MFormInputView.defaultProps = {};

export default MFormInputView;
