import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from 'utils/http';
import { IGetExtraServicesResponse } from 'models/response';
import { IGetProfilesFilters } from 'models/http/profile';
import { IExtraService } from 'models/extraServices';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class ExtraServicesHttp {
  private static route = '/';

  public async get(filters: IGetProfilesFilters, cancelTokenSource?: CancelTokenSource): Promise<IExtraService[]> {
    return serverAxios
      .post(ExtraServicesHttp.route, {
        act: 'find',
        col: 'extraServices',
        query: filters,
      },
      {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Medium,
      })
      .then((response: AxiosResponse<IGetExtraServicesResponse>) => {
        return response.data.data;
      });
  }
}

export default ExtraServicesHttp;
