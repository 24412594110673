import React from 'react';
import MTextTooltip from 'views/MTextTooltip/MTextTooltip';

import classes from './ParentsTableCellsAddress.module.scss';

interface Props {
  text: string;
}

const ParentsTableCellsAddressView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return props.text?.length > 20 ? (
    <MTextTooltip className={classes['container']} text={props.text}>
      {props.text}
    </MTextTooltip>
  ) : (
    <span>{props.text}</span>
  );
};

ParentsTableCellsAddressView.displayName = 'ParentsTableCellsAddressView';
ParentsTableCellsAddressView.defaultProps = {};

export default ParentsTableCellsAddressView;
