import React from 'react';
import { FormikContextType } from 'formik';

import MFormContainer from 'views/MForm/MFormContainer/MFormContainer';
import MFormHeader from 'views/MForm/MFormHeader/MFormHeader';
import ParentsFormGroups from '../ParentsFormSections/ParentsFormGroups/ParentsFormGroups';
import ParentsFormPersonalDetails from '../ParentsFormSections/ParentsFormPersonalDetails/ParentsFormPersonalDetails';

import { IParentsUpdateFormFields } from './ParentsUpdate.model';
import MFormFooter from 'views/MForm/MFormFooter/MFormFooter';
import ParentsFormLinkStudent from '../ParentsFormSections/ParentsFormLinkStudent/ParentsFormLinkStudent';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';

import classes from './ParentsUpdate.module.scss';
import MHistory from 'components/History/MHistory';
import { useTranslation } from 'react-i18next';
import Modal from 'views/MModal/Modal';
import statuses from 'utils/enums/statuses';

type Props = {
  id?: string;
  form: FormikContextType<IParentsUpdateFormFields>;
  isLoading: boolean;
  statusModal: boolean;
  statusButton: { action: string; status: string; color: string };
  onChangeStatusClick: () => void;
  onCloseStatusModal: () => void;
  onStatusModalSubmit: () => void;
  onBack: () => void;
};

const ParentsUpdateView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Modal
        show={props.statusModal}
        onClose={props.onCloseStatusModal}
        header={t('parents.modal.header')}
        body={`${t('parents.modal.body')} ${props.statusButton.status === 'Active' ? statuses.Inactive : statuses.Active}`}
        onConfirm={props.onStatusModalSubmit}
      ></Modal>

      <CTabs activeTab="home">
        <div className={classes['container']}>
          <div className={classes['header']}>
            <MFormHeader onBack={props.onBack}></MFormHeader>
            <div className={classes['actions']}>
              <div className={classes['actions--text']} onClick={props.onChangeStatusClick}>
                {props.statusButton.action}
              </div>
              <div className={`${classes['actions--dot']} ${classes[props.statusButton.color]}`}>{props.statusButton.status}</div>
            </div>
            <CNav variant="tabs" className={classes['tabs']}>
              <CNavItem>
                <CNavLink data-tab="home">{t('parents.tabs.information')}</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="history">{t('parents.tabs.history')}</CNavLink>
              </CNavItem>
            </CNav>
          </div>
          <CTabContent className={classes['contentWrapper']}>
            <CTabPane data-tab="home" className={classes['content']}>
              <MFormContainer formik={props.form} isLoading={props.isLoading}>
                <ParentsFormGroups form={props.form}></ParentsFormGroups>
                <ParentsFormPersonalDetails form={props.form}></ParentsFormPersonalDetails>
                <ParentsFormLinkStudent form={props.form}></ParentsFormLinkStudent>
                <MFormFooter></MFormFooter>
              </MFormContainer>
            </CTabPane>
            <CTabPane data-tab="history">
              <MHistory id={props.id || ''} collection="users" />
            </CTabPane>
          </CTabContent>
        </div>
      </CTabs>
    </React.Fragment>
  );
};

ParentsUpdateView.displayName = 'ParentsUpdateView';
ParentsUpdateView.defaultProps = {};

export default ParentsUpdateView;
