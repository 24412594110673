import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FilterValue, IdType } from 'react-table';

import sess from 'utils/session-storage';
import { RowData, TableSessionData } from 'views/MTable/Table.model';
import { SessionStorageTablesKeys } from 'utils/enums/storage';

const useTableCacheEffect = <TableFields extends RowData>(
  key: SessionStorageTablesKeys | null,
  effect: (data: TableSessionData<TableFields> | null) => void,
  dependencies: React.DependencyList,
): void => {
  const [cacheState, setCacheState] = useState<TableSessionData<TableFields> | null>(null);

  useEffect(() => {
    if (!key) {
      setCacheState(() => null);

      return;
    }

    sess
      .load<TableSessionData<TableFields>>(key)
      .then((sessionData: TableSessionData<TableFields>) => {
        const filtersObj = sessionData.filters.map((filter: { id: IdType<TableFields>; value: FilterValue }) => {
          let objectValue: FilterValue = filter.value;

          // Moment
          if (_.isString(filter.value)) {
            const dateObj = moment(filter.value);

            if (dateObj.isValid()) {
              objectValue = dateObj;
            }
          }

          // Array of moment
          if (_.isArray(filter.value)) {
            objectValue = filter.value.map((value: FilterValue) => {
              const dateObj = moment(value);

              if (dateObj.isValid()) {
                return dateObj;
              }

              return value;
            });
          }

          return { id: filter.id, value: objectValue };
        }, {});

        setCacheState({ ...sessionData, filters: filtersObj });
      })
      .catch(() => setCacheState(null));
  }, [key]);

  useEffect(() => {
    if (!key) {
      effect(null);

      return;
    }

    effect(cacheState);
  }, [cacheState, ...dependencies]);
};

export default useTableCacheEffect;
