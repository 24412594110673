import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine-light/src/index.js';
import 'leaflet-routing-machine-light/dist/leaflet-routing-machine.css';
import { useSelector } from 'react-redux';

import { AppState } from '../../../models/app-store';
import { ILatLng } from '../../../models/latlng';

interface Props {
  points: ILatLng[];
  color: string;
  weight?: number;
}

const MMapRouting: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const map = useMap();
  const viewSite = useSelector((state: AppState) => state.sites.viewSite);

  const routeServerFromStore = useSelector(
    (state: AppState) => {
      if(viewSite) {
        return state.auth.settings.find((setting: { name: string; value: string, site?: string} ) => {
          return (viewSite?.name === setting.site && setting.name === 'map route server');  
        });
      }

      return state.auth.settings.find((setting: { name: string; value: string, site?: string} ) => {
        return setting.name === 'map route server';  
      });
    }) || null;

  const [linesState, setLinesState] = useState<L.Path | null>(null);

  useEffect(() => {
    const routeServer = routeServerFromStore ? 'https://' + routeServerFromStore.value + '/route/v1' : 'https://usa.maps.moovex.ai/route/v1'; // routing algorithem

    const route = L.Routing.control({
      waypoints: props.points.map((point: ILatLng) => L.latLng(point.lat, point.lng)),
      routeWhileDragging: false,
      addWaypoints: false,
      fitSelectedRoutes: false,
      lineOptions: {
        addWaypoints: false,
        extendToWaypoints: false,
        missingRouteTolerance: 1,
        styles: [{ color: `${props.color}`, opacity: 0.8, weight: props.weight }],
      },
      createMarker: () => null,
      router: new L.Routing.OSRMv1({ serviceUrl: routeServer }), // wihch sever definition
      routeLine(route, options) {
        const line = L.Routing.line(route, options);

        setLinesState(() => line.getLayers()[0] as L.Path);

        return line;
      },
    }).addTo(map);

    route.hide(); //hide nevigation

    return () => {
      route.remove();
    };
  }, [JSON.stringify(props.points)]);

  useEffect(() => {
    if (!linesState) {
      return;
    }

    linesState.setStyle({ weight: props.weight });
  }, [props.weight, linesState]);

  return null;
};

MMapRouting.defaultProps = {
  weight: 3,
};

MMapRouting.displayName = 'MMapRouting';

export default MMapRouting;
