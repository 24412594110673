import React from 'react';

import MCardHeaderView from './MCardHeader.view';

interface Props {
  className?: string;
  color?: string;
  id?: string;
}

const MCardHeader: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MCardHeaderView className={props.className} color={props.color} id={props.id}>
      {props.children}
    </MCardHeaderView>
  );
};

MCardHeader.displayName = 'MCardHeader';
MCardHeader.defaultProps = {};

export default MCardHeader;
