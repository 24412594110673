import { useCallback } from 'react';
import moment from 'moment-timezone';
import { FilterValue } from 'react-table';

import sess from 'utils/session-storage';
import { TimeFormat } from 'utils/enums/time-format';
import { MTableAdvancedRowData } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import { MTableAdvancedServerCacheEffectData } from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';

type ReturnFunc<TableFields extends MTableAdvancedRowData, Id extends string> = (
  dataToCache: MTableAdvancedServerCacheEffectData<TableFields, Id>,
) => void;

const useTableCacheAdvanced = <TableFields extends MTableAdvancedRowData, Id extends string>(
  key: string | null,
): ReturnFunc<TableFields, Id> => {
  return useCallback(
    (dataToCache: MTableAdvancedServerCacheEffectData<TableFields, Id>) => {
      if (!key) {
        return;
      }

      const filtersFormatted = Object.entries(dataToCache.filters).map((filterField: [id: string, value: FilterValue]) => {
        // TODO: Check if possible to improve types
        const filter = { id: filterField[0], value: filterField[1] };

        if (Array.isArray(filter.value)) {
          return {
            id: filter.id,
            value: filter.value.map((value: FilterValue) =>
              moment.isMoment(value) ? value.format(TimeFormat.ServerFormatDateTime) : value,
            ),
          };
        }

        return {
          id: filter.id,
          value: moment.isMoment(filter.value) ? filter.value.format(TimeFormat.ServerFormatDateTime) : filter.value,
        };
      }, {});

      const sortFormatted = dataToCache.sort ? [{ id: dataToCache.sort.id, desc: dataToCache.sort.direction === 'desc' }] : [];

      sess.save(key, { ...dataToCache, filters: filtersFormatted, sort: sortFormatted });
    },
    [key],
  );
};

export default useTableCacheAdvanced;
