import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeValue } from 'react-select';

import MSelect from 'views/MSelect/MSelect';

import { MSelectOption } from 'views/MSelect/MSelect.model';

interface Props<IsMulti extends boolean, Id extends string> {
  value?: IsMulti extends true ? string[] : string | null;
  options: MSelectOption<null, Id>[];
  placeholder?: string;
  name?: string;
  valid?: boolean;
  invalid?: boolean;
  isMulti?: IsMulti;
  isLoading: boolean;
  isClearable?: boolean; 
  onChange?: (props: OnChangeValue<MSelectOption<null, Id>, IsMulti>) => void;
}
const MServerSelectView = <IsMulti extends boolean, Id extends string = string>(props: Props<IsMulti, Id>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MSelect
      name={props.name}
      isMulti={props.isMulti}
      value={props.value}
      options={props.options}
      valid={props.valid}
      invalid={props.invalid}
      placeholder={props.placeholder || t('common.select')}
      onChange={props.onChange}
      isClearable={props.isClearable}
      isLoading={props.isLoading}
    ></MSelect>
  );
};

MServerSelectView.defaultProps = {};
MServerSelectView.displayName = 'MServerSelectView';

export default MServerSelectView;
