import { TableName } from './table-page';

export enum SessionStorage {
  SystemSpecs = 'systemSpecs',
  Token = 'token',
}

export enum LocalStorage {}

export type SessionStorageTables =
  | TableName.Cars
  | TableName.DriverShifts
  | TableName.Drivers
  | TableName.ExtraServices
  | TableName.Fares
  | TableName.History
  | TableName.Jobs
  | TableName.Locations
  | TableName.Profiles
  | TableName.Rates
  | TableName.Services
  | TableName.Shifts
  | TableName.Shuttles
  | TableName.Suppliers
  | TableName.Trips
  | TableName.Units
  | TableName.Users;

export type SessionStorageTablesKeys = `table_${SessionStorageTables}` | 'jobsProfileHistory';
