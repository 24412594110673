import React from 'react';
import { MenuProps } from 'react-select';
import { MAddressSelectOption } from '../MAddress.model';

import MAddressInputMenuView from './MAddressInputMenu.view';


interface Props extends MenuProps<MAddressSelectOption, false> {}

const MAddressInputMenu: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MAddressInputMenuView hide={props.selectProps.inputValue?.length === 0} menuProps={props}>
      {props.children}
    </MAddressInputMenuView>
  );
};

MAddressInputMenu.displayName = 'MAddressInputMenu';
MAddressInputMenu.defaultProps = {};

export default MAddressInputMenu;
