const vehicles = {
  createNew: 'New',
  toast: {
    error: {
      badResponse: '[Vehicle] bad server response',
      edit: {},
      create: {},
    },
    success: {
      edit: {
        resetSuccess: 'Successfully.',
      },
      create: {},
    },
  },
  table: {
    headers: {
      name: 'Name',
      make: 'Make',
      model: 'Model',
      year: 'Year',
      color: 'Color',
      pax: 'Max Passengers',
      minSeats: 'Min Passengers',
      supplier: 'Supplier',
    },
  },

  edit: {
    suspendButton: {
      actionActivate: 'Unsuspend',
      actionDeactivate: 'Suspend',
      suspendModal: {
        popupTitleActive: 'Suspend Reason',
        popupTitleDeactivation: 'Suspend Vehicle',
        popupTitleUnsuspend: 'Unsuspend Reason',
        popupSubTitleDeactivation: 'Select the reason for the suspenssion',
        popupActionActive: 'Unsuspend Vehicle',
        popupActionDeactivate: 'Suspend Vehicle',
      },
    },
    activeStatusButton: {
      statusActive: 'Active',
      statusDeactivate: 'Inactive',
    },
    formTabs: {
      informationTab: 'Information',
      notesTab: 'Notes',
      historyTab: 'History',
    },
  },

  form: {
    information: {
      details: {
        header: 'General Information',
        fields: {
          name: 'Name',
          unit: 'Site',
          year: 'Year',
          make: 'Make',
          model: 'Model',
          color: 'Color',
          code: 'Code',
          location: 'Location',
          productID: 'Product ID',
          licensePlate: 'License Plate',
        },
      },
      attributes: {
        header: 'Attributes',
        itemHeader: 'Unused <1>{{name}}</1> Attribute Score',
      },
      mileageInspection: {
        header: 'Mileage & Inspection',
        fields: {
          mileage: 'Mileage',
          mileageLastInspection: 'Mileage in last inspection',
          lastInspectionDate: 'Last inspection Date',
          nextInspectionDate: 'Next inspection date',
        },
      },
      capacity: {
        header: 'Capacity',
        fields: {
          pax: 'Max Passengers',
          minSeats: 'Min Passengers',
        },
      },
      services: {
        header: 'Services',
      },
      suppliers: {
        header: 'Suppliers',
      },
      insurance: {
        header: 'Insurance',
        fields: {
          typeOfInsurance1: 'Type of insurance 1',
          typeOfInsurance1_start: 'Start date',
          typeOfInsurance1_end: 'End date',
          typeOfInsurance2: 'Type of insurance 2',
          typeOfInsurance2_start: 'Start date',
          typeOfInsurance2_end: 'End date',
        },
      },
      dimensions: {
        header: 'Trailer Dimensions',
      },
      getProductBtn: 'SHOW GETT product IDs',
    },
    valdations: {
      name: 'Name is required',
      plateNumber: {
        matches: 'Max Passengers must be a number',
        required: 'Max Passengers is Required',
      },
      service: 'Service With is required',
      pax: {
        matches: 'Pax must be a number',
        required: 'Pax is Required',
      },
      minSeats: {
        matches: 'Min Seats must be a number',
        required: 'Min Seats is Required',
      },
      model: 'Model With is required',
      color: 'Color With is required',
      year: {
        matches: 'Year must be a number',
        required: 'Year is Required',
      },
      licenseExpDate: 'License Expiration is required',
    },
  },
};

export default vehicles;
