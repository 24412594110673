import notes from './notes';
import modalReason from './modalReason';
import recurringInput from './recurringInput';
import modalChangePassword from './modalChangePassword';
import modal from './modal';
import modalCreateNote from './modalCreateNote';
import button from './button';
import form from './form';
import creditCardManagement from './creditCardManagement';

const views = {
  notes,
  modalReason,
  recurringInput,
  modalChangePassword,
  modal,
  modalCreateNote,
  button,
  form,
  creditCardManagement,
};

export default views;