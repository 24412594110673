import React, { useState } from 'react';
import { FormikContextType, FormikValues } from 'formik';

import MSpinner from 'views/MSpinner/MSpinner';

import classes from './MFormContainer.module.scss';

interface Props<Fields extends FormikValues> {
  className?: string;
  formik: FormikContextType<Fields>;
  isLoading?: boolean;
  headerJSX?: JSX.Element;
}

const MFormContainerView = <Fields extends FormikValues>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {

  const [timeStampState, setTimeStampState] = useState(0);

  const handleSubmit = (event: React.FocusEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (timeStampState + 3000 > event.timeStamp) {
      return;
    }

    setTimeStampState(() => event.timeStamp);

    props.formik.handleSubmit();
  };

  return (
    <form className={`${classes['container']} ${props.className || ''}`.trim()} onSubmit={handleSubmit} noValidate>
      {props.headerJSX}
      {props.isLoading ? (
        <div className={classes['spinnerContainer']}>
          <MSpinner size="md"></MSpinner>
        </div>
      ) : (
        <div className={classes['content']}>{props.children}</div>
      )}
    </form>
  );
};

MFormContainerView.displayName = 'MFormContainerView';

export default MFormContainerView;
