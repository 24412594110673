import React, { useImperativeHandle, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import MDatetimePickerView from './MDatetimePicker.view';

import 'react-datepicker/dist/react-datepicker.css';
import { MDatetimePickerRef } from './MDatetimePicker.model';
import { DatePickerFormats } from 'utils/enums/time-format';

interface Props {
  className?: string;
  value?: moment.Moment | null;
  valid?: boolean;
  invalid?: boolean;
  name?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: DatePickerFormats;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  disabled?: boolean;
  showYearDropdown?: boolean;
  onChange?: (value: moment.Moment | null) => void;
}

const MDatetimePicker: React.ForwardRefRenderFunction<MDatetimePickerRef, Props> = (
  props: React.PropsWithChildren<Props>,
  ref: React.ForwardedRef<MDatetimePickerRef>,
) => {
  const datePickerRef = useRef<DatePicker>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        setOpen(state: boolean) {
          if (datePickerRef.current) {
            datePickerRef.current.setOpen(state);
          }
        },
      };
    },
    [datePickerRef.current],
  );

  return (
    <MDatetimePickerView
      showYearDropdown={props.showYearDropdown}
      datePickerRef={datePickerRef}
      className={props.className}
      value={props.value?.toDate()}
      valid={props.valid}
      invalid={props.invalid}
      name={props.name}
      placeholder={props.placeholder}
      showTimeSelect={props.showTimeSelect}
      showTimeSelectOnly={props.showTimeSelectOnly}
      timeIntervals={props.timeIntervals}
      timeCaption={props.timeCaption}
      dateFormat={props.dateFormat}
      minDate={props.minDate?.toDate()}
      maxDate={props.maxDate?.toDate()}
      disabled={props.disabled}
      onChange={(date: Date | [Date, Date] | null) => props.onChange?.(moment(date as Date))}
    ></MDatetimePickerView>
  );
};

MDatetimePicker.displayName = 'MDatetimePicker';

export default React.forwardRef(MDatetimePicker);
