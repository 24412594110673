const fares = {
  toast: {
    error: {
      badResponse: '[Fare] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createFareBtn: 'New',
  showBreakdownBtn: 'Show Breakdown',
  total: 'Total: ',

  table: {
    headers: {
      biDir: 'Bi Directional',
    },
    biDirOptions: {
      way2: '2-Way',
      way1: '1-Way',
    },
  },

  fareTypeOptions: {
    Base: 'Base',
    Addition: 'Addition',
    Percent: 'Percent',
    Total: 'Total',
  },

  additions: {
    value: 'Value',
    valueType: 'Value Type',
    name: 'Name',
    hour1: 'Start Time',
    hour2: 'End Time',
    date1: 'Start Date',
    date2: 'End Date',
    cancel: 'Cancel',
    addAddition: 'Add Addition',
    priority: 'Priority',
    weekDays: 'Week Days',
    newAddition: 'New Addition',
    price: 'Price',
    total: 'Total',
    percent: 'Percent',
    columnExist: 'Title allready exists',
  },

  create: {
    confirmButtonText: 'Confirm',
    code: 'Code',
    from: 'From',
    to: 'To',
    service: 'Service',
    select: 'Select',
    validations: {
      code: 'Code is required',
    },

    modalSuccess: {
      header: 'Success.',
      body: 'Fare Created.',
    },
    modalError: {
      header: 'Error.',
      body: 'Can not create fare.',
    },
  },

  update: {
    code: 'Code',
    from: 'From',
    to: 'To',
    service: 'Service',
    price: 'Price',
    confirmButtonText: 'Update',
    successMsg: 'Fare updated successfully',
    modalError: {
      header: 'Error.',
      body: 'Can not update fare.',
    },
  },

  validations: {
    code: {
      require: 'Code is required',
    },
    from: {
      require: 'From is required',
    },
    to: {
      require: 'To is required',
    },
    service: {
      require: 'Service is required',
    },
    unit: {
      require: 'Unit is required',
    },
    rateType: {
      require: 'Rate type is required',
    },
  },

  forms: {
    information: {
      details: {
        header: 'Fare Details',
        fields: {
          units: {
            name: 'Sites',
          },
          code: {
            name: 'Code',
          },
          name: {
            name: 'Name',
          },
          tag: {
            name: 'Tag',
          },
          parentTag: {
            name: 'Parent Tag',
          },
          parentCode: {
            name: 'Parent Code',
          },
          from: {
            name: 'From',
          },
          to: {
            name: 'To',
          },
          service: {
            name: 'Service',
          },
          price: {
            name: 'Price',
          },
          value: {
            name: 'Value',
          },
          biDir: {
            name: 'Bi Directional',
          },
          rateType: {
            name: 'Rate Type',
          },
          type: {
            name: 'Type',
          },
          priority: {
            name: 'Priority',
          },
        },
      },
      mileage: {
        header: 'Mileage',
        fields: {
          distanceFrom: {
            name: 'Distance From',
          },
          distanceTo: {
            name: 'Distance To',
          },
        },
      },
      conditions: {
        header: 'Conditions',
        fields: {
          startDate: {
            name: 'Start Date',
          },
          endDate: {
            name: 'End Date',
          },
          startHour: {
            name: 'Start Time',
          },
          endHour: {
            name: 'End Time',
          },
          weekDays: {
            name: 'Weekdays',
          },
        },
      },
      additionsHead: {
        header: 'Breakdown',
        validations: {
          name: 'Value is required',
          value: 'Value is required',
          valueType: 'Value is required',
        },
        fields: {},
      },
    },
  },
};

export default fares;
