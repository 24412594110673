import React from 'react';
import { CellProps } from 'react-table';

import MHistoryTableCellDataView from './MHistoryTableCellData.view';

import { BaseHistoryData } from 'models/history';
import { IMHistoryTableData } from 'components/History/MHistory.model';

interface Props extends CellProps<IMHistoryTableData<BaseHistoryData>, Record<string, string | number>> {}

const MHistoryTableCellData: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MHistoryTableCellDataView data={props.cell.row.original}>{props.children}</MHistoryTableCellDataView>;
};

MHistoryTableCellData.displayName = 'MHistoryTableCellData';
MHistoryTableCellData.defaultProps = {};

export default MHistoryTableCellData;
