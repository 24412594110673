import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetAssignPriorityGroupsResponse } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import {
  ICreateAssignPriorityGroupData,
  IDeleteAssignPriorityFilters,
  IGetAssignPriorityGroup,
  IUpdateAssignPriorityGroupData,
  IUpdateAssignPriorityGroupFilters,
} from 'models/http/assign-priority-group';
import { IAssignPriorityGroup } from 'models/assign-priority-group';

class AssignPriorityGroups {
  private static route = '/';

  public async get(filters: IGetAssignPriorityGroup, cancelTokenSource?: CancelTokenSource): Promise<IAssignPriorityGroup[]> {
    return serverAxios
      .post(
        AssignPriorityGroups.route,
        {
          act: 'find',
          col: 'assignPriorityGroups',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetAssignPriorityGroupsResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource?: CancelTokenSource): Promise<IAssignPriorityGroup> {
    return serverAxios
      .post(
        AssignPriorityGroups.route,
        {
          act: 'find',
          col: 'assignPriorityGroups',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetAssignPriorityGroupsResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateAssignPriorityGroupData): Promise<void> {
    return serverAxios
      .post(
        AssignPriorityGroups.route,
        {
          act: 'insert',
          col: 'assignPriorityGroups',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteAssignPriorityFilters): Promise<void> {
    return serverAxios
      .post(
        AssignPriorityGroups.route,
        {
          act: 'delete',
          col: 'assignPriorityGroups',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateAssignPriorityGroupFilters, data: IUpdateAssignPriorityGroupData): Promise<void> {
    return serverAxios
      .post(
        AssignPriorityGroups.route,
        {
          act: 'update',
          col: 'assignPriorityGroups',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default AssignPriorityGroups;
