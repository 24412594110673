const students = {
  createNew: 'New',

  toast: {
    error: {
      badResponse: '[Students] bad server response',
      create: {},
      edit: {},
    },
    success: {
      resetSuccess: 'Reset Password email has been sent successfully.',
      create: {
        default: '[Student] Created',
      },
      edit: {
        default: '[Student] Updated',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Error Occurred',
      body: 'Student already exists!',
    },
    modalErrorRequest: {
      header: 'Error Occourd',
      body: 'Could Not Create Student!',
    },
  },

  edit: {
    modalRemoveConfirm: {
      header: 'Warning!',
      body: 'Are you sure you want to delete this student?',
      modalRemoveConfirmBtnConfirm: 'Delete',
    },
    formTabs: {
      informationTab: 'General Information',
      paymentTab: 'Payments',
      notesTab: 'Notes',
      historyTab: 'History',
    },
    activeStatus: {
      statusActive: 'Active',
      statusDeactivate: 'Inactive',
    },
    suspend: {
      actionActivate: 'Unsuspend',
      actionDeactivate: 'Suspend',
      suspendModal: {
        popupTitleDeactivation: 'Suspend Student',
        popupTitleActive: 'Suspend Reason',
        popupTitleUnsuspend: 'Unsuspend Reason',
        popupSubTitleDeactivation: 'Select the reason for the suspenssion',
        popupActionActive: 'Unsuspend Student',
        popupActionDeactivate: 'Suspend Student',
      },
    },
  },

  table: {
    headers: {
      id: 'Id',
      name: 'Name',
      email: 'Email',
      created: 'Created At',
      homeAddress: 'Home Address',
      alternateAddress: 'Alternate Address',
      phone: 'Phone',
      officeAddress: 'School',
      class: 'Class',
      school: 'School',
      passenger: {
        templates: {
          default: 'Passenger',
          schoolShuttles: 'Student',
          trailers: 'Passenger',
        },
      },
    },
    StudentsGettExtraHeaders: {
      home: 'Home',
      home2: 'Home2',
      work: 'Work',
      site: 'Site',
    },
    empty: 'Empty',
  },

  parentsTable: {
    headers: {
      name: 'Name',
      email: 'Email',
      address: 'Address',
      phone: 'Phone',

    },
  },

  forms: {
    information: {
      profiles: {
        header: 'Profiles',
        fields: {
          profile: 'Profiles',
          site: 'Site',
        },
      },
      parentManagement:{
        buttonRemove: 'Remove Parents',
        buttonAdd: 'Add Parents',
      },
      parents: {
        header: 'Parents',
      },
      details: {
        header: 'Personal Details',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          phone: 'Phone',
          additionalPhone: 'Additional Phone',
          dateOfBirth: 'Date of Birth',
          gender: {
            header: 'Gender',
            select: '',
            male: 'Male',
            female: 'Female',
          },
        },
      },
      address: {
        header: 'Student Address',
        sharedValues: {
          manual: 'Manual address',
        },
        fields: {
          mainAddress: {
            label: 'Main Address',
          },
          alternateAddress: {
            label: 'Alternate Address',
          }
        },
      },
      actions: {
        header: 'Actions',
        buttons: {
          delete: 'Delete',
          resetPassword: 'Reset Password',
        },
      },
      school: {
        header: 'School Details',
        fields: {
          school: 'School',
          AcademicYear: {
            header: 'Academic Year',
            select: '',
            current: '2022-2023',
          },
          level: 'Level',
          class: 'Class',
        },
      },
      linkedParents: {
        searchPlaceholder: 'Search For Parents'
      }
    },
    jobHistory: {
      header: 'Reservation History',
      lastJob: 'Last Reservation: {{lastJob}}',
      firstJob: 'First Reservation: {{firstJob}}',
    },
    validations: {
      firstName: 'First Name is required',
      lastName: 'Last Name is required',
      profiles: 'Profiles is required',
      email: {
        required: 'Email is required',
        email: 'Invalid email address',
      },
      unit: {
        required: 'Site is required',
      },
    },
  },

  parentTable: {
    headers: {
      name: 'Name',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
    }
  },
};

export default students;
