import React from 'react';
import { useTranslation } from 'react-i18next';

import HomeAgentTableBookings from './HomeAgentTables/HomeAgentTableBookings/HomeAgentTableBookings';
import HomeAgentTableViews from './HomeAgentTables/HomeAgentTableViews/HomeAgentTableViews';
import HomeAgentStatistic from './HomeAgentStatistic/HomeAgentStatistic';
import HomeAgentWeather from './HomeAgentWeather/HomeAgentWeather';
import MButton from 'views/Buttons/MButton/MButton';
import MCard from 'views/MCard/MCard';
import MFormContainer from 'views/MForm/MFormContainer/MFormContainer';
import MFormInput from 'views/MForm/MFormFields/MFormInput/MFormInput';
import MSpinner from 'views/MSpinner/MSpinner';
import MFormDateTimePicker from 'views/MForm/MFormFields/MFormDateTimePicker/MFormDateTimePicker';

import classes from './HomeAgent.module.scss';
import { Link } from 'react-router-dom';
import { FormikContextType } from 'formik';
import { IHomeAgentSearchBoxFormFields } from './HomeAgent.model';
import setFieldProps from 'utils/setFieldProps';
import { DatePickerFormats } from 'utils/enums/time-format';

interface Props {
  form: FormikContextType<IHomeAgentSearchBoxFormFields>;
  totalLast24Resevation: number;
  totalLast24AgentResevation: number;
  totalPastWeekAgentResevation: number;
  totalPastWeekResevation: number;
  loadingState: boolean;
}

const HomeAgentView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className={classes['container']}>
      <div className={classes['content']}>
        <MFormContainer className={classes['search']} formik={props.form}>
          <MFormInput {...setFieldProps(props.form, 'inputValue')} />
          <MFormDateTimePicker
            {...setFieldProps(props.form, 'fromDateFilter')}
            dateFormat={DatePickerFormats.MonthDayYear}
            className={classes['dateBox']}
          ></MFormDateTimePicker>
          <MFormDateTimePicker
            {...setFieldProps(props.form, 'toDateFilter')}
            dateFormat={DatePickerFormats.MonthDayYear}
            className={classes['dateBox']}
          ></MFormDateTimePicker>
          <MButton color="info" className={classes['searchBtn']} disabled={!props.form.values.inputValue}>
            {t('agent.searchBox.searchBtn')}
          </MButton>
        </MFormContainer>

        <div className={classes['actions']}>
          <Link to="/create-job">
            <MButton color="success">{t('agent.createNew.newReservation')} </MButton>
          </Link>
          <Link to="/quick-job">
            <MButton color="primary">{t('agent.createNew.quickReservation')}</MButton>
          </Link>
        </div>

        <HomeAgentWeather></HomeAgentWeather>
        <div className={classes['statistics']}>
          {!props.loadingState ? (
            <HomeAgentStatistic title={t('agent.statistics.lastWeek.title')} value={props.totalPastWeekResevation}></HomeAgentStatistic>
          ) : (
            <MSpinner size="md"></MSpinner>
          )}

          {!props.loadingState ? (
            <HomeAgentStatistic title={t('agent.statistics.last24Hours.title')} value={props.totalLast24Resevation}></HomeAgentStatistic>
          ) : (
            <MSpinner size="md"></MSpinner>
          )}

          {!props.loadingState ? (
            <HomeAgentStatistic
              title={t('agent.statistics.myLastWeek.title')}
              value={props.totalPastWeekAgentResevation}
            ></HomeAgentStatistic>
          ) : (
            <MSpinner size="md"></MSpinner>
          )}

          {!props.loadingState ? (
            <HomeAgentStatistic
              title={t('agent.statistics.myLast24Hours.title')}
              value={props.totalLast24AgentResevation}
            ></HomeAgentStatistic>
          ) : (
            <MSpinner size="md"></MSpinner>
          )}
        </div>

        <MCard>
          <h2 className={classes['title']}>{t('agent.table.myLastBookingsTable.title')}</h2>
          <div className={classes['table']}>
            <HomeAgentTableBookings></HomeAgentTableBookings>
          </div>
        </MCard>

        <MCard>
          <h2 className={classes['title']}>{t('agent.table.myRecentViewsTable.title')}</h2>
          <div className={classes['table']}>
            <HomeAgentTableViews></HomeAgentTableViews>
          </div>
        </MCard>
      </div>
    </div>
  );
};

HomeAgentView.displayName = 'HomeAgentView';
HomeAgentView.defaultProps = {};

export default HomeAgentView;
