import { SystemTemplate } from 'utils/enums/system-template';
import * as actions from '../actions/settings';

const initialState: State = {
  mapCenter: { lat: 0, lng: 0 },
  mapZoom: { zoom: 10 },
  geoServer: 'https://usa.maps.moovex.ai/route/v1',
  systemSpecs: 'moovex',
  systemTemplate: SystemTemplate.Default,
};

export const reducer = (state: State = initialState, action: actions.SettingsTypes): State => {
  switch (action.type) {
    case actions.SettingsActions.SetMapCenter:
      return { ...state, mapCenter: action.payload };
    case actions.SettingsActions.SetMapZoom:
      return { ...state, mapZoom: action.payload };
    case actions.SettingsActions.SetGeoServer:
      return { ...state, geoServer: action.payload };
    case actions.SettingsActions.SetSystemSpecs:
      return { ...state, systemSpecs: action.payload };
    case actions.SettingsActions.SetSystemTemplate:
      return { ...state, systemTemplate: action.payload };
    default:
      return state;
  }
};

export interface State {
  mapCenter: { lat: number; lng: number };
  mapZoom: { zoom: number };
  geoServer: string;
  systemSpecs: string;
  systemTemplate: SystemTemplate;
}
