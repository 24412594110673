import { MModalProps } from 'views/MModal/MModal.model';

export enum UIActions {
  SetTheme = '[UI] Set Change Theme',
  ShowSpinner = '[UI] Show Spinner',
  SetSidebar = '[UI] Set Side Bar',
  SetAside = '[UI] Set Aside',
  ShowModal = '[UI] Show Modal',
  HideModal = '[UI] Hide Modal',
  SetPageName = '[UI] Set Page Name',
}

// Interface

export interface ShowModal {
  type:  UIActions.ShowModal;
  payload: MModalProps;
}

export interface HideModal {
  type:  UIActions.HideModal;
}

export interface SetTheme {
  type:  UIActions.SetTheme;
  payload: { type: 'DARK' | 'LIGHT' };
}

export interface SetAside {
  type:  UIActions.SetAside;
  payload: { show: boolean };
}

export interface SetSidebar {
  type:  UIActions.SetSidebar;
  payload: { state: 'SHOW' | 'HIDE' | 'MOBILE' };
}

export interface SetPageName {
  type:  UIActions.SetPageName;
  payload: { text: string };
}

export interface ShowSpinner {
  type:  UIActions.ShowSpinner;
  payload: { state: number | boolean };
}

// Action creators
export const showModal = (modal: MModalProps): ShowModal => {
  return {
    type: UIActions.ShowModal,
    payload: { ...modal, show: true },
  };
};

export const hideModal = (): HideModal => {
  return {
    type: UIActions.HideModal,
  };
};

export const setTheme = (type: 'DARK' | 'LIGHT'): SetTheme => {
  return {
    type: UIActions.SetTheme,
    payload: { type },
  };
};

export const setAside = (state: boolean): SetAside => {
  return {
    type: UIActions.SetAside,
    payload: { show: state },
  };
};

export const setSidebar = (state: 'SHOW' | 'HIDE' | 'MOBILE'): SetSidebar => {
  return {
    type: UIActions.SetSidebar,
    payload: { state },
  };
};

export const SetPageName = (text: string): SetPageName => {
  return {
    type: UIActions.SetPageName,
    payload: { text },
  };
};

export const showSpinner = (state: boolean | number): ShowSpinner => {
  return {
    type: UIActions.ShowSpinner,
    payload: { state },
  };
};

// Types

export type UITypes = SetTheme | SetAside | SetSidebar | ShowModal | HideModal | SetPageName | ShowSpinner;
