const holidays = {
  headerButtons: {
    create: 'Novo',
    delete: 'Remover',
  },
  table: {
    headers: {
      code: 'Código',
      start: 'Início',
      end: 'Fim',
    },
  },
  create: {
    successToast: {
      body: 'O feriado foi adicionado com sucesso',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar feriado',
    },
  },
  delete: {
    successToast: {
      body: 'Os feriados foram excluídos com sucesso',
    },
    errorToast: {
      body: 'Não foi possível criar feriado',
    },
  },
  edit: {
    errorToast: {
      body: 'Não foi possível criar feriado',
    },
    successToast: {
      body: 'O feriado foi atualizado com sucesso',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar o feriado',
    },
    successModal: {
      header: 'Resultado',
      body: 'Feriado atualizado!',
    },
  },
  forms: {
    details: {
      header: 'Detalhes',
      fields: {
        name: 'Nome',
        code: 'Código',
      },
    },
    dates: {
      header: 'Intervalo de Datas',
      fields: {
        rangeSwitch: 'Intervalo de Datas',
        start: 'Início',
        end: 'Fim',
        date: 'Data',
      },
    },
  },
};

export default holidays;
