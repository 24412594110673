const holidayReport = {
  actions: {
    export: 'Export',
  },

  table: {
    headers: {
      code: 'Code',
      profile: 'Profile',
      line: 'Line',
      routingType: 'Routing Type',
      direction: 'Direction',
      name: 'Name',
      days: 'WeekDays',
      pax: 'Pax',
      supplier: 'Supplier',
    },

    cells: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
    },

    filters: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
      tripStatus: {
        options: {
          new: 'New',
          inroute: 'In Route',
          sent: 'Sent',
          approved: 'Approved',
          pickingUp: 'Picking Up',
          done: 'Done',
          cancelled: 'Cancelled',
        },
      },
      routingType: {
        options: {
          bus: 'Bus',
          demand: 'Demand',
          dynamic: 'Dynamic',
        },
      },
    },
    footer: {
      lastUpdated: 'Last Updated: {{lastUpdated}}',
      noLastUpdate: 'Unknown',
    },
  },
  tableSummary: {
    title: 'Bill Reports',
    headers: {
      trips: 'Trips',
      reservations: 'Reservations',
      passengers: 'Passengers',
      uniquePassengers: 'Unique Passengers',
    },
  },
};

export default holidayReport;
