import React from 'react';
import { Row } from 'react-table';

import ExpandCell from 'views/MTable/TableCells/ExpandCell';

import { RowData } from 'views/MTable/Table.model';

interface Props<T extends RowData> {
  openLabel?: string;
  closeLabel?: string;
  row: Row<T>;
}

const MTableCellExpendColView = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return <ExpandCell openLabel={props.openLabel} closeLabel={props.closeLabel} row={props.row}></ExpandCell>;
};

MTableCellExpendColView.displayName = 'MTableCellExpendColView';
MTableCellExpendColView.defaultProps = {};

export default MTableCellExpendColView;
