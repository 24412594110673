const auth = {
  email: 'Email address',
  password: 'Password',
  welcomeMessage: 'Hey, good to see you',

  validations: {
    email: {
      required: 'Email is required',
      email: 'Invalid email address',
      emailReceived: 'The email address you recevied from your admin',
    },

    password: {
      requeired: 'Password is required',
      password: 'Invalid Password',
    },
  },

  loginBtn: 'Login',
  authFailed: 'Email or password is invalid',
};

export default auth;
