import React, { useMemo } from 'react';

import MDropdownView from './MDropdown.view';

import { IDropdownAction, IDropdownType } from './MDropdown.model';

interface Props<Actions, Id extends string | number | null> {
  className?: string;
  show?: boolean;
  type: IDropdownType;
  actions: IDropdownAction<Actions>[];
  onSelect: (action: IDropdownAction<Actions, Id>, index: number) => void;
}

const MDropdown = <Actions, Id extends string | number | null = null>(
  props: React.PropsWithChildren<Props<Actions, Id>>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const items = useMemo(() => {
    return props.actions.reduce((acc, cur: IDropdownAction<Actions>) => {
      const groupId = cur.group || 0;
      const group = acc[groupId] || [];

      group.push(cur);

      return {
        ...acc,
        [groupId]: group,
      };
    }, {});
  }, [props.actions]);

  return (
    <MDropdownView
      className={props.className}
      ref={ref}
      items={Object.values(items)}
      type={props.type}
      state={!!props.show}
      onSelect={props.onSelect}
    >
      {props.children}
    </MDropdownView>
  );
};

MDropdown.displayName = 'MCardBodyView';

export default React.forwardRef(MDropdown) as <Actions, Id extends string | number | null = null>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<Actions, Id>>> & React.RefAttributes<HTMLDivElement>,
) => JSX.Element;
