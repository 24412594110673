const jobs = {
  toast: {
    error: {
      existsPassengerToast: {
        body: 'Passenger email already in use',
      },
      create: {
        badResponse: 'An error occured while creating the reservation',
        missingParameters: 'Please navigate to system settings and fill in missing parameters',
      },
      edit: {
        badResponse: 'An error occured while updating the reservation',
      },
      find: {
        badResponse: 'Could not find shuttles ',
      },
    },
    success: {
      create: {
        default: 'Reaervation has been created successfully',
      },
      edit: {
        default: 'Reaervation has been updated successfully',
      },
    },
  },
  create: {
    confirmButtonText: 'Book',
    confirmationModal: {
      header: 'Confirm Reservation',
      subtitle: 'Please Read the Script Below - Word for Word - Do not Rush!!!',
      content:
        'We have {{service}} reservation for {{seats}} person\nOn {{date}} from {{from}} to {{to}}\n\nIs That correct? Great!\n\nPrice is ${{price}}\nThank you for calling {{company}}',
      table: {
        headers: {
          details: 'Trip Details',
          fare: 'Fare',
        },
        fields: {
          details: {
            pickAdrs: 'Pickup Address',
            dropAdrs: 'Drop Address',
            pickTime: 'Pickup Time',
            seats: 'Adults',
          },
          fare: {
            service: 'Service',
          },
        },
      },
      bottomLine: 'Total Price',
    },
    duplicateJobsModal: {
      body: 'Please notice that there are other reservations with the exact same details, do you want to proceed?',
      btnConfirm: 'Yes',
      btnClose: 'No',
    },
    blacklistModal: {
      body: 'The card number that was entered is invalid. Please enter a valid number.',
      btnConfirm: 'Confirm',
    },
  },

  payments: {
    table: {
      headers: {
        createdBy: 'Created',
        email: 'Originator User Email',
        amount: 'Amount',
        type: 'Type',
        ccMasked: 'CC Masked',
        status: 'Status',
        refNum: 'Ref Num ',
      },
    },

    paymentsDetails: {
      title: 'Details',
      status: {
        charged: 'Charged:',
        pending: 'Pending:',
        declined: 'Declined:',
        variance: 'Variance:',
      },
    },

    addPaymentForm: {
      title: 'Add Payment',
      fields: {
        amount: 'Amount',
        paymentType: 'Payment Type',
        cardNum: 'Credit Number',
        cardExp: 'Expires',
        cvv: 'CVV',
        paymentOptions: {
          select: 'Select',
          credit: 'Credit',
          directBill: 'Direct Bill',
          card: 'Card',
          expDate: 'Exspiry Date',
        },
      },
      validations: {
        price: 'Price must be greater than zero',
      },
      confirmPaymentBtn: 'Confirm Payment',
      modalSuccess: {
        header: 'Success!',
        body: 'Payment Successful!',
      },
      modalError: {
        header: 'Error Occurred!',
        body: 'Payment Failed, Please Try Again',
      },
    },
  },

  update: {
    confirmButtonText: 'Update',
    formTabs: {
      summary: 'Summary',
      edit: 'Edit',
      payments: 'Payments',
      notes: 'Notes',
      history: 'History',
    },
    summary: {
      driver: 'Driver',
      vehicle: 'Vehicle',
      tripId: 'Trip Id',
      tripStatus: 'Trip Status',
      RPTime: 'Requested Pickup Time',
      MPTime: 'Moovex Pickup Time',
      PETA: 'Pickup ETA',
      PR: 'Pickup Reported(Driver)',
      DR: 'Dropoff Reported(Driver)',
      empty: 'Not available',
      notAssigned: 'Not Assigned',
      notRouted: 'Not Routed',
      attributes: 'Attributes',
    },
    jobNotSupported: 'Reservation Type is not supported',
  },

  setShifts: {
    select: 'Select',

    form: {
      information: {
        passengers: {
          header: 'Select Passengers',
          fields: {
            passengers: 'Passengers',
          },
        },
        details: {
          header: 'Shift Details',
          fields: {
            direction: {
              label: 'Direction',
              to: 'To',
              from: 'From',
              both: 'Both',
            },
            shift: 'Shift',
            from: 'From',
            to: 'To',
            fromDate: 'From Date',
            toDate: 'To Date',
            weekDays: 'Week Days',
            fromHour: 'From Hour',
            toHour: 'To Hour',
          },
        },
      },
    },
  },

  routeSummary: {
    reservations: 'Reservations',
    trips: 'Trips',
    assigned: 'Assigend',
    unassigned: 'Unassigend',
    drivers: 'Drivers',
    autoAssigned: 'Auto Assigned',
    preAssigned: 'Pre Assigned',
    vehicles: 'Available Vehicles',
    vehiclesUsed: 'Vehicles Used',
    routingInsights: 'Routing Insights',
    totalTripDistance: 'Total Trip Distance',
    totalTripDuration: 'Total Trip Duration',
    assignmentInsights: 'Assignment Insights',
    avgDistance: 'Avg. Distance',
    avgDistancePickup: 'Avg. Distance to Pickup',
    avgDuration: 'Avg. Duration',
    avgDurationPickup: 'Avg. Duration to Pickup',
    avgPrice: 'Avg. Price',
    avgPassengers: 'Avg. Passengers',
    waitAverage: 'Wait Average',
    routingProcessTime: 'Routing Process Time',
    assignmentProcessTime: 'Assignment Process Time',
  },

  headerButtons: {
    create: {
      new: 'New',
      quick: 'Quick',
      setShifts: 'Set Shifts',
      shuttle: 'Shuttle',
    },
    route: {
      route: 'Route',
      routeAndAssign: 'Route And Assign',
    },
    moreActions: {
      button: 'More',
      options: {
        group: {
          label: 'Group',
          modal: {
            header: 'Group Bookings',
            body: 'You are about to group {{jobs}} bookings',
          },
        },
        ungroup: {
          label: 'Ungroup',
          modal: {
            header: 'Ungroup Bookings',
            body: 'You are about to ungroup {{jobs}} bookings',
          },
        },
        cancel: {
          label: 'Cancel',
          modalSuccess: {
            header: 'Cancel Reservations',
            body: 'Please enter a reason for cancelation',
          },
        },
        delete: {
          label: 'Delete',
          modalSuccess: {
            header: 'Delete Jobs',
          },
        },
        export: {
          label: 'Export',
        },
        releaseRouting: {
          label: 'Release Routing',
        },
      },
    },
  },

  advancedRoutingModal: {
    all: 'All',
    RouteBodyText: 'You are about to route {{num}} reservations.',
    RouteAssignBodyText: 'You are about to route and assign {{num}} reservations.',
    advancedSettings: 'Advanced',
    noJoin: 'No Join',
    joinOptions: {
      noJoin: 'No Join',
      tryJoin: 'Try Join',
      onlyJoin: 'Only Join',
    },
    clusterSettings: 'Cluster Settings',
    assignSettings: 'Assign Settings',
    routeJobs: 'Route Reservations',
    routeAssignJobs: 'Route And Assign Reservations',
    routeAndAssignJobs: 'Route And Assign Jobs',
    advancedRoutingSettings: 'Advanced Routing Settings',
    mainParameters: 'Main Parameters',
    ratio: 'Ratio',
    ratioInfo:
      '1.5 means that the duration of a person sitting in the vehicle will not exceed 150% of the time he would have spent driving alone.',
    pax: 'Max Passengers',
    paxInfo: 'Maximum number of seats per trip',
    maxDuration: 'Max Duration',
    maxDurationInfo: 'Maximum duration of a trip',
    maxStops: 'Max Stops',
    maxStopsInfo: 'Maximum stops in a trip',
    pickSeconds: 'Boarding Time',
    pickSecondsInfo:
      'Calculated boarding time of the job. total duration of the trip will be prolonged. times will be added to the "wait" array for this stop.',
    dropSeconds: 'Drop-Off Time',
    dropSecondsInfo:
      'Calculated drop-off time of the job. total duration of the trip will be prolonged. times will be added to the "wait" array for this stop.',
    pickGapAfter: 'Max Late',
    pickGapAfterInfo: 'A job`s pick-up time will not be more than 30 minutes after another job`s pick-up time.',
    pickGapBefore: 'Max Early',
    pickGapBeforeInfo: 'A job`s pick-up time will not precede another job`s pick-up time by more than 30 minutes.',
    airKMPH: 'Air KMPH',
    airKMPHInfo: 'Contact Support',
    allowLate: 'Allow Late',
    allowLateInfo: 'Contact Support',
    maxPrice: 'Max Price',
    maxPriceInfo: 'Contact Support',
    maxTrips: 'Max Trips',
    maxTripsInfo: 'Max Trips Support',
    shiftMaxMinutes: 'Shift Max Minutes',
    shiftMaxMinutesInfo: 'Contact Support',

    form: {
      validation: {
        mustBeHigher: 'Must be {{val}} or higher',
      },
    },
  },

  clusters: {
    clusterRadius: 'Cluster Radius',
    clusterSnapAddress: 'Cluster Snap address',
    clusterDirection: 'Cluster Direction',
    snapToBusStop: 'Snap To Bus Stop',
    both: 'Both',
    to: 'To',
    from: 'From',
  },

  forms: {
    roundTrip: 'Round Trip',
    oneWay: 'One Way',
    information: {
      details: {
        header: 'Profile Details',
        fields: {
          site: 'Site',
          profile: 'Profile',
        },
      },
      jobDetails: {
        flightTypes: {
          international: 'International',
          domestic: 'Domestic',
          customerRequest: 'Customer Request',
        },
        fareBreakdown: 'Fare Breakdown',
      },
      linkedPassengers: {
        header: 'Linked Passengers',
        searchPlaceholder: {
          templates: {
            default: 'Search For Passengers',
            schoolShuttles: 'Search For Students',
            employees: 'Search For Employees',
            trailers: 'Search For Passengers',
          },
        },
      },
      reservationDetails: {
        header: 'Reservation Details',
        fields: {
          from: 'From',
          to: 'To',
          terminal: 'Terminal',
          seats: 'Seats',
          checkedBags: 'Checked Bags',
          switchADA: 'ADA',
          wheelchair: 'Wheelchair',
          adults: 'Adults',
          infants: 'Infants',
          carryon: 'Carry On',
        },
        clearBtn: 'Clear',
      },
      flightDetails: {
        header: 'Flight Details',
        flightTimeAndTime: 'Flight Date And Time',
        flightType: 'Flight Type',
        flightAirline: 'Airline',
        flightNumber: 'Flight Number',
        flightTerminal: 'Terminal',
      },
      caregiverInformation: {
        header: 'Caregiver Information',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          address: 'Address',
          phone: 'Phone',
        },
      },
      serviceAndTime: {
        header: 'Service And Time',
        fields: {
          scheduleBy: 'Schedule By',
          dateTime: 'Date & Time',
          service: 'Service',
          switchRecurring: 'Recurring',
          repeatEvery: 'Repeat Every',
          ends: 'Ends',
          never: 'Never',
          on: 'On',
          after: 'After',
          occurrences: 'Occurrences',
        },
        buttons: {
          now: 'Now',
        },
      },
      personalInfo: {
        header: 'Personal Information',
        fields: {
          email: 'Email',
          fullName: 'Full Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          phone: 'Phone',
          address: 'Address',
          specialIdentifier: 'Special Identifier',
        },
      },
      billingInfo: {
        sameAsPersonalInformation: 'Same As Personal Information',
        header: 'Billing Information',
        fields: {
          country: 'Country',
          state: 'State',
          zip: 'Zip Code',
        },
      },
      payments: {
        header: 'Payments',
        fields: {
          price: 'Price',
          paymentSelect: {
            header: 'Payment Type',
            options: {
              credit: 'Credit',
              directBill: 'Direct Bill',
            },
          },
          cardNum: 'Credit Number',
          cardExp: 'Expires',
          cvv: 'CVV',
        },
      },
      additionalFields: {
        header: 'Additional Fields',
        fields: {
          driver: 'Driver',
          car: 'Car',
          waitMinutes: 'Wait Minutes',
        },
      },
      notes: {
        header: 'Notes',
      },
    },
    validations: {
      unit: {
        required: 'Profile is required',
      },
      amount: {
        required: 'Amount is required',
      },
      from: {
        required: 'From is required',
      },
      direction: {
        required: 'Direction is required',
      },
      to: {
        required: 'To is required',
      },
      type: {
        required: 'Required',
      },
      email: {
        message: 'Enter a valid email',
        required: 'Email is required',
      },
      firstName: {
        conditions: {
          min: 'First Name should be of minimum 2 characters',
          max: 'First Name should be of maximum 30 characters length',
        },
        required: 'First Name is required',
      },
      lastName: {
        conditions: {
          min: 'Last Name should be of minimum 2 characters',
          max: 'Last Name should be of maximum 30 characters length',
        },
        required: 'Last Name is required',
      },
      phone: {
        message: 'Phone number is not valid',
        required: 'Phone number is required',
      },
      price: {
        message: 'Price must be a number',
        required: 'Price is Required',
      },
      flightTerminal: {
        required: 'Flight Terminal is required',
      },
      adults: {
        required: 'Adults is required',
      },
      seats: {
        required: 'Seats is required',
      },
      infants: {
        required: 'Infants is required',
      },
      carryon: {
        required: 'Carry On is required',
      },
      paymentType: {
        required: 'Payment Type is required',
      },
      cardNum: {
        required: 'Card Number is required',
      },
      cardExp: {
        required: 'Card Expire is required',
      },
      cvv: {
        required: 'CVV is required',
      },
      profile: {
        required: 'Profile is required',
      },
      fare: {
        required: 'Fare is required',
      },
    },
  },

  table: {
    footer: {
      totalSeats: 'Total Seats: {{seats}}',
    },

    filters: {
      students: 'Students',
    },
    headers: {
      externalID: 'External ID',
      returnTrip: 'Return Trip',
      legA: 'Leg A',
      legB: 'Leg B',
      driver: 'Driver',
      passenger: {
        templates: {
          default: 'Passenger',
          schoolShuttles: 'Student',
          trailers: 'Recipient Name',
        },
      },
      from: {
        templates:{
          default: 'From',
          schoolShuttles: 'From',
          trailers: 'Pickup Location',
        },        
      },
      to: {
        templates:{
          default: 'To',
          schoolShuttles: 'To',
          trailers: 'Delivery Location',
        },
      },
      seats: {
        templates: {
          default: 'Seats',
          schoolShuttles: 'Seats',
          trailers: 'Item Quantity',
        }
      },
      vehicle: {
        templates: {
          default: 'Vehicl',
          schoolShuttles: 'Vehicle',
          trailers: 'Trailer',
        }
      },
    },
    statuses: {
      draft: 'Draft',
      new: 'New',
      onHold: 'On hold',
      waiting: 'Waiting',
      canceled: 'Canceled',
      routing: 'Routing',
      routed: 'Routed',
      picked: {
        templates: {
          default: 'Picked',
          schoolShuttles: 'Picked',
          trailers: 'Out for Delivery',
        }
      },
      dropped:  {
        templates: {
          default: 'Dropped',
          schoolShuttles: 'Dropped',
          trailers: 'Delivered',
        }
      },
      noShow: {
        templates: {
          default: 'No Show',
          schoolShuttles: 'No Show',
          trailers: 'Failed Delivery',
        }
      },
    },
    dateType: {
      pick: 'Pick',
      drop: 'Drop',
    },
    icons: {
      recuring: 'Recurring Reservation',
    },
    tooltips: {
      group: 'Group',
      similar: 'Show Duplicates',
    },
  },

  assignVehiclesDrivers: {
    routeSummaryModal: {
      header: 'Route Summary',
    },
  },

  checkRouteStatus: {
    routeSummaryModal: {
      header: 'Route Summary',
    },
  },

  shuttleReservation: {
    create: {
      confirmButtonText: 'Book',
    },

    limitationModal: {
      header: 'Booking Limitation',
      body: 'You are trying to make a reservation under the booking limitation time, do you want to continue?',
    },

    form: {
      information: {
        recurring: {
          header: 'Recurring',
        },
        personalInformation: {
          header: 'Personal Details',
          seats: '- Seat 1',
          fields: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
          },
        },

        passengers: {
          header: 'Personal Details -',
          seats: 'Seat ',
        },
        paymentOption: {
          header: 'Payment Options',
          fields: {
            paymentType: 'Payment Type',
            cardNum: 'Card Number',
            cardExp: 'Expires',
            cvv: 'CVV',
            rate: 'Rate',
            amount: 'Amount',
            fare: 'Fare',
          },
          paymentTypes: {
            free: 'Free',
            opusCard: 'OPUS Card',
            cashOnBoard: 'Cash on Board',
            correspondance: 'Correspondance',
            opusCardMensuel: 'Carte OPUS - Mensuel',
            opusCardPassage: 'Carte OPUS - Passage',
            cpoPassageSTL: 'Carte a puce occasionnelle (CPO-STL) - Passage',
            cpoPassage: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
            freeZeroToEleven: 'Gratuit (0-11 Ans)',
          },
          rateTypes: {
            ordinary: 'Cash Amount',
            freeZeroToFive: 'Free (0-5 Years)',
            freeSixToEleven: 'Free (6-11 Years)',
            freeZeroToEleven: 'Free (0-11 Years)',
          },
        },
        bookingDetails: {
          header: 'Booking Details',
          fields: {
            supplier: 'Supplier',
            date: 'Date',
            hour: 'Hour',
            area: 'Area',
            direction: 'Direction',
            operationHours: 'Operation Hours',
            line: 'Shuttle',
            pickup: 'Pickup',
            dropoff: 'Dropoff',
            seats: 'Seats',
          },
        },
      },
      validations: {
        drivers: {
          required: 'Drivers is required',
        },
        area: {
          required: 'Area is required',
        },
        direction: {
          required: 'Direction is required',
        },
        unit: {
          required: 'Unit is required',
        },
        supplier: {
          required: 'Supplier is required',
        },
        driver: {
          required: 'Driver is required',
        },
        start: {
          required: 'Start Date is required',
        },
        end: {
          required: 'End Date is required',
        },
        firstName: {
          required: 'First Name is required',
        },
        lastName: {
          required: 'Last Name is required',
        },
        email: {
          required: 'Email is required',
        },
        phone: {
          required: 'Phone is required',
        },
        date: {
          required: 'Date is required',
        },
        line: {
          required: 'Shuttle is required',
        },
        hour: {
          required: 'Hour is required',
        },
        from: {
          required: 'From is required',
        },
        fromLat: {
          required: 'From Latitude is required',
        },
        fromLon: {
          required: 'From Longitude is required',
        },
        to: {
          required: 'To is required',
        },
        toLat: {
          required: 'To Latitude is required',
        },
        toLon: {
          required: 'To Latitude is required',
        },
        seats: {
          required: 'Seats is required',
          conditions: {
            min: 'Seats should be higher than {{min}}',
          },
        },
        paymentOptions: {
          required: 'Payment Options is required',
          paymentType: {
            required: 'Payment Type is required',
          },
          data: {
            required: 'Payment Data is required',
            opusCard: {
              minLength: 'Must be exactly 12 characters',
            },
          },
        },
      },
    },
  },
};

export default jobs;
