import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetProfilesResponse } from 'models/response';
import {
  ICreateProfilesData,
  IDeleteProfilesFilters,
  IGetProfilesFilters,
  IUpdateProfilesData,
  IUpdateProfilesFilters,
} from 'models/http/profile';
import { IProfile } from 'models/profile';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class ProfileHttp {
  private static route = '/';

  public async get(
    filters: IGetProfilesFilters,
    cancelTokenSource?: CancelTokenSource,
    project?: { [projectField: keyof IGetProfilesFilters]: number },
  ): Promise<IProfile[]> {
    return serverAxios
      .post(
        ProfileHttp.route,
        {
          act: 'find',
          col: 'profiles',
          count: true,
          query: filters,
          project,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetProfilesResponse>) => {
        return response.data.data;
      });
  }

  public async getbyId(id: string, cancelTokenSource?: CancelTokenSource): Promise<IProfile> {
    return serverAxios
      .post(
        ProfileHttp.route,
        {
          act: 'find',
          col: 'profiles',
          query: { _id: id },
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetProfilesResponse>) => {
        return response.data.data[0];
      });
  }

  public async create(data: ICreateProfilesData): Promise<void> {
    return serverAxios
      .post(
        ProfileHttp.route,
        {
          act: 'insert',
          col: 'profiles',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateProfilesFilters, data: IUpdateProfilesData): Promise<void> {
    return serverAxios
      .post(ProfileHttp.route, {
        act: 'update',
        col: 'profiles',
        query: filters,
        data,
      })
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteProfilesFilters): Promise<void> {
    return serverAxios
      .post(
        ProfileHttp.route,
        {
          act: 'delete',
          col: 'profiles',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async getShuttleProfilesBySupplier(suppliers: string[]): Promise<{ profileName: string }[]> {
    return serverAxios
      .post(
        ProfileHttp.route,
        {
          act: 'find',
          col: 'shuttles',
          count: true,
          project: { _id: 0, unit: 1, profileName: 1 },
          query: {
            supplier: { $in: suppliers },
          },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<{ data: { profileName: string }[] }>) => {
        return response.data.data;
      });
  }
}

export default ProfileHttp;
