import React from 'react';

import ParentsTableCellsNameView from './ParentsTableCellsName.view';

import { MTableAdvancedCellConfigParam } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import { IParentsTableData } from '../../ParentsTable.model';

type Props = MTableAdvancedCellConfigParam<IParentsTableData, 'firstName'>;

const ParentsTableCellsName: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <ParentsTableCellsNameView id={props.row.original._id} value={`${props.getValue()} ${props.row.original.lastName}`}>{props.children}</ParentsTableCellsNameView>
  );
};

ParentsTableCellsName.displayName = 'ParentsTableCellsName';
ParentsTableCellsName.defaultProps = {};

export default ParentsTableCellsName;
