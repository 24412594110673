import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { AxiosResponse, CancelTokenSource } from 'axios';
import moment from 'moment-timezone';

import MHistoryTableCellData from 'components/History/MHistoryTableCells/MHistoryTableCellData/MHistoryTableCellData';
import MHistoryView from './MHistory.view';

import { BaseHistoryData, IHistory } from 'models/history';
import { MColumnOptions } from 'views/MTable/Table.model';
import { TimeFormat } from 'utils/enums/time-format';
import { IHistoryHeader, IMHistoryTableData } from './MHistory.model';
import { serverAxios } from 'utils/http';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

interface Props {
  collection: string;
  id: string;
  className?: string;
}

const History: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [createdState, setCreatedState] = useState<IHistoryHeader>({ createdAt: '', createdBy: '' });

  const columnsOptions: MColumnOptions<IMHistoryTableData<BaseHistoryData>> = useMemo(() => {
    return {
      data: {
        Cell: MHistoryTableCellData,
      },
      time: {
        Header: t('components.history.table.headers.date'),
        Cell(item: CellProps<IMHistoryTableData<BaseHistoryData>, Record<string, string | number>>) {
          return moment(+item.cell.value).tz('America/New_York').format(TimeFormat.FullDateTimeMonthNameFullTime);
        },
      },
    };
  }, [t]);

  const fetch = useCallback(
    async (_, cancelToken: CancelTokenSource) => {
      return serverAxios

        .post(
          '/',
          {
            act: 'find',
            col: 'history',
            query: {
              collection: props.collection,
              id: props.id,
            },
            sort: { time: 1 },
          },
          {
            cancelToken: cancelToken.token,
            timeout: HttpTimeoutPriority.Medium,
          },
        )
        .then((res: AxiosResponse<{ data: IHistory<BaseHistoryData>[] }>) => {
          if (res.data.data.length) {
            const [createdItem] = res.data.data.slice(-1);

            setCreatedState({
              createdAt: moment.utc(+createdItem.time).local().format(TimeFormat.FullDateTime),
              createdBy: createdItem.user,
            });
          }

          const last: BaseHistoryData = {};

          const withPrevData: IMHistoryTableData<BaseHistoryData>[] = res.data.data.map((operation: IHistory<BaseHistoryData>) => {
            const prevData: BaseHistoryData = {};

            if (operation.data) {
              Object.entries(operation.data).forEach(([key, value]: [string, BaseHistoryData[keyof BaseHistoryData]]) => {
                prevData[key] = last[key];
                last[key] = value;
              });
            }

            return {
              user: operation.user,
              data: operation.data || {},
              operation: operation.action || operation.operation,
              time: operation.time,
              prevData,
            };
          });

          return {
            count: res.data.data.length,
            data: withPrevData,
          };
        });
    },
    [props.id, props.collection],
  );

  return (
    <MHistoryView
      created={createdState}
      fetch={fetch}
      columns={['time', 'user', 'operation', 'data']}
      columnsOptions={columnsOptions}
      className={props.className}
    ></MHistoryView>
  );
};

History.displayName = 'History';
History.defaultProps = {};

export default History;
