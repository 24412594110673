import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import ParentsUpdateView from './ParentsUpdate.view';

import MIziToast from 'views/MIziToast/MIziToast';
import { IParentsUpdateFormFields } from './ParentsUpdate.model';
import useUnmoutedFlag from 'utils/hooks/use-unmounted-flag';
import { initialValues, validationSchema } from '../ParentsForm.utils';
import { serverAxios } from 'utils/http';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import Statuses from 'utils/enums/statuses';
import { IGetParentByIdResponse } from 'models/response';
import { useHttp } from 'utils/hooks';

type Props = {
  onBack: () => void;
  onSubmit: () => void;
};

const ParentsUpdate: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const unmountedFlagRef = useUnmoutedFlag();
  const { t } = useTranslation();
  const params = useParams<'id'>();
  const navigate = useNavigate();

  const http = useHttp();

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [parentStatusState, setParentStatusState] = useState<Statuses | null>(null);
  const [statusModalState, setStatusModalState] = useState<boolean>(false);
  const [studentsState, setStudentState] = useState<string[]>([]);

  const form = useFormik<IParentsUpdateFormFields>({
    initialValues,
    validationSchema,
    onSubmit(values: IParentsUpdateFormFields) {
      if (!params.id) {
        return;
      }

      http.parent.update(params.id, values).then(async () => {
        if (unmountedFlagRef.current) {
          return;
        }

        const addStudents = values.studentEmails.filter((email: string) => !studentsState.includes(email));
        const removeStudents = studentsState.filter((email: string) => !values.studentEmails.includes(email));

        await http.parent.addChildrens(values.email, addStudents);
        await http.parent.removehildrens(values.email, removeStudents);

        MIziToast.success(t('parents.form.update.toasts.success'));
        props.onSubmit();
      }).catch(() => {
        if (unmountedFlagRef.current) {
          return;
        }

        MIziToast.success(t('parents.form.update.toasts.error'));
      });
    },
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }

    setLoadingState(() => true);

    let cancelToken: CancelTokenSource | null = axios.CancelToken.source();

    setLoadingState(() => true);

    serverAxios
      .post(
        '/',
        {
          act: 'find',
          col: 'users',
          query: { _id: params.id },
        },
        {
          cancelToken: cancelToken.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetParentByIdResponse>) => {
        const data = response.data.data[0];

        form.setValues(() => {
          return {
            site: data.unit,
            profile: data.profile || null,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            additionalPhone: data.additionalPhone || '',
            address: data.address || '',
            studentEmails: data.children || [],
          };
        });

        setStudentState(() => data.children);
        setParentStatusState(() => data.status);
      })
      .catch((error) => {
        console.error(error);

        if (unmountedFlagRef.current) {
          return;
        }

        navigate('/drivers');
        MIziToast.error(t('parents.form.update.toasts.fetchError'));
      })
      .finally(() => {
        setLoadingState(() => false);

        cancelToken = null;
      });

    return cancelToken?.cancel;
  }, [params.id, form.setValues]);

  const updateStatus = () => {

    serverAxios
      .post(
        '/',
        {
          act: 'update',
          col: 'users',
          query: { _id: params.id },
          data: parentStatusState === Statuses.Active ?  { status: Statuses.Inactive}: { status: Statuses.Active },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        if (!unmountedFlagRef.current) {
          MIziToast.success(t('parents.form.update.toasts.success'));
        }
      })
      .catch((error) => {
        if (!unmountedFlagRef.current) {
          console.error('Error:', error);
          MIziToast.error(t('parents.form.update.toasts.fetchError'));
        }
      }).finally(()=>{
        navigate('/parents');
      });

    setStatusModalState(() => false);
  };

  const statusButton = useMemo(() => {
    if (parentStatusState === Statuses.Active) {
      return {
        action: t('users.edit.suspendButton.actionDeactivate'),
        status: t('users.edit.activeStatusButton.statusActive'),
        color: 'suspend',
      };
    }

    return {
      action: t('users.edit.suspendButton.actionActivate'),
      status: t('users.edit.activeStatusButton.statusDeactivate'),
      color: 'unsuspend',
    };
  }, [parentStatusState]);

  return (
    <ParentsUpdateView
      statusModal={statusModalState}
      statusButton={statusButton}
      onChangeStatusClick={() => setStatusModalState(() => true)}
      onCloseStatusModal={() => setStatusModalState(() => false)}
      onStatusModalSubmit={updateStatus}
      form={form}
      isLoading={loadingState}
      onBack={props.onBack}
      id={params.id}
    ></ParentsUpdateView>
  );
};

ParentsUpdate.displayName = 'ParentsUpdate';
ParentsUpdate.defaultProps = {};

export default ParentsUpdate;
