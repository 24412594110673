enum JobStatus {
  New = 'new',
  OnHold = 'onhold',
  Waiting = 'waiting',
  Routing = 'routing',
  Routed = 'routed',
  Canceled = 'canceled',
  Picked = 'picked',
  Dropped = 'dropped',
  Noshow = 'noshow',
  Draft = 'draft'
}

export default JobStatus;