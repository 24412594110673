import moment from 'moment-timezone';
import { Filters } from 'react-table';

import { MDateNavigatorDirection } from 'views/MDateNavigator/MDateNavigator.model';
import { RowData } from 'views/MTable/Table.model';

type ReturnFunction = (direction: MDateNavigatorDirection) => void;

const useDateNavHandler = <TableFields extends RowData>(
  columnName: keyof TableFields & string,
  filtersCurrentState: Filters<TableFields>,
  onUpdate: (filters: Filters<TableFields>) => void,
): ReturnFunction => {
  const handler: ReturnFunction = (direction: MDateNavigatorDirection) => {
    const filters: Filters<TableFields> = [...filtersCurrentState];
    let dateIndex = filters.findIndex((filter) => filter.id === columnName);

    if (dateIndex === -1) {
      dateIndex = filters.length;
      filters.push({ id: columnName, value: [moment(), null] });
    }

    if (direction === MDateNavigatorDirection.Now) {
      filters[dateIndex] = { id: columnName, value: [moment(), null] };
    }

    const daysToAdd = {
      [MDateNavigatorDirection.Now]: 0,
      [MDateNavigatorDirection.Backword]: -1,
      [MDateNavigatorDirection.Forward]: 1,
    };

    const newStartDate = (filters[dateIndex].value[0] as moment.Moment)
      .add({ days: daysToAdd[direction] })
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    const newEndDate = newStartDate.clone().set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });

    filters[dateIndex] = {
      ...filters[dateIndex],
      value: [newStartDate, newEndDate],
    };

    onUpdate(filters);
  };

  return handler;
};

export default useDateNavHandler;
