
const agent = {
  createNew: {
    newReservation: 'New Reservation',
    quickReservation: 'Quick Reservation',
  },

  searchBox: {
    searchBtn: 'Search',
  },

  table: {
    myLastBookingsTable: {
      title: 'My Last Bookings',
      headers: {
        id: 'Id',
        date: 'Date',
        status: 'Status',
        passenger: 'Passenger',
      },
    },
    myRecentViewsTable: {
      title: 'My Recent Views',
      headers: {
        id: 'Id',
        date: 'Date',
        status: 'Status',
        passenger: 'Passenger',
      },
    },
  },

  statistics: {
    lastWeek: {
      title: 'Past Week',
    },
    last24Hours: {
      title: 'Last 24 Hours',
    },
    myLastWeek: {
      title: 'My Past Week',
    },
    myLast24Hours: {
      title: 'My Last 24 Hours',
    },
  },

  weatherInfo: {
    title: 'Weather',
    noNewMessages: 'No New Messages',
    loadingWeather: 'Loading weather...',
  },

  radioButton: {
    showTen: '10',
    showFive: '5',
  },
};

export default agent;
