import React from 'react';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';

import MFormCard from 'views/MForm/MFormCard/MFormCard';
import WithoutSite from 'views/WithoutSite/WithoutSite';
import MFormSiteField from 'components/MFormSiteField/MFormSiteField';

import { IParentsFormGroupsFeilds } from './ParentsFormGroups.model';

type Props<Fields extends IParentsFormGroupsFeilds> = {
  form: FormikContextType<Fields>;
};

const ParentsFormGroupsView = <Fields extends IParentsFormGroupsFeilds>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MFormCard header={t('parents.form.sections.groups.header')}>
      <WithoutSite>
        <MFormSiteField formInstance={props.form}></MFormSiteField>
      </WithoutSite>
    </MFormCard>
  );
};

ParentsFormGroupsView.displayName = 'ParentsFormGroupsView';
ParentsFormGroupsView.defaultProps = {};

export default ParentsFormGroupsView;
