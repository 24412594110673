import { GuardData } from './guard.model';
import { ModuleCodes } from 'models/modules';

const accessGuard = (module: ModuleCodes | { code: ModuleCodes; action: string }): ((guardData: GuardData) => boolean) => {
  return (guardData: GuardData) => {
    if (typeof module === 'string' && !guardData.perm.can('view', module)) {
      return false;
    }

    if (typeof module === 'object' && !guardData.perm.can(module.action, module.code)) {
      return false;
    }

    return true;
  };
};

export default accessGuard;
