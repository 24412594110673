const blacklist = {
  toast: {
    error: {
      badResponse: '[Black List] bad server response',
      create: {},
      edit: {},
      duplicate: 'Card already exists',
    },
    success: {
      create: {
        body: 'Card Has Been Added Successfully',
      },
      edit: {
        body: 'Card Has Been Updated Successfully',
      },

      delete: {
        body: 'Card Has Been Deleted Successfully',
      },
    },
  },

  headerButtons: {
    create: 'New',
    delete: 'Delete',
  },

  table: {
    headers: {
      cardNumber: 'Card Number',
      site: 'Site',
    },
  },

  forms: {
    information: {
      details: {
        header: 'Details',
        fields: {
          cardNumber: 'Card Number',
        },
      },
      actions: {
        header: 'Actions',
        buttons: {
          delete: 'Delete',
        },
      },
    },
  },
};

export default blacklist;
