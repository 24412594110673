import React, { FormEvent } from 'react';
import { Filters, Row } from 'react-table';

import MServerTable, { RequestBody } from 'views/MTable/MServerTable/MServerTable';

import { BaseQuery, MColumnOptions, RowData } from 'views/MTable/Table.model';
import { CancelTokenSource } from 'axios';
import { TableName } from 'utils/enums/table-page';

interface Props<T extends RowData> {
  columns: string[];
  columnsOptions?: MColumnOptions<T>;
  generalFilterColumns?: Props<T>['columns'][number][];
  selectedRow?: Partial<T> | Partial<T>[];
  showNoResults?: boolean;
  name?: TableName;
  baseQuery?: BaseQuery;
  disabled?: boolean;
  isMulty?: boolean; 
  generalFilterPlaceholder?: string;
  hideFooter?: boolean;
  limit?: number;
  showOnlyWithGeneralFilter?: boolean;
  className?: string;
  fetch?: (queryData: RequestBody, cancelToken: CancelTokenSource) => Promise<{ count: number; data: T[] }>;
  onFilter?: (prop: FormEvent<HTMLInputElement>) => void;
  onChange?: (event: T) => void;
  generalFilter?: string;
  onFiltersChange?: (filters: Filters<T>, generalFilter: string) => void;
}

const FormSelect = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <MServerTable
      className={props.className}
      columns={props.columns}
      columnsOptions={props.columnsOptions}
      selectedRow={props.selectedRow}
      generalFilterColumns={props.generalFilterColumns}
      fetch={props.fetch}
      disableFilters
      disableCache
      generalFilterPlaceholder={props.generalFilterPlaceholder}
      name={props.name}
      hideFooter={props.hideFooter}
      baseQuery={props.baseQuery}
      disablePagination
      isMulty={props.isMulty}
      showOnlyWithGeneralFilter={props.showOnlyWithGeneralFilter ?? true}
      hideSelection
      hideColumnSelect
      limit={props.limit}
      onRowClick={(row: Row<T>) => props.onChange?.(row.original)}
      onFiltersChange={props.onFiltersChange}
      generalFilter={props.generalFilter}
    ></MServerTable>
  );
};

FormSelect.displayName = 'FormSelect';
FormSelect.defaultProps = {};

export default FormSelect;
