const shifts = {
  toast: {
    error: {
      badResponse: '[Taxa] resposta inválida do servidor',
      create: {},
      edit: {},
      delete: {
        default: 'Erro ao excluir os turnos: {{error}}.',
      },
    },
    success: {
      delete: {
        default: '{{numDeleted}} turnos excluídos com sucesso.',
      },
      create: {},
      edit: {},
    },
  },
  headerButtons: {
    create: 'Novo',
    delete: 'Remover',
  },
  table: {
    headers: {
      name: 'Nome',
      status: 'Status',
      start: 'Início',
      end: 'Fim',
      year: 'Ano',
      direction: 'Direção',
      weekDays: 'Dias da semana',
    },
    cells: {
      weekDays: {
        weekDaysOptions: [
          'Dom',
          'Segunda',
          'Ter',
          'Qua',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
      },
      status: {
        statusOptions: {
          statusActive: 'Ativo',
          statusNotActive: 'Não ativo',
        },
      },
    },
  },
  form: {
    information: {
      details: {
        header: {
          templates: {
            default: 'Turno',
            schoolShuttles: 'Horário escolar',
            employees: 'Turno',
            trailers: 'Turno',
          },
        },
        fields: {
          name: 'Nome',
          status: 'Status',
          start: 'Início',
          end: 'Fim',
          year: 'Ano',
          direction: 'Direção',
          weekDays: 'Dias da Semana',
        },
      },
      directionOptions: {
        selectDirection: 'Selecione a Direção',
        collect: {
          templates: {
            default: 'A recolher',
            schoolShuttles: 'Para a Escola',
            employees: 'Para trabalhar',
            trailers: 'Recolher',
          },
        },
        distribute: {
          templates: {
            default: 'Distribuir',
            schoolShuttles: 'Da escola',
            employees: 'Do trabalho',
            trailers: 'Distribuir',
          },
        },
        collectAndDistribute: {
          templates: {
            default: 'Coletar/Distribuir',
            schoolShuttles: 'Para a Escola / Da Escola',
            employees: 'Para o trabalho / Do trabalho',
            trailers: 'Coletar/Distribuir',
          },
        },
      },
    },
    validations: {
      name: {
        required: 'Um nome é requerido',
        name: '',
      },
      start: {
        required: 'O início é necessário',
        start: '',
      },
      end: {
        required: 'Terminar é requerido',
        end: '',
      },
      year: {
        required: 'Requer-se o ano',
        year: '',
      },
      direction: {
        required: 'A direção é obrigatória',
        direction: '',
      },
      weekDays: {
        required: 'Você deve selecionar pelo menos um dia',
        weekDays: '',
      },
    },
  },
  delete: {
    confirmation: {
      header: 'Confirmação de exclusão de turnos',
      body: {
        dynamic: 'Você está prestes a excluir {{numShifts}} turnos.',
        all: 'Você está prestes a excluir todos os turnos.',
      },
    },
  },
};

export default shifts;
