import React from 'react';

import InputFilterView from './InputFilter.view';

import { MFilterProps } from '../../Table.model';
import classes from './MInputFilter.module.scss';

const InputFilter = <T extends { [key: string]: unknown }>(props: MFilterProps<T>): JSX.Element => {
  const updateChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;

    props.column.setFilter(value);
    props.onChange(value);
  };

  return (
    <InputFilterView
      {...props}
      className={classes['container']}
      value={props.column.filterValue || ''}
      onChange={updateChange}
      placeholder={props.placeholder}
      updateChange={updateChange}
    />
  );
};

InputFilter.displayName = 'InputFilter';

export default InputFilter;
