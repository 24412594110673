const profiles = {
  toast: {
    error: {
      badResponse: '[Profile] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Name',
      status: 'Status',
      profileType: 'Profile Type',
      rateType: 'Rate Type',
    },
    actions: {
      create: 'New',
      delete: 'Delete',
    },
  },
  forms: {
    general: {
      header: 'General',
      fields: {
        profileType: {
          label: 'Profile Type',
          options: {
            retail: 'Retail',
            individual: 'Individual',
            wholesale: 'Wholesale',
            corporate: 'Corporate',
            web: 'Web',
            hotel: 'Hotel',
            thirdParty: '3rd Party',
            tourOperator: 'Tour Operator',
            receiptiveOperator: 'Receiptive Operator',
          },
        },
        rateType: {
          label: 'Rate Type',
          options: {
            connections: 'Connections',
            corporate: 'Corporate Rate',
            expedia: 'Expedia',
            groundTransportation: 'Ground Transportation',
            holidayTaxis: 'Holiday Taxis',
            respectiveOperator: 'Respective Operator Rate',
            retail: 'Reatil Rate',
            tourOperator: 'Tour Operator Rate',
            viator: 'Viator',
          },
        },
        name: {
          label: 'Name',
        },
        status: {
          label: 'Status',
          options: {
            active: 'Active',
            inactive: 'Inactive',
            onHold: 'On Hold',
          },
        },
        sharedWith: {
          label: 'Shared With',
          options: {
            private: 'Private',
          },
        },
        email: {
          label: 'Email address',
        },
        startDate: {
          label: 'Start Date',
        },
        phone: {
          label: 'Phone number',
        },
        additionalPhone: {
          label: 'Additional phone',
        },
        fax: {
          label: 'Fax number',
        },
      },
    },
    billing: {
      header: 'Billing',
      fields: {
        name: {
          label: 'Billing Name',
        },
        email: {
          label: 'Email Address',
        },
        phone: {
          label: 'Phone Number',
        },
        address: {
          label: 'Address',
        },
        city: {
          label: 'City',
        },
        state: {
          label: 'State',
        },
        zip: {
          label: 'Zip Code',
        },
        country: {
          label: 'Country',
          options: {
            usa: 'USA',
          },
        },
      },
    },
    notes: {
      header: 'Notes',
      fields: {
        general: {
          label: 'General',
        },
        driver: {
          label: 'Driver',
        },
        dispatch: {
          label: 'Dispatch',
        },
      },
    },
    complexNotes: {
      header: 'Notes',
      fields: {
        type: {
          label: 'Note type',
          options: {
            accident: 'Accident',
            complaint: 'Complaint',
            agentIssue: '(QC) Agent Issue',
            carIssue: '(QC) Vehicle Issue',
            noShow: 'No Show',
            general: 'General note',
            disaptch: 'Note to dispatch',
            driver: 'Note to driver',
            agent: 'Agent Note',
            cancel: 'Cancelation Note',
            booking: 'Booking Note',
          },
        },
        value: {
          label: 'Note Content',
        },
      },
      add: 'Add Note',
    },
    jobPreferences: {
      header: 'Reservation Preferences',
      fields: {
        emailSent: {
          label: 'Email Sent',
        },
        payment: {
          label: 'Payment',
          options: {
            cash: 'Cash on board',
            creditCard: 'Credit Card',
            direct: 'Direct bill',
            voucher: 'Voucher',
          },
        },
        service: {
          label: 'Service',
        },
        extraServices: {
          label: 'Extra Services',
          headers: {
            type: 'TYPE',
            value: 'VALUE',
            surcharge: 'SURCHARGE',
            description: 'DESCRIPTION',
          },
        },
      },
    },
    jobHistory: {
      header: 'Reservation History',
      lastJob: 'Last Reservation: {{lastJob}}',
      firstJob: 'First Reservation: {{firstJob}}',
    },
    contacts: {
      header: 'Contacts',
      fields: {
        name: {
          label: 'Contact Name',
        },
        type: {
          label: 'Contact Type',
          options: {
            sales: 'Salesperson',
            employee: 'Employee',
          },
        },
        email: {
          label: 'Email',
        },
        phone: {
          label: 'Phone Number',
        },
        fax: {
          label: 'Fax Number',
        },
        address: {
          label: 'Address',
        },
        city: {
          label: 'City',
        },
        state: {
          label: 'State',
        },
        zip: {
          label: 'Zip Code',
        },
        country: {
          label: 'Country',
        },
        comment: {
          label: 'Comment',
        },
      },
      add: 'Add Contact',
    },
    rules: {
      header: 'Rules',
      fields: {
        discount: {
          label: 'Discount',
          options: {
            none: 'None',
            fixed: 'Fixed',
            fixedPP: 'Fixed Per Passenger',
            percent: 'Percent',
            percentR: 'Percent (Rounded)',
            percentRU: 'Percent (Rounded Up)',
          },
        },
        markup: {
          label: 'Markup',
          options: {
            none: 'None',
            fixed: 'Fixed',
            fixedPP: 'Fixed Per Passenger',
            percent: 'Percent',
            percentR: 'Percent (Rounded)',
            percentRU: 'Percent (Rounded Up)',
          },
        },
      },
    },
    passengers: {
      header: 'Passengers',
      found: '{{number}} passengers found',
      linkedTable: {
        title: 'Profile Passengers List',
        columns: {
          passenger: 'Passenger',
          registerDate: 'Date Created',
          vip: 'VIP',
          registerWith: 'Created at',
          reservations: 'Reservations',
        },
        actions: {
          clear: 'Clear Filter',
          remove: 'Remove Passenger',
        },
      },
      nonLinkedTable: {
        title: 'Non Linked Passengers List',
        columns: {
          passenger: 'Passenger',
          registerDate: 'Date Create',
          registerWith: 'Create at',
          profilesLinked: 'Profiles linked',
          reservations: 'Reservations',
        },
        actions: {
          clear: 'Clear Filter',
          add: 'Link Passenger',
        },
      },
    },
  },
  update: {
    success: {
      content: 'Your changes were saved successfully.',
    },
    error: {
      content: 'Could Not Save Changes.',
    },
  },
  create: {
    success: {
      content: 'Your Profile Was Created.',
    },
    error: {
      content: 'Could Not Create Profile.',
    },
  },
  delete: {
    deleteBtn: 'Delete',
    deleteModal: {
      header: 'Delete Profile?',
      body: 'You are about to delete {{number}} profiles',
    },
  },
};

export default profiles;
