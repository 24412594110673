const billReport = {
  actions: {
    export: 'Export',
  },

  table: {
    headers: {
      tripId: 'Trip ID',
      reservationId: 'Reservation ID',
      status: 'Reservation Status',
      supplier: 'Supplier',
      profile: 'Profile',
      area: 'Area',
      date: 'Date',
      week: 'Day',
      time: 'Planned Pickup',
      dropOff: 'Planned Dropoff',
      line: 'Line',
      from: 'From',
      fromLat: 'FromLat',
      fromLon: 'FromLon',
      to: 'To',
      routingType: 'Routing Type',
      externalId: 'External ID',
      passenger: 'Passenger',
      phone: 'Phone',
      toLat: 'ToLat',
      toLon: 'ToLon',
      email: 'Email',
      source: 'Source',
      tripSeats: 'Trip Seats',
      dist: 'Actual Distance',
      vehicle: 'Vehicle',
      pickedTime: 'Actual Pickup Time',
      droppedTime: 'Actual Dropoff Time',
      paymentType: 'Payment Type',
      paymentDetails: 'Payment Details',
      shuttleArea: 'Area',
      direction: 'Direction',
      payAmount: 'Amount',
      driver: 'Driver',
      timeIndex: 'Time Index',
      createdBy: 'Created By',
      delayNotification: 'Delay Notification',
      assignedAt: 'Assigned Time',
      canceledBy: 'Canceled by',
      driverArrival: 'Arrival Time',
    },

    cells: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
    },

    filters: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
      tripStatus: {
        options: {
          new: 'New',
          inroute: 'In Route',
          sent: 'Sent',
          approved: 'Approved',
          pickingUp: 'Picking Up',
          done: 'Done',
          cancelled: 'Cancelled',
        },
      },
      routingType: {
        options: {
          bus: 'Bus',
          demand: 'Demand',
          dynamic: 'Dynamic',
        },
      },
    },
    footer: {
      lastUpdated: 'Last Updated: {{lastUpdated}}',
      noLastUpdate: 'Unknown',
    },
  },
  tableSummary: {
    title: 'Bill Reports',
    headers: {
      trips: 'Trips',
      reservations: 'Reservations',
      passengers: 'Passengers',
      uniquePassengers: 'Unique Passengers',
    },
  },
};

export default billReport;
