const suppliers = {
  toast: {
    error: {
      badResponse: '[Supplier] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createNew: 'New',
  table: {
    headers: {
      name: 'Name',
      supplierStatus: 'Status',
    },
  },

  create: {
    modalSuccess: {
      header: 'Success',
      body: 'Supplier Created!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Create Supplier',
    },
  },

  edit: {
    modalSuccess: {
      header: 'Success',
      body: 'Supplier Updated!',
    },
    modalError: {
      header: 'Error occurred',
      body: 'Could Not Update Supplier',
    },
  },

  forms: {
    information: {
      generalInformation: {
        header: 'General Information',
        fields: {
          name: 'Name',
          unit: 'Site',
          status: 'Status',
          statusOptions: {
            select: 'Select',
            active: 'Active',
            inactive: 'Inactive',
          },
        },
      },
      advancedSettings: {
        header: 'Advanced Settings',
        fields: {
          areas: 'Areas',
          offPeak: 'Off Peak',
          defaultCommission: 'Default Commission',
          pariorityScoring: 'Pariority Scoring',
          minutesBefore: 'Booking Limitation',
          multiplePaxDetails: 'Enable Multiple Passengers Details',
          pax: 'Maximum Passengers'
        },
      },
      contactInformation: {
        header: 'Contact Information',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          phone: 'Phone',
          email: 'Email',
        },
      },
    },
    select: 'Select',
  },
};

export default suppliers;
