import React from 'react';
import { useTranslation } from 'react-i18next';
import { CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react';
import { TFunctionResult } from 'i18next';

import MButton from 'views/Buttons/MButton/MButton';

import classes from './MModal.module.scss';

interface Props {
  show?: boolean;
  header?: string;
  body: string | React.ReactChild | TFunctionResult;
  customFooter?: React.ReactChild;
  btnConfirmName?: string;
  btnCloseName?: string;
  hideConfirm?: boolean;
  hideCloseTopBotton?: boolean;
  showOnClose?: boolean;
  modalClass?: string;
  size?: 'sm' | 'lg' | 'xl';
  confirmDisabled?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const MModalView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <CModal className={props.modalClass} size={props.size} show={props.show} onClosed={props.onClose}>
      <CModalHeader closeButton={!props.hideCloseTopBotton} className={classes['header']}>
        {props.header}
      </CModalHeader>
      <CModalBody className={classes['body']}>{props.body}</CModalBody>
      {props.showOnClose || !props.hideConfirm ? (
        <CModalFooter>
          {props.customFooter || (
            <React.Fragment>
              {props.showOnClose && (
                <MButton type="button" color="danger" onClick={props.onClose}>
                  {props.btnCloseName || t('views.modal.cancelButton')}
                </MButton>
              )}
              {props.hideConfirm ? null : (
                <MButton type="button" color="primary" onClick={props.onConfirm} disabled={props.confirmDisabled}>
                  {props.btnConfirmName || t('views.modal.confirmButton')}
                </MButton>
              )}
            </React.Fragment>
          )}
        </CModalFooter>
      ) : null}
    </CModal>
  );
};

MModalView.displayName = 'MModalView';
MModalView.defaultProps = {};

export default MModalView;
