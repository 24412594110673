const timeline = {
  filters: {
    status: {
      placeholder: 'Status',
      options: {
        new: 'Novo',
        in_route: 'Em rota',
        sent: 'Enviado',
        approved: 'Aprovado',
        done: 'OK',
        canceled: 'Cancelado',
        picking_up: 'Recolhimento',
      },
    },
    driver: 'Motorista',
    vehicle: 'Veículo',
  },
  alerts: {
    etaChanged:
      'O ESTIMATIVA para as paradas {{stopsIds}} da viagem {{tripId}} foi atualizado - O motorista foi notificado pelo sistema.',
  },
  empty: 'Não existem Viagens em {{filter}}',
  driverInfo: {
    phoneNumber: 'Número de telefone',
    lettersLeft: 'Cartas Restantes',
    sendMessage: 'Enviar mensagem',
  },
};

export default timeline;
