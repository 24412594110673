const permissions = {
  actions: {
    save: {
      button: 'Save',
      confirmModal: {
        title: 'Confirmation',
        content: 'Are you sure would you like to save the changes?',
        confirm: 'Confirm',
      },
      error: 'An error occured updating the permissions.',
      success: 'Permissions successfully updated!',
    },
    search: {
      placeholder: 'Search...',
    },
  },
  roleCard: {
    select: {
      all: 'Select all',
      none: 'Deselect all',
    },
    count: '{{ count }} users',
  },
};

export default permissions;
