import * as yup from 'yup';

import { IParent } from 'models/parent';
import { IParentsCreateFormFields } from './ParentsCreate/ParentsCreate.model';
import { IParentsUpdateFormFields } from './ParentsUpdate/ParentsUpdate.model';
import { FormValidationObject } from 'views/MForm/MForm.model';
import { UserRoles } from 'utils/enums/user-roles';
import Statuses from 'utils/enums/statuses';

export const initialValues: IParentsCreateFormFields | IParentsUpdateFormFields = {
  site: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  additionalPhone: '',
  address: '',
  studentEmails: [],
};

export const validationSchema = yup.object<FormValidationObject<keyof (IParentsCreateFormFields | IParentsUpdateFormFields)>>({
  site: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  additionalPhone: yup.string(),
  address: yup.string(),
  studentEmails: yup.array().of(yup.string()),
});

export const prepareDataToServer = (values: IParentsCreateFormFields | IParentsUpdateFormFields): Omit<IParent, '_id'> => {
  const data: Omit<IParent, '_id'> = {
    unit: values.site!,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    role: UserRoles.Parent,
    status: Statuses.Active,
  };

  if (values.additionalPhone) {
    // Check if not empty string
    data.additionalPhone = values.additionalPhone;
  }

  if (values.address) {
    // Check if not empty string
    data.address = values.address;
  }

  return data;
};
