const layout = {
  header: {
    settings: {
      sites: 'Locais',
      systemSettings: 'Configurações do sistema',
      blacklist: 'Lista Negra de Cartões',
      services: 'Serviços',
      locations: 'Localização',
      areas: 'Áreas',
      rates: 'Taxas',
      fares: 'Tarifas',
      rateTypes: 'Tipo de Taxas',
      extraServices: 'Serviços extras',
      suppliers: 'Fornecedores',
      users: 'Usuários',
      profiles: 'Perfis',
      history: 'Histórico',
      shifts: {
        templates: {
          default: 'Turnos',
          schoolShuttles: 'Horário letivo',
          employees: 'Horas de trabalho',
          trailers: 'Turnos',
        },
      },
      uploadData: 'Carregar dados',
      test: 'Teste',
      settings: 'Configurações',
      permissions: 'Permissões',
      driverShifts: 'Turnos do motorista',
      attributes: 'Atributos',
      holidays: 'Feriados',
      assignPriority: 'Atribuir prioridade',
    },
  },
  sideBar: {
    home: 'Home',
    timeline: 'Linha do tempo',
    jobs: {
      templates: {
        default: 'Reservas',
        schoolShuttles: 'Reservas',
        trailers: 'Reservas',
        employees: 'Reservas', 
      },
    },
    trips: 'Viagens',
    drivers: 'Motoristas',
    onlineDrivers: 'Motoristas on-line',
    shuttles: 'Transporte',
    passengers: 'Passageiros',
    parents: 'Pais',
    vehicles:  {
      templates: {
        default: 'Veículos',
        trailers: 'Veículos',
        schoolShuttles: 'Veículos',
        employees: 'Veículos', 
      },
    },
    liveMap: 'Mapa em tempo real',
    roadBlocks: 'Road Blocks', // TODO: translate
    driverShifts: 'Turnos do motorista',
    analytics: 'Analítica',
    reports: 'Relatórios',
    viewUnit: 'Visualizar unidade',
    students: 'Alunos',
    employees: 'Empregados',
    logout: 'Sair',
  },
  footer: {
    poweredBy: 'Carregado por',
    moovex: 'Mooverex',
  },
  asideQueue: {
    headerQueue: 'Fila',
    empty: 'Nenhuma reserva na fila encontrada',
    btnRemove: 'Remover da fila',
    btnAssign: 'Mover para viagem',
  },
};

export default layout;
