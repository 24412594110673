import { AxiosResponse, CancelTokenSource } from 'axios';

import { IGetAttributesResponse } from 'models/response';
import { IAttribute } from 'models/attribute';
import {
  IGetAttributesFilters,
  ICreateAttributesData,
  IUpdateAttributesFilters,
  IUpdateAttributesData,
  IDeleteAttributesFilters,
} from 'models/http/attributes';

import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { serverAxios } from '../../http';

class AttributeHttp {
  private static route = '/';

  public async get(filters: IGetAttributesFilters, cancelTokenSource?: CancelTokenSource): Promise<IAttribute[]> {
    return serverAxios
      .post(
        AttributeHttp.route,
        {
          act: 'find',
          col: 'attributes',
          query: filters,
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Highest,
        },
      )
      .then((response: AxiosResponse<IGetAttributesResponse>) => {
        return response.data.data;
      });
  }

  public async create(data: ICreateAttributesData): Promise<void> {
    return serverAxios
      .post(
        AttributeHttp.route,
        {
          act: 'insert',
          col: 'attributes',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async update(filters: IUpdateAttributesFilters, data: IUpdateAttributesData): Promise<void> {
    return serverAxios
      .post(
        AttributeHttp.route,
        {
          act: 'update',
          col: 'attributes',
          query: filters,
          data,
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(filters: IDeleteAttributesFilters): Promise<void> {
    return serverAxios
      .post(
        AttributeHttp.route,
        {
          act: 'delete',
          col: 'attributes',
          query: filters,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default AttributeHttp;
