const shuttleReports = {
  actions: {
    export: 'Export',
    showTotals: 'Show Totals',
  },

  table: {
    headers: {
      tripId: 'Trip Id',
      routingType: 'Routing Type',
      reservationId: 'Reservation Id',
      externalId: 'External ID',
      reservationStatus: 'Reservation Status',
      tripStatus: 'Trip Status',
      date: 'Date',
      dateType: 'Date Type',
      passenger: 'Passenger',
      email: 'Email',
      phone: 'Phone',
      seats: 'Seats',
      supplier: 'Supplier',
      shuttle: 'Shuttle',
      line: 'Line',
      from: 'From',
      to: 'To',
      driver: 'Driver',
      vehicle: 'Vehicle',
      paymentType: 'Payment Type',
      paymentDetails: 'Payment Details',
      profile: 'Profile',
      shuttleArea: 'Area',
      dropOff: 'Drop Off',
      area: 'Area',
      direction: 'Direction',
      price: 'Price',
      payAmount: 'Amount',
      pickedTime: 'Actual Pickup Time',
      droppedTime: 'Actual Dropoff Time',
      noshowTime: 'Actual No-Show Time',
      shuttleFare: 'Shuttle Fare',
    },

    cells: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
    },

    filters: {
      reservationStatus: {
        options: {
          new: 'New',
          onhold: 'On Hold',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          routing: 'Routing',
          routed: 'Routed',
          picked: 'Picked',
          dropped: 'Dropped',
          noshow: 'Noshow',
        },
      },
      tripStatus: {
        options: {
          new: 'New',
          inroute: 'In Route',
          sent: 'Sent',
          approved: 'Approved',
          pickingUp: 'Picking Up',
          done: 'Done',
          cancelled: 'Cancelled',
        },
      },
      routingType: {
        options: {
          bus: 'Bus',
          demand: 'Demand',
          dynamic: 'Dynamic',
        },
      },
    },
    footer: {
      lastUpdated: 'Last Updated: {{lastUpdated}}',
      noLastUpdate: 'Unknown',
    }
  },
  tableSummary: {
    title: 'Shuttle Reports',
    headers: {
      trips: 'Trips',
      onDemandShuttle: 'On Demand Shuttles',
      buses: 'Buses',
      onDemandSeats: 'On Demand Seats',
    },
  },
};

export default shuttleReports;
