import Statuses from 'utils/enums/statuses';
import { Roles } from './modules';
import { IReasonDeactivation } from './reasonDeactivation';
import { IModule } from './setting';

export type Address = {
  name: string;
  lat: number;
  lng: number;
};

export type IUser = {
  _id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: Roles;
  language?: string;
  defaultLanguage?: string;
  status: Statuses;
  reasonDeactivation?: IReasonDeactivation;
  registerWith: string;
  registerDate: string;
  profiles?: string[];
  favoriteTrips?: string[];
  unit: string;
  supplier: string;
  homeAddress1: Address;
  homeAddress2: Address;
  officeAddress: Address;
  attributes?: string[]; 
};

export type ILoginUser = IUser & {
  modulesAccess: Record<string, IModule>;
};

export class User {
  public _id: string;
  private _email: string;
  private _name: string;
  private _site: string;
  public favoriteTrips: string[];
  private _language: string | null;
  private _role: string;

  constructor(userData: IUser) {
    this._role = userData.role;
    this._id = userData._id;
    this._email = userData.email;
    this._name = userData.name;
    this._language = userData.language || null;
    this._site = userData.unit;
    this.favoriteTrips = userData.favoriteTrips || [];
  }

  public getId(): string {
    return this._id;
  }

  public getEmail(): string {
    return this._email;
  }

  public getName(): string {
    return this._name;
  }

  public getLanguage(): string | null {
    return this._language;
  }

  public getSite(): string {
    return this._site;
  }

  public getRole(): string {
    return this._role;
  }
}
