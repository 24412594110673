import React from 'react';

import MButton from 'views/Buttons/MButton/MButton';

import { IDropdownAction, IDropdownType } from './MDropdown.model';
import classes from './MDropdown.module.scss';

interface Props<Actions, Id extends string | number | null> {
  className?: string;
  state: boolean;
  type: IDropdownType;
  items: IDropdownAction<Actions, Id>[][];
  onSelect: (action: IDropdownAction<Actions, Id>, index: number) => void;
}

const MDropdownView = <Actions, Id extends string | number | null = null>(
  props: React.PropsWithChildren<Props<Actions, Id>>,
  ref: React.ForwardedRef<HTMLUListElement>,
) => {
  return props.state ? (
    props.type === 'checkbox' ? (
      <ul ref={ref} className={`${classes['container']} ${props.className || ''}`.trim()}>
        {props.items.map((itemGourp: IDropdownAction<Actions, Id>[]) => {
          return itemGourp.map((item: IDropdownAction<Actions, Id>, index: number) => {
            return (
              <li className={classes['checkboxListItem']} key={item.title} title={item.title || ''}>
                <input
                  type="checkbox"
                  disabled={item.title === props.items[0][0].title}
                  checked={!!item.value}
                  onClick={() => props.onSelect(item, index)}
                />
                <span className={classes['item__text']}>{item.label}</span>
              </li>
            );
          });
        })}
      </ul>
    ) : (
      <ul ref={ref} className={`${classes['container']} ${props.className || ''}`.trim()}>
        {props.items.map((group: IDropdownAction<Actions, Id>[], index: number) => {
          return (
            <React.Fragment key={group[index].group || 0}>
              {group.map((item: IDropdownAction<Actions, Id>, index: number) => {
                return (
                  <li key={String(item.value)} title={item.title || ''}>
                    <MButton
                      disabled={item.disable}
                      className={classes['item']}
                      onClick={() => props.onSelect(item, index)}
                      color="transparent"
                    >
                      {item.label}
                    </MButton>
                  </li>
                );
              })}
              {index !== props.items.length - 1 ? <div className={classes['separateGroup']}></div> : null}
            </React.Fragment>
          );
        })}
      </ul>
    )
  ) : null;
};

MDropdownView.displayName = 'MDropdownView';

export default React.forwardRef(MDropdownView) as <Actions, Id extends string | number | null = null>(
  props: React.PropsWithoutRef<React.PropsWithChildren<Props<Actions, Id>>> & React.RefAttributes<HTMLDivElement>,
) => JSX.Element;
