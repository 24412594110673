import React, { ReactNode } from 'react';
import CIcon from '@coreui/icons-react';

import { MSimpleSelectOption } from './MSimpleSelect.model';
import classes from './MSimpleSelect.module.scss';

interface Props<OptionValue extends string | number> {
  className?: string;
  value?: OptionValue | null; 
  name?: string;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  showSelected?: boolean;
  invalid?: boolean;
  options: MSimpleSelectOption<OptionValue>[];
  placeholder?: string;
  defaultValue?: OptionValue;
  hideDefaultValue?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const MSimpleSelectView = <OptionValue extends string | number>(props: React.PropsWithChildren<Props<OptionValue>>): JSX.Element => {

  return (
    <div className={classes['container']}>
      <select
        className={`${classes['container__field']} ${props.invalid ? classes['container__field--invalid'] : ''} ${props.className}`}
        name={props.name}
        onChange={props.onChange}
        value={props.value || ''}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
      >
        {props.placeholder ? (
          <option value="" hidden={props.hideDefaultValue} className={classes['defaultOption']}>
            {props.placeholder}
          </option>
        ) : null}
        {props.options?.map(
          (option: MSimpleSelectOption<OptionValue>): ReactNode => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
              className={classes['container_option']}
            >
              {option.label}
            </option>
          ),
        ) || null}
      </select>
      {props.invalid ? <CIcon className={classes['container__icon']} name="cil-exclamation-circle"></CIcon> : null}
    </div>
  );
};

MSimpleSelectView.displayName = 'MSimpleSelectView';
MSimpleSelectView.defaultProps = {};

export default MSimpleSelectView;
