const support = {
  header: 'Admin',
  howCreateUser: 'How do I create a new user?',
  explainHowCreateaUser:
    'To create a new user, log in to the web admin, click the” Users” tab in the left side menu, click “New User”, enter their details and click “Add” on the bottom.',

  howCreateaJob: 'How do I book reservations?',
  explainHowCreateaJob:
    'In order to book a reservation, select &apos;	reservations&apos;	 on the main menu and then tap on &apos;	New reservation&apos;	. Fill in the relevant information and tap on Book.',

  howCreateaJobManual: 'How do I route reservations manually?',
  explainHowCreateaJobManual:
    'On the left menu, tap on reservations. In order to route the desired reservations into optimized shared trips, select reservations by clicking the small square to the left of the reservations you wish to optimize (<b>Or</b> the square tothe left of All page/All Results) and then tap on route',

  howMakeReports: 'How do I view reports?',
  explainMakeReports: 'Advanced reporting can be found under the &quot;Reports&quot; tab in the portal.',

  whereGuide: 'Where can I find a user guide?',
  explaneWhereGuideStart: 'If you need some help getting started, you can take a look at our',
  explaneWhereGuideEnd: 'admin user guide',

  infoHeader: 'What if I need to speak to someone?',
  infoBody1: 'You can reach us on Tel:',
  infoBody2: 'or by email:',
  infoBody3: 'Our customer care team are available 24/7 to answer any questions',
};

export default support;
