import React from 'react';
import { OnChangeValue } from 'react-select';

import MSelectFilterView from './MSelectFilter.view';

import { MSelectOption } from 'views/MSelect/MSelect.model';
import { MTableAdvancedFilterProps } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';

interface Props<Id extends string, IsMulti extends boolean> extends MTableAdvancedFilterProps<IsMulti extends true ? Id[] : Id> {
  isMulti: IsMulti;
  options: MSelectOption<null, Id>[];
  isLoading?: boolean;
}

const MSelectFilter = <Id extends string, IsMulti extends boolean = false>(
  props: React.PropsWithChildren<Props<Id, IsMulti>>,
): JSX.Element => {
  const updateFiltersChange = (value: OnChangeValue<MSelectOption<null, Id>, IsMulti>) => {
    if (props.isMulti) {
      const inputValue = value as OnChangeValue<MSelectOption<null, Id>, true>;
      const newFilter = inputValue.map((option: MSelectOption<null, Id>) => option.id);

      props.onChange((newFilter.length <= 0 ? null : newFilter) as Parameters<typeof props.onChange>[0]);

      return;
    }

    const inputValue = value as OnChangeValue<MSelectOption<null, Id>, false>;
    const newFilter = inputValue?.id || null;

    props.onChange(newFilter as Parameters<typeof props.onChange>[0]);
  };

  const value = (props.isMulti ? props.value || [] : props.value || null) as IsMulti extends true ? Id[] : Id | null;

  return (
    <MSelectFilterView
      options={props.options}
      isMulti={props.isMulti}
      onChange={updateFiltersChange}
      value={value}
      isLoading={props.isLoading}
      placeholder={props.defaultPlaceholder}
    ></MSelectFilterView>
  );
};

MSelectFilter.displayName = 'MSelectFilter';

export default MSelectFilter;
