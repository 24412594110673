import React, { CSSProperties } from 'react';

import MButtonView from './MButton.view';

import { ThemeColors } from 'models/general';

interface Props {
  className?: string;
  color?: ThemeColors;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  block?: boolean;
  to?: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MButton: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MButtonView
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
      type={props.type}
      block={props.block}
      style={props.style}
      to={props.to}
    >
      {props.children}
    </MButtonView>
  );
};

MButton.displayName = 'MButton';
MButton.defaultProps = {};

export default MButton;
