import { useTranslation } from 'react-i18next';
import React from 'react';

import MServerTable, { RequestBody } from 'views/MTable/MServerTable/MServerTable';

import { BaseHistoryData } from 'models/history';
import { MColumnOptions } from 'views/MTable/Table.model';
import { IHistoryHeader, IMHistoryTableData } from './MHistory.model';
import classes from './MHistory.module.scss';
import { TableName } from 'utils/enums/table-page';
import { CancelTokenSource } from 'axios';

interface Props<T extends BaseHistoryData> {
  columns: string[];
  columnsOptions: MColumnOptions<IMHistoryTableData<T>>;
  created: IHistoryHeader;
  className?: string;
  fetch: (query: RequestBody, cancelToken: CancelTokenSource) => Promise<{ count: number; data: IMHistoryTableData<T>[] }>;
}

const HistoryView = <T extends BaseHistoryData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classes['container']}>
      <MServerTable
        className={`${classes['table']} ${props.className || ''}`}
        headerButtons={
          <div>
            <span>{t('components.history.table.headers.createdBy')}</span>: {props.created.createdBy},{' '}
            <span>{t('components.history.table.headers.createdAt')}</span>: {props.created.createdAt}
          </div>
        }
        fetch={props.fetch}
        columns={props.columns}
        columnsOptions={props.columnsOptions}
        rowClassName={classes['historyRow']}
        name={TableName.History}
        disableFilters
        disablePagination
      ></MServerTable>
    </div>
  );
};

HistoryView.displayName = 'HistoryView';
HistoryView.defaultProps = {};

export default HistoryView;
