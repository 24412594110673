import { IDriverFormAdditionalSettingsFields } from './DriversForm/DriverFormSections/DriversFormAdditionalSettings/DriversFormAdditionalSettings.model';
import { IDriverFormAddressDetailsFields } from './DriversForm/DriverFormSections/DriversFormAddressDetails/DriversFormAddressDetails.model';
import { IDriverFormAttributesFields } from './DriversForm/DriverFormSections/DriversFormAttributes/DriversFormAttributes.model';
import { IDriverFormPersonalDetailsFields } from './DriversForm/DriverFormSections/DriversFormPersonalDetails/DriversFormPersonalDetails.model';
import { IDriverFormSupplierDetailsFields } from './DriversForm/DriverFormSections/DriversFormSupplierDetails/DriversFormSupplierDetails.model';
import { IDriverFormVehicleDetailsFields } from './DriversForm/DriverFormSections/DriversFormVehicleDetails/DriversFormVehicleDetails.model';
import { IReasonDeactivation } from 'models/reasonDeactivation';

import DriverStatus from 'utils/enums/driver-status';
import Statuses from 'utils/enums/statuses';
import { UserRoles } from 'utils/enums/user-roles';

export enum FirstTripDistanceType {
  Miles = 'miles',
  KM = 'km',
}

export type IDriverTableData = {
  _id: string;
  email: string;
  car: string;
  name: string;
  firstName: string;
  lastName: string;
  status: Statuses;
  driverStatus?: DriverStatus;
  phone: string;
  phone2: string;
  image?: string;
  created?: string;
  supplier: string;
  availableCars?: string[];
  services: string[];
  areas: string[];
  unit: string;
  role: UserRoles.Driver;
  reasonDeactivation?: IReasonDeactivation;
  profileImage: string;
  address: string;
  firstTripDistance?: number;
  firstTripDistanceType?: FirstTripDistanceType;
  externalId: string;
  attributes: string[];
};

export type IDriverFormFields = IDriverFormPersonalDetailsFields &
  IDriverFormVehicleDetailsFields &
  IDriverFormAddressDetailsFields &
  IDriverFormAdditionalSettingsFields &
  IDriverFormSupplierDetailsFields &
  IDriverFormAttributesFields;
