const vehicles = {
  createNew: 'Novo',
  toast: {
    error: {
      badResponse: '[Veículo] resposta inválida do servidor',
      edit: {},
      create: {},
    },
    success: {
      edit: {
        resetSuccess: 'Com sucesso.',
      },
      create: {},
    },
  },
  table: {
    headers: {
      name: 'Nome',
      make: 'Fazer',
      model: 'Modelo',
      year: 'Ano',
      color: 'Cor',
      pax: 'Máximo de passageiros',
      minSeats: 'Mínimo de passageiros',
      supplier: 'Fornecedor',
    },
  },
  edit: {
    suspendButton: {
      actionActivate: 'Reativar',
      actionDeactivate: 'Compensar',
      suspendModal: {
        popupTitleActive: 'Motivo da Suspensão',
        popupTitleDeactivation: 'Veículo suspenso',
        popupTitleUnsuspend: 'Motivo do Recolhimento da Suspensão',
        popupSubTitleDeactivation: 'Selecione o motivo da suspensão',
        popupActionActive: 'Reativar Veículo',
        popupActionDeactivate: 'Veículo suspenso',
      },
    },
    activeStatusButton: {
      statusActive: 'Ativo',
      statusDeactivate: 'Inativa',
    },
    formTabs: {
      informationTab: 'Informação',
      notesTab: 'Observações',
      historyTab: 'Histórico',
    },
  },
  form: {
    information: {
      details: {
        header: 'Informações Gerais',
        fields: {
          name: 'Nome',
          unit: 'Local',
          year: 'Ano',
          make: 'Fazer',
          model: 'Modelo',
          color: 'Cor',
          code: 'Código',
          location: 'Localização',
          productID: 'Identificador do produto',
          licensePlate: 'Placa de Veículo',
        },
      },
      attributes: {
        header: 'Atributos',
        itemHeader: 'Atributo de Pontuação <1>{{name}</1> não utilizado',
      },
      mileageInspection: {
        header: 'Quilometragem e inspeção',
        fields: {
          mileage: 'Quilometragem',
          mileageLastInspection: 'Milhagem na última inspeção',
          lastInspectionDate: 'Data da última inspeção',
          nextInspectionDate: 'Próxima data da inspeção',
        },
      },
      capacity: {
        header: 'Capacidade:',
        fields: {
          pax: 'Máximo de passageiros',
          minSeats: 'Mínimo de passageiros',
        },
      },
      services: {
        header: 'Serviços',
      },
      suppliers: {
        header: 'Fornecedores',
      },
      insurance: {
        header: 'Seguro',
        fields: {
          typeOfInsurance1: 'Tipo de seguro 1',
          typeOfInsurance1_start: 'Data de início',
          typeOfInsurance1_end: 'Data de término',
          typeOfInsurance2: 'Tipo de seguro 2',
          typeOfInsurance2_start: 'Data de início',
          typeOfInsurance2_end: 'Data de término',
        },
      },
      dimensions: {
        header: 'Dimensões do Veículo',
      },
      getProductBtn: 'EXIBIR IDs de produtos GETT',
    },
    valdations: {
      name: 'Um nome é requerido',
      plateNumber: {
        matches: 'Máximo de passageiros deve ser um número',
        required: 'Máximo de passageiros é requerido',
      },
      service: 'Serviço com é necessário',
      pax: {
        matches: 'Pax deve ser um número',
        required: 'Pax é Necessário',
      },
      minSeats: {
        matches: 'O mínimo de Assentos deve ser um número',
        required: 'O mínimo de Assentos é Necessário',
      },
      model: 'Modelar com é obrigatório',
      color: 'Corar com é obrigatório',
      year: {
        matches: 'O ano deve ser um número',
        required: 'O Ano é Requerido',
      },
      licenseExpDate: 'A expiração da licença é obrigatória',
    },
  },
};

export default vehicles;
