import React from 'react';
import { MRadioOption } from './MRadioGroup.model';

import MRadioGroupView from './MRadioGroup.view';

interface Props {
  value: string;
  name: string;
  options: MRadioOption[];
  display?: 'col' | 'row';
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MRadioGroup: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MRadioGroupView options={props.options} name={props.name} value={props.value} display={props.display} onChange={props.onChange}>
      {props.children}
    </MRadioGroupView>
  );
};

MRadioGroup.displayName = 'MRadioGroup';
MRadioGroup.defaultProps = {};

export default MRadioGroup;
