import React from 'react';
import CIcon from '@coreui/icons-react';

import MCard from 'views/MCard/MCard';
import MCardBody from 'views/MCard/MCardBody/MCardBody';

import classes from './HomeAgentStatistic.module.scss';

interface Props {
  title: string;
  value: number;
}

const HomeAgentStatisticView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const fontSize = props.value.toString().length < 4 ? 80 : 240 / props.value.toString().length;

  return (
    <MCard className={classes['container']}>
      <MCardBody className={classes['body']}>
        <div className={classes['title']}>
          <CIcon name="cilTag" className={classes['icon']} />
          <span>{props.title}</span>
        </div>
        <div className={classes['content']} style={{ fontSize: fontSize }}>
          {props.value}
        </div>
      </MCardBody>
    </MCard>
  );
};

HomeAgentStatisticView.displayName = 'HomeAgentStatisticView';
HomeAgentStatisticView.defaultProps = {};

export default React.memo(HomeAgentStatisticView);
