import React from 'react';
import { FormikContextType, FormikValues } from 'formik';

import MFormContainerView from './MFormContainer.view';

interface Props<Fields extends FormikValues> {
  className?: string;
  isLoading?: boolean;
  formik: FormikContextType<Fields>;
  headerJSX?: JSX.Element;
}

const MFormContainer = <Fields extends FormikValues>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  return (
    <MFormContainerView className={props.className} formik={props.formik} isLoading={props.isLoading} headerJSX={props.headerJSX}>
      {props.children}
    </MFormContainerView>
  );
};

MFormContainer.displayName = 'MFormContainer';
MFormContainer.defaultProps = {};

export default MFormContainer;
