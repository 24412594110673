const resetPassword ={
  resetPasswordTitle: 'Reset your password',
  createPasswordTitle: 'Create your password',
  resetNotSuccess: 'Something went wrong. Please try again, or contact michael@moovex.com',
  resetPasswordBtn: 'Continue',
  passRequierments:
    'Your password needs to be at least 8 characters, include at least one uppercase letter, one special character and one number.',
  resetPasswordHint: 'Please enter a new password.',
  resetSuccessful: 'You have successfully changed your password',
  backToLogIn: 'Please back to login and sign in again',
  passProtect0: 'Password is required',
  passProtect1: 'Password is to short',
  passProtect2: 'This password doesn’t match. Try again.',
  passProtect3: 'Password should contain at least one uppercase letter, one special character and number',
  passProtect4: 'Please confirm new password',
  newPassword: 'New Password',
  reEnterPassword: 'Confirm your password',
};


export default resetPassword;