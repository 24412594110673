import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  value: string;
};

const ParentsTableCellsNameView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <Link to={`/parents/edit/${props.id}`}>{props.value}</Link>;
};

ParentsTableCellsNameView.displayName = 'ParentsTableCellsNameView';
ParentsTableCellsNameView.defaultProps = {};

export default ParentsTableCellsNameView;
