import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AppState } from '../models/app-store';
import * as fromConnections from './reducers/connections';
import * as fromSettings from './reducers/settings';

import * as fromSites from './reducers/sites';
import * as fromTrips from './reducers/trips';
import * as fromAuth from './reducers/auth';

import { watchAuth } from './sagas/auth';

import * as fromUI from './reducers/ui';

const composeEnhancers: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const rootReducer: Reducer<AppState> = combineReducers({
  auth: fromAuth.reducer,
  ui: fromUI.reducer,
  settings: fromSettings.reducer,
  connections: fromConnections.reducer,
  trips: fromTrips.reducer,
  sites: fromSites.reducer,
});

const storeRoot = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAuth);

export const store = storeRoot;
