import React from 'react';
import ReactDatePicker from 'react-datepicker';

import classes from './MDatetimePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerFormats } from 'utils/enums/time-format';

interface Props {
  datePickerRef: React.RefObject<ReactDatePicker>;
  className?: string;
  value?: Date | null;
  valid?: boolean;
  invalid?: boolean;
  name?: string;
  placeholder?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: DatePickerFormats;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
  showYearDropdown?: boolean;
}

const MDatetimePickerView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={props.className}>
      <ReactDatePicker
        dropdownMode="select"
        showYearDropdown={props.showYearDropdown}
        scrollableYearDropdown
        ref={props.datePickerRef}
        popperClassName={classes['popper']}
        className={'form-control' + (props.valid ? ' is-valid' : '') + (props.invalid ? ' is-invalid' : '')}
        autoComplete="off"
        name={props.name}
        placeholderText={props.placeholder}
        showTimeSelect={props.showTimeSelect}
        showTimeSelectOnly={props.showTimeSelectOnly}
        wrapperClassName="w-100"
        selected={props.value}
        timeFormat="HH:mm"
        onChange={props.onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        timeIntervals={props.timeIntervals}
        dateFormat={props.dateFormat || 'MMM d yyyy, h:mm'}
        disabled={props.disabled}
      ></ReactDatePicker>
    </div>
  );
};

MDatetimePickerView.displayName = 'MDatetimePickerView';

export default MDatetimePickerView;
