import React from 'react';
import { OnChangeValue } from 'react-select';

import MFormServerSelectView from './MFormServerSelect.view';

import { MFormFieldProps } from 'views/MForm/MForm.model';
import { ServerTableNames } from 'utils/enums/table-page';
import { MSelectOption } from 'views/MSelect/MSelect.model';
import { TableRequestIdentifiersKeys } from 'views/MServerSelect/MServerSelect.model';
import { BaseQuery } from 'views/MTable/Table.model';

interface Props<IsMulti extends boolean, Table extends ServerTableNames>
  extends MFormFieldProps<[OnChangeValue<MSelectOption, IsMulti>], IsMulti extends true ? string[] : string> {
  table: Table;
  field: TableRequestIdentifiersKeys<Table>;
  isMulti?: IsMulti;
  staticOptions?: MSelectOption[];
  unit?: string | null;
  filterIds?: string[];
  baseQuery?: BaseQuery;
  isClearable?: boolean;
  placeholder?: string;
}

const MFormServerSelect = <Table extends ServerTableNames, IsMulti extends boolean = false>(props: Props<IsMulti, Table>): JSX.Element => {
  return (
    <MFormServerSelectView
      table={props.table}
      field={props.field}
      value={props.value}
      isMulti={props.isMulti}
      name={props.name}
      staticOptions={props.staticOptions}
      valid={props.valid}
      invalid={props.invalid}
      unit={props.unit}
      filterIds={props.filterIds}
      baseQuery={props.baseQuery}
      isClearable={props.isClearable}
      placeholder={props.placeholder}
      onChange={props.onChange}
    ></MFormServerSelectView>
  );
};

MFormServerSelect.displayName = 'MFormServerSelect';
MFormServerSelect.defaultProps = {};

export default MFormServerSelect;
