import React from 'react';

interface Props {
  id?: string;
  name: string;
  checked: boolean;
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'];
  className?: string;
  disabled?: boolean;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const MRadioView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <input
      type="radio"
      id={props.id}
      value={props.value}
      name={props.name}
      checked={props.checked}
      className={props.className}
      disabled={props.disabled}
      onChange={props.onChange}
    />
  );
};

MRadioView.displayName = 'MRadioView';
MRadioView.defaultProps = {};

export default MRadioView;
