import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IGetRoadBlocksResponse } from 'models/response';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';
import { IRoadBlock } from 'models/roadBlocks';

class RoadBlocksHttp {
  private static route = '/';

  public async get(cancelTokenSource?: CancelTokenSource): Promise<IRoadBlock[]> {
    return serverAxios
      .post(
        RoadBlocksHttp.route,
        {
          act: 'find',
          col: 'roadblocks',
        },
        {
          cancelToken: cancelTokenSource?.token,
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((response: AxiosResponse<IGetRoadBlocksResponse>) => {
        return response.data.data.map((roadBlock) => {
          return {
            id: roadBlock._id,
            name: roadBlock.name,
            lat: roadBlock.lat,
            lon: roadBlock.lon,
          };
        });
      });
  }

  public async createMany(data: Partial<IRoadBlock>[]): Promise<void> {
    return serverAxios
      .post(
        RoadBlocksHttp.route,
        {
          act: 'insert',
          col: 'roadblocks',
          data,
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }

  public async delete(ids: string[]): Promise<void> {
    return serverAxios
      .post(
        RoadBlocksHttp.route,
        {
          act: 'delete',
          col: 'roadblocks',
          query: { _id: { $in: ids } },
        },
        {
          timeout: HttpTimeoutPriority.Low,
        },
      )
      .then(() => {
        return;
      });
  }
}

export default RoadBlocksHttp;
