const assignPriority = {
  table: {
    headerButtons: {
      create: {
        label: 'Criar',
      },
      delete: {
        label: 'Remover',
        modal: {
          header: 'Excluir Atribuir prioridade',
          body: 'Você está prestes a excluir {{selected}} Atribuir registros de prioridade',
          confirm: {
            toasts: {
              failure: 'Ocorreu um erro ao excluir a atribuição de prioridade.',
            },
          },
        },
      },
    },
    headers: {
      attributes: 'Nome',
      assignPriority: 'Atribuir prioridade',
      site: 'Local',
    },
  },
  form: {
    create: {
      submit: {
        toasts: {
          success: 'Atribuir prioridade criada com sucesso!',
          failure:
            'Ocorreu um erro ao criar uma nova prioridade de atribuição.',
        },
      },
    },
    update: {
      submit: {
        toasts: {
          success: 'Atribuição de prioridade atualizada com sucesso!',
          failure:
            'Ocorreu um erro ao atualizar uma nova prioridade atribuída.',
        },
      },
      populate: {
        toasts: {
          failure:
            'Ocorreu um erro ao obter os dados de atribuição de prioridade.',
        },
      },
    },
    sections: {
      site: {
        header: 'Local',
      },
      details: {
        header: 'Detalhes',
        fields: {
          assignPriority: {
            label: 'Atribuir prioridade',
          },
          attributes: {
            label: 'Atributos',
          },
        },
      },
    },
  },
};

export default assignPriority;
