const rates = {
  toast: {
    error: {
      badResponse: '[Taxa] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  createNew: 'Novo',
  total: 'Total: {{amount}}',
  additions: {
    newAddition: 'Nova adição',
    form: {
      formButtons: {
        cancel: 'Cancelar',
        addAddition: 'Adicionar Adicionar',
      },
      fields: {
        name: {
          label: 'Nome',
          validations: {
            required: 'Um nome é requerido',
          },
        },
        valueType: {
          label: 'Tipo do Valor',
          valueOptions: {
            price: 'Preço',
            total: 'Total',
            percent: 'Porcentagem',
          },
        },
        value: {
          label: 'Valor',
          validations: {
            required: 'O valor é obrigatório',
          },
        },
        startTime: {
          label: 'Horário de início',
        },
        endTime: {
          label: 'Fim do tempo',
        },
        startDate: {
          label: 'Data de início',
        },
        endDate: {
          label: 'Data de término',
        },
        weekDays: {
          label: 'Dias da Semana',
        },
        priority: {
          label: 'Prioridade',
        },
      },
    },
  },
  create: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Taxa criada!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar a taxa',
    },
  },
  edit: {
    modalSuccess: {
      header: 'Resultado',
      body: 'Taxa atualizada!',
    },
    modalError: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar a taxa',
    },
  },
  form: {
    blocks: {
      details: 'Detalhes',
      mileage: 'Adicione quilometragem',
      conditions: 'Condições',
      additions: 'Adições',
    },
    fields: {
      unit: {
        label: 'Local',
        validations: {
          required: 'O local é obrigatório',
        },
      },
      code: {
        label: 'Código',
        validations: {
          required: 'O código é obrigatório',
        },
      },
      from: {
        label: 'De',
        validations: {
          required: 'De é obrigatório',
        },
      },
      to: {
        label: 'Para',
        validations: {
          required: 'Para é requerido',
        },
      },
      biDir: {
        label: 'Bidirecional',
        validations: {},
      },
      service: {
        label: 'Serviço',
        validations: {
          required: 'A assistência é necessária',
        },
      },
      price: {
        label: 'Preço',
        validations: {},
      },
      date1: {
        label: 'Data de início',
        validations: {},
      },
      date2: {
        label: 'Data de término',
        validations: {},
      },
      hour1: {
        label: 'Horário de início',
        validations: {},
      },
      hour2: {
        label: 'Fim do tempo',
        validations: {},
      },
      distanceFrom: {
        label: 'Distância de',
      },
      distanceTo: {
        label: 'Distância até',
      },
      weekDays: {
        label: 'Dias da Semana',
      },
    },
  },
};

export default rates;
