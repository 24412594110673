import React, { CSSProperties } from 'react';

import MCardView from './MCard.view';

interface Props {
  elemRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
}

const MCard: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MCardView className={props.className} style={props.style} elemRef={props.elemRef}>
      {props.children}
    </MCardView>
  );
};

MCard.displayName = 'MCard';
MCard.defaultProps = {};

export default MCard;
