import { OnChangeValue } from 'react-select';
import React from 'react';

import MSelect from 'views/MSelect/MSelect';

import classes from './MSelectFilter.module.scss';
import { MSelectOption } from 'views/MSelect/MSelect.model';

type Props<Id extends string, IsMulti extends boolean> = {
  isMulti: IsMulti;
  options: MSelectOption[];
  isLoading?: boolean;
  placeholder?: string;
  value: IsMulti extends true ? Id[] : Id | null;
  onChange: (value: OnChangeValue<MSelectOption<null, Id>, IsMulti>) => void;
};

const MSelectFilterView = <Id extends string, IsMulti extends boolean = false>(props: Props<Id, IsMulti>): JSX.Element => {
  return (
    <div className={classes['container']}>
      <MSelect
        isLoading={props.isLoading}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        onChange={props.onChange}
        options={props.options}
        value={props.value}
      ></MSelect>
    </div>
  );
};

MSelectFilterView.displayName = 'MSelectFilterView';

export default MSelectFilterView;
