import React from 'react';
import { CIcon } from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';

import MInput from 'views/MInput/MInput';

import classes from './MSearchInput.module.scss';

interface Props {
  value?: string;
  placeholder?: string;
  size?: 's' | 'm' | 'l';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MSearchInputView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${classes['container']} ${props.value ? classes['container--filled'] : ''}  ${classes[`container--${props.size || 's'}`]}`}
    >
      <CIcon className={classes['container__icon']} name="cis-search" size="sm" />
      <MInput
        className={classes['container__input']}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder || t('common.table.generalSearch')}
      />
    </div>
  );
};

MSearchInputView.displayName = 'MSearchInputView';
MSearchInputView.defaultProps = {};

export default MSearchInputView;
