const areas = {
  toast: {
    error: {
      badResponse: '[Area] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  create: {
    createBtn: 'New',
    successModal: {
      header: 'Success',
      body: 'Area Created!',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Create Area',
    },
    validations: {
      name: 'Name is required',
      type: 'Type is required',
    },
  },
  delete: {
    deleteBtn: 'Delete',
    deleteModal: {
      header: 'Delete Areas?',
      body: 'You are about to delete {{number}} areas',
    },
  },
  edit: {
    successModal: {
      header: 'Success',
      body: 'Area Updated!',
    },
    errorModal: {
      header: 'Error Occourd',
      body: 'Could Not Update Area',
    },
    validations: {
      name: 'Name is required',
      type: 'Type is required',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Details',
        fields: {
          showOtherAreas: 'Show Other Areas',
          name: 'Name',
          type: 'Type',
        },
      },
      map: {
        header: 'Map',
      },
    },
  },
};

export default areas;
