const forgotPassword = {
  forgotYourPassword: 'Esqueceu sua senha?',
  backToLogin: 'Continuar para login',
  sendPassword: 'Enviar senha',
  emailHint: 'O endereço de e-mail que você recebeu do seu administrador',
  noWorries:
    'Não há problemas! Por favor, digite seu e-mail e enviaremos um link para redefinir sua senha',
  PleaseEnterEmailAddress: 'Por favor, digite seu endereço de e-mail',
  thankYou: 'Obrigado!',
  forgotPassword: 'Esqueceu a senha',
  successHint:
    'Se o e-mail que você digitou já existe em nosso sistema, enviaremos um link para redefinir sua senha em alguns segundos',
  emailAddress: 'Endereço de e-mail',
  redirect: 'Você será redirecionado em',
  seconds: ' segundos',
  emailRequires: 'E-mail é obrigatório',
  invalidEmail: 'E-mail é inválido',
};

export default forgotPassword;
