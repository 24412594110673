const history = {
  table: {
    headers: {
      createdBy: 'Created By',
      createdAt: 'Created At',
      date: 'Date',
    },
  },
};

export default history;
