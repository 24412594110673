import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useSystemTemplate from './use-system-template';

type TranslateFunc = (key: string,templete?: boolean) => string;

const useTranslationByTemplate = (): TranslateFunc => {
  const { t } = useTranslation();

  const systemTemplate = useSystemTemplate();

  return useCallback(
    (key: string,template?: boolean) => {

      if(template){
        return t(`${key}.templates.${systemTemplate}`);
      }

      return t(key);
    },
    [t, systemTemplate],
  );
};

export default useTranslationByTemplate;
