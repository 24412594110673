const timeline ={
  filters: {
    status: {
      placeholder: 'Status',
      options: {
        new: 'New',
        in_route: 'In Route',
        sent: 'Sent',
        approved: 'Approved',
        done: 'Done',
        canceled: 'Canceled',
        picking_up: 'Picking Up',
      },
    },
    driver: 'Driver',
    vehicle: 'Vehicle',
  },
  alerts: {
    etaChanged: 'ETA to stops {{stopsIds}} of trip {{tripId}} has been updated - Driver has been notified by the system.',
  },
  empty: 'No Trips At {{filter}}',
  driverInfo:{
    phoneNumber: 'Phone Number',
    lettersLeft:'Letters Left',
    sendMessage:'Send Message',
  },
};

export default timeline;