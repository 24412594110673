import CIcon from '@coreui/icons-react';
import React from 'react';

import classes from './MInput.module.scss';

interface Props {
  innerRef?: React.RefObject<HTMLInputElement>;
  name?: string;
  valid?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  value?: string | number;
  type?: string;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  placeholder?: string;
  autoComplete: string;
  autoFocus?: boolean;
  defaultValue?: string;
  pattern?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (value: React.ClipboardEvent<HTMLInputElement>) => void;
}

const MInputView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${classes['container']} ${props.invalid ? classes['container--invalid'] : ''} ${props.className || ''}`.trim()}>
      <input
        ref={props.innerRef}
        name={props.name}
        disabled={props.disabled}
        value={props.value}
        type={props.type}
        className={classes['container__field']}
        min={props.min}
        max={props.max}
        step={props.step}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        defaultValue={props.defaultValue}
        pattern={props.pattern}
        onInput={props.onInput}
        onFocus={props.onFocus}
        autoFocus={props.autoFocus}
        onPaste={props.onPaste}
        onBlur={props.onBlur}
        onChange={props.onChange}
      />
      {props.invalid ? <CIcon className={classes['container__icon']} size="md" name="cil-exclamation-circle"></CIcon> : null}
    </div>
  );
};

MInputView.displayName = 'MInputView';
MInputView.defaultProps = {};

export default MInputView;
