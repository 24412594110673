import React from 'react';
import { TableToggleRowsSelectedProps } from 'react-table';

import TableCheckbox from 'views/MTable/TableCheckbox/TableCheckbox';

interface Props extends TableToggleRowsSelectedProps {
  disableSelection?: boolean;
  toggleRowsSelectedProps: TableToggleRowsSelectedProps;
}

const MTableCellExpandCheckView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <TableCheckbox {...props.toggleRowsSelectedProps} disabled={props.disableSelection}></TableCheckbox>;
};

MTableCellExpandCheckView.displayName = 'MTableCellExpandCheckView';
MTableCellExpandCheckView.defaultProps = {};

export default MTableCellExpandCheckView;
