import React, { useCallback, useState } from 'react';
import { FormikContextType } from 'formik';
import { IParentsFormLinkStudentFeilds } from './ParentsFormLinkStudent.model';
import MButton from 'views/Buttons/MButton/MButton';
import MSelectTable from 'views/MSelectTable/MSelectTable';

import classes from './ParentsFormLinkStudent.module.scss';
import { TableName } from 'utils/enums/table-page';
import { BaseQuery, MColumnOptions } from 'views/MTable/Table.model';
import { useTranslation } from 'react-i18next';
import { Filters } from 'react-table';
import { IStudentTableData } from 'pages/Parents/ParentsTable/ParentsTable.model';
import { IJobLinkedProfilesTableData } from 'pages/Jobs/JobForm/JobFormSections/JobFormLinkedProfiles/JobFormLinkedProfiles.model';


type Props<Fields extends IParentsFormLinkStudentFeilds> = {
  columns: string[];
  showOnlyWithGeneralFilter?: boolean;
  name?: TableName;
  baseQuery?: BaseQuery;
  generalFilterColumns?: string[];
  form: FormikContextType<Fields>;
  columnsOptions?: MColumnOptions<IJobLinkedProfilesTableData>;
  buttonTitle: string;
  disabled?: boolean; 
  data: Partial<IStudentTableData>[] | null;
  onSelectMany: (row: Partial<IStudentTableData>[]) => void;
  onChooseItems: () => void;
};

const ParentsFormLinkStudentView = <Fields extends IParentsFormLinkStudentFeilds>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {
  const { t } = useTranslation();
  const [generalFilterState, setGeneralFilterState] = useState<string>('');

  const filtersChange = useCallback(
    (_: Filters<IStudentTableData>, generalFilter: string) => {
      setGeneralFilterState(generalFilter);
    },
    [setGeneralFilterState],
  );

  const chooseItems = () => {
    props.onChooseItems();
    setGeneralFilterState('');
  };


  return (
    <React.Fragment>
      <MSelectTable
        showOnlyWithGeneralFilter={props.showOnlyWithGeneralFilter}
        columns={props.columns}
        showNoResults
        baseQuery={props.baseQuery}
        hideFooter
        name={props.name}
        generalFilterPlaceholder={t('parents.form.sections.linkStudent.searchPlaceholder')}
        generalFilterColumns={props.generalFilterColumns}
        columnsOptions={props.columnsOptions}
        isMulty
        onSelectMany={props.onSelectMany}
        onFiltersChange={filtersChange}
        generalFilter={generalFilterState}
        data={props.data}
        className={classes['curserPointer']}
      ></MSelectTable>

      <div className={classes['buttonWrapper']}>
        <MButton disabled={props.disabled} onClick={chooseItems} className={classes['button']} type="button" color="primary">
          {props.buttonTitle}
        </MButton>
      </div>
    </React.Fragment>
  );
};

ParentsFormLinkStudentView.defaultProps = {};
ParentsFormLinkStudentView.displayName = 'ParentsFormLinkStudentView';

export default ParentsFormLinkStudentView;
