const notes = {
  title: 'Title',
  details: 'Details',
  content: 'Content',
  showForm: 'New Note',
  to: { label: 'To', validation: 'To is required' },
  addNote: 'Add',
  cancel: 'Cancel',
  save: 'Save',
  createdAt: 'Created At: ',
  createdBy: 'Created By: ',
  noteType: 'Note Type ',
  typeNewNote: 'Type in a new note...',
  dropDownIcons: {
    pinToTop: 'Pin To Top',
    unpinFromTop: 'Unpin from top',
    EditUpdate: 'Edit update',
    DeleteUpdate: 'Delete update fro evryone',
  },
  edit: {
    toast: {
      error: {
        delete: 'Note did not removed',
        add: 'Note did not added',
        update: 'Note did not updated',
        pin: 'Note did not pinned to top',
        unpin: 'Note did not unpinned from top',
      },
    },
  },
};

export default notes;
