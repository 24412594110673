export enum TimeFormat {
  ServerFormatDateTime = 'YYYY-MM-DD HH:mm', //hh - 12hours am/pm format, HH - 24hours format
  Date = 'YYYY-MM-DD',
  FullDateTime = 'YYYY-MM-DD HH:mm:ss',
  FullDateTimeMonthName = 'yyyy MMM D, HH:mm',
  FullDateTimeMonthNameFullTime = 'yyyy MMM D, HH:mm:ss',
  FullDateTimeMonthNameX2 = 'DD MMM YYYY HH:mm',
  FullDateTimeShortYear = 'YY-MM-DD HH:mm:ss',
  FullDateTimeNoYear = 'DD/MM HH:mm',
  Time = 'HH:mm',
  PartialDate = 'DD.MM',
  CalenderDate = 'DD/MM/YYYY',
  CalenderDateNoYear = 'DD/MM',
  MonthNamedDateTime = 'MMM DD hh:mm',
  MonthDayYear = 'MMM d yyyy, HH:mm',
  ServerHolidayDateFormat = 'YYYY-MM-DD',
}

export enum DatePickerFormats {
  Date = 'yyyy-MM-dd',
  MonthDayYearNoHour = 'MMM dd yyyy',
  MFormDate = 'MM/dd/yyy',
  MFormDateTime = 'MM/dd/yyy HH:mm',
  MTableFooterDateTime = 'MM/DD/yyy HH:mm',
  MonthDayYear = 'MMM d yyyy, HH:mm',
  Time = 'HH:mm',
}

export enum RangeInputType {
  Start = 'start',
  End = 'end',
}
