import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MTableAdvancedServer from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer';
import MButton from 'views/Buttons/MButton/MButton';

import { MTableAdvancedColumn } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';
import { IParentsTableData, ParentsTableColumns } from './ParentsTable.model';
import { TableName } from 'utils/enums/table-page';
import { UserRoles } from 'utils/enums/user-roles';
import { MTableAdvancedServerRef } from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';
import { BaseQuery } from 'views/MTable/Table.model';
import classes from './ParentsTable.module.scss';

type Props = {
  numSelectedRows: 'ALL' | number;
  selectedRows: IParentsTableData['_id'][];
  tableRef: React.RefObject<MTableAdvancedServerRef>;
  columns: MTableAdvancedColumn<IParentsTableData, ParentsTableColumns, keyof IParentsTableData | undefined>[];
  onSelectionChange: (items: IParentsTableData['_id'][], allResultsQuery: BaseQuery | false) => void;
};

const ParentsTableView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <MTableAdvancedServer
      ref={props.tableRef}
      headerButtons={
        <Link key="create" to="/parents/create">
          <MButton color="primary">{t('parents.table.header.createBtn')}</MButton>
        </Link>
      }
      deleteButton
      name={TableName.Users}
      columns={props.columns}
      baseQuery={{ role: UserRoles.Parent }}
      hideDateNavigator
      getRowId={(row: IParentsTableData) => row._id}
      onSelectionChange={props.onSelectionChange}
      selectedRows={props.selectedRows}
      className={classes['tableView']}
    ></MTableAdvancedServer>
  );
};

ParentsTableView.defaultProps = {};
ParentsTableView.displayName = 'ParentsTableView';

export default ParentsTableView;
