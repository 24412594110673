const users = {
  createNew: 'Novo',
  toast: {
    error: {
      badResponse: '[usuário] resposta inválida do servidor',
      create: {},
      edit: {
        change: 'Senha anterior incorreta',
      },
    },
    success: {
      delete: {
        default: '[Usuário] removido',
      },
      create: {
        default: 'O usuário foi criado com sucesso.',
      },
      edit: {
        change: 'Senha alterada',
        default: 'Usuário atualizado.',
      },
      reset: {
        default: 'O e-mail de Redefinição de Senha foi enviado com sucesso.',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Ocorreu um Erro',
      modalAleadyExistBody:
        'O e-mail já existe. Por favor, tente um e-mail diferente.',
    },
    modalErrorRequest: {
      header: 'Proibido',
      body: 'Você não tem permissões para realizar esta ação. Entre em contato com um administrador.',
    },
    valdations: {
      firstName: 'Primeiro Nome é obrigatório',
      lastName: 'Sobrenome é obrigatório',
      phone: 'O número de telefone é obrigatório',
      email: {
        required: 'E-mail é obrigatório',
        email: 'Endereço de e-mail inválido',
      },
      role: {
        required: 'A função é obrigatória',
      },
      supplier: {
        required: 'Requer-se fornecedor',
      },
    },
  },
  edit: {
    modalRemoveConfirm: {
      header: 'Aviso!',
      body: 'Você tem certeza que deseja excluir este usuário?',
      modalRemoveConfirmBtn: 'Remover',
    },
    formTabs: {
      informationTab: 'Informação',
      notesTab: 'Observações',
      historyTab: 'Histórico',
    },
    suspendButton: {
      actionActivate: 'Reativar',
      actionDeactivate: 'Compensar',
      suspendModal: {
        popupTitleActive: 'Motivo da Suspensão',
        popupTitleDeactivation: 'Suspender usuário',
        popupTitleUnsuspend: 'Motivo do Recolhimento da Suspensão',
        popupSubTitleDeactivation: 'Selecione o motivo da suspensão',
        popupActionActive: 'Reativar usuário',
        popupActionDeactivate: 'Suspender usuário',
      },
    },
    activeStatusButton: {
      statusActive: 'Ativo',
      statusDeactivate: 'Inativa',
      statusUnconfirmed: 'Não confirmado',
    },
  },
  table: {
    headers: {
      name: 'Nome',
      email: 'E-mail',
      phone: 'Telefone',
      status: 'Status',
      role: 'Função',
    },
  },
  form: {
    selectOption: {
      select: 'Selecione',
    },
    information: {
      details: {
        header: 'Detalhes pessoais',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          name: 'Nome',
          phone: 'Telefone',
          email: 'E-mail',
          site: 'Local',
        },
      },
      roles: {
        header: 'Função',
        fields: {
          role: 'Função',
        },
      },
      actions: {
        header: 'Ações',
        buttons: {
          delete: 'Remover',
          resetPassword: 'Redefinir Senha',
          changePassword: 'Alterar senha',
        },
      },
    },
    valdations: {
      firstName: 'Primeiro Nome é obrigatório',
      lastName: 'Sobrenome é obrigatório',
      phone: 'O número de telefone é obrigatório',
      supplier: 'Requer-se fornecedor',
      role: 'A função é obrigatória',
      email: {
        required: 'E-mail é obrigatório',
        email: 'Endereço de e-mail inválido',
      },
    },
  },
  userRoles: {
    superadmin: 'Superusuário',
    admin: 'Administrador',
    supplier: 'Fornecedor',
    dispatchadmin: 'Administrador de despacho',
    dispatcher: 'Despachante',
    superagent: 'Superagente',
    agent: 'Agente',
    accountingadmin: 'Administrador de Contabilidade',
    accountent: 'Contabilista',
    driver: 'Motorista',
    passenger: 'Passageiro',
  },
};

export default users;
