import React, { useState, useMemo } from 'react';
import { FormikContextType } from 'formik';
import { IParentsFormLinkStudentFeilds } from './ParentsFormLinkStudent.model';
import { useTranslation } from 'react-i18next';
import { BaseQuery, MColumnOptions } from 'views/MTable/Table.model';
import { UserRoles } from 'utils/enums/user-roles';
import { TableName } from 'utils/enums/table-page';
import { IStudentTableData } from 'pages/Parents/ParentsTable/ParentsTable.model';
import ParentsFormLinkStudentView from './ParentsFormLinkStudent.view';
import MFormCard from 'views/MForm/MFormCard/MFormCard';


type Props<Fields extends IParentsFormLinkStudentFeilds> = {
  form: FormikContextType<Fields>;
};

const ParentsFormLinkStudent = <Fields extends IParentsFormLinkStudentFeilds>(props: React.PropsWithChildren<Props<Fields>>): JSX.Element => {


  const [selectedParentsToAddState, setSelectedParentsToAddState] = useState<Partial<IStudentTableData>[] | null>(null);
  const [selectedParentsToRemoveState, setSelectedParentsToRemoveState] = useState<Partial<IStudentTableData>[] | null>(null);
  const [selectedRowState, setSelectedRowState] = useState<Partial<IStudentTableData>[] | null>(null);

  const { t } = useTranslation();

  const baseQuery: BaseQuery = { role: { $in: [UserRoles.Passenger] }, email: { $nin: props.form.values.studentEmails } };
  const columns = ['name', 'address', 'phone'];

  const columnsOptions: MColumnOptions<IStudentTableData> = useMemo(() => {
    return {
      name: {
        Header: t('parents.studentsTable.headers.name'),
        disableFilters: true,
      },
      address: {
        Header: t('parents.studentsTable.headers.address'),
        disableFilters: true,
      },
      phone: {
        Header: t('parents.studentsTable.headers.phone'),
        disableFilters: true,
      },
    };
  }, [t]);

  const columnsOptionsToRemove: MColumnOptions<IStudentTableData> = useMemo(() => {
    return {
      name: {
        Header: t('parents.studentsTable.headers.name'),
        disableFilters: true,
      },
      address: {
        Header: t('parents.studentsTable.headers.address'),
        disableFilters: true,
      },
      phone: {
        Header: t('parents.studentsTable.headers.phone'),
        disableFilters: true,
      },
      email: {
        Header: t('parents.studentsTable.headers.email'),
        disableFilters: true,
      },
    };
  }, [t]);

  const onAddParents = () => {
    const dataToAdd = (selectedParentsToAddState || []).map((item: Partial<IStudentTableData>) => item.email!);
    const allDate = [...dataToAdd, ...props.form.values.studentEmails];

    props.form.setFieldValue('studentEmails', allDate);
    setSelectedRowState(selectedParentsToAddState);
    setSelectedParentsToAddState(() => null);

  };

  const onRemoveParents = () => {
    const parentsToRemove = (selectedParentsToRemoveState || []).map((item: Partial<IStudentTableData>) => item.email!);

    const exitedPrents = props.form.values.studentEmails;

    const results = exitedPrents.filter((item: string) => {
      return !parentsToRemove.includes(item);
    });

    props.form.setFieldValue('studentEmails', results);
    setSelectedRowState(selectedParentsToRemoveState);
    setSelectedParentsToRemoveState(() => null);
  };

  return (
    <MFormCard header={t('parents.form.sections.linkStudent.header')}>
      <ParentsFormLinkStudentView
        name={TableName.Users}
        columns={columns}
        columnsOptions={columnsOptions}
        generalFilterColumns={columns}
        baseQuery={baseQuery}
        form={props.form}
        onSelectMany={(row: Partial<IStudentTableData>[]) => setSelectedParentsToAddState(() => row)}
        onChooseItems={onAddParents}
        buttonTitle={t('parents.form.sections.linkStudent.btnAddStudent')}
        disabled={!selectedParentsToAddState}
        data={selectedRowState}
      >
        {props.children}
      </ParentsFormLinkStudentView>

      {props.form.values.studentEmails.length ? (
        <ParentsFormLinkStudentView
          name={TableName.Users}
          columns={['name', 'address', 'phone', 'email']}
          columnsOptions={columnsOptionsToRemove}
          baseQuery={{ email: { $in: props.form.values.studentEmails } }}
          form={props.form}
          onSelectMany={(row: Partial<IStudentTableData>[]) => setSelectedParentsToRemoveState(() => row)}
          onChooseItems={onRemoveParents}
          buttonTitle={t('parents.form.sections.linkStudent.btnRemoveStudent')}
          showOnlyWithGeneralFilter={false}
          disabled={!selectedParentsToRemoveState}
          data={selectedRowState}
        >
          {props.children}
        </ParentsFormLinkStudentView>
      ) : null}
    </MFormCard>
  );
};

ParentsFormLinkStudent.defaultProps = {};
ParentsFormLinkStudent.displayName = 'ParentsFormLinkStudent';

export default ParentsFormLinkStudent;
