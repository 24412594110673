const tripReports = {
  tripsPaxReports: {
    tripId: 'ID de viagem',
    date: 'Data',
    status: 'Status',
    statuses: 'Status',
    drivers: 'Motoristas',
    vehicles: 'Veículos',
    from: 'De',
    to: 'Para',
    passengers: 'Passageiros',
    statusDone: 'OK',
    statusNew: 'Novo',
    statusCanceled: 'Cancelado',
    statusInRoute: 'Em rota',
    average: 'Médio',
    trips: 'Viagens',
    tripsPassengersSummary: 'Resumo das viagens/passageiros',
  },
};

export default tripReports;
