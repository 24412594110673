import React from 'react';
import { useTranslation } from 'react-i18next';
import MInfo from 'views/MInfo/MInfo';

import classes from './MFormFieldWrap.module.scss';

interface Props {
  name?: string;
  displayName?: JSX.Element | string;
  className?: string;
  error?: string | null;
  action?: JSX.Element | null;
  errorClass?: string;
  onClickClear?: () => void;
  information?: string;
}

const MFormFieldWrapView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      {props.displayName || props.action ? (
        <div className={classes['top']}>
          {props.displayName ? <label htmlFor={props.name}>{props.displayName}</label> : null}
          <div className={classes['actions']}>
            {props.onClickClear ? (
              <button className={classes['clear']} type="button" onClick={props.onClickClear}>
                {t('views.form.formFieldWrap.clearFieldBtn')}
              </button>
            ) : null}
            {props.information ? <MInfo txt={props.information} /> : null}
          </div>
          {props.action || null}
        </div>
      ) : null}

      {props.children}
      {props.error ? <span className={props.errorClass}>{props.error}</span> : null}
    </div>
  );
};

MFormFieldWrapView.displayName = 'MFormFieldWrapView';
MFormFieldWrapView.defaultProps = {};

export default MFormFieldWrapView;
