const holidayReport = {
  actions: {
    export: 'Exportar',
  },
  table: {
    headers: {
      code: 'Código',
      profile: 'Perfil',
      line: 'Linha',
      routingType: 'Tipo de Linha',
      direction: 'Direção',
      name: 'Nome',
      days: 'Dias da Semana',
      pax: 'Pessoal',
      supplier: 'Fornecedor',
    },
    cells: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
    },
    filters: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
      tripStatus: {
        options: {
          new: 'Novo',
          inroute: 'Em rota',
          sent: 'Enviado',
          approved: 'Aprovado',
          pickingUp: 'Recolhimento',
          done: 'OK',
          cancelled: 'Cancelado',
        },
      },
      routingType: {
        options: {
          bus: 'Autocarro',
          demand: 'Demanda',
          dynamic: 'Dinâmico',
        },
      },
    },
    footer: {
      lastUpdated: 'Última Atualização: {{lastUpdated}}',
      noLastUpdate: 'Desconhecido',
    },
  },
  tableSummary: {
    title: 'Relatórios de faturamento',
    headers: {
      trips: 'Viagens',
      reservations: 'Reservas',
      passengers: 'Passageiros',
      uniquePassengers: 'Passageiros únicos',
    },
  },
};

export default holidayReport;
