import CIcon from '@coreui/icons-react';
import { CPagination } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Table } from '@tanstack/react-table';

import MCard from 'views/MCard/MCard';
import MDateNavigator from 'views/MDateNavigator/MDateNavigator';
import MCheckBox from 'views/MCheckBox/MCheckBox';
import { MDateNavigatorDirection } from 'views/MDateNavigator/MDateNavigator.model';
import MSearchInput from 'views/MSearchInput/MSearchInput';
import MSimpleSelect from 'views/MSimpleSelect/MSimpleSelect';
import MButton from '../../../Buttons/MButton/MButton';
import MDropdownContainer from 'views/MDropdown/MDropdownContainer/MDropdownContainer';
import MTable from '../MTableAdvanced';

import classes from './MTableAdvancedServer.module.scss';
import { MTableAdvancedColumn, MTableAdvancedExpand, MTableAdvancedRowData, MTableAdvancedSorting } from '../MTableAdvanced.model';
import { IDropdownAction } from 'views/MDropdown/MDropdown.model';
import { MTableAdvancedServerFilterRender } from './MTableAdvancedServer.model';

type Props<
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
> = {
  className?: string;
  rowClassName?: string;
  tableRef?: React.RefObject<Table<T>>;
  columns: MTableAdvancedColumn<T, Id, (keyof T & string) | undefined>[];
  limit: number;
  data: T[];
  loading: boolean;
  pagesCount: number;
  hideGeneralFilter: boolean;
  page: number;
  allResults: boolean;
  count: number;
  sorting?: MTableAdvancedSorting<T>;
  disableFilters?: boolean;
  hideDateNavigator?: boolean;
  disablePagination?: boolean;
  showOnlyWithGeneralFilter?: boolean;
  deleteButton?: boolean;
  hideFooter?: boolean;
  hideSelection?: boolean;
  headerButtons?: JSX.Element | JSX.Element[] | null;
  selectedRow?: Partial<T>;
  selectedRows?: RowIdValue[];
  footerDataView?: JSX.Element | JSX.Element[];
  disableDeleteBtn?: boolean;
  generalFilterPlaceholder?: string;
  generalFilterValue?: string;
  hiddenColumns: Id[];
  filters: MTableAdvancedServerFilterRender[];
  expand?: MTableAdvancedExpand<T>;
  hideColumnSelect?: boolean;
  columnItems: IDropdownAction<boolean>[];
  allowHeaderMaxWidth?: boolean;
  onChangeColumnItem: (action: IDropdownAction<boolean, Id>, index: number) => void;
  onSortingChange?: (value: MTableAdvancedSorting<T>) => void;
  onSelectionChange?: (value: RowIdValue[]) => void;
  onDeleteBtnClick?: () => void;
  onRowClick?: (row: Row<T>) => void;
  onLimitChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onAllResultsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPageChange: (page: number) => void;
  onGeneralFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDateNavigatorClick?: (direction: MDateNavigatorDirection) => void;
  onClearButtonClick: () => void;
  getRowId?: (row: T) => RowIdValue;
  onShowDuplicates?: () => void;
};

const MServerTableView = <
  T extends MTableAdvancedRowData,
  Id extends string,
  RowIdValue extends { [Id in keyof T]: T[Id] extends string ? T[Id] : never }[keyof T],
>(
    props: React.PropsWithChildren<Props<T, Id, RowIdValue>>,
  ): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MCard className={[classes['container'], props.className].join(' ')}>
      <div className={classes['header']}>
        <div className={`${classes['headerSection']} ${classes['headerSection--left']}`}>
          {props.headerButtons}
          {!props.hideGeneralFilter ? (
            <MSearchInput
              value={props.generalFilterValue}
              size={props.showOnlyWithGeneralFilter ? 'l' : 's'}
              placeholder={props.generalFilterPlaceholder}
              onChange={props.onGeneralFilterChange}
            ></MSearchInput>
          ) : null}
          {props.filters.map((filter: MTableAdvancedServerFilterRender) => {
            return (
              <filter.Component
                key={filter.id}
                value={filter.value}
                onChange={filter.onChange}
                defaultPlaceholder={filter.defaultPlaceholder}
              ></filter.Component>
            );
          })}
        </div>
        <div className={classes['headerSection']}>
          {!props.hideDateNavigator ? (
            <MDateNavigator className={classes['dateSection']} onClick={props.onDateNavigatorClick}></MDateNavigator>
          ) : null}
          {props.deleteButton ? (
            <MButton type="button" color="transparent" onClick={props.onDeleteBtnClick} disabled={props.disableDeleteBtn}>
              <CIcon name="cil-trash" />
            </MButton>
          ) : null}
          {!props.hideColumnSelect && (
            <MDropdownContainer actions={props.columnItems} onSelect={props.onChangeColumnItem} type="checkbox">
              {(clickHandler: () => void) => {
                return (
                  <MButton type="button" color="transparent" onClick={clickHandler}>
                    <CIcon name="cil-eye" className={classes['settingsIcon']} />
                  </MButton>
                );
              }}
            </MDropdownContainer>
          )}
          {!props.disableFilters && (
            <MButton className={classes['action']} color="transparent" onClick={props.onClearButtonClick}>
              {t('views.button.text.clearFilter')}
            </MButton>
          )}
          {!props.disablePagination && (
            <div className={classes['numberOfRowsDisplay']}>
              <MSimpleSelect
                options={[
                  { value: 10, label: '10' },
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
                value={props.limit}
                onChange={props.onLimitChange}
              ></MSimpleSelect>
            </div>
          )}
        </div>
      </div>
      {!props.showOnlyWithGeneralFilter || props.generalFilterValue ? (
        <MTable
          ref={props.tableRef}
          className={classes['table']}
          data={props.data}
          columns={props.columns}
          sorting={props.sorting}
          hiddenColumns={props.hiddenColumns}
          onSortingChange={props.onSortingChange}
          onSelectionChange={props.onSelectionChange}
          loading={props.loading}
          expand={props.expand}
          selectedRow={props.selectedRow}
          hideSelection={props.hideSelection}
          rowClassName={props.rowClassName}
          onRowClick={props.onRowClick}
          getRowId={props.getRowId}
          selectedRows={props.selectedRows}
          allowHeaderMaxWidth={props.allowHeaderMaxWidth}
        ></MTable>
      ) : null}
      {!props.hideFooter && (
        <div className={classes['footer']}>
          <div className={classes['footerTotals']}>
            {!props.hideSelection && !props.disablePagination ? (
              <React.Fragment>
                <MCheckBox id="allResults" checked={props.allResults} onChange={props.onAllResultsChange}></MCheckBox>
                <label htmlFor="allResults">{t('common.table.footer.allResult').toString()}</label>
              </React.Fragment>
            ) : null}
            <span className={classes['total']}>
              {t('common.table.total').toString()}: {props.count}
            </span>
            {props.footerDataView}
            <div className={classes['btnDuplicates']} onClick={props.onShowDuplicates}>
              Show Duplicates
            </div>
          </div>

          {props.pagesCount > 1 && !props.disablePagination && !props.loading ? (
            <CPagination
              pages={props.pagesCount}
              limit={7}
              activePage={props.page}
              onActivePageChange={props.onPageChange}
              dots
              arrows
              doubleArrows
              addListClass={classes['innerPagination']}
            ></CPagination>
          ) : null}
        </div>
      )}
    </MCard>
  );
};

MServerTableView.displayName = 'MServerTableView';

export default MServerTableView;
