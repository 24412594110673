import { ISite } from 'models/site';

export enum SitesActions {
  SetViewSite = '[Sites] Set View Site',
  SetSites = '[Sites] Set Sites',
  AddSite = '[Sites] Add Sites',
}

export interface SetViewSite {
  type: SitesActions.SetViewSite;
  payload: ISite | null;
}

export interface SetSites {
  type: SitesActions.SetSites;
  payload: ISite[];
}

export interface AddSite {
  type: SitesActions.AddSite;
  payload: ISite;
}

export const setViewSite = (viewSite: ISite | null): SetViewSite => {
  return {
    type: SitesActions.SetViewSite,
    payload: viewSite,
  };
};

export const setSites = (sites:ISite[]): SetSites => {
  return {
    type: SitesActions.SetSites,
    payload: sites,
  };
};

export const addSite = (sites: ISite): AddSite => {
  return {
    type: SitesActions.AddSite,
    payload: sites,
  };
};

export type SiteTypes = SetViewSite | SetSites | AddSite;
