import React from 'react';

import MDropdown from '../MDropdown';

import { IDropdownAction, IDropdownType } from '../MDropdown.model';
import classes from './MDropdownContainer.module.scss';

interface Props<Actions, Id extends string | number | null> {
  show?: boolean;
  dropdownRef: React.RefObject<HTMLDivElement>;
  actions: IDropdownAction<Actions>[];
  type: IDropdownType;
  alignTo?: 'start' | 'end';
  onSelect: (action: IDropdownAction<Actions, Id>, index: number) => void;
}

const MDropdownContainerView = <Actions, Id extends string | number | null = null>(
  props: React.PropsWithChildren<Props<Actions, Id>>,
): JSX.Element => {
  return (
    <div
      className={`${classes['container']} ${props.alignTo === 'end' ? classes['container--alignEnd'] : classes['container--alignStart']}`}
    >
      {props.children}
      <MDropdown
        className={classes['dropdown']}
        ref={props.dropdownRef}
        actions={props.actions}
        show={props.show}
        type={props.type}
        onSelect={props.onSelect}
      ></MDropdown>
    </div>
  );
};

MDropdownContainerView.displayName = 'MDropdownContainerView';
MDropdownContainerView.defaultProps = {};

export default MDropdownContainerView;
