import { SessionStorage, SessionStorageTablesKeys } from './enums/storage';
import * as yup from 'yup';
import moment from 'moment';

import { TimeFormat } from './enums/time-format';
import { TableName } from './enums/table-page';

const tableCacheValidation = (tableName: string) => {
  return yup.object({
    filters: tablesFiltersValidation[tableName],
    generalFilter: yup.string(),
    limit: yup.number().min(0).required(),
    page: yup.number().min(0).required(),
    sort: yup.array().of(yup.object({ id: yup.string(), desc: yup.boolean() })),
  });
};

const tablesFiltersValidation = {
  [`table_${TableName.Jobs}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['date', 'status']),
      value: yup
        .mixed()
        .when('id', {
          is: 'status',
          then: yup
            .array()
            .of(yup.string().oneOf(['new', 'onhold', 'waiting', 'canceled', 'routing', 'routed', 'picked', 'dropped', 'noshow'])),
        })
        .when('id', {
          is: 'date',
          then: yup.array().of(
            yup.string().test('isMoment', '${path} must be a Moment object', function (value) {
              return moment.isMoment(moment(value, TimeFormat.ServerFormatDateTime));
            }),
          ),
        }),
    }),
  ),
  [`table_${TableName.Trips}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['date', 'status', 'service', 'supplier', 'driver']),
      value: yup
        .mixed()
        .when('id', {
          is: 'status',
          then: yup.array().of(yup.string().oneOf(['new', 'in route', 'sent', 'approved', 'picking up', 'done', 'canceled'])),
        })
        .when('id', {
          is: 'date',
          then: yup.array().of(
            yup.string().test('isMoment', '${path} must be a Moment object', function (value) {
              return moment.isMoment(moment(value, TimeFormat.ServerFormatDateTime));
            }),
          ),
        })
        .when('id', {
          is: 'service',
          then: yup.string(),
        })
        .when('id', {
          is: 'supplier',
          then: yup.string(),
        })
        .when('id', {
          is: 'driver',
          then: yup.string(),
        }),
    }),
  ),
  [`table_${TableName.Drivers}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['driverStatus', 'car']),
      value: yup
        .mixed()
        .when('id', {
          is: 'car',
          then: yup.string(),
        })
        .when('id', {
          is: 'driverStatus',
          then: yup.array().of(yup.string().oneOf(['offline', 'online', 'en_route', 'break'])),
        }),
    }),
  ),
  [`table_${TableName.Users}`]: yup.array().of(yup.object()),
  [`table_${TableName.Shuttles}`]: yup.array().of(yup.object()),
  [`table_${TableName.Cars}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['productID', 'code', 'unit']),
      value: yup
        .mixed()
        .when('id', {
          is: 'productID',
          then: yup.string(),
        })
        .when('id', {
          is: 'code',
          then: yup.string(),
        })
        .when('id', {
          is: 'unit',
          then: yup.string(),
        }),
    }),
  ),
  [`table_${TableName.DriverShifts}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['start']),
      value: yup.mixed().when('id', {
        is: 'start',
        then: yup.array().of(
          yup.string().test('isMoment', '${path} must be a Moment object', function (value) {
            return moment.isMoment(moment(value, TimeFormat.ServerFormatDateTime));
          }),
        ),
      }),
    }),
  ),
  [`table_${TableName.Shifts}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['status', 'direction', 'weekDays']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Rates}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['from', 'to']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Locations}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.array().of(yup.string().oneOf(['', 'airport'])),
    }),
  ),
  [`table_${TableName.Suppliers}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['name', 'status']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Services}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['profileType']),
      value: yup
        .array()
        .of(yup.string().oneOf(['retail', 'individual', 'wholesale', 'corporate', 'website', 'hotel', '3rdParty', 'tourOperator'])),
    }),
  ),
  [`table_${TableName.Profiles}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['status', 'type', 'rateType']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Areas}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Units}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.History}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.Fares}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.string(),
    }),
  ),
  [`table_${TableName.ExtraServices}`]: yup.array().of(
    yup.object({
      id: yup.string().oneOf(['type']),
      value: yup.string(),
    }),
  ),
};

const validationSchema = {
  [SessionStorage.Token]: yup.string().nullable(),
  [SessionStorage.SystemSpecs]: yup.string(),
  [`table_${TableName.Jobs}`]: tableCacheValidation(`table_${TableName.Jobs}`),
  [`table_${TableName.Shuttles}`]: tableCacheValidation(`table_${TableName.Shuttles}`),
  [`table_${TableName.Trips}`]: tableCacheValidation(`table_${TableName.Trips}`),
  [`table_${TableName.Drivers}`]: tableCacheValidation(`table_${TableName.Drivers}`),
  [`table_${TableName.Cars}`]: tableCacheValidation(`table_${TableName.Cars}`),
  [`table_${TableName.Users}`]: tableCacheValidation(`table_${TableName.Users}`),
  [`table_${TableName.DriverShifts}`]: tableCacheValidation(`table_${TableName.DriverShifts}`),
  [`table_${TableName.Shifts}`]: tableCacheValidation(`table_${TableName.Shifts}`),
  [`table_${TableName.Services}`]: tableCacheValidation(`table_${TableName.Services}`),
  [`table_${TableName.Rates}`]: tableCacheValidation(`table_${TableName.Rates}`),
  [`table_${TableName.Locations}`]: tableCacheValidation(`table_${TableName.Locations}`),
  [`table_${TableName.Suppliers}`]: tableCacheValidation(`table_${TableName.Suppliers}`),
  [`table_${TableName.ExtraServices}`]: tableCacheValidation(`table_${TableName.ExtraServices}`),
  [`table_${TableName.Profiles}`]: tableCacheValidation(`table_${TableName.Profiles}`),
  [`table_${TableName.Areas}`]: tableCacheValidation(`table_${TableName.Areas}`),
  [`table_${TableName.Fares}`]: tableCacheValidation(`table_${TableName.Fares}`),
  [`table_${TableName.Units}`]: tableCacheValidation(`table_${TableName.Units}`),
};

const load = async <T>(key: SessionStorage | SessionStorageTablesKeys): Promise<T> => {
  let data: unknown;
  const item: string | null = sessionStorage.getItem(key);

  try {
    data = !item ? item : JSON.parse(item);
  } catch (error) {
    data = item;
  }

  return await validationSchema[key].validate(data).then((data: unknown) => data as T);
};

const save = (key: string, state: Record<string, unknown> | string): void => {
  if (typeof state === 'object') {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem(key, serializedState);

    return;
  }

  sessionStorage.setItem(key, state);
};

const sess = { load, save };

export default sess;
