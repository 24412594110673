const settings = {
  parameters: {
    createParameter: 'Criar parâmetro',
    searchInputPlaceholder: 'Nome do parâmetro de pesquisa',
    editParameter: 'Editar parâmetro',
    parameters: 'Parâmetros',
    selectParameterMessage: 'Selecione um parâmetro',
    addNewParameter: 'Adicionar novo parâmetro',
    label: 'Legenda',
    name: 'Nome',
    category: 'Categoria',
    unit: 'Perfil',
    type: 'Tipo',
    actions: 'Ações',
    form: {
      name: 'Nome',
      label: 'Legenda',
      selectName: 'Selecione o nome',
      enterLabel: 'Inserir Legenda',
      profile: 'Perfil',
      selectProfile: 'Selecionar perfil',
      category: 'Categoria',
      selectCategory: 'Selecionar categoria',
      type: 'Tipo',
      selectType: 'Selecionar o tipo',
      value: 'Valor',
      enterValue: 'Insira o valor',
      showAdvanced: 'Mostrar avançado',
      hideAdvanced: 'Ocultar avançado',
    },
  },
};

export default settings;
