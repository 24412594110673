import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { serverAxios } from 'utils/http';
import { useSelector } from 'react-redux';

import HomeAgentTableViewsView from './HomeAgentTableViews.view';

import { useTranslation } from 'react-i18next';
import { HomeAgentTableViewsData, IGetRecentViewsJobsDataResponse } from './HomeAgentTableViews.model';
import { MColumnOptions } from 'views/MTable/Table.model';
import { IView } from 'models/view';
import { IGetJobsResponse, IGetViewsResponse } from 'models/response';
import { AppState } from '../../../../../models/app-store';
import { JobType } from 'models/job';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

const HomeAgentTableViews: React.FC = () => {
  const { t } = useTranslation();
  const userEmail = useSelector((state: AppState) => state.auth.user?.getEmail() || '');

  const columns = ['id', 'date', 'status', 'passenger'];

  const [tableDataState, setTableDataState] = useState<HomeAgentTableViewsData[]>([]);
  const [limitState, setLimitState] = useState<number>(5);

  useEffect(() => {
    serverAxios
      .post(
        '/',
        {
          act: 'find',
          col: 'views',
          sort: { time: -1 },
          query: { col: 'jobs', user: userEmail },
          project: { _id: 0, id: 1 },
        },
        {
          timeout: HttpTimeoutPriority.Medium,
        },
      )
      .then((res: AxiosResponse<IGetViewsResponse<'id'>>) => {
        const resu = res.data.data.map((jobView: Pick<IView, 'id'>) => jobView.id);
        const uniqueIds: string[] = Array.from(new Set(resu));

        return uniqueIds.slice(0, limitState);
      })
      .then((uniqueIds: string[]) => {
        return serverAxios.post('/', {
          queries: uniqueIds.map((jobId: string) => {
            return {
              act: 'find',
              col: 'jobs',
              query: { id: jobId },
            };
          }),
        });
      })
      .then((res: AxiosResponse<IGetRecentViewsJobsDataResponse>) => {
        if (res.data.results) {
          setTableDataState(() => {
            return res.data.results.reduce((acc: HomeAgentTableViewsData[], curr: IGetJobsResponse<JobType>) => {
              if (curr.data.length !== 0) {
                return [
                  ...acc,
                  {
                    id: curr.data[0].id,
                    date: curr.data[0].date,
                    name: curr.data[0].lastName,
                    status: curr.data[0]['status'] || '',
                    passenger: curr.data[0].passenger,
                  },
                ];
              }

              return acc;
            }, []);
          });
        }
      })
      .catch(console.error);
  }, [limitState, userEmail]);

  const columnsOptions = useMemo((): MColumnOptions<HomeAgentTableViewsData> => {
    return {
      id: {
        Header: t('agent.table.myRecentViewsTable.headers.id'),
        width: 100,
      },
      date: {
        Header: t('agent.table.myRecentViewsTable.headers.date'),
      },
      status: {
        Header: t('agent.table.myRecentViewsTable.headers.status'),
        width: 100,
      },
      passenger: {
        Header: t('agent.table.myRecentViewsTable.headers.passenger'),
      },
    };
  }, []);

  return (
    <HomeAgentTableViewsView
      data={tableDataState}
      limitState={limitState}
      columns={columns}
      columnsOptions={columnsOptions}
      onChangeDisplay={(event: React.ChangeEvent<HTMLInputElement>) => setLimitState(+event.target.value)}
    ></HomeAgentTableViewsView>
  );
};

HomeAgentTableViews.displayName = 'HomeAgentTableViews';
HomeAgentTableViews.defaultProps = {};

export default HomeAgentTableViews;
