const agent = {
  createNew: {
    newReservation: 'Nova reserva',
    quickReservation: 'Reserva rápida',
  },
  searchBox: {
    searchBtn: 'Buscar',
  },
  table: {
    myLastBookingsTable: {
      title: 'Minhas últimas reservas',
      headers: {
        id: 'Identificação',
        date: 'Data',
        status: 'Status',
        passenger: 'Passageiro',
      },
    },
    myRecentViewsTable: {
      title: 'Minhas Viagens Recentes',
      headers: {
        id: 'Identificação',
        date: 'Data',
        status: 'Status',
        passenger: 'Passageiro',
      },
    },
  },
  statistics: {
    lastWeek: {
      title: 'Última semana',
    },
    last24Hours: {
      title: 'Últimas 24 Horas',
    },
    myLastWeek: {
      title: 'Minha Última Semana',
    },
    myLast24Hours: {
      title: 'Minhas Últimas 24 Horas',
    },
  },
  weatherInfo: {
    title: 'Tempo',
    noNewMessages: 'Nenhuma mensagem nova',
    loadingWeather: 'Carregando o tempo...',
  },
  radioButton: {
    showTen: '10',
    showFive: '5',
  },
};

export default agent;
