import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { FilterValue, IdType } from 'react-table';

import sess from 'utils/session-storage';
import { TimeFormat } from 'utils/enums/time-format';
import { SessionStorageTablesKeys } from 'utils/enums/storage';
import {
  MTableAdvancedServerCacheEffectData,
  MTableAdvancedServerSessionData,
} from 'views/MTable/MTableAdvanced/MTableAdvancedServer/MTableAdvancedServer.model';
import { MTableAdvancedFilters, MTableAdvancedRowData } from 'views/MTable/MTableAdvanced/MTableAdvanced.model';

const useTableCacheEffectAdvanced = <TableFields extends MTableAdvancedRowData, Id extends string>(
  key: SessionStorageTablesKeys | null,
  effect: (data: MTableAdvancedServerCacheEffectData<TableFields, Id> | null) => void,
  dependencies: React.DependencyList,
): void => {
  const [cacheState, setCacheState] = useState<MTableAdvancedServerCacheEffectData<TableFields, Id> | null>();

  useEffect(() => {
    if (!key) {
      setCacheState(() => null);

      return;
    }

    sess
      .load<MTableAdvancedServerSessionData>(key)
      .then((sessionData: MTableAdvancedServerSessionData) => {
        const filtersObj = sessionData.filters.reduce(
          (acc: MTableAdvancedFilters<Id>, filter: { id: IdType<TableFields>; value: FilterValue }) => {
            let objectValue: FilterValue = filter.value;

            // Moment
            if (_.isString(filter.value)) {
              const dateObj = moment(filter.value, TimeFormat.ServerFormatDateTime);

              if (dateObj.isValid()) {
                objectValue = dateObj;
              }
            }

            // Array of moment
            if (_.isArray(filter.value)) {
              objectValue = filter.value.map((value: FilterValue) => {
                const dateObj = moment(value, TimeFormat.ServerFormatDateTime);

                if (dateObj.isValid()) {
                  return dateObj;
                }

                return value;
              });
            }

            return { ...acc, [filter.id]: objectValue };
          },
          {},
        );

        setCacheState({
          ...sessionData,
          filters: filtersObj,
          sort: sessionData.sort.length ? { id: sessionData.sort[0].id, direction: sessionData.sort[0].desc ? 'desc' : 'asc' } : false,
        });
      })
      .catch(() => setCacheState(null));
  }, [key]);

  useEffect(() => {
    if (typeof cacheState === 'undefined') {
      return;
    }

    effect(cacheState);
  }, [cacheState, ...dependencies]);
};

export default useTableCacheEffectAdvanced;
