const drivers = {
  createNew: 'New',

  toast: {
    error: {
      badResponse: '[Driver] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  table: {
    headers: {
      name: 'Name',
      driverStatus: 'Status',
      email: 'Email',
      vehicle: 'Vehicle',
      phoneNumber: 'Phone number',
      address: 'Address',
      firstTripDistance: '1st Trip Max Distance',
      action: 'Action',
      attributes: 'Attributes',
    },
    statusOptions: {
      online: 'Online',
      offline: 'Offline',
      break: 'Break',
      enRoute: 'En-Route',
    },
    viewOptions: {
      showSuspended: 'Show Suspended',
      showUnsuspended: 'Show Unsuspended',
    },
  },

  create: {
    modalSuccess: {
      header: 'Success',
      body: 'Driver Created!',
    },
    modalAleadyExist: {
      header: 'Error Occurred',
      body: 'Driver already exists!',
    },
    modalErrorRequest: {
      header: 'Error Occourd',
      body: 'Could Not Create Driver!',
    },
  },

  edit: {
    formTabs: {
      informationTab: 'Information',
      notesTab: 'Notes',
      historyTab: 'History',
    },
    toast: {
      toastResetSuccess: 'Reset Password email has been sent successfully.',
      toastError: 'Something went wrong, please try again.',
      toastRemoveDriverSuccess: 'Driver deleted successfully',
    },
    modalSuccess: {
      header: 'Success',
      body: 'Driver Updated!',
    },
    modalError: {
      header: 'Error Occourd',
      body: 'Could Not Update Driver',
    },
    driverStatusOptions: {
      activate: 'Unsuspend',
      statusSuspended: 'Suspended',
      deactivate: 'Suspend',
      statusOffline: 'Offline',
      statusOnline: 'Online',
      statusEnRoute: 'En-Route',
      statusBreak: 'Break',
      popUpOptions: {
        popupTitleActive: 'Suspend Reason',
        popupTitleDeactivation: 'Suspend Driver',
        popupTitleUnsuspend: 'Unsuspend Reason',
        popupSubTitleDeactivation: 'Select the reason for the suspenssion',
        popupActionActive: 'Unsuspend Driver',
        popupActionDeactivate: 'Suspend Driver',
      },
    },
    modalRemoveConfirm: {
      header: 'Warning!',
      body: 'Are you sure you want to delete this driver?',
      confirmBtn: 'Delete',
    },
  },
  deleteModal: {
    header: 'Delete Drivers',
    body: 'You are about to delete {{num}} drivers.',
  },

  forms: {
    information: {
      personalDetails: {
        header: 'Personal Details',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          name: 'Display Name',
          phone: 'Phone Number',
          email: 'Email Address',
          password: 'Password',
        },
      },
      vehicles: {
        header: 'Assigned Vehicles',
        fields: {
          available: 'Available Vehicles',
          current: 'Current Vehicle',
        },
      },
      address: {
        header: 'Starting Address',
        fields: {
          mainAddress: 'Main Address',
          firstTripDistance: '1st Trip Max Distance',
          alt: 'Alternative Address',
          firstTripDistanceType: {
            miles: 'In Miles',
            km: 'In KM',
          },
        },
      },
      settings: {
        header: 'Additional Settings',
        fields: {
          services: 'Services',
          areas: 'Areas',
          externalId: 'External ID',
          perAreaMatch: 'Per Area Match Score'
        },
        validation: {
          perAreaMatch: 'Can not enter more than 6 digits'
        },
        guide: {
          perAreaMatch:
          `0 = no consideration,
          Scoring between 1 to 999998 Will give the driver more score if all trip areas match the driver areas,
          999999 = Driver can only take trips from his areas (all trip stops, origin, destination, must be within the areas)`
        }
      },
      supplier: {
        header: 'Suppliers',
        fields: {
          supplier: 'Suppliers',
        },
      },
      attributes: {
        header: 'Attributes',
        itemHeader: 'Unused <1>{{name}}</1> Attribute Score',
      },
      buttons: {
        btnResetPassword: 'Reset Password',
        btnDelete: 'Delete',
      },
    },
  },
};

export default drivers;
