
const button = {
  text: {
    cancel: 'Cancel',
    clearFilter: 'Clear Filters',
    close: 'Close',
    create: 'Create',
    edit: 'Edit',
    save: 'Save',
  },
};

export default button;
