import React from 'react';

import MTooltip from 'views/MTooltip/MTooltip';

interface Props {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const MTextTooltipView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MTooltip text={props.text} className={props.className} style={props.style}>{props.children}</MTooltip>;
};

MTextTooltipView.displayName = 'MTextTooltipView';
MTextTooltipView.defaultProps = {};

export default MTextTooltipView;
