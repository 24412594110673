import { FormikContextType, FormikValues } from 'formik';
import { MFormFieldProps } from 'views/MForm/MForm.model';

// settings the minimum props for form field
const setFieldProps = <
  Fields extends FormikValues,
  Value,
  OnChangeData extends unknown[],
  FormattedValue = Value,
  OnChangeFormated = OnChangeData,
>(
    formik: FormikContextType<Fields>,
    name: (keyof Fields & string) | string,
    options: {
    onChange?: (...event: OnChangeData) => void;
    beforeChange?: (...event: OnChangeData) => OnChangeFormated; // manipulate value before handling change (used for mselect,changing value from selectoption to string)
    formatValue?: (value: Value) => FormattedValue;
    noValidation?: boolean;
    simplifySelect?: boolean;
  } = {},
  ): MFormFieldProps<OnChangeData, FormattedValue> => {
  const namePath = name.split('.');

  const sourceValue = namePath.reduce((acc: FormikValues, current: string) => (acc ? acc[current] : ''), formik.values) as Value;

  const props: MFormFieldProps<OnChangeData, FormattedValue> = {
    name,
    value: options.formatValue ? options.formatValue(sourceValue) : (sourceValue as unknown as FormattedValue),
    onChange(...event: OnChangeData) {
      options.onChange?.(...event);

      const formatedValue: OnChangeFormated | OnChangeData[0] = options.beforeChange ? options.beforeChange(...event) : event[0];
      // Handle as event

      if (typeof formatedValue === 'object' && formatedValue['target']) {
        formik.handleChange(formatedValue);

        return;
      }

      formik.setFieldValue(name, formatedValue);
      formik.handleChange('');
    },
  };

  if (!options.noValidation) {
    let error = false;
    let touched = false;

    try {
      error = !!namePath.reduce((acc: FormikValues, current: string) => acc[current], formik.errors);
    } catch {
      // error not finding errors
    }

    try {
      touched = !!namePath.reduce((acc: FormikValues, current: string) => acc[current], formik.touched);
    } catch {
      // error not finding tocuhed
    }

    // formik.touched[name] && !formik.errors[name];
    props.invalid = error && touched;
  }

  return props;
};

export default setFieldProps;
