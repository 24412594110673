import React, { useEffect, useMemo, useState } from 'react';
import axios, { CancelTokenSource, AxiosResponse } from 'axios';

import HomeAgentTableBookingsView from './HomeAgentTableBookings.view';

import { useTranslation } from 'react-i18next';
import { HomeAgentTableBookingsData } from './HomeAgentTableBookings.model';
import { MColumnOptions } from 'views/MTable/Table.model';
import { serverAxios } from 'utils/http';
import { IJob, JobType } from 'models/job';

const HomeAgentTableBookings: React.FC = () => {
  const { t } = useTranslation();

  const columns = ['id', 'date', 'status', 'passenger'];

  const [tableDataState, setTableDataState] = useState<HomeAgentTableBookingsData[]>([]);
  const [limitState, setLimitState] = useState<number>(5);

  useEffect(() => {
    let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

    serverAxios
      .post(
        '/',
        {
          col: 'jobs',
          act: 'myrecentjobs',
          limit: limitState,
        },
        {
          cancelToken: cancelTokenSource.token,
        },
      )
      .then((res: AxiosResponse<IJob<JobType>[]>) => {
        setTableDataState(() => {
          return res.data.map((historyData) => {
            return {
              id: historyData[0].id,
              date: historyData[0].date,
              name: historyData[0].lastName,
              status: historyData[0].status,
              passenger: historyData[0].passenger,
            };
          });
        });

        return;
      })
      .catch(console.error)
      .finally(() => {
        cancelTokenSource = null;
      });

    return () => {
      cancelTokenSource?.cancel();
    };
  }, [setTableDataState, limitState]);

  const columnsOptions = useMemo((): MColumnOptions<HomeAgentTableBookingsData> => {
    return {
      id: {
        Header: t('agent.table.myLastBookingsTable.headers.id'),
        width: 100,
      },
      date: {
        Header: t('agent.table.myLastBookingsTable.headers.date'),
      },
      status: {
        Header: t('agent.table.myLastBookingsTable.headers.status'),
        width: 100,
      },
      passenger: {
        Header: t('agent.table.myLastBookingsTable.headers.passenger'),
      },
    };
  }, []);

  return (
    <HomeAgentTableBookingsView
      data={tableDataState}
      columns={columns}
      columnsOptions={columnsOptions}
      limitState={limitState}
      onChangeDisplay={(event: React.ChangeEvent<HTMLInputElement>) => setLimitState(+event.target.value)}
    ></HomeAgentTableBookingsView>
  );
};

HomeAgentTableBookings.displayName = 'HomeAgentTableBookings';
HomeAgentTableBookings.defaultProps = {};

export default HomeAgentTableBookings;
