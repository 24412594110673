import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

const useDebounceState = <S>(
  initialState: S | (() => S),
  debounceTime: number,
): [state: S, debouncedState: S, setState: React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initialState);
  const [debouncedState, setDebouncedState] = useState<S>(initialState);

  const setTimer = useCallback(
    _.debounce((callback: () => void) => callback(), debounceTime),
    [debounceTime],
  );

  useEffect(() => {
    setTimer(() => {
      setDebouncedState(() => state);
    });

    return () => {
      setTimer.cancel();
    };
  }, [state, setTimer]);

  return [state, debouncedState, setState];
};

export default useDebounceState;
