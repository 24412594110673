import React from 'react';
import { BaseHistoryData } from 'models/history';

import classes from './HistoryTableDataCol.module.scss';
import { IMHistoryTableData } from '../MHistory.model';

interface Props<T extends BaseHistoryData> {
  data: IMHistoryTableData<T>;
}

const HistoryTableDataColView = <T extends BaseHistoryData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return (
    <React.Fragment>
      {Object.entries(props.data.data || {}).map(([key, value]) => (
        <div
          title={(JSON.stringify(props.data.prevData[key]) || '') + ' ' + JSON.stringify(value)}
          className={classes['dataRow']}
          key={key}
        >
          {`${key}: `}
          {props.data.prevData[key] ? (
            <span className={classes['prevValue']}>{JSON.stringify(props.data.prevData[key])}</span>
          ) : null}{' '}
          {JSON.stringify(value)}
        </div>
      ))}
    </React.Fragment>
  );
};

HistoryTableDataColView.displayName = 'HistoryTableDataColView';
HistoryTableDataColView.defaultProps = {};

export default HistoryTableDataColView;
