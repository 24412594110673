import L from 'leaflet';
import 'leaflet-editable';
import 'leaflet.path.drag';
import React from 'react';
import { MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { AppState } from 'models/app-store';

import { CSSObjectWithLabel, OnChangeValue } from 'react-select';
import MAddress from 'views/MAddress/MAddress';
import { MAddressSelectOption } from 'views/MAddress/MAddress.model';
import { IMMapMarker, IMMapPolyline, MMapRoute } from './MMap.model';
import classes from './MMap.module.scss';
import MMapRouting from './MMapRouting/MMapRouting';
import { useTranslation } from 'react-i18next';

interface Props<Clusters extends boolean> {
  zoom?: number;
  scrollWheelZoom?: boolean;
  className?: string;
  editable?: boolean;
  markers?: IMMapMarker<Clusters>[];
  polylines?: IMMapPolyline[];
  routes?: MMapRoute[];
  style?: React.CSSProperties;
  zoomControl?: boolean;
  searchValue: string;
  onSearch?: (address?: string) => void;
  clusters?: Clusters;
  onCreate: (map: L.Map) => void;
}

const MMapView = <Clusters extends boolean>(props: React.PropsWithChildren<Props<Clusters>>): JSX.Element => {
  const mapCenter = useSelector((state: AppState) => state.settings.mapCenter);
  const mapZoom = useSelector((state: AppState) => state.settings.mapZoom.zoom);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.onSearch && (
        <div className={classes['topBar']}>
          <div className={classes['searchContainer']}>
            <MAddress
              name="mmap-search-input"
              withLocations
              disabled={false}
              placeholder={t('components.map.search.title')}
              customStylesSelect={{
                input(provided: CSSObjectWithLabel) {
                  return {
                    ...provided,
                    color: '#000',
                    maxWidth: '100%',
                  };
                },
                singleValue(provided: CSSObjectWithLabel) {
                  return {
                    ...provided,
                    color: '#000',
                  };
                },
                control(provided: CSSObjectWithLabel) {
                  return {
                    ...provided,
                    border: '1px solid #9b9b9b',
                    backdropFilter: 'blur(2px)',
                    background: 'rgba(255, 255, 255, 0.5)',
                    boxShadow: '3px 3px 5px #0000004b',
                  };
                },
                placeholder(provided: CSSObjectWithLabel) {
                  return {
                    ...provided,
                    color: '#000',
                    display: 'inline-block',
                  };
                },
              }}
              onChange={(value: OnChangeValue<MAddressSelectOption, false>) => props.onSearch?.(value?.value)}
            ></MAddress>
          </div>
        </div>
      )}

      <MapContainer
        style={{ ...props.style, zIndex: 0 }}
        whenCreated={props.onCreate}
        className={props.className}
        center={mapCenter}
        zoom={props.zoom ?? mapZoom}
        scrollWheelZoom={props.scrollWheelZoom ?? true}
        editable={props.editable}
      >
        <TileLayer url={process.env.REACT_APP_TILES_SERVER}></TileLayer>

        {!props.clusters
          ? props.markers?.map((marker: IMMapMarker<Clusters>, index: number) => {
            return (
              <Marker
                draggable={marker.draggable}
                key={index}
                icon={marker.icon}
                position={[marker.coord.lat, marker.coord.lng]}
                eventHandlers={marker.eventHandlers}
              >
                {marker.popup ? <Popup>{marker.popup}</Popup> : null}
              </Marker>
            );
          })
          : null}
        {props.routes?.map((route: MMapRoute) => (
          <MMapRouting
            key={JSON.stringify(route.routePoints)}
            points={route.routePoints}
            color={route.color}
            weight={route.weight}
          ></MMapRouting>
        )) || null}
        {props.polylines?.map((polyline: IMMapPolyline) => {
          return <Polyline key={polyline.id} weight={1} positions={[polyline.from, polyline.to]} color={polyline.color}></Polyline>;
        })}
      </MapContainer>
    </React.Fragment>
  );
};

MMapView.displayName = 'MMapView';
MMapView.defaultProps = {};

export default MMapView;
