import React from 'react';

import MSearchInputView from './MSearchInput.view';

interface Props {
  value?: string;
  placeholder?: string;
  size?: 's' | 'm' | 'l';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MSearchInput: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MSearchInputView value={props.value} size={props.size} placeholder={props.placeholder} onChange={props.onChange}>
      {props.children}
    </MSearchInputView>
  );
};

MSearchInput.displayName = 'MSearchInput';
MSearchInput.defaultProps = {};

export default MSearchInput;
