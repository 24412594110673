import React from 'react';

import MInfoView from './MInfo.view';

interface Props {
  txt?: string;
}

const MInfo: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <MInfoView txt={props.txt}></MInfoView>;
};

MInfo.displayName = 'MInfo';
MInfo.defaultProps = {};

export default MInfo;
