import { ITripsTableData } from 'pages/Trips/TripsTable/TripsTable.model';

export enum TripsActions {
  SetSeleceted = '[Trips] Set Selected',
  SetOnQueueRemove = '[Trips] Set On Queue Remove',
  SetOnQueueAssign = '[Trips] Set On Queue Assign',
  SetUserAssign = '[Trips] Set User Assign',
}

// Interface

export interface SetSeleceted {
  type: TripsActions.SetSeleceted;
  payload: { trips: ITripsTableData[] };
}

export interface SetOnQueueRemove {
  type: TripsActions.SetOnQueueRemove;
  payload: { callback: ((jobIds: string[]) => void) | null };
}

export interface SetOnQueueAssign {
  type: TripsActions.SetOnQueueAssign;
  payload: { callback: ((jobIds: string[]) => void) | null };
}

// Action creators

export const setSelected = (trips: ITripsTableData[]): SetSeleceted => {
  return {
    type: TripsActions.SetSeleceted,
    payload: { trips },
  };
};

export const SetOnQueueRemove = (callback: ((jobIds: string[]) => void) | null): SetOnQueueRemove => {
  return {
    type: TripsActions.SetOnQueueRemove,
    payload: { callback },
  };
};

export const SetOnQueueAssign = (callback: ((jobIds: string[]) => void) | null): SetOnQueueAssign => {
  return {
    type: TripsActions.SetOnQueueAssign,
    payload: { callback },
  };
};

export type TripsTypes = SetSeleceted | SetOnQueueRemove | SetOnQueueAssign;
