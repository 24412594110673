import React from 'react';
import CIcon from '@coreui/icons-react';

import classes from './MDateNavigator.module.scss';
import { MDateNavigatorDirection } from './MDateNavigator.model';
import MButton from 'views/Buttons/MButton/MButton';

interface Props {
  className?: string;
  onClick?: (direction: MDateNavigatorDirection) => void;
}

const MDateNavigatorView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={props.className}>
      <MButton className={classes['navigator']} onClick={() => props.onClick?.(MDateNavigatorDirection.Backword)}>
        <CIcon size="lg" name="cilArrowThickLeft"></CIcon>
      </MButton>
      <MButton className={classes['navigator']} onClick={() => props.onClick?.(MDateNavigatorDirection.Now)}>
        <CIcon size="lg" name="cilCalendar"></CIcon>
      </MButton>
      <MButton className={classes['navigator']} onClick={() => props.onClick?.(MDateNavigatorDirection.Forward)}>
        <CIcon size="lg" name="cilArrowThickRight"></CIcon>
      </MButton>
    </div>
  );
};

MDateNavigatorView.displayName = 'MDateNavigatorView';
MDateNavigatorView.defaultProps = {};

export default MDateNavigatorView;
