import { AxiosResponse, CancelTokenSource } from 'axios';

import { serverAxios } from '../../http';
import { IRateType } from 'models/rate';
import { IGetRateTypeResponse } from 'models/response';
import { IGetRateTypeFilters } from 'models/http/rateType';
import { HttpTimeoutPriority } from 'utils/enums/http-timeout-priority';

class RateTyperHttp {
  private static route = '/';

  public async get(filters: IGetRateTypeFilters, cancelTokenSource?: CancelTokenSource): Promise<IRateType[]> {
    return serverAxios
      .post(RateTyperHttp.route, {
        act: 'find',
        col: 'ratetypes',
        query: filters,
      },
      {
        cancelToken: cancelTokenSource?.token,
        timeout: HttpTimeoutPriority.Medium,
      })
      .then((response: AxiosResponse<IGetRateTypeResponse>) => {
        return response.data.data;
      });
  }
}

export default RateTyperHttp;
