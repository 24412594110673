const services = {
  toast: {
    error: {
      badResponse: '[Serviço] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {
        body: 'O serviço foi adicionado com sucesso',
      },
      edit: {},
    },
  },
  headerButtons: {
    create: 'Novo',
    delete: 'Remover',
  },
  table: {
    headers: {
      maxPassengers: 'Máximo de passageiros',
      sharedWith: 'Compartilhado com',
    },
  },
  create: {
    errorToast: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar serviço',
    },
    successToast: {
      header: 'Resultado',
      body: 'O serviço foi adicionado com sucesso',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar serviço',
    },
    successModal: {
      header: 'Resultado',
      body: 'Serviço criado!',
    },
  },
  edit: {
    errorToast: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar serviço',
    },
    successToast: {
      header: 'Resultado',
      body: 'O serviço foi atualizado com sucesso',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar o serviço',
    },
    successModal: {
      header: 'Resultado',
      body: 'Serviço Atualizado!',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Detalhes',
        fields: {
          name: 'Nome',
          type: 'Tipo',
          maxPassengers: 'Máximo de passageiros',
          shared: 'Compartilhado com',
        },
      },
      actions: {
        header: 'Ações',
        buttons: {
          delete: 'Remover',
          resetPassword: 'Redefinir Senha',
        },
      },
    },
  },
};

export default services;
