import React from 'react';
import { TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps } from 'react-table';
import MCheckBox from 'views/MCheckBox/MCheckBox';

import classes from './TableCheckbox.module.scss';

type Props = (TableToggleAllRowsSelectedProps | TableToggleRowsSelectedProps) & {
  disabled?: boolean;
};

const TableCheckbox: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MCheckBox
      checked={props.checked}
      style={props.style}
      title={props.title}
      className={classes['border']}
      disabled={props.disabled}
      onChange={props.onChange}
    ></MCheckBox>
  );
};

TableCheckbox.displayName = 'TableCheckbox';

export default TableCheckbox;
