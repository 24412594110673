import React from 'react';
import { TFunctionResult } from 'i18next';

import MCard from 'views/MCard/MCard';
import MCardBody from 'views/MCard/MCardBody/MCardBody';
import MCardHeader from 'views/MCard/MCardHeader/MCardHeader';

import classes from './MFormCard.module.scss';
import MButton from 'views/Buttons/MButton/MButton';

interface Props {
  className?: string;
  header?: string | React.ReactChild | TFunctionResult;
  bodyClassName?: string;
  showToggler?: boolean;
  showBody: boolean;
  headerClassName?: string;
  onToggleClick: () => void;
}

const MFormCardView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MCard className={props.className}>
      {props.header ? (
        <MCardHeader className={`${classes['header']} ${props.headerClassName}`}>
          {props.header}
          {props.showToggler && (
            <MButton type="button" onClick={props.onToggleClick}>
              +
            </MButton>
          )}
        </MCardHeader>
      ) : null}
      {props.showBody && <MCardBody className={props.bodyClassName}>{props.children}</MCardBody>}
    </MCard>
  );
};

MFormCardView.displayName = 'MFormCardView';
MFormCardView.defaultProps = {};

export default MFormCardView;
