const billReport = {
  actions: {
    export: 'Exportar',
  },
  table: {
    headers: {
      tripId: 'ID de viagem',
      reservationId: 'Identificador de reserva',
      status: 'Status da reserva',
      supplier: 'Fornecedor',
      profile: 'Perfil',
      area: 'Área',
      date: 'Data',
      week: 'Dia',
      time: 'Recolhimento planejado',
      dropOff: 'Descarte planejado',
      line: 'Linha',
      from: 'De',
      fromLat: 'De Lat',
      fromLon: 'De Longa',
      to: 'Para',
      routingType: 'Tipo de Linha',
      externalId: 'Identificador Externo',
      passenger: 'Passageiro',
      phone: 'Telefone',
      toLat: 'Para Lat',
      toLon: 'ToLon',
      email: 'E-mail',
      source: 'Fonte',
      tripSeats: 'Assentos de viagem',
      dist: 'Distância real',
      vehicle: 'Veículo',
      pickedTime: 'Tempo Real de Recolhimento',
      droppedTime: 'Tempo Real de Devolução',
      paymentType: 'Tipo de pagamento',
      paymentDetails: 'Detalhes de pagamento',
      shuttleArea: 'Área',
      direction: 'Direção',
      payAmount: 'Quantidade',
      driver: 'Motorista',
      timeIndex: 'Índice de tempo',
      createdBy: 'Criado por',
      delayNotification: 'Notificação de Atraso',
    },
    cells: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
    },
    filters: {
      reservationStatus: {
        options: {
          new: 'Novo',
          onhold: 'Em espera',
          waiting: 'Aguardando',
          cancelled: 'Cancelado',
          routing: 'Encaminhamento',
          routed: 'Encaminhado',
          picked: 'Escolhido',
          dropped: 'Eliminado',
          noshow: 'Não comparência',
        },
      },
      tripStatus: {
        options: {
          new: 'Novo',
          inroute: 'Em rota',
          sent: 'Enviado',
          approved: 'Aprovado',
          pickingUp: 'Recolhimento',
          done: 'OK',
          cancelled: 'Cancelado',
        },
      },
      routingType: {
        options: {
          bus: 'Autocarro',
          demand: 'Demanda',
          dynamic: 'Dinâmico',
        },
      },
    },
    footer: {
      lastUpdated: 'Última Atualização: {{lastUpdated}}',
      noLastUpdate: 'Desconhecido',
    },
  },
  tableSummary: {
    title: 'Relatórios de faturamento',
    headers: {
      trips: 'Viagens',
      reservations: 'Reservas',
      passengers: 'Passageiros',
      uniquePassengers: 'Passageiros únicos',
    },
  },
};

export default billReport;
