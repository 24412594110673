const imports = {
  mappingFields: 'Mapping Fields',
  fieldsInDB: 'Fields in DB',
  csvFields: 'CSV Field',
  selectTheField: 'Select The Field',
  csvFile: 'Csv File',
  typeOfImport: 'Type Of Import',
  selectTypeOfReports: 'Select Type Of Reports',
  default: 'Default Value',
  uploadedSuccessfuly: 'Uploaded successfuly!',
  downloadExample: 'Download example',
  noCollection: 'No collection with this name',
  uploadMessage: 'REQUEST - {{num}} items, This might take up to 1 second per item.',
};

export default imports;
