const shifts = {
  toast: {
    error: {
      badResponse: '[Rate] bad server response',
      create: {},
      edit: {},
      delete: {
        default: 'Error deleting shifts: {{error}}.',
      },
    },
    success: {
      delete: {
        default: '{{numDeleted}} shifts successfully deleted.',
      },
      create: {},
      edit: {},
    },
  },

  headerButtons: {
    create: 'New',
    delete: 'Delete',
  },

  table: {
    headers: {
      name: 'Name',
      status: 'Status',
      start: 'Start',
      end: 'End',
      year: 'Year',
      direction: 'Direction',
      weekDays: 'weekDays',
    },
    cells: {
      weekDays: {
        weekDaysOptions: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
      status: {
        statusOptions: {
          statusActive: 'Active',
          statusNotActive: 'Not Active',
        },
      },
    },
  },

  form: {
    information: {
      details: {
        header: {
          templates: {
            default: 'Shift',
            schoolShuttles: 'School hours',
            employees: 'Shift',
            trailers: 'Shift',
          },
        },
        fields: {
          name: 'Name',
          status: 'Status',
          start: 'Start',
          end: 'End',
          year: 'Year',
          direction: 'Direction',
          weekDays: 'Week Days',
        },
      },
      directionOptions: {
        selectDirection: 'Select Direction',
        collect: {
          templates: {
            default: 'Collect',
            schoolShuttles: 'To School',
            employees: 'To work',
            trailers: 'Collect',
          },
        },
        distribute: {
          templates: {
            default: 'Distribute',
            schoolShuttles: 'From School',
            employees: 'From work',
            trailers: 'Distribute',
          },
        },
        collectAndDistribute: {
          templates: {
            default: 'Collect/Distribute',
            schoolShuttles: 'To School / From School',
            employees: 'To Work / From Work',
            trailers: 'Collect/Distribute',
          },
        },
      },
    },
    validations: {
      name: {
        required: 'Name is required',
        name: '',
      },
      start: {
        required: 'Start is required',
        start: '',
      },
      end: {
        required: 'End is required',
        end: '',
      },
      year: {
        required: 'Year is required',
        year: '',
      },
      direction: {
        required: 'Direction is required',
        direction: '',
      },
      weekDays: {
        required: 'You must select at least one day',
        weekDays: '',
      },
    },
  },

  delete: {
    confirmation: {
      header: 'Delete shifts confirmation',
      body: {
        dynamic: 'You are about to delete {{numShifts}} shifts.',
        all: 'You are about to delete all shifts.',
      },
    },
  },
};

export default shifts;
