import React from 'react';
import { OnChangeValue } from 'react-select';

import MServerSelect from 'views/MServerSelect/MServerSelect';

import { ServerTableNames } from 'utils/enums/table-page';
import { MSelectOption } from 'views/MSelect/MSelect.model';
import { TableRequestIdentifiersKeys } from 'views/MServerSelect/MServerSelect.model';
import { BaseQuery } from 'views/MTable/Table.model';

interface Props<IsMulti extends boolean, Table extends ServerTableNames> {
  table: Table;
  field: TableRequestIdentifiersKeys<Table>;
  value: IsMulti extends true ? string[] : string;
  isMulti?: IsMulti;
  name: string;
  valid?: boolean;
  invalid?: boolean;
  unit?: string | null;
  staticOptions?: MSelectOption[];
  filterIds?: string[];
  baseQuery?: BaseQuery;
  isClearable?: boolean;
  placeholder?: string;
  onChange?: (props: OnChangeValue<MSelectOption, IsMulti>) => void;
}

const MFormServerSelectView = <IsMulti extends boolean, Table extends ServerTableNames>(props: Props<IsMulti, Table>): JSX.Element => {
  return (
    <MServerSelect
      table={props.table}
      field={props.field}
      value={props.value}
      isMulti={props.isMulti}
      name={props.name}
      valid={props.valid}
      invalid={props.invalid}
      staticOptions={props.staticOptions}
      onChange={props.onChange}
      unit={props.unit}
      isClearable={props.isClearable}
      filterIds={props.filterIds}
      baseQuery={props.baseQuery}
      placeholder={props.placeholder}
    ></MServerSelect>
  );
};

MFormServerSelectView.displayName = 'MFormServerSelectView';
MFormServerSelectView.defaultProps = {};

export default MFormServerSelectView;
