
import { AppState } from 'models/app-store';
import { useSelector } from 'react-redux';
import { SystemTemplate } from 'utils/enums/system-template';

const useSystemTemplate = (): SystemTemplate => {
  
  const systemTemplate: SystemTemplate = useSelector((state: AppState) => {
    return state.settings.systemTemplate;
  });

  return systemTemplate || SystemTemplate.Default;
};

export default useSystemTemplate;
