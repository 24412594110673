import React from 'react';
import { BaseHistoryData } from 'models/history';

import HistoryTableDataColView from './HistoryTableDataCol.view';
import { IMHistoryTableData } from '../MHistory.model';

interface Props<T extends BaseHistoryData> {
  data: IMHistoryTableData<T>;
}

const HistoryTableDataCol = <T extends BaseHistoryData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return <HistoryTableDataColView data={props.data}></HistoryTableDataColView>;
};

HistoryTableDataCol.displayName = 'HistoryTableDataCol';
HistoryTableDataCol.defaultProps = {};

export default HistoryTableDataCol;
