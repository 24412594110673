const areas = {
  toast: {
    error: {
      badResponse: '[Área] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {},
    },
  },
  create: {
    createBtn: 'Novo',
    successModal: {
      header: 'Resultado',
      body: 'Área criada!',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar área',
    },
    validations: {
      name: 'Um nome é requerido',
      type: 'Inserir é obrigatório',
    },
  },
  delete: {
    deleteBtn: 'Remover',
    deleteModal: {
      header: 'Excluir Áreas?',
      body: 'Você está prestes a excluir {{number}} áreas',
    },
  },
  edit: {
    successModal: {
      header: 'Resultado',
      body: 'Área atualizada!',
    },
    errorModal: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível atualizar a área',
    },
    validations: {
      name: 'Um nome é requerido',
      type: 'Inserir é obrigatório',
    },
  },
  forms: {
    information: {
      details: {
        header: 'Detalhes',
        fields: {
          showOtherAreas: 'Mostrar Outras Áreas',
          name: 'Nome',
          type: 'Tipo',
        },
      },
      map: {
        header: 'Mapa',
      },
    },
  },
};

export default areas;
