import axios, { AxiosResponse } from 'axios';

const path = process.env.NODE_ENV === 'development' ? '/api' : '';

const rootAxios = axios.create({
  baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}${path}`,
  timeout: process.env.REACT_APP_SERVER_REQUEST_TIMEOUT,
});

rootAxios.interceptors.response.use((response: AxiosResponse): AxiosResponse => response, (error) => {
  if (!axios.isCancel(error)) {
    return Promise.reject(error?.response?.data.error);
  }

  return new Promise(() => null);

  // Return this line if we want to activate "catch" callback on request cancelation
  // return Promise.reject(error);
});

export const serverAxios = rootAxios;

export const pbAxios = axios.create({
  baseURL: process.env.REACT_APP_PANDORA_BOT_URL,
});
