const tripReports = {
  tripsPaxReports: {
    tripId: 'Trip ID',
    date: 'Date',
    status: 'Status',
    statuses: 'Statuses',
    drivers: 'Drivers',
    vehicles: 'Vechicles',
    from: 'From',
    to: 'To',
    passengers: 'Passengers',
    statusDone: 'Done',
    statusNew: 'New',
    statusCanceled: 'Canceled',
    statusInRoute: 'In Route',
    average: 'Average',
    trips: 'Trips',
    tripsPassengersSummary: 'Trips/Passengers Summary',
  },
};

export default tripReports;
