import React from 'react';
import { components, ValueContainerProps } from 'react-select';

import { MReactSelectOption } from '../MSelect.model';
import classes from './MSelectValueContainer.module.scss';

interface Props<IsMulti extends boolean> extends ValueContainerProps<MReactSelectOption, IsMulti> {}

const MSelectValueContainerView = <IsMulti extends boolean>(props: React.PropsWithChildren<Props<IsMulti>>): JSX.Element => {
  return <components.ValueContainer {...props} className={classes['container']}>{props.children}</components.ValueContainer>;
};

MSelectValueContainerView.displayName = 'MSelectValueContainerView';
MSelectValueContainerView.defaultProps = {};

export default MSelectValueContainerView;
