import React, { CSSProperties } from 'react';

import classes from './MCard.module.scss';

interface Props {
  elemRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
}

const MCardView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${classes['container']} ${props.className || ''}`.trim()} style={props.style} ref={props.elemRef}>
      {props.children}
    </div>
  );
};

MCardView.displayName = 'MCardView';
MCardView.defaultProps = {};

export default MCardView;
