import React from 'react';
import { Row } from 'react-table';

import MButton from 'views/Buttons/MButton/MButton';

import { RowData } from '../Table.model';

interface Props<T extends RowData> {
  value: T;
  row: Row<T>;
}

const MTableCellExpandRowView = <T extends RowData>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  return <MButton {...props.row.getToggleRowExpandedProps()}>{props.value}</MButton>;
};

MTableCellExpandRowView.displayName = 'MTableCellExpandRowView';
MTableCellExpandRowView.defaultProps = {};

export default MTableCellExpandRowView;
