const parents = {
  tabs: {
    history: 'Histórico',
    information: 'Informação',
  },
  modal: {
    header: 'Alterar Status',
    body: 'Você tem certeza de que deseja alterar o status dos pais para',
  },
  table: {
    statuses: {
      active: 'Ativo',
      inactive: 'Inativa',
    },
    header: {
      createBtn: 'Criar',
      name: 'Nome',
      email: 'E-mail',
      phone: 'Telefone',
      status: 'Status',
      address: 'Endereço',
      createdBy: 'Criado por',
      createdAt: 'Criado em',
    },
  },
  form: {
    create: {
      toasts: {
        success: 'Principal criado com sucesso',
        error: 'Ocorreu um erro ao criar o principal',
      },
    },
    update: {
      toasts: {
        success: 'Principal atualizado com sucesso',
        error: 'Ocorreu um erro ao atualizar a principal',
        fetchError: 'Ocorreu um erro, por favor, tente novamente.',
      },
    },
    sections: {
      groups: {
        header: 'Local & Perfil',
        fields: {
          profile: {
            label: 'Perfil',
          },
        },
      },
      personalDetails: {
        header: 'Detalhes pessoais',
        fields: {
          firstName: {
            label: 'Primeira nome',
          },
          lastName: {
            label: 'Sobrenome',
          },
          email: {
            label: 'E-mail',
          },
          phone: {
            label: 'Telefone',
          },
          additionalPhone: {
            label: 'Telefone adicional',
          },
          address: {
            label: 'Endereço',
          },
        },
      },
      linkStudent: {
        header: 'Alunos',
        btnAddStudent: 'Adicione aluno',
        btnRemoveStudent: 'Remover aluno',
        searchPlaceholder: 'Buscar',
      },
    },
  },
  studentsTable: {
    headers: {
      name: 'Nome',
      address: 'Endereço',
      phone: 'Telefone',
      email: 'E-mail',
    },
  },
};

export default parents;
