const shuttles = {
  toast: {
    error: {
      badResponse: '[Shuttle] bad server response',
      create: {},
      edit: {},
    },
    success: {
      create: {},
      edit: {
        default: '[Shuttle] updated successful',
      },
      delete: {
        default: '[Shuttle] deleted successful',
      },
    },
    duplicate: '[Shuttle] duplicated',
  },

  createNew: 'New',
  deleteBtn: 'Delete',

  deleteModal: {
    header: 'Delete Shuttles',
    body: 'You are about to delete {{num}} shuttles.',
  },

  duplicateModal: {
    header: 'Duplicate Shuttle:  {{name}}',
    placeholder: 'Enter shuttle code',
    fields: { duplicateShuttleCode: 'Shuttle Code:' },
    footer: {
      title: 'You are about to duplicate this shuttle',
      button: 'Confirm',
    },
  },
  table: {
    headers: {
      code: 'Code',
      line: 'Line',
      direction: 'Direction',
      weekdays: 'Weekdays',
      supplier: 'Operated By',
      name: 'Name',
      pax: 'Pax',
    },
    notAvailable: 'Not Available',
  },

  update: {
    submit: {
      modal: {
        driversChanged: {
          header: 'Changes approval',
          body: 'Drivers selection changes have been identified. Approving the action might result in driver assignment changes.',
        },
      },
    },
  },

  form: {
    info: {
      details: {
        header: {
          create: 'Create Shuttle',
          edit: 'Edit Shuttle',
          information: 'Shuttle Information',
        },
        shuttleBy: {
          flexibleTime: 'Flexible Time',
          fixedTime: 'Fixed Time',
        },
        fields: {
          unit: 'Site',
          supplier: 'Operated By',
          hour1: 'From',
          hour2: 'To',
          shuttleBy: 'Shuttle By',
          code: 'Code',
          name: 'Name',
          area: 'Area',
          line: 'Line',
          profile: 'Profile',
          direction: 'Direction',
          drivers: 'Drivers',
          allDrivers: 'All',
          pax: 'Pax',
          paymentType: 'Payment Type',
          routingType: 'Routing Type',
        },
        paymentTypeOptions: {
          free: 'Free',
          opusCard: 'OPUS Card',
          cashOnBoard: 'Cash On Board',
          correspondance: 'Correspondance',
          opusCardMensuel: 'Carte OPUS - Mensuel',
          opusCardPassage: 'Carte OPUS - Passage',
          cpoPassageSTL: 'Carte a puce occasionnelle (CPO-STL) - Passage',
          cpoPassageEXO: 'Carte a puce occasionnelle (CPO-EXO) - Passage',
          freeZeroToEleven: 'Gratuit (0-11 Ans)',
        },
        routingTypeOptions: {
          dynamic: 'Dynamic',
          demand: 'Demand',
          bus: 'Bus',
          exo: 'Exo',
        },
      },
      times: {
        header: 'Time',
        fields: {
          hour: 'Hour',
          weekDays: 'Week Days',
          limit: 'Limit',
          busses: 'Busses',
          holidays: 'Holidays',
          drivers: 'Drivers',
        },
        btnAdd: 'Add Time',
        weekDays: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
        },
      },
      stops: {
        header: 'Stops',
        fields: {
          address: 'Address',
          name: 'Name',
          zone: 'Zone',
          save: 'Save',
        },
        btnAdd: 'Add Stop',
      },
      zone: '(Zone: {{zone}})',
    },
    validation: {
      unitRequired: 'Site is required',
      codeRequired: 'Code is required',
      nameRequired: 'Name is required',
      paxRequired: 'Pax is required',
      hourRequired: 'Hour is required',
      limitRequired: 'Limit is required',
      latRequired: 'Latitude is required',
      lonRequired: 'Longitude it required',
      duplicateShuttleCode: 'You must enter a shuttle name',
    },
    weekDays: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
  },

  removeStopModal: {
    body: 'Are you sure you want to remove this stop?',
  },

  removeTimeModal: {
    body: 'Are you sure you want to remove this timestamp?',
  },
};

export default shuttles;
