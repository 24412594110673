const students = {
  createNew: 'Novo',
  toast: {
    error: {
      badResponse: '[Alunos] resposta inválida do servidor',
      create: {},
      edit: {},
    },
    success: {
      resetSuccess: 'O e-mail de Redefinição de Senha foi enviado com sucesso.',
      create: {
        default: '[Aluno] Criado',
      },
      edit: {
        default: '[Aluno] Atualizado',
      },
    },
  },
  create: {
    modalAleadyExist: {
      header: 'Ocorreu um Erro',
      body: 'O aluno já existe!',
    },
    modalErrorRequest: {
      header: 'Ocorreu um erro',
      body: 'Não foi possível criar aluno!',
    },
  },
  edit: {
    modalRemoveConfirm: {
      header: 'Aviso!',
      body: 'Você tem certeza que deseja excluir este aluno?',
      modalRemoveConfirmBtnConfirm: 'Remover',
    },
    formTabs: {
      informationTab: 'Informações Gerais',
      paymentTab: 'Pagamentos',
      notesTab: 'Observações',
      historyTab: 'Histórico',
    },
    activeStatus: {
      statusActive: 'Ativo',
      statusDeactivate: 'Inativa',
    },
    suspend: {
      actionActivate: 'Reativar',
      actionDeactivate: 'Compensar',
      suspendModal: {
        popupTitleDeactivation: 'Suspender Aluno',
        popupTitleActive: 'Motivo da Suspensão',
        popupTitleUnsuspend: 'Motivo do Recolhimento da Suspensão',
        popupSubTitleDeactivation: 'Selecione o motivo da suspensão',
        popupActionActive: 'Reativar Aluno',
        popupActionDeactivate: 'Suspender Aluno',
      },
    },
  },
  table: {
    headers: {
      id: 'Identificação',
      name: 'Nome',
      email: 'E-mail',
      created: 'Criado em',
      homeAddress: 'Endereço de casa',
      alternateAddress: 'Endereço Alternativo',
      phone: 'Telefone',
      officeAddress: 'Escola',
      class: 'Aula',
      school: 'Escola',
      passenger: {
        templates: {
          default: 'Passageiro',
          schoolShuttles: 'Aluno',
          trailers: 'Passageiro',
        },
      },
    },
    StudentsGettExtraHeaders: {
      home: 'Home',
      home2: 'Home2',
      work: 'Trabalho',
      site: 'Local',
    },
    empty: 'Vazio',
  },
  parentsTable: {
    headers: {
      name: 'Nome',
      email: 'E-mail',
      address: 'Endereço',
      phone: 'Telefone',
    },
  },
  forms: {
    information: {
      profiles: {
        header: 'Perfis',
        fields: {
          profile: 'Perfis',
          site: 'Local',
        },
      },
      parentManagement: {
        buttonRemove: 'Remover os Pais',
        buttonAdd: 'Adicione Pais',
      },
      parents: {
        header: 'Pais',
      },
      details: {
        header: 'Detalhes pessoais',
        fields: {
          firstName: 'Primeira Nome',
          lastName: 'Sobrenome',
          email: 'E-mail',
          phone: 'Telefone',
          additionalPhone: 'Telefone Adicional',
          dateOfBirth: 'Data de nascimento',
          gender: {
            header: 'Sexo',
            select: '',
            male: 'Masculino',
            female: 'Feminino',
          },
        },
      },
      address: {
        header: 'Endereço do aluno',
        sharedValues: {
          manual: 'Endereço do manual',
        },
        fields: {
          mainAddress: {
            label: 'Morada principal',
          },
          alternateAddress: {
            label: 'Endereço Alternativo',
          },
        },
      },
      actions: {
        header: 'Ações',
        buttons: {
          delete: 'Remover',
          resetPassword: 'Redefinir Senha',
        },
      },
      school: {
        header: 'Detalhes da Escola',
        fields: {
          school: 'Escola',
          AcademicYear: {
            header: 'Ano lectivo',
            select: '',
            current: '2022-2023',
          },
          level: 'Nível',
          class: 'Aula',
        },
      },
      linkedParents: {
        searchPlaceholder: 'Procurar Pais',
      },
    },
    jobHistory: {
      header: 'Histórico de reservas',
      lastJob: 'Última reserva: {{lastJob}}',
      firstJob: 'Primeira Reserva: {{firstJob}}',
    },
    validations: {
      firstName: 'Primeiro Nome é obrigatório',
      lastName: 'Sobrenome é obrigatório',
      profiles: 'Perfis é obrigatório',
      email: {
        required: 'E-mail é obrigatório',
        email: 'Endereço de e-mail inválido',
      },
      unit: {
        required: 'O local é obrigatório',
      },
    },
  },
  parentTable: {
    headers: {
      name: 'Nome',
      address: 'Endereço',
      phone: 'Telefone',
      email: 'E-mail',
    },
  },
};

export default students;
