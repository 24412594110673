export type ISite = {
  _id?: string;
  name: string;
  operation?: OperationTypes;
};

export enum OperationTypes {
  Default = 'Default',
  School = 'School',
  Employees = 'Employees',
}
