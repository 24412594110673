import React from 'react';

import MFormInputView from './MFormInput.view';

import { MFormFieldProps } from 'views/MForm/MForm.model';

interface Props extends MFormFieldProps<[React.ChangeEvent<HTMLInputElement>], string | number> {
  type?: string;
  disabled?: boolean;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  placeholder?: string;
  autoComplete?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => string;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const MFormInput: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <MFormInputView
      name={props.name}
      valid={props.valid}
      invalid={props.invalid}
      disabled={props.disabled}
      value={props.value}
      type={props.type}
      className={props.className}
      min={props.min}
      max={props.max}
      step={props.step}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      onInput={props.onInput}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={props.onChange}
    ></MFormInputView>
  );
};

MFormInput.displayName = 'MFormInput';
MFormInput.defaultProps = {};

export default MFormInput;
