const layout = {
  header: {
    settings: {
      sites: 'Sites',
      systemSettings: 'System Settings',
      blacklist: 'Card Blacklist',
      services: 'Services',
      locations: 'Locations',
      areas: 'Areas',
      rates: 'Rates',
      fares: 'Fares',
      rateTypes: 'Rates Type',
      extraServices: 'Extra Services',
      suppliers: 'Suppliers',
      users: 'Users',
      profiles: 'Profiles',
      history: 'History',
      shifts: {
        templates: {
          default: 'Shifts',
          schoolShuttles: 'School Hours',
          employees: 'Work Hours',
          trailers: 'Shifts',
        },
      },
      uploadData: 'Upload Data',
      test: 'Test',
      settings: 'Settings',
      permissions: 'Permissions',
      driverShifts: 'Driver Shifts',
      attributes: 'Attributes',
      holidays: 'Holidays',
      assignPriority: 'Assign Priority',
    },
  },

  sideBar: {
    home: 'Home',
    timeline: 'Timeline',
    jobs: {
      templates: {
        default: 'Reservations',
        schoolShuttles: 'Bookings',
        employees: 'Bookings',
        trailers: 'Orders',
      },
    },
    trips: 'Trips',
    drivers: 'Drivers',
    onlineDrivers: 'Online Drivers',
    shuttles: 'Shuttles',
    passengers: 'Passengers',
    parents: 'Parents',
    vehicles: {
      templates: {
        default: 'Vehicles',
        trailers: 'Trailers',
        schoolShuttles: 'Vehicles',
        employees: 'Vehicles', 
      },
    },
    liveMap: 'Live Map',
    roadBlocks: 'Road Blocks',
    driverShifts: 'Driver Shifts',
    analytics: 'Analytics',
    reports: 'Reports',
    viewUnit: 'View Unit',
    students: 'Students',
    employees: 'Employees',
    logout: 'Logout',
  },

  footer: {
    poweredBy: 'Powered by',
    moovex: 'Moovex',
  },

  asideQueue: {
    headerQueue: 'Queue',
    empty: 'No Queued Reservations Found',
    btnRemove: 'Remove from queue',
    btnAssign: 'Move to trip',
  },
};


export default layout;